import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import {useGetMetadata} from "shared-react/api/MetadataApi";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import OrderForm from 'shared-react/shop/OrderForm';
import OrderForm2 from "shared-react/shop/OrderForm2";
import {
    NAME_EDIT_ORDER,
    NAME_NEW_ORDER, removeOrderProduct, saveOrder,
    setOrderCustomDeliveryPrice, setOrderSellerId, setOrderCustomerId,
    setOrderDataField,
    updateOrderProduct
} from "shared-react/ducks/order";
import {useSaveOrder, API_CALCULATE_ORDER, API_ORDERS} from "shared-react/api/OrderApi";
import {getSaleOrderPath} from 'shared-react/utils/routes';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import ProgressButton from 'shared-react/utils/ProgressButton';
import LinkBack from 'utils/LinkBack';
import PageTitle from "utils/PageTitle";
import ResponseErrors from "shared-react/utils/ResponseErrors";

function SaleOrderEditPage({
                               setOriginalOrder,
                               history,
                               isCalculating,
                               editOrderData, editOrder,
                               updateOrderProduct,
                               removeOrderProduct,
                               setOrderSellerId,
                               setOrderCustomerId,
                               setOrderDataField,
                               setOrderCustomDeliveryPrice,
                               editOrderUpdateUuid
}) {
    const [savedOrder, setSavedOrder, isSubmitting, orderErrors, saveOrder] = useSaveOrder(API_ORDERS, editOrderData, false);
    const [metadata, isLoading, errors, fetchMetadata] = useGetMetadata(['allUserCustomers', 'userSellers', 'userDeliveryMethods', 'userPaymentMethods', 'warehouses', 'availablePayseraPayments', 'availableOmnivaMachines', 'userWarehouses']);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (savedOrder) {
            setOriginalOrder(savedOrder);
            setSavedOrder(null);
            history.push(getSaleOrderPath(savedOrder.id));
        }
    }, [savedOrder]);

    // useEffect(() => {
    //     //todo[as]: calculate order action to get amounts
    // }, []);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    let submitMyForm = null;

    const handleSubmitMyForm = (e) => {
        if (submitMyForm) {
            submitMyForm(e);
        }
    };

    const bindSubmitForm = (submitForm) => {
        submitMyForm = submitForm;
    };

    const onSubmitOrderForm = (values) => {
        saveOrder();
    };

    // OrderData
    // protected $customerId;
    // protected $deliveryMethodId;
    // protected $customDeliveryPrice;

    // FulfillmentData
    // protected $id;
    // protected $warehouseId;
    // protected $orderProducts = array();

    // OrderProductData
    // protected $id;
    // protected $warehouseId;
    // protected $productId;
    // protected $quantity;
    // protected $customPrice;
    // protected $customPriceReason;


    // 1. transform order into updatableOrder, like checkout
    //const orderData = buildOrderDataFromOrder(order);

    // const initialValues = {
    //     deliveryMethodId: deliveryMethodId,
    //     customDeliveryPrice: customDeliveryPrice,
    // };

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 48,
                    padding: '0 16px',
                }}>
                    <LinkBack to={getSaleOrderPath(editOrder.id)} text={"Atgal"}/>
                    <div style={{display: 'flex'}}>
                        <PageTitle title={"#" + editOrder.id + " redagavimas"}/>
                    </div>
                    <ResponseErrors errors={orderErrors} />
                    <span style={{
                        flex: 1,
                    }} />
                    <ProgressButton
                        loading={isCalculating || isSubmitting}
                        text={"Išsaugoti"}
                        onClick={(e) => {
                            console.log('on click');
                            handleSubmitMyForm(e);
                        }}
                        color="secondary"
                    />
                </div>
                </>
            }>
                <OrderForm2
                    onSubmitOrderForm={onSubmitOrderForm}
                    orderData={editOrderData}
                    order={editOrder}
                    orderUuid={editOrderUpdateUuid}
                    isUpdating={isCalculating}
                    isSubmitting={isSubmitting}
                    showAddProducts={true}
                    setOrderDataField={setOrderDataField}
                    setOrderCustomDeliveryPrice={setOrderCustomDeliveryPrice}
                    setOrderSellerId={setOrderSellerId}
                    setOrderCustomerId={setOrderCustomerId}
                    updateOrderProduct={updateOrderProduct}
                    removeOrderProduct={removeOrderProduct}
                    metadataUserSellers={metadata.userSellers}
                    metadataCustomers={metadata.allUserCustomers}
                    metadata={metadata}
                    responseErrors={orderErrors}
                    bindSubmitForm={bindSubmitForm}
                    metadataUserDeliveryMethods={metadata.userDeliveryMethods}
                    metadataUserPaymentMethods={metadata.userPaymentMethods}
                    availablePayseraPayments={metadata.availablePayseraPayments}
                    availableOmnivaMachines={metadata.availableOmnivaMachines}
                />
            </FillHeightContainer>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    isCalculating: state.editOrder.isCalculating,
    editOrderData: state.editOrder.orderData,
    editOrder: state.editOrder.order,
    editOrderUpdateUuid: state.editOrder.updateUuid,
});

const mapDispatchToProps = (dispatch) => ({
    updateOrderProduct: (fulfillmentIndex, productId, warehouseId, quantity, customPrice, customPriceReason) => {
        dispatch(updateOrderProduct(NAME_EDIT_ORDER, API_CALCULATE_ORDER, fulfillmentIndex, productId, warehouseId, quantity, customPrice, customPriceReason));
    },
    removeOrderProduct: (fulfillmentIndex, productId, warehouseId) => {
        dispatch(removeOrderProduct(NAME_EDIT_ORDER, API_CALCULATE_ORDER, fulfillmentIndex, productId, warehouseId));
    },
    setOrderSellerId: (sellerId) => {
        dispatch(setOrderSellerId(NAME_EDIT_ORDER, API_CALCULATE_ORDER, sellerId));
    },
    setOrderCustomerId: (customerId) => {
        dispatch(setOrderCustomerId(NAME_EDIT_ORDER, API_CALCULATE_ORDER, customerId));
    },
    saveOrder: () => {
        dispatch(saveOrder(NAME_EDIT_ORDER, API_ORDERS));
    },
    setOrderCustomDeliveryPrice: (customDeliveryPrice) => {
        dispatch(setOrderCustomDeliveryPrice(NAME_EDIT_ORDER, API_CALCULATE_ORDER, customDeliveryPrice));
    },
    setOrderDataField: (fieldName, fieldValue, shouldRecalculate = false) => {
        dispatch(setOrderDataField(NAME_EDIT_ORDER, API_CALCULATE_ORDER, fieldName, fieldValue, shouldRecalculate));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaleOrderEditPage);