import React from 'react';

import PurchaseOrderForm from 'purchaseOrders/PurchaseOrderForm'
import PageTitle from "utils/PageTitle";
import LinkBack from "../utils/LinkBack";
import {getPurchaseOrdersPath} from 'shared-react/utils/routes';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

function ProductCategoryAddPage() {
    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 54,
                    padding: '0 16px',
                }}>
                    <LinkBack to={getPurchaseOrdersPath()} text={"Atgal"}/>
                    <PageTitle title={"Naujas užsakymas"} gutterBottom/>
                </div>
                </>
            }>
                <div style={{padding: 12}}>
                    <PurchaseOrderForm
                        isNew={true}
                        purchaseOrder={{}}
                        onSaveSuccess={() => {}}
                    />
                </div>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default ProductCategoryAddPage;