import React, {useState} from 'react';
//import styled from "styled-components";
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import {MaterialTextField} from "../formik/MaterialFields";
import useDebouncedCallback from '../useDebounce/callback';

// const StyledNoMarginMaterialTextField = styled(MaterialTextField)`
//   && {
//     margin: 0;
//     width: 70px;
//
//     .input {
//       padding-top: 8px;
//       padding-bottom: 8px;
//     }
//   }
// `;

const NoMarginMaterialTextField = styled(MaterialTextField)({
    width: '71px',
    margin: 1,
});


const useStyles = makeStyles(theme => ({
    textField: {
        width: '72px',
        margin: 0,
    }
}));

function isNormalInteger(str) {
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

function CheckoutPageAmountChanger({handleChange, initialValue, fulfillmentIndex, productId, warehouseId, updateOrderProduct}) {
    const [value, setValue] = useState(initialValue);
    //const classes = useStyles();

    const [debouncedUpdateOrderProduct] = useDebouncedCallback(
        (fulfillmentIndex, productId, warehouseId, value) => {
            updateOrderProduct(fulfillmentIndex, productId, warehouseId, value);
        },
        500,
        []
    );

    return (
        <React.Fragment>
            <MaterialTextField
                //className={classes.textField}
                value={value}
                onChange={(e) => {
                    let value = e.target.value;
                    if (!isNormalInteger(value)) {
                        value = 1;
                    }

                    setValue(value);

                    if (typeof updateOrderProduct === 'function') {
                        //updateOrderProduct(productId, warehouseId, value);
                        debouncedUpdateOrderProduct(fulfillmentIndex, productId, warehouseId, value);
                    }
                }}
                margin="dense"
                type="number"
                inputProps={{ min: "1", step: "1", classes: {input: 'input'} }}
            />
        </React.Fragment>
    );
}



export default CheckoutPageAmountChanger;