import React from 'react';

import ShippingIcon from '@material-ui/icons/LocalShipping';

import StatusChip from 'shared-react/shop/statusChips/StatusChip';

import {
    DELIVERY_METHOD_PANEVEZYS,
    DELIVERY_METHOD_RYGA,
    getLabelByDeliveryCodeAndStatus,
} from 'shared-react/utils/paymentAndDelivery';

function StatusChipFulfillment({small = false, label, status = 0, fixedWidth = 130}) {
    return (
        <StatusChip icon={<ShippingIcon/>} small={small} status={status} label={label} fixedWidth={fixedWidth}/>
    );
}

export default StatusChipFulfillment;