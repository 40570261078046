import React, {useEffect} from 'react';

import DialogActions from '@material-ui/core/DialogActions';

import {usePaymentSetToPaid} from 'shared-react/api/SaleOrderApi';
import {getLabelByPaymentStatus} from 'shared-react/utils/paymentAndDelivery';
import ProgressButton from 'shared-react/utils/ProgressButton';
import ResponseErrors from 'shared-react/utils/ResponseErrors';
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";

function PaymentSetToPaid({ order, onPaymentUpdated }) {
    const [updatedOrder, isInProgress, errors, setToPaid] = usePaymentSetToPaid(order.id);
    // const [updatedOrder2, isInProgress2, errors2, setToNotPaid] = usePaymentSetToNotPaid(order.id);

    useEffect(() => {
        if (updatedOrder) {
            onPaymentUpdated(updatedOrder);
        }
    }, [updatedOrder]);

    // useEffect(() => {
    //     if (updatedOrder2) {
    //         onPaymentUpdated(updatedOrder2);
    //     }
    // }, [updatedOrder2]);

    return (
        <React.Fragment>
            {!order.paid ? (
                <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_EDIT}>
                    <DialogActions>
                        <ResponseErrors errors={errors} />
                        {/*<span style={{flex: 1}}/>*/}
                        <ProgressButton
                            loading={isInProgress}
                            text={'Pažymėti ' + getLabelByPaymentStatus(!order.paid)}
                            variant={order.paid ? "text" : "contained"}
                            onClick={() => {
                                if (!order.paid) {
                                    setToPaid();
                                }
                            }}
                        />
                    </DialogActions>
                </PermissionRequiredComponent>
            ) : null}
        </React.Fragment>
    );
}

export default PaymentSetToPaid;