// Actions
const PREFIX           = 'my-app/shop/';
const SET_WAREHOUSE_ID = PREFIX + 'SET_WAREHOUSE_ID';
const SET_SEARCH_TEXT  = PREFIX + 'SET_SEARCH_TEXT';
const SET_PAGE         = PREFIX + 'SET_PAGE';

const initialState = {
    searchText: '',
    warehouseId: '',
    page: 1,
    hydrated: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = { ...initialState, ...state, hydrated: true };
    }

    switch (action.type) {
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload.searchText,
                page: 1,
            };
        case SET_WAREHOUSE_ID:
            return {
                ...state,
                warehouseId: parseInt(action.payload.warehouseId, 10),
            };
        case SET_PAGE:
            return {
                ...state,
                page: parseInt(action.payload.page),
            };
        default:
            return state;
    }
}

// Action Creators
export function setSearchText(searchText) {
    return {type: SET_SEARCH_TEXT, payload: {searchText}};
}

export function setWarehouseId(warehouseId) {
    return {type: SET_WAREHOUSE_ID, payload: {warehouseId}};
}

export function setPage(page) {
    return {type: SET_PAGE, payload: {page}};
}