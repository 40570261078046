import {GridItemBlock, GridItemComponent} from './GridItem';
import {GridContainerBlock, GridContainerComponent} from './GridContainer';
import {FancyImageBlock, FancyImageComponent} from './FancyImage';
import {MaterialButtonBlock, MaterialButtonComponent} from './MaterialButton';
import {TextBlock, TextComponent} from './Text';
import {RteCommands} from './rte/RteCommands';
import {loadSpacingCss} from 'utils/grapesjs/utils';
import {setupNewTraits} from 'utils/grapesjs/traits';

function loadCss(editor) {
    editor.Config.canvasCss += `
    /* Layout */

    .gjs-dashed .grid-container {
        padding: 10px;
    }
    
    .gjs-dashed .grid-item {
        padding: 10px;
    }

    .gjs-dashed .grid-container,
    .gjs-dashed .grid-item,
    .gjs-dashed .fancy-image-container {
      min-height: 1.5rem !important;
    }
  `
}

function CustomPlugin(editor, opts = {}) {
    let c = opts;

    let defaults = {
        // CKEditor options
        options: {},

        // On which side of the element to position the toolbar
        // Available options: 'left|center|right'
        position: 'left',
    };

    // Load defaults
    for (let name in defaults) {
        if (!(name in c))
            c[name] = defaults[name];
    }

    const blockManager = editor.BlockManager;
    const domComponents = editor.DomComponents;
    const tm = editor.TraitManager;

    RteCommands(editor);
    cleanupPanels(editor);
    setupNewTraits(tm);

    GridContainerBlock(blockManager);
    GridContainerComponent(domComponents, editor);

    GridItemBlock(blockManager);
    GridItemComponent(domComponents, editor);

    TextBlock(blockManager, editor);
    TextComponent(domComponents, editor);

    FancyImageBlock(blockManager);
    FancyImageComponent(domComponents, editor);

    MaterialButtonBlock(blockManager);
    MaterialButtonComponent(domComponents, editor);

    loadCss(editor);
    loadSpacingCss(editor);

    editor.on('load', function() {
        editor.runCommand('sw-visibility');
    });
}

export default CustomPlugin;

function cleanupPanels(editor) {
    const panelManager = editor.Panels;
    panelManager.removeButton("options", 'sw-visibility');
    panelManager.removeButton("options", 'export-template');
    panelManager.removeButton("views", 'open-sm');
    panelManager.addButton('options', {
        id: 'undo',
        className: 'fa fa-undo',
        command: e => e.runCommand('core:undo'),
    });
    panelManager.addButton('options', {
        id: 'redo',
        className: 'fa fa-repeat',
        command: e => e.runCommand('core:redo'),
    });

    //https://github.com/artf/grapesjs/issues/2514
    panelManager.removePanel('devices-c');
    editor.getConfig().showDevices = 0;
    const commands = editor.Commands;
    commands.add('set-device-desktop', {
        run(editor) {
            editor.setDevice('Desktop');
        }
    });
    commands.add('set-device-tablet', {
        run(editor) {
            editor.setDevice('Tablet');
        }
    });
    commands.add('set-device-mobile', {
        run(editor) {
            editor.setDevice('Mobile portrait');
        }
    });
    panelManager.addPanel({id: 'devices-c'})
        .get('buttons').add([{
        id: 'set-device-desktop',
        command: 'set-device-desktop',
        className: 'fa fa-desktop',
        active: 1,
    },{
        id: 'set-device-tablet',
        command: 'set-device-tablet',
        className: 'fa fa-tablet',
    },{
        id: 'set-device-mobile',
        command: 'set-device-mobile',
        className: 'fa fa-mobile',
    }]);
    editor.Panels.render();
}