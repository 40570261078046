import React, {useState} from 'react';
import qs from 'qs';
import {withRouter} from "react-router-dom";

import {FILTER_TYPE_SELECT} from 'utils/FiltersToChoose'

import Chip from '@material-ui/core/Chip';

function FiltersSelected({filters, match, location, history}) {
    const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    let appliedFilters = [];
    Object.keys(urlQueryParams).forEach(function(urlQs) {
        const appliedFilterIndex = filters.findIndex((
            filter => filter.qs === urlQs
        ));

        if (appliedFilterIndex !== -1) {
            let value = null;

            if (filters[appliedFilterIndex].type === FILTER_TYPE_SELECT) {
                const appliedFilterValueIndex = filters[appliedFilterIndex].values.findIndex((
                    filterOption => filterOption.value === urlQueryParams[urlQs]
                ));

                if (appliedFilterValueIndex !== -1) {
                    value = filters[appliedFilterIndex].values[appliedFilterValueIndex].label;
                }
            } else {
                value = urlQueryParams[urlQs];
            }

            if (value !== null) {
                appliedFilters.push({
                    name: filters[appliedFilterIndex].name,
                    qs: filters[appliedFilterIndex].qs,
                    value: value,
                });
            }
        }
    });

    const removeFilter = (filterName) => {
        const newQueryParams = {
            ...urlQueryParams,
            page: 1,
        };
        delete newQueryParams[filterName];

        const queryString = '?' + qs.stringify(newQueryParams);

        history.push(location.pathname + queryString);
    };

    return (
        <React.Fragment>
            {appliedFilters.map((appliedFilter, index) => (
                <Chip
                    key={index}
                    // icon={icon}
                    label={appliedFilter.name + ': ' + appliedFilter.value}
                    onDelete={() => {removeFilter(appliedFilter.qs)}}
                    // className={classes.chip}
                    style={{marginBottom: 8, marginRight: 8}}
                />
            ))}
        </React.Fragment>
    );
}

export default withRouter(FiltersSelected);