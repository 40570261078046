import React, {useState} from 'react';
import {Formik, Form, Field, useFormikContext} from 'formik';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import FormBuilder from "shared-react/formik/FormBuilder/FormBuilder";
function ProductCollectionForm() {
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder name={'title'} />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder name={'publishedAt'} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder name={'collectionProducts'} />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ProductCollectionForm;