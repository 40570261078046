import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import ListItemText from "@material-ui/core/ListItemText";

const useListItemStyles = makeStyles(theme => ({
    root: props => ({
        ...(props.gutterLeft && {marginLeft: theme.spacing(2)}),
        ...(props.gutterRight && {marginRight: theme.spacing(2)}),
        ...(props.width && {width: props.width}),
        ...(props.width && {minWidth: props.width}),
        textAlign: props.align,
        flex: props.flex ? '1 0 auto' : '0 1 auto',
    }),

    // orderProducts: [
    //     {
    //         productId,
    //         quantity,
    //         ...(customPrice !== undefined && {customPrice}),
    //         ...(customPriceReason !== undefined && {customPriceReason}),
    //     }
    // ],

// padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
// padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;
// width: ${(props) => getListItemWidth(props, 70)}px;
// min-width: ${(props) => getListItemWidth(props, 70)}px;
// flex: 0 1 auto;
}));

export function StyledListItemText({
    width = false,
    gutterLeft = false,
    gutterRight = false,
    flex = true,
    align = 'left',
    ...restProps
}) {
    const classes = useListItemStyles({
        width,
        gutterLeft,
        gutterRight,
        flex,
        align,
    });

    return <ListItemText className={classes.root} {...restProps} />
}

export function ListItemTextAmount(props) {
    return <StyledListItemText width={50} align="right" flex={false} {...props} />
}

export function ListItemTextCurrency(props) {
    return <StyledListItemText width={90} align="right" flex={false} {...props} />
}




// export const StyledListItemText = styled(ListItemText)`
//   && {
//     padding-left: 0;
//     padding-right: 0;
//     flex: 0 1 auto;
//   }
// `;

export const StyledListItemTextLeft = styled(ListItemText)`
  && {
    padding-left: 0;
    padding-right: 0;
    min-width: 70px;
    flex: 0 1 auto;
  }
`;

export const StyledListItemTextRight = styled(ListItemText)`
  && {
    padding-left: 0;
    padding-right: 0;
    min-width: 50px;
    text-align: right;
    flex: 0 1 auto;
  }
`;

// export const ListItemTextCustomWidth = styled(({paddingLeft, paddingRight, textAlign, width, ...restProps}) => <ListItemText {...restProps}/>)`
//   && {
//     text-align: ${props => props.textAlign ? props.textAlign : 'left'};
//     width: ${props => props.width}px;
//     min-width: ${(props) => props.width}px;
//     flex: 0 1 auto;
//   }
// `;

export const ListItemTextCustomWidth = ({...props}) => {
    const theme = useTheme();
    return <ListItemTextCustomWidth2 theme={theme} {...props} />
};

export const ListItemTextCustomWidth2 = styled(({paddingLeft, paddingRight, textAlign, width, ...restProps}) => <ListItemText {...restProps}/>)`
  && {
    padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
    padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    width: ${props => props.width}px;
    min-width: ${(props) => props.width}px;
    flex: 0 1 auto;
  }
`;

// padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
// padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;

// export const ListItemTextCustomWidth = styled(({paddingLeft, paddingRight, textAlign, width, ...restProps}) => {
//     //const theme = useTheme();
//
//     return (<ListItemText theme={{spacing: {unit: 8}}} {...restProps}/>);
// })`
//   && {
//
//     text-align: ${props => props.textAlign ? props.textAlign : 'left'};
//     width: ${props => props.width}px;
//     min-width: ${(props) => props.width}px;
//     flex: 0 1 auto;
//   }
// `;

function ListItemTextCustomPadding({paddingLeft = false, paddingRight = false, hasAdornment=false, ...props}) {
    return (
        <ListItemText
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            hasAdornment={hasAdornment}
            {...props}
        />
    );
}

function getListItemWidth(props, initialWidth) {
    const additionalLeftWidth = props.paddingLeft ? props.theme.spacing(2) : 0;
    const additionalRightWidth = props.paddingRight ? props.theme.spacing(2) : 0;
    const additionalAdornmentWidth = props.hasAdornment ? 20 : 0;

    return initialWidth + additionalLeftWidth + additionalRightWidth + additionalAdornmentWidth;
}

export const ListItemDivCustomWidth = styled.div`
  && {
    padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
    padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;
    width: ${props => props.width}px;
    min-width: ${(props) => props.width}px;
    flex: 0 1 auto;
  }
`;

export const ListAmountInput = styled.div`
  && {
    padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
    padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;
    width: ${(props) => getListItemWidth(props, 70)}px;
    min-width: ${(props) => getListItemWidth(props, 70)}px;
    flex: 0 1 auto;
  }
`;

export const ListPriceInput = styled.div`
  && {
    padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
    padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;
    width: ${(props) => getListItemWidth(props, 90)}px;
    min-width: ${(props) => getListItemWidth(props, 90)}px;
    flex: 0 1 auto;
  }
`;

export const ListIndexNumber = styled.div`
  && {
    padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
    padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;
    width: ${(props) => getListItemWidth(props, 18)}px;
    min-width: ${(props) => getListItemWidth(props, 18)}px;
    flex: 0 1 auto;
  }
`;

export const ListItemTextSmallIconButton = styled.div`
  && {
    padding-left: ${props => props.paddingLeft ? props.theme.spacing(2) : 0}px;
    padding-right: ${props => props.paddingRight ? props.theme.spacing(2) : 0}px;
    width: ${(props) => getListItemWidth(props, 52)}px;
    min-width: ${(props) => getListItemWidth(props, 52)}px;
    flex: 0 1 auto;
    text-align: center;
  }
`;

export const ListItemAmountText = styled.div`
  && {
    margin-left: ${props => props.marginLeft ? props.theme.spacing(2) : 0}px;
    margin-right: ${props => props.marginRight ? props.theme.spacing(2) : 0}px;
    width: 20px;
    min-width: 20px;
    flex: 0 1 auto;
    font-size: 0.8125rem;
    text-align: right;
  }
`;

export const ListItemPriceText = styled.div`
  && {
    margin-left: ${props => props.marginLeft ? props.theme.spacing(2) : 0}px;
    margin-right: ${props => props.marginRight ? props.theme.spacing(2) : 0}px;
    width: 65px;
    min-width: 65px;
    flex: 0 1 auto;
    font-size: 0.8125rem;
    text-align: right;
  }
`;
