import React, {useState} from 'react';
import styled from 'styled-components';
import { withRouter, matchPath } from "react-router-dom";


import { useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LinkWrapper from 'utils/LinkWrapper';
import {ROUTE_USER_GROUP} from 'shared-react/utils/routes';

const NestedListItem = styled(ListItem)`
  && {
    padding-left: ${props => props.theme.spacing(5)}px;
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0;
  }
`;

function ListItemLink({icon, primary, to, pathsToMatch, location, nested}) {
    const theme = useTheme();
    //const selected = to === '/' ? location.pathname === to : location.pathname.includes(to);
    const shouldRenderNested = nested !== undefined ? nested : false;
    const ComputedListItem = shouldRenderNested ? NestedListItem : ListItem;


    const isMatching = matchPath(location.pathname, {path: pathsToMatch});
    const selected = isMatching !== null;

    //const match2 = matchPath(location.pathname, {path: 'random'});
    //console.log("match123", location.pathname, pathsToMatch, isMatching);

    return (
        <React.Fragment>
            <ComputedListItem to={to} theme={theme} selected={selected} button component={LinkWrapper}>
                {icon ?
                    (
                        <StyledListItemIcon>{icon}</StyledListItemIcon>
                    ) : null
                }
                <ListItemText primary={primary} />
            </ComputedListItem>
        </React.Fragment>
    );
}

export default withRouter(ListItemLink);