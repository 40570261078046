import React, {useState, useEffect} from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from "@material-ui/core/TextField";

import SearchBuyableProductsReactSelect from 'shared-react/shop/SearchBuyableProductsReactSelect';
import SearchBuyableProductsAutocomplete from 'shared-react/shop/SearchBuyableProductsAutocomplete';

import {parsePurchaseOrderProductServerToFormData} from 'purchaseOrders/schemas'
import {API_URL} from 'shared-react/api/Api';
import {API_PRODUCTS} from "shared-react/api/ProductApi";

function PurchaseOrderAddProduct({currentCount, arrayHelpers}) {
    const [position, setPosition] = useState(1);
    const onProductChosen = (product) => {
        addProduct(1, product.lastPurchasePrice, product); //todo[as]: set price from product
    };

    const addNewOrderProduct = () => {
        addProduct(1, 0, {});
    };

    //console.log('arrayHelpers length', currentCount);
    const addProduct = (amount, price, product) => {
        const orderProductToAdd = parsePurchaseOrderProductServerToFormData(
            {
                amount: amount,
                price: price,
                product: product,
            }
        );

        //console.log('PurchaseOrderAddProduct product chosen', position, product, orderProductToAdd);

        if (position > 0 && position <= currentCount) {
            arrayHelpers.insert(position - 1, orderProductToAdd);
        } else {
            arrayHelpers.push(orderProductToAdd);
        }
    };

    useEffect(() => {
        setPosition(currentCount + 1);
    }, [currentCount]);

    return (
        <Grid container spacing={4}>
            <Hidden smDown>
                <Grid item xs={6} md={2}>
                    <TextField
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        label="Pozicija"
                        variant="outlined"
                        margin="dense"
                        type="number"
                        inputProps={{ min: "1", step: "1" }}
                        fullWidth
                    />
                </Grid>
            </Hidden>
            <Grid item xs={12} md={7}>
                <SearchBuyableProductsAutocomplete
                    style={{
                        marginTop: 8,
                        marginBottom: 4,
                    }}
                    endpoint={API_URL + API_PRODUCTS}
                    label="Ieškoti esamos prekės"
                    onProductChosen={onProductChosen}
                    onlyVariants={1}
                    adittionalQueryParams={{
                        lastPurchasePrice: 1,
                    }}
                />
            </Grid>
            <Hidden mdUp>
                <Grid item xs={6} md={2}>
                    <TextField
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        label="Pozicija"
                        variant="outlined"
                        margin="dense"
                        type="number"
                        inputProps={{ min: "1", step: "1" }}
                        fullWidth
                    />
                </Grid>
            </Hidden>
            <Grid item xs={6} md={3} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                {/*<Button variant="outlined" color="primary" onClick={addNewOrderProduct}>*/}
                {/*    Nauja prekė*/}
                {/*</Button>*/}
            </Grid>
        </Grid>
    );
}

export default PurchaseOrderAddProduct;