import React from 'react';

import Button from "@material-ui/core/Button";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import ActionsButton from "utils/ActionsButton";
import ActionsChoice from "utils/ActionsChoice";
import useListSelect, {
    startSelectMode,
    cancelSelectMode,
} from "utils/ListSelect/ListSelectContext";

import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";

function ListSelectActions({children}) {
    const [listSelectState, listSelectDispatch] = useListSelect();

    return (
        <>
            {listSelectState.isSelecting ? (
                <React.Fragment>
                    <Button
                        onClick={() => {
                            // if (listSelectState.selected.length === perPage) {
                            //     setSelected([]);
                            // } else {
                            //     if (Array.isArray(orders)) {
                            //         const newSelected = orders.reduce((ordersIds, order) => {
                            //
                            //
                            //             ordersIds.push(order.id);
                            //
                            //             return ordersIds;
                            //         }, []);
                            //         setSelected(newSelected);
                            //     } else {
                            //         setSelected([]);
                            //     }
                            // }
                        }}
                        variant="outlined"
                    >
                        {/*{selected.length === perPage ? (*/}
                        {/*    <CheckBoxIcon/>*/}
                        {/*) : (*/}
                            <React.Fragment>
                                {listSelectState.selected.length === 0 ? (
                                    <CheckBoxOutlineBlankIcon/>
                                ) : (
                                    <IndeterminateCheckBoxIcon/>
                                ) }
                            </React.Fragment>
                        {/*)}*/}
                        {listSelectState.selected.length} pažymėta
                    </Button>
                </React.Fragment>
            ) : null}
            {listSelectState.isSelecting ? (
                <React.Fragment>
                    <ActionsButton
                        label={"Veiksmai"}
                        disabled={listSelectState.selected.length === 0}
                    >
                        {children}
                        {/*<ActionsChoice label={"Apjungti pardavimus"} onClick={() => {*/}

                        {/*    mergeSaleOrders();*/}
                        {/*}} />*/}
                    </ActionsButton>
                </React.Fragment>
            ) : null}
            <Button
                variant="outlined"
                color="primary"
                onClick={(e) => {
                    if (listSelectState.isSelecting) {
                        listSelectDispatch(cancelSelectMode());
                    } else {
                        listSelectDispatch(startSelectMode());
                    }
                }}
            >
                {listSelectState.isSelecting ? (<span>Atšaukti</span>) : (<span>Žymėti</span>)}
            </Button>
        </>
    );
}

export default ListSelectActions;