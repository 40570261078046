import React from 'react';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

import LinkRoute from "shared-react/utils/LinkRoute";
import {getProductPath} from 'shared-react/utils/routes';


function ListItemProduct({product}) {
    return (
        <React.Fragment>
            <ListItemAvatar>
                <Avatar>
                    <ImageIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primaryTypographyProps={{component: 'div'}}
                secondaryTypographyProps={{component: 'div'}}
                primary={
                    <React.Fragment>
                        <LinkRoute to={getProductPath(product.id)}>{product.name}</LinkRoute>
                    </React.Fragment>
                }
                secondary={
                    <React.Fragment>
                        {product.code}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

export default ListItemProduct;