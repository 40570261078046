import React, {useState, useEffect} from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import {MaterialTextField} from "../formik/MaterialFields"

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
});

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function CheckoutProductCustomPriceDialog({isOpen, customPrice, customPriceReason, onCloseDialog, onApplyCustomPrice}) {
    const defaultCustomPrice = (customPrice === undefined || customPrice === null) ? '' : customPrice;
    const defaultCustomPriceReason = (customPriceReason === undefined || customPriceReason === null) ? '' : customPriceReason;

    const [discountedPrice, setDiscountedPrice] = useState(defaultCustomPrice);
    const [discountReason, setDiscountReason] = useState(defaultCustomPriceReason);

    useEffect(() => {
        setDiscountedPrice(defaultCustomPrice);
        setDiscountReason(defaultCustomPriceReason);
    }, [defaultCustomPrice, defaultCustomPriceReason]);

    const onClose = () => {
        //setDiscountedPrice('');
        //setDiscountReason('');
        onCloseDialog();
    };

    const onApply = (discountedPrice, discountReason) => {
        onApplyCustomPrice(discountedPrice, discountReason);
        setDiscountedPrice('');
        setDiscountReason('');
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth={'xs'}
                open={isOpen}
                onClose={onClose}
            >
                <DialogContent>
                    <MaterialTextField
                        value={discountedPrice}
                        label={'Nuolaidos kaina'}
                        onChange={(e) => setDiscountedPrice(e.target.value)}
                        fullWidth
                        margin="dense"
                        type="number"
                        inputProps={{ min: "0", step: "0.01" }}
                    />
                    <MaterialTextField
                        value={discountReason}
                        label={'Nuolaidos priežastis'}
                        onChange={(e) => setDiscountReason(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Atšaukti
                    </Button>
                    <Button onClick={() => {onApply(discountedPrice, discountReason)}} color="primary">
                        Pritaikyti
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default withStyles(styles)(CheckoutProductCustomPriceDialog);