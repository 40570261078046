import React, {useState} from 'react';
import isArray from 'lodash/isArray';
import * as Yup from "yup";
import { Field, FastField } from 'formik';

import Typography from '@material-ui/core/Typography';

import RaisedCard from './RaisedCard';
import formikFieldValidate from "../utils/formikFieldValidate";
import {MaterialRadioGroupButton} from '../formik/FormikMaterialFields';
import PayseraPaymentOptions from './PayseraPaymentOptions';

const schema = Yup.object().shape({
    paymentMethodCode: Yup.string().required('Būtina pasirinkti apmokėjimo būdą'),
    payseraPaymentId: Yup.string()
        .when('paymentMethodCode', {
            is: (val) => val === PAYMENT_METHOD_PAYSERA,
            then: Yup.string().required('Būtina pasirinkti savo banką'),
            otherwise: Yup.string().nullable(),
        }),
});

import {
    PAYMENT_METHOD_PAYSERA,
} from 'shared-react/utils/paymentAndDelivery';

function Step4Payment({cmsForm, metadataUserPaymentMethods, availablePayseraPayments, orderData, setOrderDataField, ...props}) {
    if (!isArray(metadataUserPaymentMethods) || metadataUserPaymentMethods.length === 0) {
        return null;
    }

    const userPaymentMethodsOptions = metadataUserPaymentMethods.map((userPaymentMethod) => {
        return {
            value: userPaymentMethod.code,
            label: (
                <React.Fragment>
                    <Typography variant="subtitle2">
                        {userPaymentMethod.name}
                    </Typography>
                    {userPaymentMethod.infoText ? (
                        <Typography variant="caption" component="div">
                            {userPaymentMethod.infoText}
                        </Typography>
                    ) : null}
                </React.Fragment>
            ),
        };
    });

    React.useEffect(() => {
        if (
            isArray(metadataUserPaymentMethods) && metadataUserPaymentMethods.length > 0
            &&
            (
                typeof orderData.paymentMethodCode === 'undefined' ||
                orderData.paymentMethodCode === null ||
                orderData.paymentMethodCode === '' ||
                typeof metadataUserPaymentMethods.find((paymentMethod) => paymentMethod.code === orderData.paymentMethodCode) === 'undefined'
            )
        ) {
            console.log('set paymentMethodCode', metadataUserPaymentMethods);
            setOrderDataField('paymentMethodCode', metadataUserPaymentMethods[0].code, false);
        }
    }, []);

    return (
        <React.Fragment>
            <RaisedCard label={<>{cmsForm ? '4' : '3'}. Apmokėjimas</>} {...props}>
                <div style={{marginTop: 8}}>
                    <FastField
                        name="paymentMethodCode"
                        //validate={formikFieldValidate(schema)}
                        component={MaterialRadioGroupButton}
                        // onChange={(e) => console.log('MaterialRadioGroupButton changed ', e.target.value)}
                        options={userPaymentMethodsOptions}
                        onChange={(e) => setOrderDataField('paymentMethodCode', e.target.value, false)}
                    />
                    {orderData.paymentMethodCode === PAYMENT_METHOD_PAYSERA ? (
                        <PayseraPaymentOptions
                            payseraPaymentId={orderData.payseraPaymentId}
                            setOrderDataField={setOrderDataField}
                            options={availablePayseraPayments}
                        />
                    ) : null}
                    {/*<Button variant="outlined" fullWidth style={{*/}
                    {/*textTransform: 'none',*/}
                    {/*paddingLeft: 0,*/}
                    {/*paddingRight: 8,*/}
                    {/*justifyContent: 'start',*/}
                    {/*marginBottom: 16,*/}
                    {/*}}>*/}
                    {/*<Radio style={{padding: 8}} />*/}
                    {/*<div style={{*/}
                    {/*textAlign: 'left',*/}
                    {/*}}>*/}
                    {/*<Typography variant="subtitle2">*/}
                    {/*El. Bankininkystė*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" component="div">*/}
                    {/*Atsiskaitykite pasirinktu banku...*/}
                    {/*</Typography>*/}
                    {/*</div>*/}
                    {/*</Button>*/}
                    {/*<Button variant="outlined" color="primary" fullWidth style={{*/}
                    {/*textTransform: 'none',*/}
                    {/*paddingLeft: 0,*/}
                    {/*paddingRight: 8,*/}
                    {/*justifyContent: 'start',*/}
                    {/*marginBottom: 16,*/}
                    {/*}}>*/}
                    {/*<Radio style={{padding: 8}} />*/}
                    {/*<div style={{*/}
                    {/*textAlign: 'left',*/}
                    {/*}}>*/}
                    {/*<Typography variant="subtitle2" color="primary">*/}
                    {/*Atsiskaitymas atsiimamt produktus*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" component="div" color="primary">*/}
                    {/*Galima atsiskaityti tik <b>grynaisiais</b> pinigais*/}
                    {/*</Typography>*/}
                    {/*</div>*/}
                    {/*</Button>*/}
                </div>
            </RaisedCard>
        </React.Fragment>
    );
}

export default React.memo(Step4Payment);