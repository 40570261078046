import React, {useState, useEffect} from 'react';
import {Link, Route} from 'react-router-dom';

import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {getUserMethod, getUserUrl, useGetUser} from "shared-react/api/UserApi";
import LinkBack from "utils/LinkBack";
import PageTitleWithButton from "utils/PageTitleWithButton";
import PageTitle from "utils/PageTitle";
import {getUserPath, getUsersPath} from 'shared-react/utils/routes';
import UserForm, {customerSchema} from "shared-react/user/UserForm";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import useFormikForm from "shared-react/formik/useFormikForm";
import {parseUserToFormData} from "shared-react/schemas/user";

function UserPage({ match, history }) {
    const userId = match.params.id;
    const [user, setUser, isUserLoading, userErrors, fetchUser] = useGetUser(userId);

    useEffect(() => {
        fetchUser();
    }, []);

    const initialFormData = parseUserToFormData(user ? user : {});
    const [savedUser, UserFormikWrapper, userFormProps, UserFormErrors, userFormErrorsProps, UserFormSubmitButton, userFormSubmitButtonProps] = useFormikForm({
        method: getUserMethod(initialFormData),
        url: getUserUrl(initialFormData),
        formData: initialFormData,
        validationSchema: customerSchema,
        submitButtonText: 'Išsaugoti',
    });

    useEffect(() => {
        if (savedUser) {
            setUser(savedUser);
            history.push(getUsersPath());
        }
    }, [savedUser]);

    if (!user) {
        return (<DataLoadingIndicator />);
    }

    return (
        <>
            <Route path={`${match.path}`} exact render={() => (
                <React.Fragment>
                    <FillHeightContainer header={
                        <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: 54,
                            padding: '0 16px',
                        }}>
                            <LinkBack to={getUsersPath()} text={"Atgal"}/>
                            <PageTitleWithButton
                                pageTitle={user.firstName + ' ' + user.lastName}
                                buttonTitle={"Redaguoti"}
                                buttonRoute={getUserPath(user.id) + '/redagavimas'}
                            />
                        </div>
                        </>
                    }>
                    </FillHeightContainer>

                    {/*<ul>*/}
                        {/*{userPermissionGroup.permissions.map((permission) => (*/}
                            {/*<li key={permission.id}>{permission.name}</li>*/}
                        {/*))}*/}
                    {/*</ul>*/}
                </React.Fragment>
            )} />
            <Route path={`${match.path}/redagavimas`} render={() => (
                <React.Fragment>
                    <UserFormikWrapper {...userFormProps}>
                        <FillHeightContainer header={
                            <>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: 54,
                                    padding: '0 16px',
                                }}>
                                    <LinkBack to={getUsersPath()} text={"Atgal"}/>
                                    {/*<LinkBack to={`${match.url}`} text={"Atgal"}/>*/}
                                    <PageTitle title={user.firstName + ' ' + user.lastName + " redagavimas"}/>
                                    <span style={{flex: 1}} />
                                    <UserFormSubmitButton {...userFormSubmitButtonProps}/>

                                </div>
                                <UserFormErrors {...userFormErrorsProps}/>
                            </>
                        }>
                            <div style={{padding: 16}}>
                                <UserForm
                                    wrapIntoPaper={true}
                                />
                            </div>
                        </FillHeightContainer>
                    </UserFormikWrapper>
                </React.Fragment>
            )} />
        </>
    );
}

export default UserPage;