import React from 'react';

import Hidden from '@material-ui/core/Hidden';
import EventIcon from '@material-ui/icons/Event';

import StatusChip from "./StatusChip";

function StatusChipGroupSameSize({small, icon = <EventIcon/>, status = 0, trueValueText = '', falseValueText = '', fixedWidth = 'auto'}) {
    const label = status ? trueValueText : falseValueText;

    return (
        <>
            <Hidden xsDown>
                <StatusChip icon={icon} small={small} status={status} label={label} fixedWidth={fixedWidth}/>
            </Hidden>
            <Hidden smUp>
                <StatusChip icon={icon} small={small} status={status} label={label} fixedWidth={fixedWidth}/>
            </Hidden>
        </>
    );
}

export default StatusChipGroupSameSize;