import {gridItemWidthPrefix, gridItemWidths} from "./GridItem";
import {
    setupClassTrait,
    getSpacingPaddingTopTrait,
    getSpacingPaddingBottomTrait,
    setupSpacingPaddingTopTrait,
    setupSpacingPaddingBottomTrait,
} from 'utils/grapesjs/traits';

export const gridDirectionPrefix = 'grid-direction-xs-';
export const gridDirections = ['row', 'column', 'column-reverse', 'row-reverse'];

export const gridAlignItemsPrefix = 'grid-align-items-xs-';
export const gridAlignItems = ['center', 'flex-start', 'flex-end', 'baseline'];

export const gridAlignContentPrefix = 'grid-align-content-xs-';
export const gridAlignContent = ['center', 'flex-start', 'flex-end', 'space-between', 'space-around'];

export const gridJustifyContentPrefix = 'grid-align-content-xs-';
export const gridJustifyContent = ['center', 'flex-start', 'flex-end', 'space-between', 'space-around', 'space-evenly'];


export const GridContainerBlock = (bm) => {
    // bm.add('grid-container').set({
    //     label: `
    //         i2
    //         <div>Grid Container</div>
    //     `,
    //     category: 'Grid',
    //     content: {
    //         type: 'grid-container',
    //         classes: ['grid-container']
    //     }
    // });
};

export const GridContainerComponent = (domc, editor) => {
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;

    loadCss(editor);

    domc.addType('grid-container', {
        model: defaultModel.extend({
            defaults: Object.assign({}, defaultModel.prototype.defaults, {
                'custom-name': 'GridContainer',
                tagName: 'div',
                draggable: true,//'.container, .container-fluid',
                droppable: '.grid-item',
                classes: ['grid-container', 'grid-direction-xs-row'],
                traits: [
                    {
                        name: 'direction',
                        type: 'select',
                        label: 'Direction',
                        changeProp: 1,
                        options: gridDirections.map(rule => ({
                            value: rule,
                            name: rule,
                        }))
                    },
                    {
                        name: 'justifyContent',
                        type: 'select',
                        label: 'Justify',
                        changeProp: 1,
                        options: gridJustifyContent.map(rule => ({
                            value: rule,
                            name: rule,
                        }))
                    },
                    {
                        name: 'alignContent',
                        type: 'select',
                        label: 'Align Content',
                        changeProp: 1,
                        options: gridAlignContent.map(rule => ({
                            value: rule,
                            name: rule,
                        }))
                    },
                    getSpacingPaddingTopTrait(),
                    getSpacingPaddingBottomTrait(),
                ],
                direction: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                spacingPaddingTop: '5',
                spacingPaddingBottom: '5',
            }),
            init() {
                setupClassTrait(this, 'direction', gridDirections, gridDirectionPrefix, 'row');
                setupClassTrait(this, 'justifyContent', gridJustifyContent, gridJustifyContentPrefix, 'center');
                setupClassTrait(this, 'alignContent', gridAlignContent, gridAlignContentPrefix, 'center');

                setupSpacingPaddingTopTrait(this);
                setupSpacingPaddingBottomTrait(this);
            },
        }, {
            isComponent(el) {
                if(el && el.classList && el.classList.contains('grid-container')) {
                    return {type: 'grid-container'};
                }
            }
        }),
        view: defaultView
    });
}


function loadCss(editor) {
    editor.CssComposer.setRule('.grid-container', {
        width: '100%',
        display: 'flex',
        'flex-wrap': 'wrap',
        'box-sizing': 'border-box',
    });

    gridDirections.forEach((flexDirection) => {
        editor.CssComposer.setRule('.' + gridDirectionPrefix + flexDirection, {
            'flex-direction': flexDirection,
        });
    });

    gridAlignItems.forEach((alignItems) => {
        editor.CssComposer.setRule('.' + gridAlignItemsPrefix + alignItems, {
            'align-items': alignItems,
        });
    });

    gridAlignContent.forEach((alignContent) => {
        editor.CssComposer.setRule('.' + gridAlignContentPrefix + alignContent, {
            'align-content': alignContent,
        });
    });

    gridJustifyContent.forEach((justifyContent) => {
        editor.CssComposer.setRule('.' + gridJustifyContentPrefix + justifyContent, {
            'justify-content': justifyContent,
        });
    });

    editor.CssComposer.setRule('.grid-wrap-xs-nowrap', {
        'flex-wrap': 'nowrap',
    });
    editor.CssComposer.setRule('.grid-wrap-xs-wrap-reverse', {
        'flex-wrap': 'wrap-reverse',
    });
}