import React, {useState} from 'react';
import { Field } from 'formik';

import isArray from 'lodash/isArray';

import Typography from '@material-ui/core/Typography';

import useDebouncedCallback from '../useDebounce/callback';
import Currency from '../utils/Currency';
import {FormMaterialTextField, MaterialRadioGroupButton} from '../formik/FormikMaterialFields';
import OmnivaMachineOptions from './OmnivaMachineOptions';
import {
    DELIVERY_METHOD_CUSTOM,
    DELIVERY_METHOD_PANEVEZYS,
    DELIVERY_METHOD_RYGA,
    OMNIVA_LT,
} from 'shared-react/utils/paymentAndDelivery';

function CheckoutDeliveryMethods({
                                     metadataUserDeliveryMethods,
                                     orderData,
                                     setOrderDataField,
                                     availableOmnivaMachines,
                                     setFieldValue
}) {
    //console.log('formik', formik);
    if (metadataUserDeliveryMethods.length === 0) {
        return null;
    }

    //const [localCustomDeliveryPrice, setLocalCustomDeliveryPrice] = useState(orderData.customDeliveryPrice);
    const [setDebouncedCustomDeliveryPrice] = useDebouncedCallback(
        (value) => {
            //setOrderCustomDeliveryPrice(value);
            setOrderDataField('customDeliveryPrice', value, true)
        },
        500,
        []
    );

    const userDeliveryMethodsOptions = metadataUserDeliveryMethods.map((userDeliveryMethod) => {
        return {
            value: userDeliveryMethod.code,
            label: (
                <React.Fragment>
                    <Typography variant="subtitle2">
                        {userDeliveryMethod.name}
                    </Typography>
                    <Typography variant="caption" component="div" style={{color: 'red'}}>
                        <Currency value={userDeliveryMethod.total} />
                    </Typography>
                    {userDeliveryMethod.infoText ? (
                        <Typography variant="caption" component="div">
                            {userDeliveryMethod.infoText}
                        </Typography>
                    ) : null}
                </React.Fragment>
            ),
        };
    });

    React.useEffect(() => {
        if (
            isArray(metadataUserDeliveryMethods) && metadataUserDeliveryMethods.length > 0
            &&
            (
                typeof orderData.deliveryMethodCode === 'undefined' ||
                orderData.deliveryMethodCode === null ||
                orderData.deliveryMethodCode === '' ||
                typeof metadataUserDeliveryMethods.find((deliveryMethod) => deliveryMethod.code === orderData.deliveryMethodCode) === 'undefined'
            )
        ) {
            setOrderDataField('deliveryMethodCode', metadataUserDeliveryMethods[0].code, true);
        }
    }, []);

    return (
        <React.Fragment>
            <Field
                subscription={{
                    submitError: true,
                    dirtySinceLastSubmit: true,
                    error: true,
                    touched: true,
                    value: true,
                }}
                name="deliveryMethodCode"
                component={MaterialRadioGroupButton}
                // onChange={(e) => console.log('MaterialRadioGroupButton changed ', e.target.value)}
                options={userDeliveryMethodsOptions}
                onChange={(e) => setOrderDataField('deliveryMethodCode', e.target.value, true)}
            />
            {orderData.deliveryMethodCode === DELIVERY_METHOD_CUSTOM ? (
                <Field
                    name="customDeliveryPrice"
                    onChange={(e) => {
                        //setFieldValue('customDeliveryPrice', e.target.value);
                        setDebouncedCustomDeliveryPrice(e.target.value);
                    }}
                    //defaultValue=""
                    label={'Kaina'}
                    margin="dense"
                    type="number"
                    inputProps={{ min: "0", step: "0.01" }}
                    fullWidth
                    component={FormMaterialTextField}
                />
            ) : null}
            {orderData.deliveryMethodCode === OMNIVA_LT ? (
                <OmnivaMachineOptions
                    options={availableOmnivaMachines}
                    defaultValue={orderData.omnivaPickupPointId}
                    setOrderDataField={setOrderDataField}
                />
            ) : null}
        </React.Fragment>
    );
}

export default CheckoutDeliveryMethods;