import {FILTER_TYPE_NUMBER, FILTER_TYPE_CUSTOMER, FILTER_TYPE_DATE, FILTER_TYPE_SELECT} from 'utils/FiltersToChoose';

export const INVOICES_FILTERS = [
    {
        name: 'Sąskaitos numeris',
        qs: 'nr',
        type: FILTER_TYPE_NUMBER,
        defaultValue: '',
        cast: String,
    },
    {
        name: 'Pirkėjas',
        qs: 'customerId',
        type: FILTER_TYPE_CUSTOMER,
        defaultValue: null,
        cast: Number,
    },
    {
        name: 'Data nuo',
        qs: 'dateFrom',
        type: FILTER_TYPE_DATE,
        defaultValue: null,
        cast: String,
    },
    {
        name: 'Data iki',
        qs: 'dateTo',
        type: FILTER_TYPE_DATE,
        defaultValue: null,
        cast: String,
    },
];