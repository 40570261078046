import React, {useState, useEffect} from 'react';
import { withRouter, matchPath } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Collapse from '@material-ui/core/Collapse';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/styles";

import {StyledListItemIcon} from 'menu/ListItemLink';
import LinkWrapper from 'utils/LinkWrapper';

function ListItemNestedLink({to, icon, location, history, match, pathsToMatch, primary, children}) {
    //const selected = to.findIndex(str => location.pathname.includes(str)) >= 0;
    const isMatching = matchPath(location.pathname, {path: pathsToMatch});
    const selected = isMatching !== null;
    const [isOpen, setIsOpen] = useState(selected);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setIsOpen(selected);
    }, [selected]);

    return (
        <React.Fragment>
            {/*if not mobile, clicking on parent forward to first link*/}
            {/*if mobile, just toggles collape*/}
            <ListItem button to={to} component={LinkWrapper} onClick={(e) => {
                // if (!isMobile) {
                //     history.push(to);
                // }

                //setIsOpen((toggleIsOpen) => !toggleIsOpen);
            }}>
                <StyledListItemIcon>
                    {icon}
                </StyledListItemIcon>
                <ListItemText primary={primary} />
                {/*{isOpen ? <ExpandLess /> : <ExpandMore />}*/}
            </ListItem>
            <Collapse in={selected || isOpen} timeout="auto" unmountOnExit style={{minHeight: 'auto'}}>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </React.Fragment>
    );
}

export default withRouter(ListItemNestedLink);