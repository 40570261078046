import React from 'react';

import { useFormikContext, Formik, Form, Field } from 'formik';

import {FormMaterialSelectField, FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";

function FormField() {
    return (
        <Field
            name="phoneNumber"
            label={'Telefono numeris'}
            margin="dense"
            fullWidth={false}
            type="number"
            component={FormMaterialTextField}
            nomargin={1}
            style={{
                marginLeft: 8,
                flex: 1,
            }}
        />
    );
}

export default FormField;