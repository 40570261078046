import React from "react";

const FancyImageContainer = ({children, borderStyle = 'none', background = '#f4f6f8', padding = 5}) => {
    return (
        <React.Fragment>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',

                padding: padding,
                background: background,
                border: '1px solid #c4cdd5',
                borderStyle: borderStyle,
                borderRadius: 5,
                lineHeight: 0,

                overflow: 'hidden',
            }}>
                {children}
            </div>
        </React.Fragment>
    )
};

export default FancyImageContainer;