import React from 'react';
import ReactDOM from 'react-dom';
import App from 'layout/App';
import * as serviceWorker from './serviceWorker';
//import {addLocaleData} from 'react-intl';
//import en from 'react-intl/locale-data/en';
//import lt from 'react-intl/locale-data/lt';
//import lv from 'react-intl/locale-data/lv';
//import ru from 'react-intl/locale-data/ru';
import {IntlProvider} from 'react-intl';

//addLocaleData([...en, ...lt]); //, ...lv, ...ru

ReactDOM.render(<IntlProvider locale={'lt'}><App/></IntlProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
