import React from 'react';
import {useIntl, FormattedNumber} from 'react-intl';


function Currency({value, style="currency", ...props}) {
    return (
        <FormattedNumber
            value={value}
            style={style}
            currency="EUR"
            {...props}
        />
    );
}


export function getCurrencyString(value) {
    const intl = useIntl();

    return intl.formatNumber(value, {style: 'currency', currency: 'EUR'});
}

export default Currency;

