import {
    spacingTopPrefix,
    spacingBottomPrefix,
    spacingValues,
} from 'utils/grapesjs/utils';

export const setupNewTraits = (tm) => {
    const textTrait = tm.getType('text');

console.log('textTrait', textTrait.prototype);
    tm.addType('content', Object.assign({}, textTrait.prototype.defaults, {
        events: {
            'keyup': 'onChange',
        },
    }));
};

export const setupClassTrait = (_this, name, allClasses, classPrefix, defaultValue) => {
    _this.on('change:' + name, buildHandleTraitClassChange(name, allClasses, classPrefix));
    const currentValue = typeof _this.get(name) !== 'undefined' ? _this.get(name) : defaultValue;
    //console.log('setupClassTrait', name, currentValue, typeof currentValue);
    handleTraitClassChange(_this, name, allClasses, classPrefix, currentValue);
};

function buildHandleTraitClassChange(name, allClasses, classPrefix) {
    return (component, newValue) => {
        return handleTraitClassChange(component, name, allClasses, classPrefix, newValue);
    };
};

function handleTraitClassChange(component, name, allClasses, classPrefix, newValue) {
    component.removeClass(allClasses.map(rule => classPrefix + rule));
    //console.log('handleTraitClassChange', name, newValue, allClasses);
    if (newValue != 'empty') {
        component.addClass([classPrefix + newValue]);
    }

    //component.getTrait(name).set('value', newValue);
    //console.log('handleTraitClassChange', component, classPrefix, newValue, component.getTrait(name));
};

export const getSpacingPaddingTopTrait = () => {
    return getSelectTrait('spacingPaddingTop', 'Padding Top', 'None', spacingValues);
};
export const getSpacingPaddingBottomTrait = () => {
    return getSelectTrait('spacingPaddingBottom', 'Padding Bottom', 'None', spacingValues);
};
export const setupSpacingPaddingTopTrait = (_this) => {
    setupClassTrait(_this, 'spacingPaddingTop', spacingValues, spacingTopPrefix, '5');
};
export const setupSpacingPaddingBottomTrait = (_this) => {
    setupClassTrait(_this, 'spacingPaddingBottom', spacingValues, spacingBottomPrefix, '5');
};

export const getSelectTrait = (name, label, emptyLabel = 'None', values) => {
   return {
       name: name,
       type: 'select',
       label: label,
       changeProp: 1,
       options: values.map(rule => ({
           id: rule,
           value: rule,
           name: rule ? rule : emptyLabel,
       }))
   };
};
