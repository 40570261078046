import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
    usePopupState,
    bindToggle,
    bindPopper
} from 'material-ui-popup-state/hooks'

function ActionsButton({disabled, label, children}) {
    const popupState = usePopupState({ variant: 'popper', popupId: 'actionsMenu' });

    const handleClose = event => {
        if (popupState.anchorEl.contains(event.target)) {
            return;
        }

        popupState.close();
    };

    const childrenWithExtraProp = React.Children.map(children, child => {
        return React.cloneElement(child, {
            onClick: (event) => {
                if (typeof child.props.onClick === 'function') {
                    child.props.onClick(event);
                }

                handleClose(event);
            },
        });
    });

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                disabled={disabled}
                {...bindToggle(popupState)}
            >
                {label}
                <ArrowDropDownIcon/>
            </Button>
            <Popper {...bindPopper(popupState)} transition style={{zIndex: 3}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {childrenWithExtraProp}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
export default ActionsButton;