import React from 'react';
import {connect} from "react-redux";

import SearchProduct from "shop/SearchProduct";

function SearchProducts({products, warehouseId, productsInCartLookup}) {
    if (products.length === 0) {
        return (
            <span>Prekių rasti nepavyko</span>
        );
    }

    return (
        <React.Fragment>
            {products.map((product) => (
                <SearchProduct
                    productsInCartLookup={productsInCartLookup}
                    key={product.id}
                    warehouseId={warehouseId}
                    product={product}
                />
            ))}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    let productsInCartLookup = [];

    state.newOrder.orderData.fulfillments.forEach((fulfillment, index) => {
        fulfillment.orderProducts.forEach((orderProduct, index) => {
            productsInCartLookup.push(fulfillment.warehouseId + '-' + orderProduct.productId);
        });
    });

    return {
        productsInCartLookup,
    };
};

export default connect(
    mapStateToProps
)(SearchProducts);