import React from 'react';
import {Field, useFormikContext} from "formik";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import ProgressButton from "shared-react/utils/ProgressButton";
import {useSaveVariationOptionValue} from "shared-react/api/VariationOptionApi";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import {FormMaterialAutocomplete} from "shared-react/formik/FormikMaterialFields";
import {useMetadataDispatch, addMetadata} from "shared-react/metadata/MetadataContext";

const filter = createFilterOptions();

function VariationOptionValue({variantIndex, variationOptionValueIndex, variationOptionValues, variationOptionId, label}) {
    const { setFieldValue } = useFormikContext();
    const [dialogValue, setDialogValue] = React.useState({
        value: '',
        variationOption: {
            id: variationOptionId
        },
    });
    const [open, toggleOpen] = React.useState(false);
    //console.log('dialogValue', dialogValue);
    const [savedVariationOptionValue, isSubmitting, errors, saveOptionVariation] = useSaveVariationOptionValue(dialogValue, true);
    const metadataDispatch = useMetadataDispatch();

    const handleClose = () => {
        setDialogValue({
            value: '',
            variationOption: {
                id: variationOptionId
            },
        });

        toggleOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        saveOptionVariation();
    };

    React.useEffect(() => {
        if (savedVariationOptionValue) {
            metadataDispatch(addMetadata('variationOptionValues', savedVariationOptionValue));
            setFieldValue(`variants[${variantIndex}].variationOptionValues[${variationOptionValueIndex}]`, savedVariationOptionValue);
            handleClose();
        }
    }, [savedVariationOptionValue]);

    return (
        <React.Fragment>
            <Field
                name={`variants[${variantIndex}].variationOptionValues[${variationOptionValueIndex}]`}
                label={label}
                options={variationOptionValues}
                optionLabelField={'value'}
                fullWidth
                component={FormMaterialAutocomplete}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                onChange={(event, newValue) => {
                    //console.log('onChange', event, newValue);

                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                value: newValue,
                                variationOption: {
                                    id: variationOptionId
                                },
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            value: newValue.inputValue,
                            variationOption: {
                                id: variationOptionId
                            },
                        });
                    } else {
                        setFieldValue(`variants[${variantIndex}].variationOptionValues[${variationOptionValueIndex}]`, newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const filteredOptionVariationId = filtered.filter((variationOptionValue) => {
                        if (variationOptionValue !== null) {
                            return variationOptionValue.variationOption.id === variationOptionId;
                        }

                        return false;
                    });
                    //console.log('filterOptions2', options, params, filtered);
                    if (params.inputValue !== '') {
                        filteredOptionVariationId.push({
                            inputValue: params.inputValue,
                            value: `Pridėti "${params.inputValue}"`,
                        });
                    }

                    return filteredOptionVariationId;
                }}
                freeSolo
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">Pridėti varianto reikšmę</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="value"
                            value={dialogValue.value}
                            onChange={(event) => setDialogValue({
                                value: event.target.value,
                                variationOption: {
                                    id: variationOptionId
                                },
                            })}
                            label="Pavadinimas"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions style={{display: 'flex'}}>
                        <Button onClick={handleClose} color="primary">
                            Atšaukti
                        </Button>
                        <ResponseErrors errors={errors} />
                        <span style={{flex: 1}}></span>
                        <ProgressButton
                            loading={isSubmitting}
                            text={"Pridėti"}
                            onClick={handleSubmit}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}

export default VariationOptionValue;