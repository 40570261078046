import React, {useEffect, useState} from 'react';

import List from "@material-ui/core/List";
import ListIemKeyValue from 'shared-react/utils/ListIemKeyValue';
import {initialState} from "../ducks/order";

function CustomerListInfo({
                              isCompany = '',
                              email = '',
                              phoneNumberCountryCode = '',
                              phoneNumber = '',
                              address = '',
                              code = '',
                              pvmCode = '',
}) {
    return (
        <React.Fragment>
            <List dense style={{
                padding: 0,
            }}>
                <ListIemKeyValue dense title={"El. paštas"} value={email} />
                <ListIemKeyValue dense title={"Tel. numeris"} value={phoneNumber ? phoneNumberCountryCode + ' ' + phoneNumber : ''} />
                <ListIemKeyValue dense title={"Adresas"} value={address} />
                {isCompany ? (
                    <React.Fragment>
                        <ListIemKeyValue dense title={"Įmonės kodas"} value={code} />
                        <ListIemKeyValue dense title={"PVM mokėtojo kodas"} value={pvmCode} />
                    </React.Fragment>
                ) : null}
            </List>
        </React.Fragment>
    );
}

export default CustomerListInfo;