import React, {useState} from 'react';
import {connect} from "react-redux";

import {MaterialTextField} from "shared-react/formik/MaterialFields"

import {setSearchText} from "shared-react/ducks/shop";
import useDebouncedCallback from "shared-react/useDebounce/callback";

function SearchText({isDisabled, searchText, setSearchText}) {
    const [localSearchText, setLocalSearchText] = useState(searchText);

    const [setDebouncedSearchText] = useDebouncedCallback(
        (value) => {
            setSearchText(value);
        },
        500,
        []
    );

    return (
        <MaterialTextField
            label="Pavadinimas ar kodas"
            value={localSearchText}
            onChange={(e) => {
                setLocalSearchText(e.target.value);
                setDebouncedSearchText(e.target.value);
            }}
            disabled={isDisabled}
            margin="dense"
            nomargin={1}
            fullWidth
        />
    );
}

const mapStateToProps = (state) => {
    return {
        searchText: state.shop.searchText,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setSearchText: (searchText) => {
        dispatch(setSearchText(searchText));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchText);