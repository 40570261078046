import React from 'react';
import styled from 'styled-components';

import {useTheme} from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import LinkWrapper from "utils/LinkWrapper";

const StyledButton = styled(Button)`
  && {
    padding-left: 0;
    margin-left: -${props => props.theme.spacing(1)}px;
    color: ${props => props.theme.palette.text.hint};
  }
`;

const StyledChevronLeft = styled(ChevronLeft)`
  && {
    font-size: 20px;
  }
`;

function LinkBack({to, text, ...props}) {
    const theme = useTheme();
    return (
        <StyledButton theme={theme} to={to} size="small" component={LinkWrapper} {...props}>
            <StyledChevronLeft />
            {text}
        </StyledButton>
    );
}
export default LinkBack;