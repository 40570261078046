import {API_URL, getAuthTokenHeaders, useMakeRequest} from "./Api";
import {API_PRODUCTS} from "./ProductApi";
const API_IMAGES_SORT = '/v2/images-sort';
const API_IMAGE_UPLOAD = '/v2/image-upload';
const API_IMAGE_CROP = '/v2/images/{type}/{imageName}/crop';
const API_IMAGE_DELETE = '/v2/images/{type}/{imageName}';

export function useUploadImage(imageType, imageFile) {
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("imageType", imageType);

    //const productUrl = productId ? '/' + productId : '';

    const { data, isLoading, errors, doRequest } =  useMakeRequest(
        'post',
        API_URL + API_IMAGE_UPLOAD,
        formData,
        {
            'Content-Type': 'multipart/form-data',
        }
    );

    return [ data, isLoading, errors, doRequest ];
}

export function useSortImages(imageType, images) {
    const formData = {
        imageType: imageType,
        images: images,
    };
    const { data, isLoading, errors, doRequest } =  useMakeRequest('put', API_URL + API_IMAGES_SORT, formData);

    return [ data, isLoading, errors, doRequest ];
}

export function useCropImage(imageType, cropImageData) {
    const formData = {
        x: cropImageData.x,
        y: cropImageData.y,
        width: cropImageData.width,
        height: cropImageData.height,
    };
    const url = API_URL +
                API_IMAGE_CROP
                    .replace('{imageName}', cropImageData.name)
                    .replace('{type}', imageType);
    const { data, isLoading, errors, doRequest } =  useMakeRequest('put', url, formData);

    return [ data, isLoading, errors, doRequest ];
}

export function useDeleteProductImage(imageType, imageName) {
    ///api/v2/products/{productId}/images/{imageName}
    const url = API_URL +
                API_IMAGE_DELETE
                    .replace('{imageName}', imageName)
                    .replace('{type}', imageType);

    const { data, isLoading, errors, doRequest } =  useMakeRequest(
        'delete',
        url
    );

    return [ data, isLoading, errors, doRequest ];
}