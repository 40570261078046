import React, {useState, useEffect, useRef} from "react";
import {get} from 'lodash';

import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { styled } from '@material-ui/core/styles';
import SortableTree, { getVisibleNodeCount, getNodeAtPath } from 'react-sortable-tree';
import isArray from 'lodash/isArray';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import RootRef from '@material-ui/core/RootRef';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import {useTheme} from "@material-ui/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {MaterialTextField, MaterialSelectField, MaterialDatePickerField} from './MaterialFields';

import ControlledCheckbox from './ControlledCheckbox';

const StyledFormControl = styled(FormControl)`
  && {
    margin-top: ${props => props.theme.spacing(1)}px;
    margin-bottom: ${props => props.theme.spacing(1)}px;
  }
`;

const filter = createFilterOptions();

export function FormMaterialAutocomplete(
    {
        field,
        label,
        options,
        optionLabelField = 'title',
        optionValueField = 'id',
        form: { touched, errors, setFieldValue },
        onChange,
        ...props
    }) {
        const helperText = get(touched, field.name) ? get(errors, field.name) : "";
        const error = get(touched, field.name) && Boolean(get(errors, field.name));

        return (
            <>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => {
                        //console.log('getOptionLabel', option, typeof option === 'string');
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return get(option, optionLabelField, '');
                    }}
                    renderOption={(option) => get(option, optionLabelField)}
                    style={{ width: '100%' }}
                    getOptionSelected={(option, value) => {
                        //console.log('getOptionSelected', option, value);
                        return get(value, optionValueField) === get(option, optionValueField);
                        // return get(value, 'id') === option.id;
                        //console.log('getOptionSelected', option, value);
                    }}
                    value={field.value}
                    onChange={(event, newValue) => {
                        //console.log('onchange autocomplete newvalue', newValue);
                        if (typeof onChange === 'function') {
                            onChange(event, newValue);
                        } else {
                            setFieldValue(field.name, newValue);
                        }
                    }}
                    renderInput={(params) => <MaterialTextField margin="dense" label={label}  /*{...field}*/ {...params} variant="outlined" />}
                    {...props}
                />
                {helperText ? (
                    <FormHelperText error={error}>{helperText}</FormHelperText>
                ) : null}
            </>
        );
}

export function FormMaterialSelectField({ field, options, form: { touched, errors, setFieldValue }, onChange, ...props }) {
    return (
        <MaterialSelectField
            helperText={get(touched, field.name) ? get(errors, field.name) : ""}
            error={get(touched, field.name) && Boolean(get(errors, field.name))}
            options={options}
            {...field}
            {...props}
            onChange={(event) => {
                setFieldValue(field.name, event.target.value);

                if (typeof onChange === 'function') {
                    onChange(event);
                }
            }}
        />
    );
}

export function MaterialRadioGroup({ label, field, options, form: { touched, errors, setFielValue }, onChange, skipFormikSet = false, ...props }) {
    //const theme = useTheme();
    //console.log('MaterialRadioGroup', field, props);

    return (
        <FormControl
            component="fieldset"
            error={touched[field.name] && Boolean(errors[field.name])}
        >
            <RadioGroup
                {...field}
                {...props}
                onChange={(e, value) => {
                    if (!skipFormikSet) {
                        setFielValue(field.name, value);
                    }
                    //console.log('MaterialRadioGroup change', e, value, typeof value, onChange);

                    if (typeof onChange === 'function') {
                        onChange(e, value);
                    }

                    if (typeof field.onChange === 'function') {
                        field.onChange(e, value);
                    }
                }}
            >
                {options.map((option, key) => (
                    <FormControlLabel key={key} value={option.value} control={<Radio color="secondary" />} label={(<b>{option.label}</b>)} />
                ))}
                {/*<FormControlLabel value="0" control={<Radio color="primary" />} label={<b>Paprasta prekė.</b>} />*/}
                {/*<FormControlLabel value="1" control={<Radio color="primary" />} label={<React.Fragment><b>Prekė su variantais.</b> Kai prekė turi skirtingus dydžius ar spalvas.</React.Fragment>} />*/}
                {/*<FormControlLabel value="2" control={<Radio color="primary" />} label={<React.Fragment><b>Produktų grupė ar rinkinys.</b> Kai produktas susideda iš kelių paprastų produktų.</React.Fragment>} />*/}
            </RadioGroup>
            {/*<FormHelperText>You can display an error</FormHelperText>*/}
        </FormControl>
    );
}

export function MaterialRadioGroupButton({ label, field, form: { touched, errors }, options, ...props }) {
    return (
        <FormControl
            fullWidth
            component="fieldset"
            error={touched[field.name] && Boolean(errors[field.name])}
        >
            <RadioGroup
                {...field}
                {...props}
            >
                {options.map((option, index) => (
                    <label key={index}>
                        <Button variant="outlined" component="span" fullWidth style={{
                            textTransform: 'none',
                            paddingLeft: 0,
                            paddingRight: 8,
                            justifyContent: 'start',
                            marginBottom: 8,
                            marginTop: 8,
                        }}>
                            <Radio value={option.value} />
                            <div style={{
                                textAlign: 'left',
                            }}>
                                {option.label}
                            </div>
                        </Button>
                    </label>
                ))}
            </RadioGroup>
            {/*<FormHelperText>You can display an error</FormHelperText>*/}
        </FormControl>
    );
}

export function FormCheckboxField({ label, field, form: { touched, errors, setFieldValue }, onChange, ...props }) {
    //const theme = useTheme();
    const hasError = get(touched, field.name) && Boolean(get(errors, field.name));

    return (
        <FormControl
            fullWidth
            component="fieldset"
            error={hasError}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        {...field}
                        {...props}
                        checked={field.value}
                        value={field.name}
                        onChange={(e, value) => {
                            //console.log('FormCheckboxField onchange', value);
                            setFieldValue(field.name, value);

                            if (typeof onChange === 'function') {
                                onChange(e, value);
                            }
                        }}
                    />
                }
                label={label}
            />
            {hasError ? (
                <FormHelperText>{ get(errors, field.name, '') }</FormHelperText>
            ) : null}

        </FormControl>
    );
}


export const FormDatePickerField = ({ field, form: { touched, errors, setFieldValue, setFieldError, setFieldTouched }, nomargin, ...props }) => {
    const currentError = errors[field.name];

    //console.log('FormDatePickerField', field, get(touched, field.name), Boolean(get(errors, field.name)));
    return (
        <>
            <MaterialDatePickerField
                // helperText={currentError}
                // error={Boolean(currentError)}
                helperText={get(touched, field.name) ? get(errors, field.name) : ""}
                error={get(touched, field.name) && Boolean(get(errors, field.name))}
                format="yyyy-MM-dd"
                name={field.name}
                value={field.value}
                onError={(error, value) => {
                    //console.log('1. onError', error, value, field.value);
                    //setFieldTouched(field.name, true);
                    //setFieldValue(field.name, null, true);


                    //setFieldError(field.name, error);
                    //setFieldTouched(field.name, true);
                }}
                // mask={value => (console.log('vaue', value) && value ? [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/] : [])}
                onChange={date => {
                    //console.log('2. onChange', date, date instanceof Date);

                    // console.log(date, isValid(date), datefnsFormat(date, 'yyyy-MM-dd'));
                    if (date instanceof Date && !isNaN(date.getTime())) {
                        setFieldValue(field.name, date, true);
                    } else {
                        setFieldValue(field.name, null, true);
                    }
                }}
                {...props}
                style={{
                    ...nomargin === 1 && {
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                }
            />
        </>
    );
};

export const OmnivaPickupPointsField = ({ field, form: { touched, errors, setFieldValue, setFieldError, setFieldTouched }, ...props }) => {
    const inputLabel = React.useRef(null);
    const inputLabel2 = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [labelWidth2, setLabelWidth2] = React.useState(0);

    const selectedMachineField = field.value ? field.value : '';
    const selectedCountyField = selectedMachineField ? options.option['ZIP'] : '';

    const [selectedCounty, setSelectedCounty] = React.useState(selectedCountyField);
    const [selectedMachine, setSelectedMachine] = React.useState(selectedMachineField);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        setLabelWidth2(inputLabel2.current.offsetWidth);
    }, []);

    //console.log('OmnivaMachineOptions', options);

    if (isObject(options) && Object.keys(options).length > 0) {
        const counties = Object.keys(options);

        return (
            <div>
                <FormControl variant="outlined" fullWidth style={{margin: '8px 0'}}>
                    <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                        Pasirinkite savivaldybę
                    </InputLabel>
                    <Select
                        value={selectedCounty}
                        onChange={(e) => {setSelectedCounty(e.target.value)}}
                        input={<OutlinedInput labelWidth={labelWidth} name="age" id="outlined-age-simple" />}
                    >
                        {counties.map((option, index) => (
                            <MenuItem value={option} key={index}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/*{!selectedCounty ? (*/}
                {/*<span>Norėdami pasirinkti paštomatą, pirma turite pasirinkti savivaldybę aukščiau esantčiame lauke</span>*/}
                {/*) : null}*/}
                <FormControl variant="outlined" disabled={!selectedCounty} fullWidth style={{margin: '8px 0'}}>
                    <InputLabel ref={inputLabel2} htmlFor="outlined-age-simple">
                        Pasirinkite paštomatą
                    </InputLabel>
                    <Select
                        value={selectedMachine}
                        onChange={(e) => {setSelectedMachine(e.target.value)}}
                        input={<OutlinedInput labelWidth={labelWidth2} name="age" id="outlined-age-simple" />}
                    >
                        {selectedCounty && options[selectedCounty].map((option, index) => (
                            <MenuItem value={option['ZIP']} key={index} style={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}>
                                <Typography variant="inherit" noWrap>
                                    {option['NAME']}
                                </Typography>
                                <Typography variant="caption" display="block">{option['A2_NAME']}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    } else {
        return (
            <span>Nėra aktyvuotų Omniva paštomatų</span>
        )
    }
};

export function FormMaterialTextField({ field, form: { touched, errors, setFieldValue }, onChange, skipFormikSet = false, ...props }) {
    //console.log('FormMaterialTextField', field, touched, errors);
    return (
        <MaterialTextField
            helperText={get(touched, field.name) ? get(errors, field.name) : ""}
            error={get(touched, field.name) && Boolean(get(errors, field.name))}
            {...field}
            {...props}
            onChange={(event) => {
                if (!skipFormikSet) {
                    setFieldValue(field.name, event.target.value);
                }

                if (typeof onChange === 'function') {
                    onChange(event);
                }
            }}
        />
    );
}


export function MaterialTableTextField({ field, form: { touched, errors }, ...props }) {
    const theme = useTheme();

//     margin-top: 0;
//     margin-bottom: 0;
//
// & .smallerInput {
//         padding-top: ${props => props.theme.spacing(1)}px;;
//         padding-bottom: ${props => props.theme.spacing(1)}px;;
//     }

    return (
        <MaterialTextField
            theme={theme}
            helperText={get(touched, field.name) ? get(errors, field.name) : ""}
            error={get(touched, field.name) && Boolean(get(errors, field.name))}
            variant="outlined"
            margin="none"
            size="small"
            nomargin={true}
            InputProps={{
                classes: {
                    input: 'smallerInput',
                },
            }}
            {...field}
            {...props}
        />
    );
}

export function MaterialMultiSelectChip({ options, label, field, form: { touched, errors }, fullWidth, ...props }) {
    const theme = useTheme();
    const [labelWidth, setLabelWidth] = useState(0);
    const labelEl = useRef(null);

    useEffect(() => {
        //console.log('labelEl', labelEl, labelEl.current, labelEl.current.offsetWidth);
        setLabelWidth(labelEl.current.offsetWidth);
    }, []);

    // const measuredRef = useCallback(labelEl => {
    //     console.log('labelEl', labelEl, labelEl.current);
    //     if (labelEl !== null && labelEl.current !== null) {
    //         console.log('labelEl2', labelEl, labelEl.current, labelEl.current.offsetWidth);
    //         setLabelWidth(labelEl.current.offsetWidth);
    //     }
    // }, []);

    return (
        <StyledFormControl
            theme={theme}
            variant="outlined"
            fullWidth={fullWidth}
        >
            {/*<RootRef rootRef={labelEl}>*/}
                <InputLabel {...props} htmlFor="select-multiple-chip">{label}</InputLabel>
            {/*</RootRef>*/}
            <Select
                multiple
                input={<OutlinedInput
                    id="select-multiple-chip"
                    labelWidth={labelWidth}
                />}
                renderValue={selected => {
                    const selectedOptions = options.filter(option => {
                        return selected.indexOf(option.id) > -1;
                    });

                    return (
                        <div>
                            {selectedOptions.map(option => (
                                <Chip key={option.id} label={option.title} />
                            ))}
                        </div>
                    )
                }}
                {...field}
                {...props}
            >
                {options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </StyledFormControl>
    );
}

const expandTreeData = (category) => {
    const newChildren =
        typeof category.children !== 'undefined' && isArray(category.children) ?
            category.children.map(expandTreeData)
            :
            [];

    return {
        id: category.id,
        title: category.title,
        children: newChildren,
        expanded: true,
    };
};

const getAllSelfAndChildrenIds = (ids = [], category) => {
    //console.log('getAllSelfAndChildrenIds', category, ids);
    const childrenIds = category.children.reduce(getAllSelfAndChildrenIds, []);

    return [
        ...ids,
        category.id,
        ...childrenIds,
    ];
};

export function MaterialTreeSelectField({ setFieldValue, treeData, options, label, field, form: { touched, errors }, fullWidth, ...props }) {
    const theme = useTheme();
    const [labelWidth, setLabelWidth] = useState(0);
    const [selectWidth, setSelectWidth] = useState(0);
    const labelEl = useRef(null);
    const selectEl = useRef(null);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        //console.log('labelEl', labelEl, labelEl.current, labelEl.current.offsetWidth);
        setLabelWidth(labelEl.current.offsetWidth);
        setSelectWidth(selectEl.current.offsetWidth);
    }, []);

    // const measuredRef = useCallback(labelEl => {
    //     console.log('labelEl', labelEl, labelEl.current);
    //     if (labelEl !== null && labelEl.current !== null) {
    //         console.log('labelEl2', labelEl, labelEl.current, labelEl.current.offsetWidth);
    //         setLabelWidth(labelEl.current.offsetWidth);
    //     }
    // }, []);

    const expandedTreeData = treeData.map(expandTreeData);
    const count = getVisibleNodeCount({treeData: expandedTreeData});
//console.log('treeData', treeData);
//console.log('field values', field.value);
    return (
        <React.Fragment>
            <StyledFormControl
                theme={theme}
                variant="outlined"
                fullWidth={fullWidth}
            >
                <RootRef rootRef={labelEl}>
                    <InputLabel {...props} htmlFor="select-multiple-chip">{label}</InputLabel>
                </RootRef>
                <RootRef rootRef={selectEl}>
                    <Select
                        open={false}
                        onOpen={() => {}}
                        onClick={(e) => {

                            if (isDialogOpen) {
                                setIsDialogOpen(false);
                            } else {
                                setIsDialogOpen(true);
                            }
                        }}
                        multiple
                        input={<OutlinedInput
                            id="select-multiple-chip"
                            labelWidth={labelWidth}
                        />}
                        renderValue={selected => {
                            const selectedOptions = options.filter(option => {
                                return selected.indexOf(option.id) > -1;
                            });

                            return (
                                <div>
                                    {selectedOptions.map(option => (
                                        <Chip key={option.id} label={option.title} />
                                    ))}
                                </div>
                            )
                        }}
                        {...field}
                        {...props}
                    >
                        {options.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.title}
                            </MenuItem>
                        ))}
                    </Select>
                </RootRef>
            </StyledFormControl>
            {isDialogOpen ? (
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                >
                    <DialogTitle id="max-width-dialog-title">Kategorijos</DialogTitle>
                    <DialogContent>
                        <div style={{height: count * 62 + 5}}>
                            <SortableTree
                                treeData={expandedTreeData}
                                onChange={treeData => {
                                }}
                                getNodeKey={({ node }) => {
                                    return node.id;
                                }}
                                canDrag={false}
                                generateNodeProps={rowInfo => {
                                    return ({
                                        buttons: [
                                            <ControlledCheckbox
                                                onChange={(response) => {
                                                    let fieldValues = [
                                                        ...field.value
                                                    ];
                                                    let checkboxValue = rowInfo.node.id;

                                                    if (response.isChecked) {
                                                        //get all children ids + current id
                                                        const allIds = rowInfo.path;

                                                        allIds.map((id) => {
                                                            if (fieldValues.indexOf(id) === -1) {
                                                                fieldValues.push(id);
                                                            }
                                                        });
                                                    } else {
                                                        const allIds = getAllSelfAndChildrenIds([], rowInfo.node);

                                                        allIds.map((id) => {
                                                            if (fieldValues.indexOf(id) !== -1) {
                                                                fieldValues.splice(fieldValues.indexOf(id), 1);
                                                            }
                                                        });

                                                        // remove all those ids


                                                    }
//get all parent or get all children

                                                    setFieldValue(field.name, fieldValues);
                                                }}
                                                checked={field.value.includes(rowInfo.node.id)}
                                                // isInitiallyChecked={field.value.includes(rowInfo.node.id)}
                                                value={rowInfo.node.id}
                                            />,
                                        ],
                                    })
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOpen(false)} color="primary">
                            Uždaryti
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </React.Fragment>
    );
}