import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray, ErrorMessage, FastField} from 'formik';
import {Link, Redirect} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Yup from 'yup';

import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ImageIcon from '@material-ui/icons/Image';
import Avatar from "@material-ui/core/Avatar";
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormHelperText from '@material-ui/core/FormHelperText';

import {useSaveProductTransfer} from "shared-react/api/ProductTransferApi";
import {FormMaterialSelectField, FormMaterialTextField, FormDatePickerField} from "shared-react/formik/FormikMaterialFields";
import {useGetMetadata} from "shared-react/api/MetadataApi";

import {ListAmountInput, ListPriceInput, ListItemTextSmallIconButton, ListIndexNumber} from "utils/List";
import ProductTransferAddProduct from "productTransfers/ProductTransferAddProduct";
import {getProductTransferPath} from "shared-react/utils/routes";
import ProgressButton from "shared-react/utils/ProgressButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";

const ProductTransferSchema = Yup.object().shape({
    warehouseFrom: Yup.object({
        id: Yup.string().required('Šis laukas negali būti tuščias'),
    }),
    warehouseTo: Yup.object({
        id: Yup.string().required('Šis laukas negali būti tuščias'),
    }),
    products: Yup.array()
        .required('Pervežime turi būti bent viena prekė'),
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "none",
    //padding: grid,
    width: '100%',
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    //margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "none",

    // styles we need to apply on draggables
    ...draggableStyle
});

const initialProductTransferValues = {
    id: null,
    products: [],
    warehouseFrom: {
        id: '',
    },
    warehouseTo: {
        id: '',
    },
};

function ProductTransferForm({productTransfer, isNew, onSaveSuccess}) {
    const theme = useTheme();
    const [productTransferData, setProductTransferData] = useState(null);
    const [savedProductTransfer, isSubmitting, productTransferErrors, submitProductTransfer] = useSaveProductTransfer(productTransferData, isNew);
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(
        ['userWarehouses']
    );
    const [redirectToTransferPage, setRedirectToTransferPage] = useState(false);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (savedProductTransfer) {
            onSaveSuccess(savedProductTransfer);
            setRedirectToTransferPage(true);
        }
    }, [savedProductTransfer]);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    const onSaveProductTransfer = (values) => {
        //const parsedFormData = parseProductTransferData(values);

        setProductTransferData(values);
        submitProductTransfer();
    };
    const initialValues = isNew ? initialProductTransferValues : productTransfer;

    if (redirectToTransferPage) {
        return (<Redirect push to={getProductTransferPath(savedProductTransfer.id)}/>);
    }

    const onDragEnd = (result, arrayHelpers) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        //console.log('onDragEnd', result.source.index, result.destination.index, result);

        if (result.source.index !== result.destination.index) {
            arrayHelpers.move(result.source.index, result.destination.index);
        }
    };

    const warehouseOptions = metadata.userWarehouses.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.id,
    }));

    return (
        <React.Fragment>
            {/*<PageTitle title={pageTitle}/>*/}
            {/*<span style={{flex: 1}}></span>*/}
            {/*<Button to={buttonRoute} variant="outlined" color="primary" component={LinkWrapper}>*/}
                {/*Asd*/}
            {/*</Button>*/}
            <Formik
                initialValues={initialValues}
                validationSchema={ProductTransferSchema}
                onSubmit={(values) => {
                    //console.log('values', values);
                    onSaveProductTransfer(values);
                }}
            >
                {({values, errors, setFieldValue}) => {
                    //console.log('values errors', values, errors);
                    return (
                        <React.Fragment>
                            <Form>
                                <Paper style={{marginBottom: 16}}>
                                    <div style={{padding: 16}}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                {initialValues.id === null ? (
                                                    <Field
                                                        name={`warehouseFrom.id`}
                                                        label={'Iš sandėlio'}
                                                        options={warehouseOptions}
                                                        component={FormMaterialSelectField}
                                                        fullWidth
                                                        nomargin={1}
                                                        margin="dense"
                                                    />
                                                ) : (
                                                    <>
                                                        {productTransfer.warehouseFrom.name}
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {initialValues.id === null ? (
                                                    <Field
                                                        name={`warehouseTo.id`}
                                                        label={'Į sandėlį'}
                                                        options={warehouseOptions}
                                                        component={FormMaterialSelectField}
                                                        fullWidth
                                                        nomargin={1}
                                                        margin="dense"
                                                    />
                                                ) : (
                                                    <>
                                                        {productTransfer.warehouseTo.name}
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Paper style={{marginBottom: 16}}>
                                    <FieldArray
                                        name={`products`}
                                        render={arrayHelpers => {
                                            return (
                                                <React.Fragment>
                                                    {values.products.length > 0 ? (
                                                        <DragDropContext onDragEnd={(result) => {
                                                            onDragEnd(result, arrayHelpers);
                                                        }}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        <List dense={true}>
                                                                            {values.products.map((transferProduct, productIndex) => (
                                                                                <Draggable key={productIndex} draggableId={'dragganle-' + productIndex} index={productIndex}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            key={productIndex}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            <ListItem
                                                                                                style={{paddingRight: 0}}
                                                                                            >
                                                                                                <ListIndexNumber paddingRight theme={theme}>
                                                                                                    {productIndex+1}
                                                                                                </ListIndexNumber>
                                                                                                <Hidden xsDown>
                                                                                                    <ListItemAvatar  style={{marginRight: 16}}>
                                                                                                        <Avatar>
                                                                                                            <ImageIcon />
                                                                                                        </Avatar>
                                                                                                    </ListItemAvatar>
                                                                                                </Hidden>
                                                                                                <ListItemText
                                                                                                    style={{padding: 0}}
                                                                                                    disableTypography
                                                                                                    primary={transferProduct.product.name}
                                                                                                    secondary={
                                                                                                        <React.Fragment>
                                                                                                            <Typography
                                                                                                                style={{fontSize: 'inherit'}}
                                                                                                                variant="body2"
                                                                                                                color="textSecondary"
                                                                                                            >
                                                                                                                {transferProduct.product.code}
                                                                                                            </Typography>
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                />
                                                                                                <ListAmountInput
                                                                                                    theme={theme}
                                                                                                    paddingRight
                                                                                                >
                                                                                                    <FastField
                                                                                                        name={`products[${productIndex}].amount`}
                                                                                                        label={'Kiekis'}
                                                                                                        type={"number"}
                                                                                                        inputProps={{ min: "1", step: "1" }}
                                                                                                        nomargin={1}
                                                                                                        margin="dense"
                                                                                                        fullWidth
                                                                                                        component={FormMaterialTextField}
                                                                                                    />
                                                                                                </ListAmountInput>
                                                                                                <ListItemTextSmallIconButton>
                                                                                                    <IconButton onClick={() => arrayHelpers.remove(productIndex)}>
                                                                                                        <DeleteIcon/>
                                                                                                    </IconButton>
                                                                                                </ListItemTextSmallIconButton>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </List>
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    ) : null}
                                                    <div style={{padding: '16px 16px 16px 16px'}}>
                                                        <ProductTransferAddProduct currentCount={values.products.length} arrayHelpers={arrayHelpers}/>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </Paper>
                                <div style={{display: 'flex'}}>
                                    <ul style={{flex: 1, paddingLeft: 16, paddingRight: 16, margin: 0}}>
                                        <ErrorMessage
                                            name="products"
                                            component={FormHelperText}
                                            variant="filled"
                                            error
                                        />
                                        {!isSubmitting && productTransferErrors.length > 0
                                            ?
                                            (
                                                productTransferErrors.map((error, errorIndex) => (
                                                    <li key={errorIndex}><FormHelperText error>{error}</FormHelperText></li>
                                                ))
                                            )

                                            : null
                                        }
                                    </ul>
                                    <div>
                                        <ProgressButton
                                            loading={isSubmitting}
                                            text={"Išsaugoti"}
                                            type={"submit"}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    );
}

export default ProductTransferForm;