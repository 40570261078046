import React from "react";

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

import {getProductImagePath, TYPE_THUMBNAIL} from "shared-react/utils/images";

const ListProductImageAvatar = ({productMainImage = null}) => {
    return (
        <ListItemAvatar>
            {productMainImage ? (
                <Avatar src={getProductImagePath(TYPE_THUMBNAIL, productMainImage)} variant="rounded" />
            ) : (
                 <Avatar variant="rounded">
                     <ImageIcon />
                 </Avatar>
             )}
        </ListItemAvatar>
    )
};

export default ListProductImageAvatar;