import schema from "schm";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";

const boolToString = (prevSchema) => prevSchema.merge({
    // parsers: {
    //     boolToString: v => v === true ? "1" : "0",
    // },
    parse: (user = {}) => {
        let userGroups = [];
        if (isObject(user) && 'userGroups' in user && isArray(user['userGroups'])) {
            user['userGroups'].forEach(function(userGroup) {
                if ("id" in userGroup) {
                    userGroups.push(userGroup.id);
                }
            });
        }

        const newUser = {
            ...user,
            isCompany: user.isCompany === true ? "1" : "0",
            userGroups: userGroups,
        };

        return prevSchema.parse(newUser);
    },
});

const userSchema = schema({
    id: { type: Number, default: null},
    username: { type: String, default: ""},
    firstName: { type: String, default: ""},
    lastName: { type: String, default: ""},
    isCompany: { type: String, default: "0", boolToString: true},
    code: { type: String, default: ""},
    pvmCode: { type: String, default: ""},
    email: { type: String, default: ""},
    phoneNumber: { type: String, default: ""},
    phoneNumberCountryCode: { type: String, default: "+370"},
    address: { type: String, default: ""},
    userGroups: { type: Array, default: []},
    plainPassword: { type: String, default: ""},
    plainPasswordConfirmation: { type: String, default: ""},
}, boolToString);

export function parseUserToFormData(user) {
    return userSchema.parse(user);
}