import schema from "schm";
import translate from "schm-translate";
import datefnsParse from "date-fns/parse";
import {parseFormDate} from 'shared-react/utils/form';

const packageMaterialSchema = schema({
    id: { type: Number, default: ""},
    title: { type: String, default: ""},
    category: { type: Number, default: 0},
    type: { type: Number, default: 1},
    amountPerPackage: { type: Number, default: 1},
    packagesAmount: { type: Number, default: 1},
    weightInGrams: { type: Number, default: 0},
    recyclable: { type: Boolean, default: true},
});

export const packageSchema = schema({
    id: { type: Number, default: ""},
    title: { type: String, default: ""},
    supplyingFrom: { type: Date, default: new Date()},

    materials: { type: [packageMaterialSchema], default: []},
});

export function parsePackage(aPackage) {
    if (typeof aPackage !== 'object' || aPackage === null) {
        aPackage = {};
    }

    if (!('supplyingFrom' in aPackage)) {
        aPackage['supplyingFrom'] = new Date(new Date().getFullYear(), 0, 1);
    } else {
        aPackage['supplyingFrom'] = parseFormDate(aPackage['supplyingFrom']);
    }

    return packageSchema.parse(aPackage);
}

export function getEmptyMaterial() {
    return packageMaterialSchema.parse({});
}