import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import LinkText from './LinkText';

function LinkRoute({...props}) {
    return (
        <LinkText component={RouterLink} {...props} />
    );
}
export default LinkRoute;