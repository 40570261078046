import {
    gridDirectionPrefix, gridDirections,
    gridAlignItemsPrefix, gridAlignItems,
    gridJustifyContentPrefix, gridJustifyContent,
} from './GridContainer';

import {setupClassTrait} from 'utils/grapesjs/traits';

export const gridItemWidthPrefix = 'grid-item-';
export const gridItemWidths = ['empty', 'auto', 'start', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const GridItemBlock = (bm) => {
    // bm.add('grid-item').set({
    //     label: `
    //         i
    //         <div>Grid Item</div>
    //     `,
    //     category: 'Grid',
    //     content: {
    //         type: 'grid-item',
    //         classes: ['grid-item', 'grid-item-xs-12']
    //     }
    // });

    bm.add('grid-item-column-1').set({
        label: '1 Column',
        attributes: { class: 'gjs-fonts gjs-f-b1' },
        category: 'Grid',
        content: {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                },
            ],
        }
    });

    bm.add('grid-item-columns-2').set({
        label: '2 Columns',
        attributes: { class: 'gjs-fonts gjs-f-b2' },
        category: 'Grid',
        content: {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '6',
                },
                {
                    type: 'grid-item',
                    mdWidth: '6',
                },
            ],
        }
    });

    bm.add('grid-item-columns-3').set({
        label: '3 Columns',
        attributes: { class: 'gjs-fonts gjs-f-b3' },
        category: 'Grid',
        content: {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '4',
                },
                {
                    type: 'grid-item',
                    mdWidth: '4',
                },
                {
                    type: 'grid-item',
                    mdWidth: '4',
                },
            ],
        }
    });

    bm.add('grid-item-columns-39').set({
        label: '2 Columns 3/9',
        attributes: { class: 'gjs-fonts gjs-f-b37' },
        category: 'Grid',
        content: {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '3',
                },
                {
                    type: 'grid-item',
                    mdWidth: '9',
                },
            ],
        }
    });

    bm.add('grid-item-columns-48').set({
        label: '2 Columns 4/8',
        attributes: { class: 'gjs-fonts gjs-f-b37' },
        category: 'Grid',
        content: {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '4',
                },
                {
                    type: 'grid-item',
                    mdWidth: '8',
                },
            ],
        }
    });

    bm.add('grid-item-columns-57').set({
        label: '2 Columns 5/7',
        attributes: { class: 'gjs-fonts gjs-f-b37' },
        category: 'Grid',
        content: {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '5',
                },
                {
                    type: 'grid-item',
                    mdWidth: '7',
                },
            ],
        }
    });

    bm.add('grid-item-column-center').set({
        label: 'Center Block',
        attributes: { class: 'fa fa-minus-square-o' },
        category: 'Grid',
        content: {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '6',
                },
            ],
        }
    });

};

export const GridItemComponent = (domc, editor) => {
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;

    loadCss(editor);

    domc.addType('grid-item', {
        model: defaultModel.extend({
            defaults: Object.assign({}, defaultModel.prototype.defaults, {
                'custom-name': 'GridItem',
                tagName: 'div',
                //classes: ['grid-item'],
                draggable: '.grid-container',
                droppable: true,
                classes: ['grid-item', 'grid-direction-xs-column', 'grid-align-items-xs-center', 'grid-justify-xs-center'],
                traits: [
                    {
                        name: 'direction',
                        type: 'select',
                        label: 'Direction',
                        changeProp: 1,
                        options: gridDirections.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule,
                        }))
                    },
                    {
                        name: 'justifyContent',
                        type: 'select',
                        label: 'Justify',
                        changeProp: 1,
                        options: gridJustifyContent.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule,
                        }))
                    },
                    {
                        name: 'alignItems',
                        type: 'select',
                        label: 'Align Items',
                        changeProp: 1,
                        options: gridAlignItems.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule,
                        }))
                    },
                    {
                        name: 'xsWidth',
                        type: 'select',
                        label: 'xs Width',
                        changeProp: 1,
                        options: gridItemWidths.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule !== 'empty' ? rule : 'None',
                        })),
                    },
                    {
                        name: 'smWidth',
                        type: 'select',
                        label: 'sm Width',
                        changeProp: 1,
                        options: gridItemWidths.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule !== 'empty' ? rule : 'None',
                        })),
                    },
                    {
                        name: 'mdWidth',
                        type: 'select',
                        label: 'md Width',
                        changeProp: 1,
                        options: gridItemWidths.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule !== 'empty' ? rule : 'None',
                        })),
                    },

                ],
                direction: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                xsWidth: '12',
                smWidth: 'none',
                mdWidth: 'none',
            }),
            init() {
                setupClassTrait(this, 'direction', gridDirections, gridDirectionPrefix, 'row');
                setupClassTrait(this, 'justifyContent', gridJustifyContent, gridJustifyContentPrefix, 'center');
                setupClassTrait(this, 'alignItems', gridAlignItems, gridAlignItemsPrefix, 'center');
                setupClassTrait(this, 'xsWidth', gridItemWidths, gridItemWidthPrefix + 'xs-', '12');
                setupClassTrait(this, 'smWidth', gridItemWidths, gridItemWidthPrefix + 'sm-', '');
                setupClassTrait(this, 'mdWidth', gridItemWidths, gridItemWidthPrefix + 'md-', '');
            },
        }, {
            isComponent(el) {
                let match = false;
                if (el && el.classList) {
                    el.classList.forEach(function(klass) {
                        if(klass=="grid-item" || klass.match(/^grid-item-/)) {
                            match = true;
                        }
                    });
                }
                if (match) return {type: 'grid-item'};
            }
        }),
        view: defaultView
    });
}

function loadCss(editor) {
    editor.CssComposer.setRule('.grid-item', {
        margin: '0',
        padding: '8px',
        'box-sizing': 'border-box',
        display: 'flex',
        'flex-wrap': 'wrap',
    });

    generateColumnSizes(editor, 'xs', null);
    generateColumnSizes(editor, 'sm', '(min-width:600px)');
    generateColumnSizes(editor, 'md', '(min-width:960px)');
    generateColumnSizes(editor, 'lg', '(min-width:1280px)');
    generateColumnSizes(editor, 'xl', '(min-width:1920px)');
}

function generateColumnSizes(editor, size, media) { //xs, 1|2|3, percentage, media
    const mediaObject = media ? {
        atRuleType: 'media',
        atRuleParams: media,
    } : {};
    //column, percentage,
    editor.CssComposer.setRule(`.grid-item-${size}-auto`, {
        'flex-grow': 0,
        'max-width': 'none',
        'flex-basis': 'auto',
    }, mediaObject);

    editor.CssComposer.setRule(`.grid-item-${size}-true`, {
        'flex-grow': 1,
        'max-width': '100%',
        'flex-basis': 0,
    }, mediaObject);

    for (let i = 1; i <= 12; i++) {
        editor.CssComposer.setRule(`.grid-item-${size}-${i}`, {
            'flex-grow': 1,
            'max-width': (100 / 12 * i).toFixed(6) + '%',
            'flex-basis': (100 / 12 * i).toFixed(6) + '%',
        }, mediaObject);
    }
}