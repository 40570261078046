import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray, ErrorMessage, FastField} from 'formik';
import {Link, Redirect} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Yup from 'yup';
import datefnsFormat from 'date-fns/format'

import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ImageIcon from '@material-ui/icons/Image';
import Avatar from "@material-ui/core/Avatar";
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {useSavePurchaseOrder} from "shared-react/api/PurchaseOrderApi";
import {FormMaterialSelectField, FormMaterialTextField, FormDatePickerField} from "shared-react/formik/FormikMaterialFields";
import PurchaseOrderAddProduct from "./PurchaseOrderAddProduct";
import PurchaseOrderParseInvoicePdf from "purchaseOrders/PurchaseOrderParseInvoicePdf";
import PurchaseOrderParseInvoice from "purchaseOrders/PurchaseOrderParseInvoice";
import {parsePurchaseOrderServerToFormData, parsePurchaseOrderFormToServerData} from 'purchaseOrders/schemas'
import {useGetMetadata} from "shared-react/api/MetadataApi";

import {ListAmountInput, ListPriceInput, ListItemTextSmallIconButton, ListIndexNumber} from "utils/List";
import PurchaseOrderFormInputs from "purchaseOrders/PurchaseOrderFormInputs";
import {getPurchaseOrderPath, ROUTE_PURCHASE_ORDER} from "shared-react/utils/routes";
import PageTitleWithButton from "utils/PageTitleWithButton";
import PageTitle from "../utils/PageTitleWithButton";
import LinkWrapper from "../utils/LinkWrapper";
import ProgressButton from "shared-react/utils/ProgressButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";

const PurchaseOrderSchema = Yup.object().shape({
    title: Yup.string()
        .required('Šis laukas negali būti tuščias'),
    warehouseId: Yup.string()
        .required('Šis laukas negali būti tuščias'),
    receivedAt: Yup.date()
        .nullable()
        .required('Šiame lauke turi būti data formatu 0000-00-00'),
    orderProducts: Yup.array()
        .required('Užsakyme turi būti bent viena prekė'),
});

const grid = 8;
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "none",
    //padding: grid,
    width: '100%',
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    //margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "none",

    // styles we need to apply on draggables
    ...draggableStyle
});

function PurchaseOrderForm({purchaseOrder, isNew, onSaveSuccess}) {
    const theme = useTheme();
    const [purchaseOrderData, setPurchaseOrderData] = useState(null);
    const [savedPurchaseOrder, isSubmitting, purchaseOrderErrors, submitPurchaseOrder] = useSavePurchaseOrder(purchaseOrderData, isNew);
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(
        ['warehouses']
    );
    const [redirectToPurchaseOrder, setRedirectToPurchaseOrder] = useState(false);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (savedPurchaseOrder) {
            onSaveSuccess(savedPurchaseOrder);
            setRedirectToPurchaseOrder(true);
        }
    }, [savedPurchaseOrder]);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    if (redirectToPurchaseOrder) {
        return (<Redirect push to={getPurchaseOrderPath(savedPurchaseOrder.id)}/>);
    }

    const onSavePurchaseOrder = (values) => {
        const newValues = {
            ...values,
            receivedAt: datefnsFormat(values.receivedAt, 'yyyy-MM-dd'),
        };
        const parsedFormData = parsePurchaseOrderFormToServerData(newValues);

        setPurchaseOrderData(parsedFormData);
        submitPurchaseOrder();
    };
    const initialValues = parsePurchaseOrderServerToFormData(purchaseOrder, metadata.warehouses[0]);

    const onDragEnd = (result, arrayHelpers) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        //console.log('onDragEnd', result.source.index, result.destination.index, result);

        if (result.source.index !== result.destination.index) {
            arrayHelpers.move(result.source.index, result.destination.index);
        }
    };

    const warehouseOptions = metadata.warehouses.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.id,
    }));

    return (
        <React.Fragment>
            {/*<PageTitle title={pageTitle}/>*/}
            {/*<span style={{flex: 1}}></span>*/}
            {/*<Button to={buttonRoute} variant="outlined" color="primary" component={LinkWrapper}>*/}
                {/*Asd*/}
            {/*</Button>*/}
            <Formik
                initialValues={initialValues}
                validationSchema={PurchaseOrderSchema}
                onSubmit={(values) => {
                    onSavePurchaseOrder(values);
                }}
            >
                {({values, errors, setFieldValue}) => {
                    const total = values.orderProducts.reduce((total, orderProducts) => {
                         return total + parseFloat((orderProducts.amount * orderProducts.price).toFixed(2));
                     }, 0.0);
                    // console.log('total', total.toFixed(2));

                    return (
                        <React.Fragment>
                            <Form>
                                <Paper style={{marginBottom: 16}}>
                                    <div style={{padding: 16}}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={4}>
                                                <Field
                                                    name="title"
                                                    label="Pavadinimas"
                                                    component={FormMaterialTextField}
                                                    fullWidth
                                                    nomargin={1}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            {initialValues.id === null ? (
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Field
                                                        name={`warehouseId`}
                                                        label={'Sandėlis'}
                                                        options={warehouseOptions}
                                                        component={FormMaterialSelectField}
                                                        fullWidth
                                                        nomargin={1}
                                                        margin="dense"
                                                    />
                                                </Grid>
                                            ) : null}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Field
                                                    name="receivedAt"
                                                    label={'Gavimo data'}
                                                    component={FormDatePickerField}
                                                    invalidDateMessage={"Neegzistuojanti data"}
                                                    fullWidth
                                                    nomargin={1}
                                                    margin="dense"
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Paper style={{marginBottom: 16}}>
                                    <FieldArray
                                        name={`orderProducts`}
                                        render={arrayHelpers => {
                                            return (
                                                <React.Fragment>
                                                    {values.orderProducts.length > 0 ? (
                                                        <DragDropContext onDragEnd={(result) => {
                                                            onDragEnd(result, arrayHelpers);
                                                        }}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        <List dense={true}>
                                                                            {values.orderProducts.map((orderProduct, productIndex) => (
                                                                                <Draggable key={productIndex} draggableId={'dragganle-' + productIndex} index={productIndex}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            key={productIndex}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            <ListItem
                                                                                                style={{paddingRight: 0}}
                                                                                            >
                                                                                                <ListIndexNumber paddingRight theme={theme}>
                                                                                                    {productIndex+1}
                                                                                                </ListIndexNumber>
                                                                                                <Hidden xsDown>
                                                                                                    <ListItemAvatar  style={{marginRight: 16}}>
                                                                                                        <Avatar>
                                                                                                            <ImageIcon />
                                                                                                        </Avatar>
                                                                                                    </ListItemAvatar>
                                                                                                </Hidden>
                                                                                                <ListItemText
                                                                                                    style={{padding: 0}}
                                                                                                    disableTypography
                                                                                                    primary={orderProduct.product.id ? orderProduct.product.name : (
                                                                                                        <div style={{padding: '8px 8px 8px 0'}}>
                                                                                                            <Grid container spacing={1}>
                                                                                                                <Grid item xs={12}>
                                                                                                                    <FastField
                                                                                                                        name={`orderProducts[${productIndex}].product.name`}
                                                                                                                        label={'Pavadinimas'}
                                                                                                                        nomargin={1}
                                                                                                                        margin="dense"
                                                                                                                        fullWidth
                                                                                                                        component={FormMaterialTextField}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12} sm={6}>
                                                                                                                    <FastField
                                                                                                                        name={`orderProducts[${productIndex}].product.code`}
                                                                                                                        label={'Kodas'}
                                                                                                                        nomargin={1}
                                                                                                                        margin="dense"
                                                                                                                        fullWidth
                                                                                                                        component={FormMaterialTextField}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12} sm={6}>
                                                                                                                    <FastField
                                                                                                                        name={`orderProducts[${productIndex}].product.price`}
                                                                                                                        label={'Kaina'}
                                                                                                                        nomargin={1}
                                                                                                                        margin="dense"
                                                                                                                        type="number"
                                                                                                                        inputProps={{ min: "0", step: "0.01" }}
                                                                                                                        InputProps={{
                                                                                                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                                                                                        }}
                                                                                                                        fullWidth
                                                                                                                        component={FormMaterialTextField}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    secondary={
                                                                                                        <React.Fragment>
                                                                                                            {orderProduct.product.id ? (
                                                                                                                <>
                                                                                                                    <Typography
                                                                                                                        style={{fontSize: 'inherit'}}
                                                                                                                        variant="body2"
                                                                                                                        color="textSecondary"
                                                                                                                    >
                                                                                                                        {orderProduct.product.code}
                                                                                                                    </Typography>
                                                                                                                    <Hidden smUp>
                                                                                                                        <div style={{display: 'flex', marginTop: 8}}>
                                                                                                                            {/*<div style={{flex: 1}}></div>*/}
                                                                                                                            <PurchaseOrderFormInputs
                                                                                                                                arrayHelpers={arrayHelpers}
                                                                                                                                productIndex={productIndex}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </Hidden>
                                                                                                                </>
                                                                                                            ) : null}
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                />
                                                                                                <Hidden xsDown>
                                                                                                    <PurchaseOrderFormInputs
                                                                                                        arrayHelpers={arrayHelpers}
                                                                                                        productIndex={productIndex}
                                                                                                    />
                                                                                                </Hidden>
                                                                                                <ListItemTextSmallIconButton>
                                                                                                    <IconButton onClick={() => arrayHelpers.remove(productIndex)}>
                                                                                                        <DeleteIcon/>
                                                                                                    </IconButton>
                                                                                                </ListItemTextSmallIconButton>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </List>
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    ) : null}
                                                    <div style={{
                                                        padding: '16px 52px 16px 16px',
                                                        textAlign: 'right',
                                                        fontWeight: 'bold',
                                                    }}>
                                                        Bendra suma: {total.toFixed(2)}
                                                    </div>
                                                    <div style={{padding: '16px 16px 16px 16px'}}>
                                                        <PurchaseOrderAddProduct currentCount={values.orderProducts.length} arrayHelpers={arrayHelpers}/>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </Paper>
                                <div style={{display: 'flex'}}>
                                    {/*<PurchaseOrderParseInvoicePdf orderProducts={values.orderProducts} setFieldValue={setFieldValue} />*/}
                                    {/*<PurchaseOrderParseInvoice orderProducts={values.orderProducts} setFieldValue={setFieldValue} />*/}
                                    <ul style={{flex: 1, paddingLeft: 16, paddingRight: 16, margin: 0}}>
                                        <ErrorMessage
                                            name="orderProducts"
                                            component={FormHelperText}
                                            variant="filled"
                                            error
                                        />
                                        {!isSubmitting && purchaseOrderErrors.length > 0
                                            ?
                                            (
                                                purchaseOrderErrors.map((error, errorIndex) => (
                                                    <li key={errorIndex}><FormHelperText error>{error}</FormHelperText></li>
                                                ))
                                            )

                                            : null
                                        }
                                    </ul>
                                    <div>
                                        <ProgressButton
                                            loading={isSubmitting}
                                            text={"Išsaugoti"}
                                            type={"submit"}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    );
}

export default PurchaseOrderForm;