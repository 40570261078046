import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import {useGetMetadata} from 'shared-react/api/MetadataApi';
import {API_PRODUCTS, useGetProducts} from 'shared-react/api/ProductApi';
import SearchProducts from "shop/SearchProducts";
import WarehouseSelect from "shared-react/shop/WarehouseSelect";
import SearchText from "shop/SearchText";
import CustomerSelect from "shared-react/user/CustomerSelect";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {
    NAME_NEW_ORDER,
    setOrderCustomerId,
} from "shared-react/ducks/order";
import {setPage} from "shared-react/ducks/shop";
import ListTablePagination from "utils/ListTablePagination";
import {getSearchPath, getProductsMergePath} from 'shared-react/utils/routes';
import ProgressInputIndicator from 'shared-react/utils/ProgressInputIndicator';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

import {ROUTE_PRODUCTS_NEW} from 'shared-react/utils/routes';
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from 'shared-react/utils/PermissionRequiredComponent';
import LinkWrapper from "utils/LinkWrapper";
import ListSelectActions from "../utils/ListSelect/ListSelectActions";
import ActionsChoice from "../utils/ActionsChoice";
import useListSelect, {ListSelectProvider} from "../utils/ListSelect/ListSelectContext";
import {API_URL} from "shared-react/api/Api";
import {API_CALCULATE_ORDER} from "shared-react/api/OrderApi";

function SearchPage({match, history, customerId, warehouseId, searchText, setOrderCustomerId, page, setPage}) {
    const perPage = 40;
    //const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const queryParams = {
        page,
        perPage,
        q: searchText,
        customerId,
        warehouseId,
        onlyVariants: 0,
        order: "saleCountDesc",
    };

    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(['userCustomers', 'warehouses', 'userWarehouses']);
    const [products, totalCount, isProductsLoading, productsErrors, fetchProducts] = useGetProducts(API_URL + API_PRODUCTS, queryParams);

    const [listSelectState, listSelectDispatch] = useListSelect();

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (searchText.length > 2) {
            fetchProducts();
        }
    }, [page, customerId, warehouseId, searchText]);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    return (
        <>
        <FillHeightContainer header={
            <>
                <div style={{
                    margin: 8,
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SearchText
                                // isDisabled={isProductsLoading}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <CustomerSelect
                                customersMetadata={metadata.userCustomers}
                                customerId={customerId}
                                creatable
                                label={"Pirkėjas"}
                                onChange={(customerId) => {
                                    setOrderCustomerId(customerId);
                                }}
                                disabled={isProductsLoading}
                                nomargin={1}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} style={{display: 'inline-flex'}}>
                            <div style={{
                                flex: '1 1 0%',
                                marginRight: 16,
                            }}>
                                <WarehouseSelect
                                    metadata={metadata}
                                    isDisabled={isProductsLoading}
                                />
                            </div>
                            <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_CREATE}>
                                <Fab to={ROUTE_PRODUCTS_NEW} color="primary" aria-label="add" size="small" component={LinkWrapper}>
                                    <AddIcon />
                                </Fab>
                            </PermissionRequiredComponent>
                        </Grid>
                        <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_EDIT}>
                            <Grid item xs={12}>
                                <ListSelectActions>
                                    <ActionsChoice label={"Apjungti prekes"} onClick={() => {
                                        //mergeSaleOrders();
                                        history.push(getProductsMergePath(listSelectState.selected.join(',')));
                                    }} />
                                </ListSelectActions>
                            </Grid>
                        </PermissionRequiredComponent>
                    </Grid>
                </div>
                {isProductsLoading ? (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: 4,
                        bottom: -5,
                    }}>
                        <ProgressInputIndicator />
                    </div>
                ) : null}
            </>
        }>
            <div>
                {
                    products && !isProductsLoading
                        ?
                        (
                            <React.Fragment>
                                <Paper square elevation={0}>
                                    <List dense={true} style={{paddingBottom: 0}}>
                                        <SearchProducts
                                            products={products}
                                            warehouseId={warehouseId}
                                        />
                                        <ListTablePagination
                                            perPage={perPage}
                                            totalCount={totalCount}
                                            page={page}
                                            onChangePage={(event, page) => {
                                                setPage(page);
                                                //history.push(getSearchPath(page));
                                            }}
                                        />
                                    </List>
                                </Paper>
                            </React.Fragment>
                        )
                        :
                        null
                }
            </div>
        </FillHeightContainer>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        customerId: state.newOrder.orderData.customerId,
        warehouseId: state.shop.warehouseId,
        searchText: state.shop.searchText,
        page: state.shop.page,
        //userDefaultWarehouseId: state.auth.defaultWarehouseId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setOrderCustomerId: (customerId) => {
        dispatch(setOrderCustomerId(NAME_NEW_ORDER, API_CALCULATE_ORDER, customerId));
    },
    setPage: (page) => {
        dispatch(setPage(page));
    },
});

function SearchPageWithProvider({...props}) {
    return (
        <ListSelectProvider>
            <SearchPage {...props} />
        </ListSelectProvider>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPageWithProvider);