import React from 'react';
import {Field, FieldArray} from "formik";

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';

import {FormMaterialSelectField, FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";
import {parseProductUserGroupPrice} from 'schemas/product';
import ConfirmationDialog from 'shared-react/utils/ConfirmationDialog';

function ProductFormPrices({namePrefix, currentUserGroupPrices, userGroupOptions}) {
    return (
        <React.Fragment>
            <Field
                name={`${namePrefix}price`}
                label="Bendra"
                margin="dense"
                fullWidth
                component={FormMaterialTextField}
                type="number"
                inputProps={{ min: "0", step: "0.01" }}
            />
            <Field
                name={`${namePrefix}discountPrice`}
                label="Nuolaida"
                margin="dense"
                fullWidth
                component={FormMaterialTextField}
                type="number"
                inputProps={{ min: "0", step: "0.01" }}
            />
            <FieldArray
                name={`${namePrefix}userGroupPrices`}
                render={arrayHelpers => (
                    <div>
                        {currentUserGroupPrices.map((userGroupPrice, userGroupPriceIndex) => (
                            <React.Fragment key={`${namePrefix}userGroupPrice${userGroupPriceIndex}`}>
                                <Divider variant="middle" />
                                <div style={{ paddingTop: 8, paddingBottom: 8 }} >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} lg={5}>
                                            <Field
                                                name={`${namePrefix}userGroupPrices[${userGroupPriceIndex}].userGroupId`}
                                                label={'Kliento grupė'}
                                                margin="dense"
                                                options={userGroupOptions}
                                                component={FormMaterialSelectField}
                                                fullWidth
                                                nomargin={1}
                                            />
                                        </Grid>
                                        <Grid item xs={4} lg={2}>
                                            <Field
                                                name={`${namePrefix}userGroupPrices[${userGroupPriceIndex}].quantity`}
                                                label={'Min. kiekis'}
                                                margin="dense"
                                                component={FormMaterialTextField}
                                                fullWidth
                                                nomargin={1}
                                                type="number"
                                                inputProps={{ min: "1", step: "1" }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} lg={3}>
                                            <Field
                                                name={`${namePrefix}userGroupPrices[${userGroupPriceIndex}].price`}
                                                label={'Vnt. kaina'}
                                                margin="dense"
                                                component={FormMaterialTextField}
                                                fullWidth
                                                nomargin={1}
                                                type="number"
                                                inputProps={{ min: "0", step: "0.01" }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} lg={2} style={{textAlign: 'right'}}>
                                            <ConfirmationDialog
                                                dialogContent={"Ar tikrai norite pašalinti šią grupės ir kiekio kainą"}
                                                confirmationFunction={() => arrayHelpers.remove(userGroupPriceIndex)}
                                            >
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ConfirmationDialog>
                                        </Grid>
                                    </Grid>
                                </div>
                            </React.Fragment>
                        ))}
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                arrayHelpers.push({
                                    ...parseProductUserGroupPrice(),
                                })
                            }}>
                            Pridėti grupės ar kiekio kainą
                        </Button>
                    </div>
                )}
            />
        </React.Fragment>
    );
}

export default ProductFormPrices;