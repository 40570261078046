import React, {useEffect, useRef, useState} from 'react';
import grapesjs from "grapesjs";
import grapesJSWeb from "grapesjs-preset-webpage";
import get from "lodash/get";
import {getImagePath, TYPE_MEDIUM} from "shared-react/utils/images";

// import grapesJsPluginCkeditor from "grapesjs-plugin-ckeditor";
import CustomPlugin from "utils/grapesjs/CustomPlugin";
import {useFormikContext} from "formik";
import {getDefaultArticleTemplate} from "./articleDefaults";
import isArray from "lodash/isArray";

function updateArticleFields(editorRef, values, setFieldValue)
{
    const editor = editorRef.current;

    if (editor) {
        // console.log('updateArticleFields', editor.runCommand('gjs-get-inlined-html'));
        //setFieldValue('articleContent', editor.getHtml());
        // setFieldValue('articleContent', editor.runCommand('gjs-get-inlined-html'));

        setFieldValue('article.gjsComponents', JSON.stringify(editor.getComponents()));
        setFieldValue('article.gjsStyles', JSON.stringify(editor.getStyle()));
        setFieldValue('article.gjsHtml', editor.getHtml());
        setFieldValue('article.gjsCss', editor.getCss());
    }
}

function ArticleEditor() {
    const editorRef = useRef(null);
    const { values, setFieldValue, isSubmitting } = useFormikContext();
    const [count, setCount] = useState(0);
    //const [localContent, setLocalContent] = useState(values.articleContent);

    React.useEffect(() => {
        if (isSubmitting) {
            //console.log('isSubmitting!!!', values);
            updateArticleFields(editorRef, values, setFieldValue);
        }
    }, [isSubmitting]);

    useEffect(() => {
        let components = [];
        let style = [];

        try {
            components = JSON.parse(values.article.gjsComponents);
            style = JSON.parse(values.article.gjsStyles);
        } catch(e) {
        }

        const images = values.article.images.map((image) => {
            return getImagePath(TYPE_MEDIUM, image.folder, image.versionedName);
        });

        console.log('article components', getDefaultArticleTemplate());

        const editor = grapesjs.init({
            assetManager: {
                assets: images,
                upload: false,
                dropzone: false,
            },
            styleManager: {
                appendTo: '',
            },
            // Indicate where to init the editor. You can also pass an HTMLElement
            container: '#gjs-article',
            fromElement: false,
            components: isArray(components) && components.length > 0 ? components : getDefaultArticleTemplate(),
            style: style || '',
            // Size of the editor
            // height: '800px',
            // width: 'auto',
            storageManager: false,
            // storageManager: {
            //     type: 'remote',
            //     stepsBeforeSave: 1,
            //     urlStore: 'http://store/endpoint',
            //     urlLoad: 'http://load/endpoint',
            //     params: {}, // Custom parameters to pass with the remote storage request, eg. CSRF token
            //     headers: {}, // Custom headers for the remote storage request
            // },
            // Avoid any default panel
            avoidInlineStyle: true,
            plugins: [CustomPlugin],
           //  plugins: [grapesJSWeb],
            // pluginsOpts: {
            //     [grapesJSWeb]: {
            //         // blocks: ['link-block', 'quote', 'text-basic'],
            //         blocksBasicOpts: {
            //             flexGrid: true,
            //         }
            //     },
            // },
        });

        // let bm = editor.BlockManager;
        // const assetManager = editor.AssetManager;
        // const domc = editor.DomComponents;

        // bm.add('image', {
        //     label: 'label',
        //     category: 'category',
        //     // attributes: { class: 'gjs-fonts gjs-f-image' },
        //     content: {
        //         style: { color: 'black' },
        //         type: 'image',
        //         src: 'asd',
        //         //activeOnRender: 1
        //     }
        // });
        //
        // assetManager.addType('image', {
        //     // As you adding on top of an already defined type you can avoid indicating
        //     // `am.getType('image').view.extend({...` the editor will do it by default
        //     // but you can eventually extend some other type
        //     view: {
        //         getPreview() {
        //             const pfx = this.pfx;
        //             const src = this.model.get('src');
        //             const model = this.model;
        //             console.log('model', model);
        //           return `
        //           <div class="${pfx}preview" style="background-image: url('${src}');"></div>
        //           <div class="${pfx}preview-bg ${this.ppfx}checker-bg"></div>
        //         `;
        //         },
        //         // If you want to see more methods to extend check out
        //         // https://github.com/artf/grapesjs/blob/dev/src/asset_manager/view/AssetImageView.js
        //         onRemove(e) {
        //             e.stopPropagation();
        //             const model = this.model;
        //
        //             if (confirm('Are you sure?')) {
        //                 model.collection.remove(model);
        //             }
        //         }
        //     },
        // })
        //
        // domc.addType('image', {
        //     model: {
        //         toJSON(...args) {
        //             //console.log('toJson', args, domc.getType('image'), domc.getTypes());
        //             //console.log('toJson', args, domc.getType('default').model.prototype.toJSON.apply(this, args), domc.getTypes());
        //
        //             const obj = domc.getType('default').model.prototype.toJSON.apply(this, args);
        //             console.log('obj', obj);
        //
        //             const src = get(obj, 'src', null);
        //             const attributesSrc = get(obj, 'attributes.src', null);
        //             if (src && attributesSrc && src === attributesSrc) {
        //                 delete obj.src;
        //             }
        //
        //             return obj;
        //         },
        //     },
        // });

        editor.on('change:changesCount', e => {
            setCount(prev => {
                return prev + 1;
            });
        });



        editorRef.current = editor;

        return () => {
            editor.destroy();
        }
    }, []);

    useEffect(() => {
        updateArticleFields(editorRef, values, setFieldValue);

        console.log('count update!!!');

    }, [count]);

    useEffect(() => {
        const editor = editorRef.current;

        if (editor) {
            const assetManager = editor.AssetManager;

            let imagesToRemove = [];
            assetManager.getAll().each((item, index) => {
                imagesToRemove.push(item.get('src'));
            });

            imagesToRemove.forEach((imageSrc, index) => {
                assetManager.remove(imageSrc);
            });

            values.article.images.forEach((image, index) => {
                console.log('image', image, index);
                assetManager.add(getImagePath(TYPE_MEDIUM, image.folder, image.versionedName));
            });
        }
    }, [values.article.images]);

    return (
        <>
            <div
                id="gjs-article"
                style={{
                    display: 'block',
                }}
                // dangerouslySetInnerHTML={{
                //     //__html2: localContent,
                //     __html: localContent ? localContent : `
                //
                //                     `,
                // }}
            >
            </div>
        </>
    )
}

export default ArticleEditor;