import { createMuiTheme } from '@material-ui/core/styles';

//const theme = createMuiTheme();

// export const secondaryColor = '#FDD835';
export const secondaryColor = '#f4bc01';
export const secondaryColorButtonHover = '#b19725';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#424242',
        },
        secondary: {
            main: secondaryColor,
        },
        //type: 'dark',
    },
});

export const darkTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#fff',
        },
        secondary: {
            main: secondaryColor,
        },
        type: 'dark',
    },
});

//console.log('theme', theme);

export default theme;