import React from 'react';
import {connect} from 'react-redux'
import get from 'lodash/get';

function PermissionRequiredComponent({ children, userPermissions, permission, fallback = null, ...rest }) {
    return (
        <React.Fragment>
            {userPermissions.includes(permission)
                ?
                <React.Fragment>{children}</React.Fragment>
                : fallback ?
                    <React.Fragment>{fallback}</React.Fragment> : null
            }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userPermissions: get(state, 'auth.permissions', []),
    }
};

export default connect(
    mapStateToProps,
    null
)(PermissionRequiredComponent);