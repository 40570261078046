import React from 'react';
import {Field, FieldArray} from "formik";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {MaterialTableTextField} from "shared-react/formik/FormikMaterialFields";

function ProductFormWarehouses({namePrefix, warehouses}) {
    return (
        <React.Fragment>
            <FieldArray
                name={`${namePrefix}warehouses`}
                render={arrayHelpers => {
                    return (
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sandėlis</TableCell>
                                    <TableCell align="right">Priminimas</TableCell>
                                    <TableCell align="right">Kiekis</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {warehouses.map((warehouse, index) => (
                                    <TableRow key={`${namePrefix}-${index}`}>
                                        <TableCell>{warehouse.name}</TableCell>
                                        <TableCell align="right">
                                            <Field
                                                name={`${namePrefix}warehouses.${index}.remindAt`}
                                                type="number"
                                                component={MaterialTableTextField}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Field
                                                name={`${namePrefix}warehouses.${index}.amount`}
                                                type="number"
                                                component={MaterialTableTextField}
                                            />
                                        </TableCell>
                                    </TableRow>


                                ))}
                                {/*<div key={`${namePrefix}-${index}`}>*/}
                                    {/*<Field*/}
                                        {/*name={`${namePrefix}warehouses.${index}.remindAt`}*/}
                                        {/*label={warehouse.name}*/}
                                        {/*fullWidth*/}
                                        {/*component={MaterialTextField}*/}
                                    {/*/>*/}
                                {/*</div>*/}
                            </TableBody>
                        </Table>
                    )
                }}
            />
        </React.Fragment>
    );
}

export default ProductFormWarehouses;