import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
    Switch,
    Route,
} from 'react-router-dom'

import {useTheme} from "@material-ui/styles";

import RoutePermission from 'utils/RoutePermission'
import UsersPage from 'users/UsersPage'
import UserPage from 'users/UserPage'
import UserPermissionGroupsPage from 'userPermissionGroups/UserPermissionGroupsPage'
import UserPermissionGroupPage from 'userPermissionGroups/UserPermissionGroupPage'
import UserGroupsPage from 'userGroups/UserGroupsPage'
import UserGroupPage from 'userGroups/UserGroupPage'
import NewUserGroupPage from 'userGroups/NewUserGroupPage'
import ProductsPage from 'products/ProductsPage'
import ProductPage from 'products/ProductPage'
import ProductAddEditPage from 'products/ProductAddEditPage'
import ProductsMergePage from 'products/ProductsMergePage'
import ProductCategoriesPage from 'productCategories/ProductCategoriesPage'
import ProductCategoryAddPage from "productCategories/ProductCategoryAddPage";
import ProductCategoryEditPage from "productCategories/ProductCategoryEditPage";
import ProductCollectionsPage from 'productCollections/ProductCollectionsPage'
import ProductCollectionAddEditPage from "productCollections/ProductCollectionAddEditPage";
import SearchPage from "shop/SearchPage";
import CheckoutPage from "shop/CheckoutPage";
import SaleOrdersPage from "saleOrders/SaleOrdersPage";
import SaleOrderPage from "saleOrders/SaleOrderPage";
import PurchaseOrdersPage from "purchaseOrders/PurchaseOrdersPage";
import PurchaseOrderPage from "purchaseOrders/PurchaseOrderPage";
import PurchaseOrderAddPage from "purchaseOrders/PurchaseOrderAddPage";
import ProductTransfersPage from "productTransfers/ProductTransfersPage";
import ProductTransferPage from "productTransfers/ProductTransferPage";
import ProductTransfersAddPage from "productTransfers/ProductTransferAddPage";
import PackagesPage from "packages/PackagesPage";
import PackageAddPage from "packages/PackageAddPage";
import PackageEditPage from "packages/PackageEditPage";
import ProductsLowQuantityPage from "productsLowQuantity/ProductsLowQuantityPage";
import InvoicesPage from "invoices/InvoicesPage";
import CarouselSlidesPage from "website/carousel/CarouselSlidesPage";
import AddEditCarouselSlidePage from "website/carousel/AddEditCarouselSlidePage";
import NewsPage from "website/news/NewsPage";
import AddEditNewsPage from "website/news/AddEditNewsPage";

import AppMenu from "menu/AppMenu";

import StyledToolbarHeightDiv from 'utils/StyledToolbarHeightDiv';

import * as permissions from 'shared-react/constants/permissions';
import * as routes from 'shared-react/utils/routes';
import NewUserPage from "../users/NewUserPage";
import PrintInvoicePage from "../invoices/PrintInvoicePage";
import PrintReceiptPage from "../invoices/PrintReceiptPage";

const StyledMain = styled.main`
  && {
    flex-grow: 1;
    // padding: ${props => props.theme.spacing(2)}px;
    // ${props => props.theme.breakpoints.up('md')} {
    //     padding: ${props => props.theme.spacing(3)}px;
    // }
`;
//padding: ${props => props.theme.spacing(3)}px ${props => props.theme.spacing(3)}px ${props => props.theme.spacing(3)}px ${props => props.theme.spacing.unit * 6}px;

const Home = () => (
    <div>
        {/*<h2>Home</h2>*/}
    </div>
);

const NoMatch = () => (
    <div>
        <h2>Puslapis nerastas</h2>
    </div>
);

function RootApp({logoutUser}) {
    const theme = useTheme();

    return (
        <React.Fragment>
            <div style={{display:'flex'}}>
                <AppMenu />
                <StyledMain theme={theme}>
                    <StyledToolbarHeightDiv theme={theme} />
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        {/*<Route exact path="/paieska/:searchText?" component={SearchPage}/>*/}
                        <RoutePermission
                            permission={permissions.PERMISSION_SALE_ORDERS_VIEW}
                            path={routes.ROUTE_SALE_ORDER}
                            component={SaleOrderPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_SALE_ORDERS_LIST_VIEW}
                            path={routes.ROUTE_SALE_ORDERS}
                            component={SaleOrdersPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCTS_EDIT}
                            path={routes.ROUTE_PRODUCTS_MERGE}
                            component={ProductsMergePage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCTS_CREATE}
                            path={routes.ROUTE_PRODUCTS_NEW}
                            component={ProductAddEditPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCTS_VIEW}
                            path={routes.ROUTE_PRODUCT}
                            component={ProductPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCTS_LIST_VIEW}
                            exact
                            path={routes.ROUTE_PRODUCTS}
                            component={SearchPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_CATEGORIES_VIEW}
                            path={routes.ROUTE_PRODUCT_CATEGORIES_NEW}
                            component={ProductCategoryAddPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_CATEGORIES_EDIT}
                            path={routes.ROUTE_PRODUCT_CATEGORY}
                            component={ProductCategoryEditPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_CATEGORIES_LIST_VIEW}
                            path={routes.ROUTE_PRODUCT_CATEGORIES}
                            component={ProductCategoriesPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_CATEGORIES_VIEW}
                            path={routes.ROUTE_PRODUCT_COLLECTIONS_NEW}
                            component={ProductCollectionAddEditPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_CATEGORIES_EDIT}
                            path={routes.ROUTE_PRODUCT_COLLECTION}
                            component={ProductCollectionAddEditPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_CATEGORIES_LIST_VIEW}
                            path={routes.ROUTE_PRODUCT_COLLECTIONS}
                            component={ProductCollectionsPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCTS_LOW_QUANTITY_LIST_VIEW}
                            path={routes.ROUTE_PRODUCT_LOW_QUANTITY}
                            component={ProductsLowQuantityPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USERS_CREATE}
                            path={routes.ROUTE_USERS_NEW}
                            component={NewUserPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USERS_LIST_VIEW}
                            path={routes.ROUTE_USERS}
                            component={UsersPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USERS_VIEW}
                            path={routes.ROUTE_USER}
                            component={UserPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USER_PERMISSIONS_LIST_VIEW}
                            path={routes.ROUTE_USER_PERMISSION_GROUPS}
                            component={UserPermissionGroupsPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USER_PERMISSIONS_VIEW}
                            path={routes.ROUTE_USER_PERMISSION_GROUP}
                            component={UserPermissionGroupPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USER_GROUPS_CREATE}
                            path={routes.ROUTE_USER_GROUPS_NEW}
                            component={NewUserGroupPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USER_GROUPS_VIEW}
                            path={routes.ROUTE_USER_GROUP}
                            component={UserGroupPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_USER_GROUPS_LIST_VIEW}
                            path={routes.ROUTE_USER_GROUPS}
                            component={UserGroupsPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PURCHASE_ORDERS_CREATE}
                            path={routes.ROUTE_PURCHASE_ORDERS_NEW}
                            component={PurchaseOrderAddPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PURCHASE_ORDERS_VIEW}
                            path={routes.ROUTE_PURCHASE_ORDER}
                            component={PurchaseOrderPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PURCHASE_ORDERS_LIST_VIEW}
                            path={routes.ROUTE_PURCHASE_ORDERS}
                            component={PurchaseOrdersPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_TRANSFERS_CREATE}
                            path={routes.ROUTE_PRODUCT_TRANSFERS_NEW}
                            component={ProductTransfersAddPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_TRANSFERS_VIEW}
                            path={routes.ROUTE_PRODUCT_TRANSFER}
                            component={ProductTransferPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PRODUCT_TRANSFERS_LIST_VIEW}
                            path={routes.ROUTE_PRODUCT_TRANSFERS}
                            component={ProductTransfersPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PACKAGES_CREATE}
                            path={routes.ROUTE_PACKAGES_NEW}
                            component={PackageAddPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PACKAGES_VIEW}
                            path={routes.ROUTE_PACKAGE}
                            component={PackageEditPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_PACKAGES_LIST_VIEW}
                            path={routes.ROUTE_PACKAGES}
                            component={PackagesPage}/>
                        {/*<Route path={routes.ROUTE_PRODUCTS} component={ProductsPage}/>*/}
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_INVOICES}
                            component={InvoicesPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_PRINT_INVOICE}
                            component={PrintInvoicePage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_PRINT_RECEIPT}
                            component={PrintReceiptPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_CAROUSEL_SLIDES}
                            component={CarouselSlidesPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_CAROUSEL_NEW_SLIDE}
                            component={AddEditCarouselSlidePage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_CAROUSEL_SLIDE}
                            component={AddEditCarouselSlidePage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_NEWS_NEW}
                            component={AddEditNewsPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_NEWS_PAGE}
                            component={NewsPage}/>
                        <RoutePermission
                            permission={permissions.PERMISSION_INVOICES_LIST_VIEW}
                            path={routes.ROUTE_NEWS_SINGLE}
                            component={AddEditNewsPage}/>

                        <Route
                            exact path={routes.ROUTE_CHECKOUT}
                            component={CheckoutPage}/>

                        <Route component={NoMatch}/>
                    </Switch>
                </StyledMain>
            </div>

            {/*<PermissionComponent permission={'EDIT_USER_PERMISSIONS'} component={Login}></PermissionComponent>*/}
            {/*<AuthenticatedRoute component={Login} permission={'EDIT_USER_PERMISSIONS'}></AuthenticatedRoute>*/}
        </React.Fragment>
    );
}



export default RootApp;