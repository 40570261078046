export const DELIVERY_METHOD_CUSTOM = 'CUSTOM';
export const DELIVERY_METHOD_PANEVEZYS = 'PANEVEZYS';
export const DELIVERY_METHOD_RYGA = 'RYGA';
export const DELIVERY_METHOD_VILNIUS = 'VILNIUS';
export const OMNIVA_LT = 'OMNIVA_LT';

export const PAYMENT_METHOD_CASH = 'CA';
export const PAYMENT_METHOD_PAYSERA = 'PS';
export const PAYMENT_METHOD_BANK = 'BA';

export function getLabelByDeliveryCodeAndStatus(deliveryCode, status) {
    let labelToShow;

    switch (deliveryCode) {
        case DELIVERY_METHOD_PANEVEZYS:
        case DELIVERY_METHOD_RYGA:
        case DELIVERY_METHOD_VILNIUS:
            labelToShow = status ? "Atsiimta" : "Neatsiimta";
            break;
        default:
            labelToShow = status ? "Išsiųsta" : "Neišsiųsta";
    }

    return labelToShow;
}

export function getLabelByPaymentStatus(status) {
    return status ? "Apmokėta" : "Neapmokėta";
}