import Piklor from './ColorPicker';

export const RteCommands = (editor) => {
    const rte = editor.RichTextEditor;

    //re-enable original ones
    rteActions.forEach((rteAction) => {
        rte.remove(rteAction);

        rte.add(rteAction, defActions[rteAction]);
    });

    //add new ones
    enableNewRteCommands(editor);
};

const rteActions = ['bold', 'italic', 'underline', 'strikethrough', 'link'];
const textClasses = ['text-align-justify', 'text-align-left', 'text-align-right', 'text-align-center'];
const btnState = {
    ACTIVE: 1,
    INACTIVE: 0,
    DISABLED: -1
};
const defActions = {
    bold: {
        name: 'bold',
        icon: '<b>B</b>',
        attributes: { title: 'Bold' },
        result: rte => rte.exec('bold')
    },
    italic: {
        name: 'italic',
        icon: '<i>I</i>',
        attributes: { title: 'Italic' },
        result: rte => rte.exec('italic')
    },
    underline: {
        name: 'underline',
        icon: '<u>U</u>',
        attributes: { title: 'Underline' },
        result: rte => rte.exec('underline')
    },
    strikethrough: {
        name: 'strikethrough',
        icon: '<s>S</s>',
        attributes: { title: 'Strike-through' },
        result: rte => rte.exec('strikeThrough')
    },
    link: {
        icon: `<span style="transform:rotate(45deg)">&supdsub;</span>`,
        name: 'link',
        attributes: {
            style: 'font-size:1.4rem;padding:0 4px 2px;',
            title: 'Link'
        },
        state: (rte, doc) => {
            if (rte && rte.selection()) {
                return isValidAnchor(rte) ? btnState.ACTIVE : btnState.INACTIVE;
            } else {
                return btnState.INACTIVE;
            }
        },
        result: rte => {
            if (isValidAnchor(rte)) {
                rte.exec('unlink');
            } else {
                rte.insertHTML(`<a class="link" href="">${rte.selection()}</a>`);
            }
        }
    },
};

const isValidAnchor = rte => {
    const anchor = rte.selection().anchorNode;
    const parentNode = anchor && anchor.parentNode;
    const nextSibling = anchor && anchor.nextSibling;
    return (
        (parentNode && parentNode.nodeName == 'A') ||
        (nextSibling && nextSibling.nodeName == 'A')
    );
};

const enableNewRteCommands = (editor, opts = {}) => {
    const options = {
        ...{
            // default options
            base: {
                bold: true,
                italic: true,
                underline: true,
                strikethrough: false,
                link: true,
            },
            //fonts: {
            //  fontName: ['font1',...,'fontn'],
            //  fontSize: true,
            //  //An array of strings representing colors
            //  fontColor: [],
            //  //An array of strings representing colors
            //  hilite: [],
            //}
            fonts: {
                fontColor: false,
                hilite: false,
            },
            format: {
                // heading1: true,
                // heading2: true,
                // heading3: true,
                heading4: true,
                heading5: true,
                heading6: true,
                body1: true,
                paragraph: false,
                //quote: false,
                clearFormatting: false,
            },
            subscriptSuperscript: false,
            indentOutdent: false,
            list: false,
            align: true,
            //actions: {
            //  copy: true,
            //  cut: true,
            //  paste: true,
            //  delete: true,
            //},
            actions: false,
            undoredo: false,
            extra: false,
            icons: {},
            darkColorPicker: true
        },
        ...opts
    };

    const {
              icons
          } = options;

    const formatBlock = 'formatBlock';

    const rte = editor.RichTextEditor;
    if (options.maxWidth)
        rte.getToolbarEl().firstChild.style.maxWidth = options.maxWidth;

    const fontNames = options.fonts.fontName ?
                      (Array.isArray(options.fonts.fontName) ? options.fonts.fontName : false) : false;

    options.fonts.fontName = fontNames;
    let fontOptionsEl = '';
    fontNames && fontNames.forEach(font => {
        fontOptionsEl += '<option>' + font.toString() + '</option>'
    });

    const fontNamesEl = `<select style="height:1.8rem;color:inherit;" class="gjs-field gjs-field-select">
      ${fontOptionsEl}
    </select>`;

    //remove defaults if not required
    options.base && !options.base.bold && rte.remove('bold');
    options.base && !options.base.italic && rte.remove('italic');
    options.base && !options.base.underline && rte.remove('underline');
    options.base && !options.base.strikethrough && rte.remove('strikethrough');
    options.base && !options.base.link && rte.remove('link');
    options.fonts && options.fonts.fontName && rte.add('fontName', {
        icon: fontNamesEl,
        // Bind the 'result' on 'change' listener
        event: 'change',
        attributes: {
            style: "padding: 0 4px 2px;",
            title: 'Font Name'
        },
        result: (rte, action) => rte.exec('fontName', action.btn.firstChild.value),
        // Callback on any input change (mousedown, keydown, etc..)
        update: (rte, action) => {
            const value = rte.doc.queryCommandValue(action.name);
            if (value != 'false') { // value is a string
                action.btn.firstChild.value = value;
            }
        }
    });
    options.fonts && options.fonts.fontSize && rte.add('fontSize', {
        icon: `<select style="height:1.8rem;color:inherit;" class="gjs-field gjs-field-select">
          <option value="1">xx-small</option>
          <option value="2">x-small</option>
          <option value="3">small</option>
          <option value="4">medium</option>
          <option value="5">large</option>
          <option value="6">x-large</option>
          <option value="7">xx-large</option>
        </select>`,
        // Bind the 'result' on 'change' listener
        event: 'change',
        attributes: {
            style: "padding: 0 4px 2px;",
            title: 'Font Size'
        },
        result: (rte, action) => rte.exec('fontSize', action.btn.firstChild.value),
        // Callback on any input change (mousedown, keydown, etc..)
        update: (rte, action) => {
            const value = rte.doc.queryCommandValue(action.name);
            if (value != 'false') { // value is a string
                action.btn.firstChild.value = value;
            }
        }
    });
    let pk1 = null;
    options.fonts && options.fonts.fontColor && rte.add('fontColor', {
        icon: `${icons.fontColor || '<b style="pointer-events:none;border-bottom:2px solid">A</b>'}
      <div id="foreColor-picker" 
          class="${options.darkColorPicker ? 'rte-color-picker dark' : 'rte-color-picker light'}">
      </div>`,
        attributes: {
            id: 'rte-font-color',
            title: 'Font Color'
        },
        result: rte => {
            if (!pk1) pk1 = new Piklor("#foreColor-picker", options.fonts.fontColor ?
                                                            (Array.isArray(options.fonts.fontColor) ? options.fonts.fontColor : null) : null, {
                open: "span#rte-font-color.gjs-rte-action",
                closeOnBlur: true
            });
            pk1.colorChosen(col => rte.exec('foreColor', col));
        },
    });
    let pk2 = null;
    options.fonts && options.fonts.hilite && rte.add('hiliteColor', {
        icon: `${icons.hiliteColor || '<b style="pointer-events:none;" class="rte-hilite-btn">A</b>'}
      <div id="hilite-picker" 
        class="${options.darkColorPicker ? 'rte-color-picker dark' : 'rte-color-picker light'}">
      </div>`,
        attributes: {
            id: 'rte-font-hilite',
            title: 'Font Highlight'
        },
        result: rte => {
            if (!pk2) pk2 = new Piklor("#hilite-picker", options.fonts.hilite ?
                                                         (Array.isArray(options.fonts.hilite) ? options.fonts.hilite : null) : null, {
                open: "span#rte-font-hilite.gjs-rte-action",
                closeOnBlur: true
            });
            pk2.colorChosen(col => rte.exec('hiliteColor', col));
        },
    });
    options.format && options.format.heading1 && rte.add('heading1', {
        icon: icons.heading1 || '<div>H1</div>',
        attributes: {
            title: 'Heading 1'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                component.set('variant', 'h1');
            }
        }
    });
    options.format && options.format.heading2 && rte.add('heading2', {
        icon: icons.heading2 || '<div>H2</div>',
        attributes: {
            title: 'Heading 2'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                component.set('variant', 'h2');
            }
        }
    });
    options.format && options.format.heading3 && rte.add('heading3', {
        icon: icons.heading3 || '<div>H3</div>',
        attributes: {
            title: 'Heading 3'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                component.set('variant', 'h3');
            }
        }
    });
    options.format && options.format.heading4 && rte.add('heading4', {
        icon: icons.heading4 || '<div>T1</div>',
        attributes: {
            title: 'Heading 4'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                component.set('variant', 'h4');
            }
        }
    });
    options.format && options.format.heading5 && rte.add('heading5', {
        icon: icons.heading5 || '<div>T2</div>',
        attributes: {
            title: 'Heading 5'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                component.set('variant', 'h5');
            }
        }
    });
    options.format && options.format.heading6 && rte.add('heading6', {
        icon: icons.heading6 || '<div>T3</div>',
        attributes: {
            title: 'Heading 6'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                component.set('variant', 'h6');
            }
        }
    });
    options.format && options.format.body1 && rte.add('body1', {
        icon: icons.body1 || '<div>T4</div>',
        attributes: {
            title: 'Body 1'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                component.set('variant', 'body1');
            }
        },
    });
    options.format && options.format.paragraph && rte.add('paragraph', {
        icon: icons.paragraph || '&#182;',
        attributes: {
            title: 'Paragraph'
        },
        result: rte => rte.exec(formatBlock, '<p>')
    });
    options.format && options.format.quote && rte.add('quote', {
        icon: icons.quote || '<i class="fa fa-quote-left"></i>',
        attributes: {
            title: 'Quote'
        },
        result: rte => rte.exec(formatBlock, '<blockquote>')
    });
    options.format && options.format.clearFormatting && rte.add('clearFormatting', {
        icon: icons.clear || '<i class="fa fa-eraser"></i>',
        attributes: {
            title: 'Clear Formatting'
        },
        result: rte => rte.exec('removeFormat')
    });
    options.indentOutdent && rte.add('indent', {
        icon: icons.indent || '<i class="fa fa-indent"></i>',
        attributes: {
            title: 'Indent'
        },
        result: rte => rte.exec('indent')
    });
    options.indentOutdent && rte.add('outdent', {
        icon: icons.outdent || '<i class="fa fa-outdent"></i>',
        attributes: {
            title: 'Outdent'
        },
        result: rte => rte.exec('outdent')
    });
    options.subscriptSuperscript && rte.add('subscript', {
        icon: icons.subscript || '<div>X<sub>2</sub></div>',
        attributes: {
            title: 'Subscript'
        },
        result: rte => rte.exec('subscript')
    });
    options.subscriptSuperscript && rte.add('superscript', {
        icon: icons.superscript || '<div>X<sup>2</sup></div>',
        attributes: {
            title: 'Superscript'
        },
        result: rte => rte.exec('superscript')
    });
    options.list && rte.add('olist', {
        icon: icons.olist || '<i class="fa fa-list-ol"></i>',
        attributes: {
            title: 'Ordered List'
        },
        result: rte => rte.exec('insertOrderedList')
    });
    options.list && rte.add('ulist', {
        icon: icons.ulist || '<i class="fa fa-list-ul"></i>',
        attributes: {
            title: 'Unordered List'
        },
        result: rte => rte.exec('insertUnorderedList')
    });
    options.align && rte.add('justifyLeft', {
        icon: '<i class="fa fa-align-left"></i>',
        attributes: {
            title: 'Align Left'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                //component.removeClass(textClasses);
                //component.addClass(['text-align-left']);

                component.set('align', 'left');
            }
        },
        state: (rte, doc) => {
            const component = editor.getSelected();
            if (component) {
                const componentClasses = component.getClasses();

                return componentClasses.includes('text-align-left') ?  btnState.ACTIVE : btnState.INACTIVE;
            }
            return btnState.INACTIVE;
        },
    });
    options.align && rte.add('justifyCenter', {
        icon: '<i class="fa fa-align-center"></i>',
        attributes: {
            title: 'Align Center'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                //component.removeClass(textClasses);
               // component.addClass(['text-align-center']);

                component.set('align', 'center');
            }
        },
        state: (rte, doc) => {
            const component = editor.getSelected();
            if (component) {
                const componentClasses = component.getClasses();

                return componentClasses.includes('text-align-center') ?  btnState.ACTIVE : btnState.INACTIVE;
            }
            return btnState.INACTIVE;
        },
    });
    options.align && rte.add('justifyFull', {
        icon: icons.justifyFull || '<i class="fa fa-align-justify"></i>',
        attributes: {
            title: 'Align Justify'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                // component.removeClass(textClasses);
                // component.addClass(['text-align-justify']);

                component.set('align', 'justify');

            }
        },
        state: (rte, doc) => {
            const component = editor.getSelected();
            if (component) {
                const componentClasses = component.getClasses();

                return componentClasses.includes('text-align-justify') ?  btnState.ACTIVE : btnState.INACTIVE;
            }
            return btnState.INACTIVE;
        },
    });
    options.align && rte.add('justifyRight', {
        icon: icons.justifyRight || '<i class="fa fa-align-right"></i>',
        attributes: {
            title: 'Align Right'
        },
        result: rte => {
            const component = editor.getSelected();
            if (component) {
                // component.removeClass(textClasses);
                // component.addClass(['text-align-right']);

                component.set('align', 'right');
            }
        },
        state: (rte, doc) => {
            const component = editor.getSelected();
            if (component) {
                const componentClasses = component.getClasses();

                return componentClasses.includes('text-align-right') ?  btnState.ACTIVE : btnState.INACTIVE;
            }
            return btnState.INACTIVE;
        },
    });
    options.actions && options.actions.copy && rte.add('copy', {
        icon: icons.copy || '<i class="fa fa-files-o"></i>',
        attributes: {
            title: 'Copy'
        },
        result: rte => rte.exec('copy')
    });
    options.actions && options.actions.cut && rte.add('cut', {
        icon: icons.cut || '<i class="fa fa-scissors"></i>',
        attributes: {
            title: 'Cut'
        },
        result: rte => rte.exec('cut')
    });
    options.actions && options.actions.paste && rte.add('paste', {
        icon: icons.paste || '<i class="fa fa-clipboard"></i>',
        attributes: {
            title: 'Paste'
        },
        result: rte => rte.exec('paste')
    });
    options.actions && options.actions.delete && rte.add('delete', {
        icon: icons.delete || '<i class="fa fa-trash-o"></i>',
        attributes: {
            title: 'Delete'
        },
        result: rte => rte.exec('delete')
    });
    options.extra && rte.add('code', {
        icon: icon.code || '<i class="fa fa-code"></i>',
        attributes: {
            title: 'Code'
        },
        result: rte => rte.exec(formatBlock, '<pre>')
    });
    options.extra && rte.add('line', {
        icon: icons.line || '<b>&#8213;</b>',
        attributes: {
            title: 'Horizontal Line'
        },
        result: rte => rte.exec('insertHorizontalRule')
    });
    options.undoredo && rte.add('undo', {
        icon: icons.undo || '<i class="fa fa-reply"></i>',
        attributes: {
            title: 'Undo'
        },
        result: rte => rte.exec('undo')
    });
    options.undoredo && rte.add('redo', {
        icon: icons.redo || '<i class="fa fa-share"></i>',
        attributes: {
            title: 'Redo'
        },
        result: rte => rte.exec('redo')
    });
};