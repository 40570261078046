import React from 'react';

import StatusChipGroup from "shared-react/shop/statusChips/StatusChipGroup";
import EventIcon from "@material-ui/icons/Event";

function StatusChipPublishedGroup({publishedAt,  fixedWidth = 'auto'}) {
    return (
        <>
            <StatusChipGroup
                icon={<EventIcon/>}
                status={publishedAt != null ? 1 : 0}
                trueValueText={publishedAt != null ? publishedAt : ''}
                falseValueText={'Juodraštis'}
                fixedWidth={false}
                gutterLeft
            />
        </>
    );
}

export default StatusChipPublishedGroup;