import React, {useEffect} from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

import UserForm, {customerSchema} from './UserForm';
import {getUserMethod, getUserUrl, useGetUser} from "shared-react/api/UserApi";
import useFormikForm from "../formik/useFormikForm";

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function AddEditCustomerDialogUserForm({onSaveUser, onClose, initialFormData}) {
    const [savedUser, UserFormikWrapper, userFormProps, UserFormErrors, userFormErrorsProps, UserFormSubmitButton, userFormSubmitButtonProps] = useFormikForm({
        method: getUserMethod(initialFormData),
        url: getUserUrl(initialFormData),
        formData: initialFormData,
        validationSchema: customerSchema,
        submitButtonText: 'Išsaugoti',
    });

    useEffect(() => {
        if (savedUser) {
            onSaveUser(savedUser);
        }
    }, [savedUser]);

    return (
        <React.Fragment>
            <UserFormikWrapper {...userFormProps}>
                <UserForm />
                <DialogActions style={{display: 'flex'}}>
                    <Button onClick={onClose} color="primary">
                        Uždaryti
                    </Button>
                    <span style={{flex: 1}}>
                        <UserFormErrors {...userFormErrorsProps}/>
                    </span>
                    <UserFormSubmitButton {...userFormSubmitButtonProps}/>
                </DialogActions>
            </UserFormikWrapper>
        </React.Fragment>
    );
}

export default AddEditCustomerDialogUserForm;