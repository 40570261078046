import React from 'react';
import {connect} from 'react-redux'

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {StyledListItemIcon} from 'menu/ListItemLink';
import {logoutUser} from "ducks/auth";

function ListItemLogout({logoutUser, icon}) {
    return (
        <ListItem button onClick={() => logoutUser()}>
            <StyledListItemIcon>
                {icon}
            </StyledListItemIcon>
            <ListItemText primary={"Atsijungti"} />
        </ListItem>
    );
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => {
        dispatch(logoutUser())
    },
});

export default connect(null, mapDispatchToProps)(ListItemLogout);