import React from 'react'
import arrayMove from "array-move";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'list-select/';
const START_SELECT_MODE = PREFIX + 'START_SELECT_MODE';
const CANCEL_SELECT_MODE = PREFIX + 'CANCEL_SELECT_MODE';
const TOGGLE_SELECTED = PREFIX + 'TOGGLE_SELECTED';

const initialState = {
    isSelecting: false,
    selected: [],
    hydrated: false,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }
    //console.log('got reducer action', action);

    switch (action.type) {
        case START_SELECT_MODE:
            return {
                ...state,
                isSelecting: true,
            };
        case CANCEL_SELECT_MODE:
            return {
                ...state,
                isSelecting: false,
                selected: [],
            };
        case TOGGLE_SELECTED:
            const value = action.payload.value;
            let newSelected;

            const existingValueIndex = state.selected.indexOf(value);
            if (existingValueIndex === -1) {
                newSelected = [
                    ...state.selected,
                    value,
                ];
            } else {
                newSelected = [
                    ...state.selected.slice(0, existingValueIndex),
                    ...state.selected.slice(existingValueIndex + 1),
                ];
            }

            return {
                ...state,
                selected: newSelected,
            };
        default:
            return state;
    }
}

// Action Creators
export function startSelectMode() {
    return {type: START_SELECT_MODE, payload: {}};
}

export function cancelSelectMode() {
    return {type: CANCEL_SELECT_MODE, payload: {}};
}

export function toggleSelected(value) {
    return {type: TOGGLE_SELECTED, payload: {value}};
}

function ListSelectProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        // additional initial params
    });

    //console.log('change', state, dispatch);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

function useListSelectState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useListSelectState must be used within a ListSelectProvider')
    }
    return context
}

function useListSelectDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useListSelectDispatch must be used within a ListSelectProvider')
    }
    return context
}

function useListSelect() {
    return [useListSelectState(), useListSelectDispatch()]
}

export default useListSelect;

export {ListSelectProvider, useListSelectState, useListSelectDispatch, useListSelect}
