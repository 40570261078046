import React, {useEffect, useState} from 'react';

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import {useGetUsers} from 'shared-react/api/UserApi';
import {ROUTE_USERS_NEW, getUsersPath, getUserPath, getProductsPath} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import ListTablePagination from "utils/ListTablePagination";
import {MaterialTextField} from "shared-react/formik/MaterialFields";
import LinkWrapper from "utils/LinkWrapper";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ProgressInputIndicator from 'shared-react/utils/ProgressInputIndicator';
import useDebouncedCallback from "shared-react/useDebounce/callback";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

function UsersPage({match, history}) {
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const searchText = typeof match.params.searchText === 'undefined' ? '' : match.params.searchText;
    const perPage = 30;
    const [localSearchText, setLocalSearchText] = useState(searchText);
    const queryParams = {
        'per_page': perPage,
        'page': page,
        'q': searchText,
    };

    const [users, totalCount, isUsersLoading, usersErrors, fetchUsers] = useGetUsers(queryParams);

    useEffect(() => {
        fetchUsers();
    }, [page, searchText]);

    useEffect(() => {
        setLocalSearchText(searchText);
    }, [searchText]);

    const [setDebouncedSearchText] = useDebouncedCallback(
        (value) => {
            //console.log('setDebouncedSearchText', value);

            if (value.length === 0) {
                value = null;
            }

            history.push(getUsersPath(1, value));
        },
        500,
        []
    );

    if (!users) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <React.Fragment>
                            <MaterialTextField
                                margin="dense"
                                fullWidth
                                nomargin={1}
                                onChange={(e) => {
                                    setLocalSearchText(e.target.value);
                                    setDebouncedSearchText(e.target.value);
                                }}
                                label="Paieška"
                                value={localSearchText}
                            />
                        </React.Fragment>
                        <Fab to={ROUTE_USERS_NEW} color="primary" aria-label="add" size="small" style={{marginLeft: 12}} component={LinkWrapper}>
                            <AddIcon />
                        </Fab>
                    </div>
                    {isUsersLoading ? (
                        <ProgressInputIndicator />
                    ) : null}
                </>
            }>
                {users && !isUsersLoading ? (
                    <Paper elavation={0} square>
                        <List dense={true}>
                            {users.map((user) =>
                                <ListItem
                                    key={user.id}
                                    button
                                    to={getUserPath(user.id) + '/redagavimas'}
                                    component={LinkWrapper}
                                >
                                    <ListItemText
                                        primary={user.firstName + ' ' + user.lastName}
                                        secondary={user.username ? user.username : null}
                                    />
                                </ListItem>
                            )}
                        </List>
                        <ListTablePagination
                            perPage={perPage}
                            totalCount={totalCount}
                            page={page}
                            onChangePage={(event, page) => {
                                history.push(getUsersPath(page));
                            }}
                        />
                    </Paper>
                ) : null}
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default UsersPage;