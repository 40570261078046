import React, {useEffect, useState, useMemo} from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form, Field, FieldArray } from 'formik';

import * as Yup from "yup";

import { useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ProgressButton from '../utils/ProgressButton';
import {FormCheckboxField} from "../formik/FormikMaterialFields";
import Step1Seller from './Step1Seller';
import Step2Buyer from './Step2Buyer';
import Step3Delivery from './Step3Delivery';
import Step4Payment from './Step4Payment';
import Step5Review from './Step5Review';
import ResponseErrors from "../utils/ResponseErrors";
import CustomerSelectField from "../formik/FormikCustomerSelectField";
import FocusError from "shared-react//formik/FocusError";
import {
    STRING_EMAIL_REQUIRED,
    STRING_PHONE_NUMBER_COUNTRY_CODE_REQUIRED,
    STRING_PHONE_NUMBER_LT_NULLABLE,
    STRING_PHONE_NUMBER_LT_REQUIRED,
    STRING_PHONE_NUMBER_LV_NULLABLE,
    STRING_PHONE_NUMBER_LV_REQUIRED,
} from 'shared-react/utils/yupValidations';

const deliveryMethodCodeValidation = {
    deliveryMethodCode: Yup.string().required('Būtina pasirinkti pristatymo būdą'),
    omnivaPickupPointId: Yup.string()
        .when('deliveryMethodCode', {
            is: (val) => val === "OMNIVA_LT",
            then: Yup.string().nullable().required('Paštomato laukas negali būti tuščias'),
            otherwise: Yup.string().nullable(),
        }),
};

const paymentMethodCodeValidation = {
    paymentMethodCode: Yup.string().required('Būtina pasirinkti apmokėjimo būdą'),
    payseraPaymentId: Yup.string()
        .when('paymentMethodCode', {
            is: (val) => val === "PS",
            then: Yup.string().required('Būtina pasirinkti savo banką'),
            otherwise: Yup.string().nullable(),
        }),
};

const getValidationSchema = (metadataUserSellers) => {
    const isCmsForm = isArray(metadataUserSellers) && metadataUserSellers.length;

    return Yup.object().shape({
        sellerId: isCmsForm ?
            Yup.string().nullable().required('Būtina pasirinkti pardavėją')
            : Yup.string().nullable(),

        customerId: isCmsForm ?
            Yup.string().nullable().required('Būtina pasirinkti pirkėją')
            : Yup.string().nullable(),

        customerIsCompany: isCmsForm ?
            Yup.bool().nullable()
            : Yup.bool().required('Būtina pasirinkti pirkėjo tipą'),
        customerFirstName: isCmsForm ?
            Yup.string().nullable()
            : Yup.string().when('customerIsCompany', {
                is: (val) => val === "1",
                then: Yup.string().required('Pavadinimo laukas negali būti tuščias'),
                otherwise: Yup.string().required('Vardo laukas negali būti tuščias'),
            }),
        customerLastName: isCmsForm ?
            Yup.string().nullable()
            : Yup.string().when('customerIsCompany', {
                is: (val) => val === "1",
                then: Yup.string().nullable(),
                otherwise: Yup.string().required('Pavardės laukas negali būti tuščias'),
            }),
        customerEmail: isCmsForm ?
            Yup.string().email('Netinkamas el. pašto adresas').nullable()
            : STRING_EMAIL_REQUIRED,
        customerPhoneNumberCountryCode: STRING_PHONE_NUMBER_COUNTRY_CODE_REQUIRED,
        customerPhoneNumber: isCmsForm ?
            Yup.string()
                .when('customerPhoneNumberCountryCode', {
                    is: (val) => val === '+370',
                    then: STRING_PHONE_NUMBER_LT_NULLABLE,
                    otherwise: STRING_PHONE_NUMBER_LV_NULLABLE,
                })
            : Yup.string()
                .when('customerPhoneNumberCountryCode', {
                    is: (val) => val === '+370',
                    then: STRING_PHONE_NUMBER_LT_REQUIRED,
                    otherwise: STRING_PHONE_NUMBER_LV_REQUIRED,
                }),
        customerAddress: isCmsForm ?
            Yup.string().nullable()
            : Yup.string().required('Adreso laukas negali būti tuščias'),
        customerPvmCode: isCmsForm ?
            Yup.string().nullable()
            : Yup.string().when('customerIsCompany', {
                is: (val) => val == true,
                then: Yup.string().required('PVM mokėtojo kodo laukas negali būti tuščias'),
                otherwise: Yup.string().nullable(),
            }),
        customerCode: isCmsForm ?
            Yup.string().nullable()
            : Yup.string().when('customerIsCompany', {
                is: (val) => val == true,
                then: Yup.string().required('Įmonės kodo laukas negali būti tuščias'),
                otherwise: Yup.string().nullable(),
            }),

        ...deliveryMethodCodeValidation,
        ...paymentMethodCodeValidation,

        ...(!isCmsForm && {
            customerConfirmedAgreement: Yup.boolean().oneOf([true], 'Būtina susipažinti su Pirkimo Taisyklėmis ir Privatumo Politika'),
        }),
    });
};



// const CheckoutSchema = Yup.object().shape({
//     sellerId: Yup.string()
//         .when(['customerFirstName', 'customerLastName', 'customerEmail', 'customerPhone', 'code', 'pvmCode'], {
//             is: (customerFirstName, customerLastName, customerEmail, customerPhone, code, pvmCode) => isBig && isSpecial,
//             then: Yup.number().required('Būtina pasirinkti pardavėją'),
//         }),
//     customerId: Yup.string()
//         .nullable(),
//     deliveryMethodCode: Yup.string()
//         .required('Būtina pasirinkti pristatymą'),
//     customDeliveryPrice: Yup.number()
//         .when('deliveryMethodCode', {
//             is: (val) => val === "CUSTOM", //todo[as]: use const
//             then: Yup.number()
//                 .required('Pristatymo kainos laukas negali būti tuščias'),
//         })
// });

function OrderForm2({
                       onSubmitOrderForm,
                       bindSubmitForm,
                       order, orderData,
                       orderUuid, isUpdating,
                       isSubmitting,
                       showAddProducts,
                       setOrderSellerId,
                       availablePayseraPayments,
                       availableOmnivaMachines,
                       metadataUserDeliveryMethods,
                       metadataUserPaymentMethods,
                       metadata,
                       setOrderDataField,
                       updateOrderProduct,
                       removeOrderProduct,
                       metadataUserSellers,
                       metadataCustomers,
                       responseErrors = [],
}) {
    //customerId or customerFields
    //orderData.
    // firstName: '',
    // lastName: '',
    // email: '',
    // phone: '',
    const theme = useTheme();
    const cmsForm = isArray(metadataUserSellers) && metadataUserSellers.length;
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const validationSchema = useMemo(() => getValidationSchema(metadataUserSellers), [metadataUserSellers]);

    const [stateInitialValues, setStateInitialValues] = useState(orderData);

    return (
        <Formik
            initialValues={orderData}
            enableReinitialize
            validationSchema={validationSchema}
            //keepDirtyOnReinitialize={true}
            onSubmit={(values) => {
                onSubmitOrderForm(values);
            }}
        >
            {
                ({
                     values,
                     submitForm,
                     handleSubmit,
                     setFieldValue,
                     touched,
                     errors,
                    ...rest
                 }) => {
                    //console.log('Form values', values, errors, touched, rest);
                    //console.log('Formik errors', errors);

                    //console.log('Formik metadataSellers', metadataSellers);
                    //console.log('Formik metadataCustomers', metadataCustomers);

                    if (typeof bindSubmitForm === 'function') {
                        bindSubmitForm(submitForm);
                    }

                    return (
                        <Form>
                            <FocusError/>
                            <div style={{
                                padding: theme.spacing(2)
                            }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6} lg={4} style={{
                                        padding: theme.spacing(2)
                                    }}>
                                        <Grid container spacing={4}>
                                            {cmsForm ? (
                                                <Grid item xs={12}>
                                                    <Step1Seller
                                                        sellerId={values.sellerId}
                                                        setOrderSellerId={setOrderSellerId}
                                                        metadataUserSellers={metadataUserSellers}
                                                    />
                                                </Grid>
                                            ) : null}
                                            <Grid item xs={12}>
                                                <Step2Buyer
                                                    cmsForm={cmsForm}
                                                    setOrderDataField={setOrderDataField}
                                                    metadataCustomers={metadataCustomers}
                                                    formValues={values}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/*<Grid container item xs={12} md={6} lg={4} spacing={0}>*/}
                                        {/*{cmsForm ? (*/}
                                            {/*<Grid item xs={12}>*/}
                                                {/*<Step1Seller*/}
                                                    {/*setOrderSellerId={setOrderSellerId}*/}
                                                    {/*metadataSellers={metadataSellers}*/}
                                                {/*/>*/}
                                            {/*</Grid>*/}
                                        {/*) : null}*/}
                                        {/*<Grid item xs={12}>*/}
                                            {/*<Step2Buyer*/}
                                                {/*cmsForm={cmsForm}*/}
                                                {/*setOrderDataField={setOrderDataField}*/}
                                                {/*metadataCustomers={metadataCustomers}*/}
                                                {/*formValues={values}*/}
                                            {/*/>*/}
                                        {/*</Grid>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Step3Delivery
                                            cmsForm={cmsForm}
                                            setOrderDataField={setOrderDataField}
                                            deliveryMethodCode={orderData.deliveryMethodCode}
                                            omnivaPickupPointId={orderData.omnivaPickupPointId}
                                            availableOmnivaMachines={availableOmnivaMachines}
                                            metadataUserDeliveryMethods={metadataUserDeliveryMethods}
                                            setFieldValue={setFieldValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={4}>
                                        <Step4Payment
                                            cmsForm={cmsForm}
                                            orderData={orderData}
                                            metadataUserPaymentMethods={metadataUserPaymentMethods}
                                            availablePayseraPayments={availablePayseraPayments}
                                            setOrderDataField={setOrderDataField }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Step5Review
                                            cmsForm={cmsForm}
                                            showAddProducts={showAddProducts}
                                            metadata={metadata}
                                            isUpdating={isUpdating}
                                            isSubmitting={isSubmitting}
                                            order={order}
                                            removeOrderProduct={removeOrderProduct}
                                            updateOrderProduct={updateOrderProduct}
                                            orderUuid={orderUuid}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{
                                padding: theme.spacing(2),
                                paddingTop: 0,
                            }}>
                                <Grid container spacing={1}>
                                    {responseErrors.length > 0 ? (
                                        <Grid item xs={12}>
                                            <ResponseErrors errors={responseErrors} />
                                        </Grid>
                                    ) : null}
                                    {/*{ !isEmpty(errors) && !isEmpty(touched) ? (*/}
                                    {/*    <Grid item xs={12}>*/}
                                    {/*        <ResponseErrors errors={['Prašome peržiūrėti užsakymo formą ir užpildyti trūkstamus laukus.']} />*/}
                                    {/*    </Grid>*/}
                                    {/*) : null}*/}
                                    {!cmsForm ? (
                                        <Grid item xs={12} sm={7}>
                                            <Field
                                                name="customerConfirmedAgreement"
                                                label={<><span>
                                                    Patvirtinu jog susipažinau su&nbsp;
                                                    <Typography display={'inline'}>
                                                      <Link href={'/naudojimosi-salygos'} target="_blank">
                                                        Pirkimo Taisyklėmis
                                                      </Link>
                                                    </Typography>
                                                    &nbsp;ir&nbsp;
                                                    <Typography display={'inline'}>
                                                      <Link href={'/privatumo-ir-slapuku-politika'} target="_blank">
                                                        Privatumo Politika
                                                      </Link>
                                                    </Typography>
                                                </span></>}
                                                component={FormCheckboxField}
                                                onChange={(e, value) => {
                                                    setOrderDataField('customerConfirmedAgreement', value, false);
                                                }}
                                            />

                                            {/*<FormControlLabel*/}
                                            {/*    control={*/}
                                            {/*        <Checkbox value="checkedA" />*/}
                                            {/*    }*/}
                                            {/*    label="Patvirtinu jog susipažinau su pirkimo taisyklėmis"*/}
                                            {/*/>*/}
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12} sm={!cmsForm ? 5 : 12} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        textAlign: 'right',
                                    }}>
                                        <ProgressButton
                                            loading={isUpdating || isSubmitting}
                                            text={"Pateikti užsakymą"}
                                            onClick={(e) => {
                                                handleSubmit();
                                            }}
                                            color="secondary"
                                            fullWidth={matchesSm}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    );
}

export default OrderForm2;