import React, {useEffect, useState} from 'react';
import { useFormikContext } from 'formik';

import Grid from '@material-ui/core/Grid';

import IsCompany from "shared-react/user/fields/IsCompany";
import Username from "shared-react/user/fields/Username";
import FirstName from "shared-react/user/fields/FirstName";
import LastName from "shared-react/user/fields/LastName";
import Code from "shared-react/user/fields/Code";
import PvmCode from "shared-react/user/fields/PvmCode";
import PhoneNumberCountryCode from "shared-react/user/fields/PhoneNumberCountryCode";
import PhoneNumber from "shared-react/user/fields/PhoneNumber";
import Address from "shared-react/user/fields/Address";

function UserFormMainFields({showPersonalCodeField = false}) {
    const { values } = useFormikContext();

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <IsCompany/>
            </Grid>
            <Grid item xs={12}>
                <Username/>
            </Grid>
            <Grid item xs={12}>
                <FirstName/>
            </Grid>
            {values.isCompany === '0' ? (
                <Grid item xs={12}>
                    <LastName/>
                </Grid>
            ) : null}
            {values.isCompany !== '0' || (showPersonalCodeField && values.isCompany === '0') ? (
                <Grid item xs={12}>
                    <Code/>
                </Grid>
            ) : null}
            {values.isCompany !== '0' ? (
                <Grid item xs={12}>
                    <PvmCode/>
                </Grid>
            ) : null}
            <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                    <PhoneNumberCountryCode/>
                    <PhoneNumber/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Address/>
            </Grid>
        </React.Fragment>
    );
}

export default UserFormMainFields;
