import React, {useEffect, useState} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Currency from 'shared-react/utils/Currency';

function OrderTotalsTable({order}) {
    return (
        <React.Fragment>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Prekių suma</TableCell>
                        <TableCell align="right"><Currency value={order.subTotal}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Pristatymas
                        </TableCell>
                        <TableCell align="right"><Currency value={order.deliveryPrice}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Iš viso</b></TableCell>
                        <TableCell align="right"><b><Currency value={order.total}/></b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
export default OrderTotalsTable;