import React, {useState, useEffect} from 'react';

import {getProductCategory} from 'shared-react/api/ProductCategoryApi';
import ProductCategoryForm from 'productCategories/ProductCategoryForm'
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import LinkBack from "utils/LinkBack";
import PageTitle from "utils/PageTitle";
import {getProductCategoriesPath, ROUTE_PRODUCT_CATEGORIES_NEW} from 'shared-react/utils/routes';
import PageTitleWithButton from "../utils/PageTitleWithButton";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import ProgressButton from "shared-react/utils/ProgressButton";

function ProductCategoryEditPage({ match }) {
    const productCategoryId = match.params.id;
    const [isLoading, setIsLoading] = useState(true);
    const [productCategory, setProductCategory] = useState({});

    useEffect(() => {
        getProductCategory(productCategoryId)
            .then(function (productCategory) {
                setProductCategory(productCategory);
            })
            .catch(function (error) {
                // handle error

            })
            .finally(function() {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (<DataLoadingIndicator />);
    }

    return (
        <div>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <LinkBack to={getProductCategoriesPath()} text={"Atgal"}/>
                        <PageTitle title={"Prekių kategorijos redagavimas"} gutterBottom/>
                        <span style={{flex: 1}} />
                        {/*<ProgressButton*/}
                        {/*    loading={isSubmitting}*/}
                        {/*    text={"Išsaugoti"}*/}
                        {/*    onClick={(e) => {*/}
                        {/*        handleSubmitMyForm(e);*/}
                        {/*    }}*/}
                        {/*    color="primary"*/}
                        {/*/>*/}
                    </div>
                </>
            }>
                <ProductCategoryForm
                    isNew={false}
                    initialValues={productCategory}
                />
            </FillHeightContainer>



        </div>
    );
}

export default ProductCategoryEditPage;