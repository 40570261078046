import React, {useEffect, useState} from 'react';
import {pickBy, identity} from "lodash";

import {useGetInvoices, useGetInvoice} from "shared-react/api/InvoiceApi";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';


import LinkWrapper from 'utils/LinkWrapper';
import {ListItemPriceText, ListItemTextCustomWidth} from 'utils/List'
import ListTablePagination from "utils/ListTablePagination";
import Currency from 'shared-react/utils/Currency';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon  from '@material-ui/icons/Edit';

import {ROUTE_INVOICES, getInvoicesPath, getInvoicePath} from 'shared-react/utils/routes';
import PageTitle from "utils/PageTitle";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import {MaterialTextField} from "shared-react/formik/MaterialFields";

import FiltersToChoose from "utils/FiltersToChoose";
import FiltersSelected from "utils/FiltersSelected";
import BackToLastLocationButton from "utils/BackToLastLocationButton";
import QuerySearchTextField from "utils/QuerySearchTextField";
import {INVOICES_FILTERS} from "invoices/pageFilters";

import qs from "qs";
import InvoiceDialog from "invoices/InvoiceDialog";
import InvoicePage from "invoices/InvoicePage";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function InvoicesPage({match, history, location}) {
    const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    //const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    console.log('match params', match, history);
    const page = typeof urlQueryParams.page === 'undefined' ? 1 : urlQueryParams.page;
    const perPage = 20;
    const queryParams = {
        ...urlQueryParams,
        'perPage': perPage,
        'page': page,
    };
    const queryString = '?' + qs.stringify(queryParams);

    const [listQueryString, setListQueryString] = useState(queryString);
    const [invoices, totalCount, areInvoicesLoading, invoicesErrors, fetchInvoices] = useGetInvoices(listQueryString);

    const invoiceId = typeof match.params.invoiceId === 'undefined' ? null : match.params.invoiceId;

    const [invoice, setInvoice, isInvoiceLoading, invoiceErrors, fetchInvoice] = useGetInvoice(invoiceId);
    const [invoicesMap, setInvoicesMap] = React.useState({});

    // Update query string if it changes and we are not on invoice page
    useEffect(() => {
        if (invoiceId === null) {
            setListQueryString(queryString);
        }
    }, [queryString]);

    useEffect(() => {
        fetchInvoices();
    }, [listQueryString]);

    useEffect(() => {
        if (invoices) {
            //build invoices map
            let newInvoicesMap = invoices.reduce(function(map, invoice) {
                map[invoice.id] = invoice;
                return map;
            }, {});
            setInvoicesMap(newInvoicesMap);
        }
    }, [invoices]);

    useEffect(() => {
        console.log('invoicesMap', invoicesMap);
        console.log('invoiceId', invoiceId);
        //if in map
        if (invoiceId in invoicesMap) {
            setInvoice(invoicesMap[invoiceId]);
        } else if (invoiceId !== null) {
            fetchInvoice();
        } else {
            setInvoice(null);
        }
    }, [invoiceId]);

    // states:
    // invoicesAreLoading
    // zero invoices found
    // Error loading invoices
    // single invoice is loading
    // single invoice is not found
    // error loading single invoice

    // HEADER states:
    // invoices > 0 || invoices == null|0
    // single invoice <> null || single invoice == null

    if (!invoices || isInvoiceLoading) { //todo[as]: doesn't seem right
        return (<DataLoadingIndicator />);
    }

    if (invoiceId !== null) {
        if (invoice) {
            return (
                <React.Fragment>
                    <FillHeightContainer header={
                        <>
                        <div style={{
                            padding: 8,
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            height: '100%',
                        }}>
                            <BackToLastLocationButton path={getInvoicesPath()} fallback={getInvoicesPath()} />
                            <PageTitle
                                title={"Sąskaita " + invoice.serie + invoice.number}
                            />
                            <span style={{flex: 1}}/>
                            <IconButton>
                                <PrintIcon />
                            </IconButton>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </div>
                        </>
                    }>
                        <InvoicePage invoice={invoice} />
                    </FillHeightContainer>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    Sąskaita nerasta
                </React.Fragment>
            );
        }
    } else {
        return (
            <React.Fragment>
                <FillHeightContainer header={
                    <>
                    <div style={{
                        height: '100%',
                        padding: '0 16px',
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <QuerySearchTextField
                                queryName="nr"
                                label="Sąskaitos numeris"
                                style={{
                                    flex: 1,
                                    marginRight: 8,
                                }}
                            />
                            <FiltersToChoose filters={INVOICES_FILTERS}/>
                        </div>
                        <FiltersSelected filters={INVOICES_FILTERS}/>
                    </div>
                    </>
                }>
                    {areInvoicesLoading ? (
                        <DataLoadingIndicator />
                    ) : (
                        <>
                            {/*<div>*/}
                                {/*<Tabs*/}
                                    {/*value={value}*/}
                                    {/*onChange={handleChange}*/}
                                    {/*indicatorColor="primary"*/}
                                    {/*textColor="primary"*/}
                                    {/*variant="scrollable"*/}
                                    {/*scrollButtons="auto"*/}
                                    {/*aria-label="scrollable auto tabs example"*/}
                                {/*>*/}
                                    {/*<Tab label={*/}
                                        {/*<Badge color="secondary" badgeContent={4} style={{*/}
                                            {/*padding: '0 16px',*/}
                                        {/*}}>*/}
                                            {/*Neapmokėtos*/}
                                        {/*</Badge>*/}
                                    {/*} />*/}
                                    {/*<Tab label="Vėluoja apmokėti" />*/}
                                    {/*<Tab label="Reikia perspausdinti" />*/}
                                    {/*<Tab label="Sugadintos" />*/}
                                    {/*<Tab label="Apmokėtos" />*/}
                                {/*</Tabs>*/}
                            {/*</div>*/}

                            <Paper square elavation={0}>
                                <List dense={true} style={{padding: 0}}>
                                    {invoices.map((invoice) =>
                                        <ListItem
                                            key={invoice.id}
                                            button
                                            onClick={(e) => {
                                                //setInvoice(invoice);
                                            }}
                                            to={getInvoicePath(invoice.id)}
                                            component={LinkWrapper}
                                        >
                                            <ListItemTextCustomWidth
                                                width={100}
                                                primary={invoice.serie + ' ' + invoice.number}
                                                secondary={invoice.day}
                                                marginRight={true}
                                            />
                                            <ListItemText
                                                primary={invoice.customer.firstName + ' ' + invoice.customer.lastName}
                                            />
                                            <ListItemPriceText>
                                                <Currency value={invoice.total} />
                                            </ListItemPriceText>
                                        </ListItem>
                                    )}
                                </List>
                                <ListTablePagination
                                    perPage={perPage}
                                    totalCount={totalCount}
                                    page={page}
                                    onChangePage={(event, page) => {
                                        const newUrlQueryParams = {
                                            ...urlQueryParams,
                                            page: page,
                                        };

                                        const queryString = '?' + qs.stringify(newUrlQueryParams);

                                        history.push(location.pathname + queryString);
                                    }}
                                />
                            </Paper>
                        </>
                    )}
                </FillHeightContainer>
            </React.Fragment>
        );
    }
}

export default InvoicesPage;