import React, {useEffect, useState} from 'react';

import MailOutlineIcon from '@material-ui/icons/MailOutline';

import {useFulfillmentNotifyCustomer} from "shared-react/api/SaleOrderApi";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import ProgressButton from "shared-react/utils/ProgressButton";


function NotifyUser({
                              order,
                              fulfillment,
                              onNotificationSent,
                          }) {
    const [updatedOrder, isInProgress, errors, notify] = useFulfillmentNotifyCustomer(order.id, fulfillment.id);

    useEffect(() => {
        if (updatedOrder && typeof onNotificationSent === 'function') {
            onNotificationSent(updatedOrder);
        }
    }, [updatedOrder]);

    return (
        <React.Fragment>
            <ResponseErrors errors={errors} />
            {(fulfillment.deliveryStep + 1) < fulfillment.deliveryMethod.steps.length ? (
                <ProgressButton
                    startIcon={<MailOutlineIcon />}
                    loading={isInProgress}
                    text={'Pranešti pirkėjui'}
                    variant={"outlined"}
                    onClick={() => {
                        notify();
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}

export default NotifyUser;