import {useMakeRequest, API_URL, METHOD_POST, METHOD_PUT} from './Api';
import qs from "qs";

const API_USERS = '/users';

export function useGetUsers(queryParams) {
    const queryString = '?' + qs.stringify(queryParams);

    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_USERS + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetUser(userId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_USERS + '/' + userId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveUser(userData) {
    const isNew = !(userData !== null && typeof userData === 'object'
        && 'id' in userData && userData['id'] !== null);
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + userData.id;
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_USERS + url, userData);

    return [ data, setData, isLoading, errors, doRequest ];
}

function isNew(userData) {
    return !(userData !== null && typeof userData === 'object'
             && 'id' in userData && userData['id'] !== null);
}

export function getUserUrl(userData) {
    return API_URL + API_USERS + (isNew(userData) ? '' : '/' + userData.id);
}

export function getUserMethod(userData) {
    return isNew(userData) ? METHOD_POST : METHOD_PUT;
}

export function useResetPassword(userData) {
    const method = 'post';
    const url = '/password-reset';
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_USERS + url, userData);

    return [ data, setData, isLoading, errors, doRequest ];
}