import React from 'react';

import { useFormikContext, Formik, Form, Field } from 'formik';

import {FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";

function FormField() {
    const { values } = useFormikContext();

    if (values.isCompany !== "0") {
        return null;
    }

    return (
        <Field
            name="lastName"
            label={'Pavardė'}
            margin="dense"
            fullWidth
            component={FormMaterialTextField}
            nomargin={1}
        />
    );
}

export default FormField;