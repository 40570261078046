import React, {useEffect} from 'react';
import isObject from "lodash/isObject";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from '@material-ui/core/Typography';

import {parseProductToFormData} from "schemas/product";
import {makeStyles} from "@material-ui/core/styles";
import useProductForm, {setCurrentVariantIndex} from "products/ProductFormContext";
import LoyaltyIcon from "@material-ui/icons/Loyalty";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tab: {
        width: '100%',
        maxWidth: '100%',
    },
}));

const SortableItem = SortableElement(({variant, variantTitle, ...restProps}) => {
    const classes = useStyles();

    return (
        <Tab
            label={
                <>
                    <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div>{variantTitle}</div>
                    <Typography variant="caption" display="block" color="textSecondary" style={{
                        position: 'relative',
                    }}>
                        {variant.code}
                        {variant.sellable ? (
                            <LoyaltyIcon size={'small'} style={{
                                width: '0.6em',
                                height: '0.6em',
                                top: 0,
                                right: -10,
                                position: 'absolute',
                            }} />
                        ) : null}
                    </Typography>

                </div>

                </>
            }
            className={classes.tab}
            {...restProps}
        />
    );
});

const SortableList = SortableContainer(({
        currentVariantIndex,
        variants,
        handleChange,
        arrayHelpersRef,
        metadata,
}) => {
    const classes = useStyles();

    const handleClickChange = (event, newValue) => {
        console.log('handle click change22',event, newValue);
    };

    return (
        <Tabs
            orientation="vertical"
            variant="standard"
            value={currentVariantIndex}
            onChange={handleChange}
            aria-label="Vertical tabs"
            indicatorColor="primary"
            className={classes.tabs}
        >
            {variants.map((variant, index) => {
                const reducedVariantOptions = variant.variationOptionValues.reduce((accumulator = [], optionValue) => {
                    if (isObject(optionValue) && 'value' in optionValue) {
                        accumulator.push(optionValue.value);
                    }

                    return accumulator;
                }, []);
                const variantTitle = reducedVariantOptions.length > 0 ? reducedVariantOptions.join(', ') : 'Variantas ' + (index + 1);
                //console.log('variants', variant, reducedVariantOptions);

                return (
                    <SortableItem
                        key={'item-'+index}
                        index={index}
                        variant={variant}
                        variantTitle={variantTitle}
                        {...a11yProps(index)}
                    />
                );
            })}

            <Tab
                label="Pridėti variantą"
                className={classes.tab}
                {...a11yProps(variants.length)}
                onClick={() => {
                    const emptyProduct = {variants: [{}]};
                    const initialFormData = parseProductToFormData(emptyProduct, metadata.userWarehouses);
                    const initialVariantData = initialFormData.variants[0];

                    arrayHelpersRef.current.push({
                        ...initialVariantData,
                    })
                }}
            />
        </Tabs>
    );
});

function VariantTabs({
                         metadata,
                         variants,
                         arrayHelpersRef,
}) {
    const [productFormState, productFormDispatch] = useProductForm();
    const currentVariantIndex = productFormState.variantIndex;

    const handleClickChange = (event, newValue) => {
        productFormDispatch(setCurrentVariantIndex(newValue));
    };

    const handleSortChange = ({oldIndex, newIndex}) => {
        arrayHelpersRef.current.move(oldIndex, newIndex);
    };
    //console.log('check', currentVariantIndex);
    return (
        <React.Fragment>
            <SortableList
                currentVariantIndex={currentVariantIndex}
                variants={variants}
                handleChange={handleClickChange}
                onSortEnd={handleSortChange}
                arrayHelpersRef={arrayHelpersRef}
                metadata={metadata}
                distance={1}
            />
        </React.Fragment>
    );
}

export default React.memo(VariantTabs);