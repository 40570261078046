import React, {useEffect} from 'react';
import get from "lodash/get";

import CarouselSlideForm from "website/carousel/CarouselSlideForm";
import PageTitle from "utils/PageTitle";
import LinkBack from "utils/LinkBack";
import {getCarouselSlidesPath} from 'shared-react/utils/routes';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import useFormikForm from "shared-react/formik/useFormikForm";
import {getCarouselSlideMethod, getCarouselSlideUrl, useGetCarouselSlide} from "shared-react/api/CarouselApi";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import datefnsFormat from "date-fns/format";
import {parseFormDate} from "shared-react/utils/form";
import {useMetadata} from "shared-react/metadata/MetadataContext";
import StatusChipPublishedGroup from "shared-react/shop/statusChips/StatusChipPublishedGroup";

const metadataToLoadNames = ['carouselSlideFormSchema'];

function AddEditCarouselSlidePage({match, history}) {
    const carouselSlideId = match.params.id;
    const [carouselSlide, setCarouselSlide, isCarouselSlideLoading, carouselSlideErrors, fetchCarouselSlide] = useGetCarouselSlide(carouselSlideId);
    const {metadataState, metadataDispatch, hasMetadata} = useMetadata(metadataToLoadNames);
    const initialFormData = carouselSlide ? {
        ...carouselSlide,
        publishedAt: carouselSlide.publishedAt != null ? parseFormDate(carouselSlide.publishedAt) : null,
    } : {
        id: null,
        title: "",
        publishedAt: null,
        isActive: false,
        image: null,
        primaryText: "",
        secondaryText: "",
        buttonText: "",
        buttonLink: "",
    };
    const onSaveDataTransform = (values) => {
        return {
            ...values,
            publishedAt: values.publishedAt != null ? datefnsFormat(values.publishedAt, 'yyyy-MM-dd') : null,
        };
    };

    const [savedCarouselSlide, CarouselSlideFormikWrapper, carouselSlideFormProps, CarouselSlideFormErrors, carouselSlideFormErrorsProps, CarouselSlideFormSubmitButton, carouselSlideFormSubmitButtonProps] = useFormikForm({
        method: getCarouselSlideMethod(initialFormData),
        url: getCarouselSlideUrl(initialFormData),
        formData: initialFormData,
        formSchema: get(metadataState, 'metadata.carouselSlideFormSchema', null) ? get(metadataState, 'metadata.carouselSlideFormSchema', null) : null,
        formValidationGroup: 'carouselSlidePostPut',
        submitButtonText: 'Išsaugoti',
        onSaveDataTransform: onSaveDataTransform,
    });

    useEffect(() => {
        if (carouselSlideId) {
            fetchCarouselSlide();
        }
    }, []);

    useEffect(() => {
        if (savedCarouselSlide) {
            history.push(getCarouselSlidesPath());
        }
    }, [savedCarouselSlide]);

    if ((carouselSlideId && !carouselSlide) || !hasMetadata(metadataToLoadNames)) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <CarouselSlideFormikWrapper {...carouselSlideFormProps}>
                {({values, hasValidationErrors}) => {
                    return (
                        <FillHeightContainer header={
                            <>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: 54,
                                    padding: '0 16px',
                                }}>
                                    <LinkBack to={getCarouselSlidesPath()} text={"Atgal"}/>
                                    <PageTitle title={carouselSlideId ? carouselSlide.title : "Nauja slaidė"} gutterBottom/>
                                    <StatusChipPublishedGroup publishedAt={values.publishedAt != null ? datefnsFormat(values.publishedAt, 'yyyy-MM-dd') : null}/>
                                    <span style={{flex: 1}} />
                                    <CarouselSlideFormSubmitButton {...carouselSlideFormSubmitButtonProps}/>
                                </div>
                                {hasValidationErrors ? (
                                    <ResponseErrors errors={['Ne visi laukai teisingai užpildyti']}/>
                                ) : null}
                                <ResponseErrors errors={carouselSlideErrors} />
                                <CarouselSlideFormErrors {...carouselSlideFormErrorsProps}/>
                            </>
                        }>
                            <div style={{margin: 24}}>
                                <CarouselSlideForm />
                            </div>
                        </FillHeightContainer>
                    );
                }}
            </CarouselSlideFormikWrapper>
        </React.Fragment>
    );
}

export default AddEditCarouselSlidePage;