import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import {MaterialTextField} from './MaterialFields';

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <MaterialTextField
            fullWidth
            variant="outlined"
            margin="dense"
            label={props.selectProps.label}
            error={props.selectProps.error}
            helperText={props.selectProps.helperText}
            value={props.selectProps.value === null ? '' : props.selectProps.value.label}
            className={props.selectProps.classes.textField}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option({ children, ...props }) {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = Object.assign(props, { innerProps: rest });

    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...newProps}
        >
            {children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        null
    );

    {/*<Typography*/}
        {/*color="textSecondary"*/}
        {/*className={props.selectProps.classes.placeholder}*/}
        {/*{...props.innerProps}*/}
    {/*>*/}
        {/*{props.children}*/}
    {/*</Typography>*/}
}

const NullDropdownIndicator = () => {
    return null;
};

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const MenuList = function MenuList(props) {
    const children = props.children;

    if (!children.length) {
        return (<div className="myClassListName">{children}</div>);
    }

    return (
        <>
            {children.length && children.map((key, i) => {
                delete key.props.innerProps.onMouseMove; //FIX LAG!!
                delete key.props.innerProps.onMouseOver;  //FIX LAG!!

                return (
                    <div className="myClassItemName" key={i}>{key}</div>
                );
            })}
        </>
    );
};

const components = {
    Control,
    Menu,
    //MenuList,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

function CreatableMaterialReactSelect2({value, onChange, placeholder, suggestions, classes, theme, ...props}) {
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    return (
        <CreatableSelect
            classes={classes}
            styles={selectStyles}
            options={suggestions}
            components={components}
            value={value}
            onChange={onChange}
            isClearable
            {...props}
        />
    );
}

function MaterialReactSelect2({value, onChange, placeholder, classes, theme, ...props}) {
    console.log('MaterialReactSelect2 props', props);
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    return (
        <Select
            classes={classes}
            styles={selectStyles}
            components={components}
            value={value}
            onChange={onChange}
            isClearable
            {...props}
        />
    );
}

function MaterialReactAsyncSelect2({loadOptions, onChange, onInputChange, classes, theme, ...props}) {
    //console.log('MaterialReactAsyncSelect2 props', props);
    const selectStyles = {
        input: base => ({
            ...base,
            // color: theme.palette.text.primary,
            '& input': {
                // font: 'inherit',
            },
        }),
    };

    return (
        <AsyncSelect
            cacheOptions
            classes={classes}
            styles={selectStyles}
            components={components}
            loadOptions={loadOptions}
            onChange={onChange}
            value={null}
            openMenuOnClick={false}
            onInputChange={onInputChange}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral80: 'inherit',
                },
            })}
            {...props}
        />
    );
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        // padding: '10px 0px 10px 14px', //not dense
        padding: '0 0 0 14px', // dense
        // height: 'auto',
        height: '40px',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    textField: {
        margin: 0,
    },
    chip: {
        margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        position: 'absolute',
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

export const CreatableMaterialReactSelect = withStyles(styles, { withTheme: true })(CreatableMaterialReactSelect2);

export const MaterialReactAsyncSelect = withStyles(styles, { withTheme: true })(MaterialReactAsyncSelect2);

export const MaterialReactSelect = withStyles(styles, { withTheme: true })(MaterialReactSelect2);