import qs from "qs";
import axios from "axios";
import {API_URL, getAuthTokenHeaders, useMakeRequest} from './Api';
import isEmpty from "lodash/isEmpty";

const API_USER_GROUPS = '/user-groups';

export function useGetUserGroups(queryParams) {
    const queryString = !isEmpty(queryParams) ? '?' + qs.stringify(queryParams) : '';
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_USER_GROUPS + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetUserGroup(userGroupId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_USER_GROUPS + '/' + userGroupId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveUserGroup(userGroupData) {
    const isNew = !(userGroupData !== null && typeof userGroupData === 'object'
        && 'id' in userGroupData && userGroupData['id'] !== null);
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + userGroupData.id;
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_USER_GROUPS + url, userGroupData);

    return [ data, isLoading, errors, doRequest ];
}


export function getUserGroups(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);
    const authTokenHeaders = getAuthTokenHeaders();

    return axios.get(
        API_URL + API_USER_GROUPS + queryString,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function getUserGroup(userGroupId) {
    const authTokenHeaders = getAuthTokenHeaders();
    return axios.get(
        API_URL + API_USER_GROUPS + '/' + userGroupId,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function saveUserGroup(userGroup) {
    let method = 'post';
    let url = API_URL + API_USER_GROUPS;
    const authTokenHeaders = getAuthTokenHeaders();

    if ("id" in userGroup) {
        method = 'put';
        url = url + '/' + userGroup.id;
    }

    return axios.request({
        url: url,
        method: method,
        headers: {...authTokenHeaders},
        data: {...userGroup},
    }).then((response) => {
        return response.data;
    });
}