import React from "react";
import isObject from 'lodash/isObject';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

import {getImagePath, TYPE_THUMBNAIL} from "shared-react/utils/images";

const ListImageAvatar = ({image}) => {
    return (
        <ListItemAvatar>
            {isObject(image) ? (
                <Avatar src={getImagePath(TYPE_THUMBNAIL, image.folder, image.versionedName)} variant="rounded" />
            ) : (
                 <Avatar variant="rounded">
                     <ImageIcon />
                 </Avatar>
             )}
        </ListItemAvatar>
    )
};

export default ListImageAvatar;