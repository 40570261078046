import React, {useState, useEffect} from 'react';
import {API_URL} from "shared-react/api/Api";
import {API_PRODUCTS, useGetProducts} from 'shared-react/api/ProductApi';

import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from '@material-ui/icons/Image';
import Typography from '@material-ui/core/Typography';

import LinkWrapper from 'utils/LinkWrapper';
import {MaterialTextField} from "shared-react/formik/MaterialFields";
import ListTablePagination from "utils/ListTablePagination";
import useDebouncedCallback from "shared-react/useDebounce/callback";
import {ROUTE_PRODUCTS_NEW, getProductsPath, getProductPath} from 'shared-react/utils/routes';
import PageTitleWithButton from "../utils/PageTitleWithButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ProgressInputIndicator from 'shared-react/utils/ProgressInputIndicator';
import Currency from 'shared-react/utils/Currency';
import {ListItemTextCustomWidth} from "utils/List";
import * as permissions from "shared-react/constants/permissions";

function ProductsPage({match, history}) {
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const searchText = typeof match.params.searchText === 'undefined' ? '' : match.params.searchText;
    const perPage = 40;
    const [localSearchText, setLocalSearchText] = useState(searchText);
    const queryParams = {
        page,
        perPage,
        q: searchText,
    };
    const [products, totalCount, isProductsLoading, productsErrors, fetchProducts] = useGetProducts(API_URL + API_PRODUCTS, queryParams);

    const [setDebouncedSearchText] = useDebouncedCallback(
        (value) => {
            //let searchTextString = value.length > 0 ? '/' + value : '';
            //setSearchText(value);
            history.push(getProductsPath(1, value.length > 0 ? value : null));
        },
        500,
        []
    );

    useEffect(() => {
        //console.log('products use effect');
        fetchProducts();
    }, [page, searchText]);

    //console.log('routePage, routeSearchText', page, searchText);

    useEffect(() => {
        //console.log('useEffect routePage, searchText');

        setLocalSearchText(searchText);
    }, [searchText]);

    if (!products) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <PageTitleWithButton
                pageTitle={"Prekės"}
                buttonTitle={"Pridėti naują"}
                buttonRoute={ROUTE_PRODUCTS_NEW}
                buttonPermission={permissions.PERMISSION_PRODUCTS_CREATE}
                gutterBottom
            />
            <Paper>
                <List dense={true} style={{paddingTop: 8}}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <MaterialTextField
                                        margin="dense"
                                        fullWidth
                                        nomargin={1}
                                        onChange={(e) => {
                                            setLocalSearchText(e.target.value);
                                            setDebouncedSearchText(e.target.value);
                                        }}
                                        label="Paieška (pavadinimas ar kodas)"
                                        value={localSearchText}
                                    />
                                    {isProductsLoading ? (
                                        <ProgressInputIndicator />
                                    ) : null}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    {products.length > 0 ? (
                        <React.Fragment>
                            {products.map((product) =>
                                <ListItem
                                    key={product.id}
                                    button
                                    to={getProductPath(product.parentId)}
                                    component={LinkWrapper}
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ImageIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={product.name}
                                        secondary={product.code}
                                    />
                                    <ListItemTextCustomWidth
                                        width={170}
                                        textAlign={"right"}
                                        disableTypography
                                        primary={
                                            <React.Fragment>
                                                <Typography variant="body2" component="span">
                                                    Bendra: <Currency value={product.price}/>
                                                    {product.discountPrice !== null ? (
                                                        <React.Fragment>
                                                            <br/>
                                                            Nuolaida: <Currency value={product.discountPrice}/>
                                                        </React.Fragment>
                                                    ) : null}
                                                    {product.userGroupPrices.map((userGroupPrice, userGroupPriceKey) => (
                                                        <React.Fragment key={userGroupPriceKey}>
                                                            <br/>
                                                            {userGroupPrice.userGroup === null ? "Visi" : userGroupPrice.userGroup.title}:&nbsp;{userGroupPrice.quantity}&nbsp;už&nbsp;<Currency value={userGroupPrice.price}/>
                                                        </React.Fragment>
                                                    ))}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            )}
                        </React.Fragment>
                    ) : (
                        <ListItem>
                            <ListItemText
                                primary={"Prekių rasti nepavyko"}
                            />
                        </ListItem>
                    )}
                </List>
                <ListTablePagination
                    perPage={perPage}
                    totalCount={totalCount}
                    page={page}
                    onChangePage={(event, page) => {
                        history.push(getProductsPath(page, searchText.length > 0 ? searchText : null));
                        //setPage(page+1);
                    }}
                />
            </Paper>
        </React.Fragment>
    );
}

export default ProductsPage;