import React, { useEffect, useState } from 'react';
import {Form, useFormikContext} from 'formik';

import FocusError from "./FocusError";

const FormikFormWrapper = ({children}) => {
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const {values, errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            const productFormHasErrors = Object.keys(errors).length > 0;
            setHasValidationErrors(productFormHasErrors);
        }
    }, [errors, isSubmitting, isValidating]);

    return (
        <Form>
            {typeof children === 'function' ? children({values, hasValidationErrors}) : children}
            <FocusError/>
        </Form>
    )
};

export default FormikFormWrapper;