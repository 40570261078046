import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import {Redirect} from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import {useGetMetadata} from "shared-react/api/MetadataApi";
import {getUserUrl, getUserMethod} from "shared-react/api/UserApi";
import DataLoadingIndicator from "../utils/DataLoadingIndicator";
import {parseUserToFormData} from "../schemas/user";
import UserFormMainFields from "shared-react/user/UserFormMainFields";
import UserFormPriceGroupField from "shared-react/user/UserFormPriceGroupField";
import * as Yup from "yup";
import useFormikForm from "../formik/useFormikForm";

export const customerSchema = Yup.object().shape({
    isCompany: Yup.string()
        .required('Būtina pasirinkti'),
    username: Yup.string()
        .email('Netinkamas el. pašto adresas'),
    firstName: Yup.string()
        .required('Šis laukas negali būti tuščias'),
    lastName: Yup.string()
        .when('isCompany', {
            is: (val) => val === '0',
            then: Yup.string()
                .required('Šis laukas negali būti tuščias'),
        }),
    phoneNumber: Yup.string()
        .when('phoneNumberCountryCode', {
            is: (val) => val === '+370',
            then: Yup.string().matches(/[6]\d{7}/, 'Telefono numeris turi susidaryti iš 8 skaičių ir prasidėti su 6'),
            otherwise: Yup.string().matches(/[2]\d{7}/, 'Telefono numeris turi susidaryti iš 8 skaičių ir prasidėti su 2'),
        }),

});

function UserForm({wrapIntoPaper = false}) {
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(
        ['permissionTypes', 'userGroups']
    );

    useEffect(() => {
        fetchMetadata();
    }, []);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    return (
        <>
            <Paper elevation={wrapIntoPaper ? 2 : 0} style={{padding: 16}}>
                <Grid container spacing={2}>
                    <UserFormMainFields showPersonalCodeField={true} />
                </Grid>
            </Paper>
            <Paper style={{padding: "16px", marginTop: 16}} elevation={wrapIntoPaper ? 2 : 0}>
                <Grid container spacing={2}>
                    <UserFormPriceGroupField metadata={metadata} />
                </Grid>
            </Paper>
        </>
    );
}

export default UserForm;