import React from 'react';
// import 'typeface-roboto';
import 'roboto-fontface';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';
import LastLocationProvider from 'utils/lastLocation/LastLocationProvider';
import {Provider} from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ltLocale from 'date-fns/locale/lt';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import 'react-sortable-tree/style.css';

import store from 'store';
import RootApp from 'layout/RootApp'
import RouteAuthenticated from 'utils/RouteAuthenticated'
import LoginPage from 'login/LoginPage'
import {MetadataProvider} from 'shared-react/metadata/MetadataContext'

import {secondaryColor} from "shared-react/utils/theme";
// const theme = createMuiTheme({
//     typography: {
//         useNextVariants: true,
//     },
// });

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        background: {
            default: '#eee'
        },
        primary: {
            main: '#424242',
        },
        secondary: {
            contrastText: "rgba(33, 33, 33, 0.87)",
            main: secondaryColor,
        },
        text: {
            primary: 'rgba(33, 33, 33, 0.87)',
        },
        //type: 'dark',
    },
});

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <LastLocationProvider>
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ltLocale}>
                            <MetadataProvider>
                                <React.Fragment>
                                    <CssBaseline/>
                                    <Switch>
                                        <Route exact path="/login" component={LoginPage}/>
                                        <RouteAuthenticated component={RootApp}/>
                                    </Switch>
                                </React.Fragment>
                            </MetadataProvider>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </LastLocationProvider>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
