import React from 'react';
import ProductCategoryForm from 'productCategories/ProductCategoryForm'
import LinkBack from "../utils/LinkBack";
import {getProductCategoriesPath} from "shared-react/utils/routes";
import PageTitle from "../utils/PageTitle";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

function ProductCategoryAddPage() {
    return (
        <FillHeightContainer header={
            <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 54,
                    padding: '0 16px',
                }}>
                    <LinkBack to={getProductCategoriesPath()} text={"Atgal"}/>
                    <PageTitle title={"Nauja prekių kategorija"} gutterBottom/>
                    <span style={{flex: 1}} />
                    {/*<ProgressButton*/}
                    {/*    loading={isSubmitting}*/}
                    {/*    text={"Išsaugoti"}*/}
                    {/*    onClick={(e) => {*/}
                    {/*        handleSubmitMyForm(e);*/}
                    {/*    }}*/}
                    {/*    color="primary"*/}
                    {/*/>*/}
                </div>
            </>
        }>
            <ProductCategoryForm
                isNew={true}
                initialValues={{
                    title: '',
                    description: '',
                }}
            />
        </FillHeightContainer>
    );
}

export default ProductCategoryAddPage;