import React from 'react'
import arrayMove from "array-move";

const StateContext2 = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'product-form/';
const SUBMIT_FORM = PREFIX + 'SUBMIT_FORM';
const RESET_FORM = PREFIX + 'RESET_FORM';
const FOCUS_FIRST_FORM_ERROR_START = PREFIX + 'FOCUS_FIRST_FORM_ERROR_START';
const FOCUS_FIRST_FORM_ERROR_END   = PREFIX + 'FOCUS_FIRST_FORM_ERROR_END';
const SET_CURRENT_VARIANT_INDEX    = PREFIX + 'SET_CURRENT_VARIANT_INDEX';
const SET_FORM_HAS_ERRORS    = PREFIX + 'SET_FORM_HAS_ERRORS';

const initialState = {
    doSubmit: false,
    focusFirstFormError: false,
    variantIndex: 0,
    formHasErrors: false,
    hydrated: false,
};

// Reducer
function reducer(state = initialState, action = {}) {
    //console.log('ProductFormProvider got reducer action', state, action);

    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }

    switch (action.type) {
        case SUBMIT_FORM:
            return {
                ...state,
                doSubmit: true,
            };
        case SET_CURRENT_VARIANT_INDEX:
            return {
                ...state,
                variantIndex: action.payload.variantIndex >= 0 ? action.payload.variantIndex : 0,
            };
        case RESET_FORM:
            return {
                ...state,
                doSubmit: false,
            };
        case FOCUS_FIRST_FORM_ERROR_START:
            return {
                ...state,
                focusFirstFormError: true,
            };
        case FOCUS_FIRST_FORM_ERROR_END:
            return {
                ...state,
                focusFirstFormError: false
            };
        case SET_FORM_HAS_ERRORS:
            return {
                ...state,
                formHasErrors: action.payload.formHasErrors
            };
        default:
            return state;
    }
}

// Action Creators
export function submitProductForm() {
    return {type: SUBMIT_FORM, payload: {}};
}

export function setCurrentVariantIndex(variantIndex) {
    return {type: SET_CURRENT_VARIANT_INDEX, payload: {variantIndex}};
}

export function resetProductForm() {
    return {type: RESET_FORM, payload: {}};
}

export function focusFirstFormError() {
    return {type: FOCUS_FIRST_FORM_ERROR_START, payload: {}};
}

export function resetFocusFirstFormError() {
    return {type: FOCUS_FIRST_FORM_ERROR_END, payload: {}};
}

export function setFormHasErrors(formHasErrors) {
    return {type: SET_FORM_HAS_ERRORS, payload: {formHasErrors}};
}

function ProductFormProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        // additional initial params
    });

    //console.log('ProductFormProvider change', state, dispatch);

    return (
        <StateContext2.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext2.Provider>
    )
}

function useProductFormState() {
    const context = React.useContext(StateContext2);
    if (context === undefined) {
        throw new Error('useProductFormState must be used within a ProductFormProvider')
    }
    return context
}

function useProductFormDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useProductFormDispatch must be used within a ProductFormProvider')
    }
    return context
}

function useProductForm() {
    return [useProductFormState(), useProductFormDispatch()]
}

export default useProductForm;

export {ProductFormProvider, useProductFormState, useProductFormDispatch, useProductForm}
