import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from '@material-ui/core/CircularProgress';

function ProgressIconButton({
                                loading,
                                disabled = false,
                                size = 'small',
                                buttonColor = 'default',
                                circularColor = 'primary',
                                onClick,
                                icon = <DeleteIcon fontSize="inherit" />,
                                tooltipTitle = '',
}) {
    return (
        <div style={{
            display: 'inline-block',
            position: 'relative',
        }}>
            <Tooltip title={tooltipTitle}>
                <IconButton
                    disabled={loading || disabled}
                    size={size}
                    onClick={onClick}
                    color={buttonColor}
                >
                    {icon}
                </IconButton>
            </Tooltip>
            {loading && <CircularProgress
                size={24}
                color={circularColor}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                }}
            />}
        </div>
    );
}

export default ProgressIconButton;