import React from 'react';

import { useFormikContext, Formik, Form, Field } from 'formik';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

function FormField() {
    const { values } = useFormikContext();

    return (
        <Field
            name="isCompany"
            render={({field: { onChange, name, value }}) => {
                return (
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Paskyros tipas</FormLabel>
                        <RadioGroup
                            name={name}
                            value={value}
                            onChange={onChange}
                        >
                            <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label={"Fizinis asmuo"}
                            />
                            <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label={"Juridinis asmuo"}
                            />
                        </RadioGroup>
                    </FormControl>
                );
            }}
        />
    );
}

export default FormField;