import React from 'react';

import ReceiptIcon from '@material-ui/icons/Receipt';

import StatusChip from 'shared-react/shop/statusChips/StatusChip';

function StatusChipInvoice({small = false, invoice = null, fixedWidth = 130}) {
    let labelToShow;
    if (invoice === null) {
        labelToShow = "Neišrašyta";
    } else if (invoice.needsPrinting) {
        labelToShow = "Perspausdinti";
    } else {
        labelToShow = "Išrašyta"; // invoice.serie + invoice.number;
    }

    //const labelToShow = invoice === null  ? "Neišrašyta" : invoice.serie + invoice.number;
    const status = invoice === null || invoice.needsPrinting ? 0 : 1;

    return (
        <StatusChip icon={<ReceiptIcon/>} small={small} status={status} label={labelToShow} fixedWidth={fixedWidth}/>
    );
}

export default StatusChipInvoice;