import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom'
import {Formik, Form, Field, FieldArray} from 'formik';
import datefnsFormat from "date-fns/format";


import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

import {parsePackage, getEmptyMaterial} from "packages/schemas";
import {useSavePackage} from "shared-react/api/PackageApi";
import {FormDatePickerField, FormMaterialSelectField, FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";
import ConfirmationDialog from "shared-react/utils/ConfirmationDialog";

const materialCategories = [
    {
        value: 0,
        label: 'Nenurodoma'
    },
    {
        value: 1,
        label: 'Prekinė'
    },
    {
        value: 2,
        label: 'Grupinė'
    },
    {
        value: 3,
        label: 'Transporto'
    }
];

const materialTypes = [
    {
        value: 1,
        label: 'Popierinė'
    },
    {
        value: 2,
        label: 'Plastikinė'
    },
    {
        value: 3,
        label: 'Stiklinė'
    },
    {
        value: 4,
        label: 'Metalinė'
    }
];

function PackageForm({aPackage = {}, isNew}) {
    const [packageData, setPackageData] = useState(null);
    const [savedPackage, isSubmitting, packageErrors, submitPackage] = useSavePackage(packageData, isNew);
 
    const onSavePackage = (values) => {
        const newValues = {
            ...values,
            supplyingFrom: datefnsFormat(values.supplyingFrom, 'yyyy-MM-dd'),
        };

        setPackageData(newValues);
        submitPackage();
    };

    const initialValues = parsePackage(aPackage);

    if (savedPackage) {
        return (<Redirect push to={`/pakuotes`}/>);
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    onSavePackage(values);
                }}
            >
                {({values}) => (
                    <Form>
                        <Paper>
                            <div style={{padding: 16}}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <Field
                                            name="title"
                                            label="Pavadinimas"
                                            component={FormMaterialTextField}
                                            fullWidth
                                            margin="dense"
                                            nomargin={1}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="supplyingFrom"
                                            label={'Tiekiama nuo'}
                                            component={FormDatePickerField}
                                            invalidDateMessage={"Neegzistuojanti data"}
                                            fullWidth
                                            nomargin={1}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <FieldArray
                                name={`materials`}
                                render={arrayHelpers => {
                                    return (
                                        <React.Fragment>
                                            {values.materials.map((material, materialIndex) => (
                                                <React.Fragment key={materialIndex}>
                                                    <Divider variant="middle" />
                                                    <div style={{padding: 16}}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    name={`materials[${materialIndex}].title`}
                                                                    label={'Pavadinimas'}
                                                                    component={FormMaterialTextField}
                                                                    fullWidth
                                                                    margin="dense"
                                                                    nomargin={1}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} sm={4}>
                                                                <Field
                                                                    name={`materials[${materialIndex}].category`}
                                                                    label={'Kategorija'}
                                                                    options={materialCategories}
                                                                    component={FormMaterialSelectField}
                                                                    fullWidth
                                                                    margin="dense"
                                                                    nomargin={1}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} sm={4}>
                                                                <Field
                                                                    name={`materials[${materialIndex}].type`}
                                                                    label={'Rūšis'}
                                                                    options={materialTypes}
                                                                    component={FormMaterialSelectField}
                                                                    fullWidth
                                                                    margin="dense"
                                                                    nomargin={1}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Field
                                                                    name={`materials[${materialIndex}].amountPerPackage`}
                                                                    label={'Pakuočių sk.'}
                                                                    component={FormMaterialTextField}
                                                                    fullWidth
                                                                    margin="dense"
                                                                    nomargin={1}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} style={{display: 'none'}}>
                                                                <Field
                                                                    name={`materials[${materialIndex}].packagesAmount`}
                                                                    label={'Prekinių vnt. sk.'}
                                                                    component={FormMaterialTextField}
                                                                    fullWidth
                                                                    margin="dense"
                                                                    nomargin={1}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Field
                                                                    name={`materials[${materialIndex}].weightInGrams`}
                                                                    label={'Svoris, g.'}
                                                                    component={FormMaterialTextField}
                                                                    fullWidth
                                                                    margin="dense"
                                                                    nomargin={1}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} style={{textAlign: 'right'}}>
                                                                <ConfirmationDialog
                                                                    dialogContent={"Ar tikrai norite pašalinti šią medžiagą"}
                                                                    confirmationFunction={() => arrayHelpers.remove(materialIndex)}
                                                                >
                                                                    <IconButton>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ConfirmationDialog>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => {
                                                    const emptyMaterial = getEmptyMaterial();
                                                    arrayHelpers.push(emptyMaterial);
                                                }}>
                                                Pridėti medžiagą
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </Paper>
                        <div style={{display: 'flex'}}>
                            {!isSubmitting && packageErrors.length > 0
                                ?
                                packageErrors.map((error, errorIndex) => (
                                    <span key={errorIndex}>{error}</span>
                                ))
                                : null
                            }
                            <span style={{flex: 1}}/>
                            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                Išsaugoti
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
}

export default PackageForm;