import {call, put, select, takeLatest} from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';
import produce from "immer";
import mergeWith from 'lodash/mergeWith';
import get from 'lodash/get';

import {calculateOrder, saveOrder as apiCreateOrder, API_CALCULATE_ORDER, API_ORDERS} from 'shared-react/api/OrderApi';

// Names
export const NAME_NEW_ORDER = 'NEW_ORDER';
export const NAME_EDIT_ORDER = 'EDIT_ORDER';

// Actions
const PREFIX                      = 'my-app/order/';
const UPDATE_ORDER_AND_DATA       = PREFIX + 'UPDATE_ORDER_AND_DATA';
const RESET_ORDER_AND_DATA        = PREFIX + 'RESET_ORDER_AND_DATA';
const ADD_PRODUCT                 = PREFIX + 'ADD_PRODUCT';
const UPDATE_PRODUCT              = PREFIX + 'UPDATE_PRODUCT';
const REMOVE_PRODUCT              = PREFIX + 'REMOVE_PRODUCT';
const SET_SELLER_ID               = PREFIX + 'SET_SELLER_ID';
const SET_CUSTOMER_ID             = PREFIX + 'SET_CUSTOMER_ID';
const SET_CUSTOMER_FIRST_NAME     = PREFIX + 'SET_CUSTOMER_FIRST_NAME';
const SET_CUSTOMER_LAST_NAME      = PREFIX + 'SET_CUSTOMER_LAST_NAME';
const SET_CUSTOMER_EMAIL          = PREFIX + 'SET_CUSTOMER_EMAIL';
const SET_DELIVERY_METHOD_ID      = PREFIX + 'SET_DELIVERY_METHOD_ID';
const SET_CUSTOM_DELIVERY_PRICE   = PREFIX + 'SET_CUSTOM_DELIVERY_PRICE';
export const SET_ORDER_DATA_FIELD = PREFIX + 'SET_ORDER_DATA_FIELD';
const CALCULATE_SUCCEEDED         = PREFIX + 'CALCULATE_SUCCEEDED';
const CALCULATE_FAILED            = PREFIX + 'CALCULATE_FAILED';
const SAVE                        = PREFIX + 'SAVE';
const SAVE_SUCCEEDED              = PREFIX + 'SAVE_SUCCEEDED';
const SAVE_FAILED                 = PREFIX + 'SAVE_FAILED';

// Reducer
export const initialState = {
    isCalculating: false,
    isSaving: false,
    updateUuid: uuidv4(),
    order: {
        fulfillments: [],
    },
    orderData: {
        sellerId: null,
        customerId: null,
        customerIsCompany: false,
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        customerPhoneNumberCountryCode: '+370',
        customerPhoneNumber: '',
        customerPvmCode: '',
        customerAddress: '',
        customerCode: '',
        customerConfirmedAgreement: false,
        deliveryMethodCode: '',
        customDeliveryPrice: 0,
        omnivaPickupPointId: '',
        paymentMethodCode: '',
        payseraPaymentId: '',
        fulfillments: [],
    },
    hydrated: false,
};

export function createOrderReducer(ordername) {
    return function reducer(state = initialState, action = {}) {
        const {name} = action;
        if (ordername !== name) return state;

        if (!state.hydrated) {
            state = { ...initialState, ...state, hydrated: true };
        }

        switch (action.type) {
            case UPDATE_ORDER_AND_DATA:
                const order = action.payload.order;
                const orderData = buildOrderDataFromOrder(order);

                return {
                    ...state,
                    orderData: orderData,
                    order: order,
                };
            case RESET_ORDER_AND_DATA:
                return {
                    ...state,
                    orderData: {
                        ...initialState.orderData,
                        sellerId: state.orderData.sellerId, //don't rewrite seller id
                    },
                    order: initialState.order,
                };
            case ADD_PRODUCT:
            case UPDATE_PRODUCT: {
                const fulfillmentIndex  = action.payload.fulfillmentIndex;
                const productId         = action.payload.productId;
                const warehouseId       = parseInt(action.payload.warehouseId, 10);
                const quantity          = action.payload.quantity !== undefined ? parseInt(action.payload.quantity, 10) : undefined;
                const customPrice       = action.payload.customPrice;
                const customPriceReason = action.payload.customPriceReason;

                const newFulfillments = produce(state.orderData.fulfillments, draftFulfillments => {
                    //todo[as]: look for unfulfilled first?
                    let fulfillmentToEditIndex = fulfillmentIndex;
                    if (fulfillmentIndex === undefined) {
                        fulfillmentToEditIndex = draftFulfillments.findIndex((fulfillment) => {
                            return fulfillment.warehouseId === warehouseId;
                        });
                    }

                    if (fulfillmentToEditIndex === -1) {
                        draftFulfillments.push({
                            warehouseId,
                            orderProducts: [
                                {
                                    productId,
                                    quantity: quantity === undefined ? 1 : quantity,
                                    ...(customPrice !== undefined && {customPrice}),
                                    ...(customPriceReason !== undefined && {customPriceReason}),
                                }
                            ],
                        });
                    } else {
                        const fulfillmentToEdit = draftFulfillments[fulfillmentToEditIndex];

                        const orderProductToEditIndex = fulfillmentToEdit.orderProducts.findIndex((orderProduct) => {
                            return orderProduct.productId === productId;
                        });

                        if (orderProductToEditIndex === -1) {
                            draftFulfillments[fulfillmentToEditIndex].orderProducts.push(
                                {
                                    productId,
                                    quantity: quantity === undefined ? 1 : quantity,
                                    ...(customPrice !== undefined && {customPrice}),
                                    ...(customPriceReason !== undefined && {customPriceReason}),
                                }
                            );
                        } else {
                            const productInCart = draftFulfillments[fulfillmentToEditIndex].orderProducts[orderProductToEditIndex];

                            draftFulfillments[fulfillmentToEditIndex].orderProducts[orderProductToEditIndex] = {
                                ...productInCart,
                                ...(quantity !== undefined && {quantity}),
                                ...(customPrice !== undefined && {customPrice}),
                                ...(customPriceReason !== undefined && {customPriceReason}),
                            };
                        }
                    }
                });

                return {
                    ...state,
                    isCalculating: true,
                    orderData: {
                        ...state.orderData,
                        fulfillments: newFulfillments,
                    },
                };
            }
            case REMOVE_PRODUCT: {
                const fulfillmentIndex = action.payload.fulfillmentIndex;
                const productId        = action.payload.productId;
                const warehouseId      = parseInt(action.payload.warehouseId);

                const newFulfillments = produce(state.orderData.fulfillments, draftFulfillments => {
                    let fulfillmentToEditIndex = fulfillmentIndex;
                    if (fulfillmentIndex === undefined) {
                        fulfillmentToEditIndex = draftFulfillments.findIndex((fulfillment) => {
                            return fulfillment.warehouseId === warehouseId;
                        });
                    }

                    if (fulfillmentToEditIndex === -1) {
                    } else {
                        const fulfillmentToEdit = draftFulfillments[fulfillmentToEditIndex];

                        const orderProductToRemoveIndex = fulfillmentToEdit.orderProducts.findIndex((orderProduct) => {
                            return orderProduct.productId === productId;
                        });

                        if (orderProductToRemoveIndex === -1) {
                        } else {
                            draftFulfillments[fulfillmentToEditIndex].orderProducts = [
                                ...draftFulfillments[fulfillmentToEditIndex].orderProducts.slice(0, orderProductToRemoveIndex),
                                ...draftFulfillments[fulfillmentToEditIndex].orderProducts.slice(orderProductToRemoveIndex + 1),
                            ];

                            if (draftFulfillments[fulfillmentToEditIndex].orderProducts.length === 0) {
                                draftFulfillments.splice(fulfillmentToEditIndex, 1);
                            }
                        }
                    }
                });

                return {
                    ...state,
                    isCalculating: true,
                    orderData: {
                        ...state.orderData,
                        fulfillments: newFulfillments,
                    },
                };
            }
            case SET_SELLER_ID:
                return {
                    ...state,
                    orderData: {
                        ...state.orderData,
                        sellerId: action.payload.sellerId,
                    },
                };
            case SET_CUSTOMER_ID:
                return {
                    ...state,
                    isCalculating: true,
                    orderData: {
                        ...state.orderData,
                        customerId: action.payload.customerId,
                    },
                };
            case SET_CUSTOMER_FIRST_NAME:
                return {
                    ...state,
                    orderData: {
                        ...state.orderData,
                        customerFirstName: action.payload.customerFirstName,
                    },
                };
            case SET_CUSTOMER_LAST_NAME:
                return {
                    ...state,
                    orderData: {
                        ...state.orderData,
                        customerLastName: action.payload.customerLastName,
                    },
                };
            case SET_CUSTOMER_EMAIL:
                return {
                    ...state,
                    orderData: {
                        ...state.orderData,
                        customerEmail: action.payload.customerEmail,
                    },
                };
            // case SET_DELIVERY_METHOD_ID:
            //     return {
            //         ...state,
            //         isCalculating: true,
            //         orderData: {
            //             ...state.orderData,
            //             deliveryMethodId: String(action.payload.deliveryMethodId),
            //         },
            //     };
            case SET_ORDER_DATA_FIELD:
                //payload values: fieldName, fieldValue, shouldRecalculate
                if (
                    typeof action.payload.fieldName === 'string' && typeof action.payload.fieldValue !== 'undefined'
                    && action.payload.fieldName in initialState.orderData
                ) {
                    const shouldRecalculate = typeof action.payload.shouldRecalculate === 'boolean' ? action.payload.shouldRecalculate : false;

                    return {
                        ...state,
                        isCalculating: shouldRecalculate,
                        orderData: {
                            ...state.orderData,
                            [action.payload.fieldName]: action.payload.fieldValue,
                        },
                    };
                } else {
                    return state;
                }
            case SET_CUSTOM_DELIVERY_PRICE:
                return {
                    ...state,
                    isCalculating: true,
                    orderData: {
                        ...state.orderData,
                        customDeliveryPrice: action.payload.customDeliveryPrice,
                    },
                };
            case CALCULATE_SUCCEEDED: {
                //const parsedCartData = parseCartData(action.order);

                // in case server stripped some products or modified quantities, we recalculate them here from response
                // const newOrderProducts = parsedCartData.products.reduce((orderProducts, product) => {
                //     console.log('reduce orderProducts', orderProducts, product);
                //     orderProducts[product.id] = product.amount;
                //
                //     return orderProducts;
                // console.log('CALCULATE_SUCCEEDED', action);

                // const orderData1 = mergeWith(
                //     {}, initialState.orderData, action.orderData,
                //     (a, b) => b === null ? a : b
                // );

                const orderData = action.orderData;//buildOrderDataFromOrder(action.order);

                return {
                    ...state,
                    isCalculating: false,
                    updateUuid: uuidv4(),
                    //orderData: parsedCartData, //todo[as]
                    order: action.order,
                    orderData: orderData, //todo[as]
                    //orderData: buildOrderDataFromOrder(action.order),
                };
            }
            case CALCULATE_FAILED:
                return {
                    ...state,
                    isCalculating: false,
                };
            case SAVE:
                return {
                    ...state,
                    isSaving: true,
                };
            case SAVE_SUCCEEDED:
                return {
                    ...state,
                    isSaving: false,
                    order: action.order,
                };
            case SAVE_FAILED:
                return {
                    ...state,
                    isSaving: false,
                };
            default:
                return state;
        }
    }
}

export function buildOrderDataFromOrder(order) {
    let orderData = {
        ...initialState.orderData,
        id: get(order, 'id', null),
        sellerId: get(order, 'seller.id', initialState.orderData.sellerId),
        customerId: get(order, 'customer.id', initialState.orderData.customerId),
        deliveryMethodCode: get(order, 'deliveryMethod.code', initialState.orderData.deliveryMethodCode) != null ? get(order, 'deliveryMethod.code', initialState.orderData.deliveryMethodCode) : initialState.orderData.deliveryMethodCode,
        customDeliveryPrice: get(order, 'deliveryPrice', initialState.orderData.customDeliveryPrice) != null ? get(order, 'deliveryPrice', initialState.orderData.customDeliveryPrice) : initialState.orderData.customDeliveryPrice,
        omnivaPickupPointId: get(order, 'omnivaPickupPointId', initialState.orderData.omnivaPickupPointId) != null ? get(order, 'omnivaPickupPointId', initialState.orderData.omnivaPickupPointId) : initialState.orderData.omnivaPickupPointId,
        paymentMethodCode: get(order, 'paymentMethod.code', initialState.orderData.paymentMethodCode) != null ? get(order, 'paymentMethod.code', initialState.orderData.paymentMethodCode) : initialState.orderData.paymentMethodCode,
        payseraPaymentId: get(order, 'payseraPaymentId', initialState.orderData.payseraPaymentId) != null ? get(order, 'payseraPaymentId', initialState.orderData.payseraPaymentId) : initialState.orderData.payseraPaymentId,

        customerIsCompany: get(order, 'customerIsCompany', initialState.orderData.customerIsCompany) != null ? get(order, 'customerIsCompany', initialState.orderData.customerIsCompany) : initialState.orderData.customerIsCompany,
        customerFirstName: get(order, 'customerFirstName', initialState.orderData.customerFirstName) != null ? get(order, 'customerFirstName', initialState.orderData.customerFirstName) : initialState.orderData.customerFirstName,
        customerLastName: get(order, 'customerLastName', initialState.orderData.customerLastName) != null ? get(order, 'customerLastName', initialState.orderData.customerLastName) : initialState.orderData.customerLastName,
        customerEmail: get(order, 'customerEmail', initialState.orderData.customerEmail) != null ? get(order, 'customerEmail', initialState.orderData.customerEmail) : initialState.orderData.customerEmail,
        customerPhoneNumberCountryCode: get(order, 'customerPhoneNumberCountryCode', initialState.orderData.customerPhoneNumberCountryCode) != null ? get(order, 'customerPhoneNumberCountryCode', initialState.orderData.customerPhoneNumberCountryCode) : initialState.orderData.customerPhoneNumberCountryCode,
        customerPhoneNumber: get(order, 'customerPhoneNumber', initialState.orderData.customerPhoneNumber) != null ? get(order, 'customerPhoneNumber', initialState.orderData.customerPhoneNumber) : initialState.orderData.customerPhoneNumber,
        customerPvmCode: get(order, 'customerPvmCode', initialState.orderData.customerPvmCode) != null ? get(order, 'customerPvmCode', initialState.orderData.customerPvmCode) : initialState.orderData.customerPvmCode,
        customerAddress: get(order, 'customerAddress', initialState.orderData.customerAddress) != null ? get(order, 'customerAddress', initialState.orderData.customerAddress) : initialState.orderData.customerAddress,
        customerCode: get(order, 'customerCode', initialState.orderData.customerCode) != null ? get(order, 'customerCode', initialState.orderData.customerCode) : initialState.orderData.customerCode,
    };

    let fulfillmentsData = [];
    order.fulfillments.forEach((fulfillment, index) => {
        let orderProductsData = [];

        fulfillment.orderProducts.forEach((orderProduct, orderProductIndex) => {
            let orderProductData = {
                productId: orderProduct.productId,
                quantity: orderProduct.amount,
                customPrice: orderProduct.customPrice,
                customPriceReason: orderProduct.customPriceReason,
            };

            if ('id' in orderProduct) {
                orderProductData['id'] = orderProduct.id;
            }

            orderProductsData.push(orderProductData);
        });

        let fulfillmentData = {
            warehouseId: parseInt(fulfillment.warehouse.id, 10),
            orderProducts: orderProductsData,
        };

        if ('id' in fulfillment) {
            fulfillmentData['id'] = fulfillment.id;
        }

        fulfillmentsData.push(fulfillmentData);
    });

    orderData['fulfillments'] = fulfillmentsData;

    return orderData;
}

// Action Creators
export function updateOrderAndData(name, order) {
    return {type: UPDATE_ORDER_AND_DATA, name, payload: {order}};
}

export function resetOrderAndData(name) {
    return {type: RESET_ORDER_AND_DATA, name};
}

export function addOrderProduct(name, endpoint, productId, warehouseId, quantity = undefined, customPrice = undefined, customPriceReason = undefined) {
    return {type: ADD_PRODUCT, name, endpoint, payload: {fulfillmentIndex: undefined, productId, warehouseId, quantity, customPrice, customPriceReason}};
}

export function updateOrderProduct(name, endpoint, fulfillmentIndex, productId, warehouseId, quantity = undefined, customPrice = undefined, customPriceReason = undefined) {
    return {type: UPDATE_PRODUCT, name, endpoint, payload: {fulfillmentIndex, productId, warehouseId, quantity, customPrice, customPriceReason}};
}

export function removeOrderProduct(name, endpoint, fulfillmentIndex, productId, warehouseId) {
    return {type: REMOVE_PRODUCT, name, endpoint, payload: {fulfillmentIndex, productId, warehouseId}};
}

export function setOrderSellerId(name, endpoint, sellerId) {
    return {type: SET_SELLER_ID, name, payload: {sellerId}};
}

export function setOrderCustomerId(name, endpoint, customerId) {
    return {type: SET_CUSTOMER_ID, name, endpoint, payload: {customerId}};
}

export function setOrderCustomerFirstName(name, customerFirstName) {
    return {type: SET_CUSTOMER_FIRST_NAME, name, payload: {customerFirstName}};
}

export function setOrderCustomerLastName(name, customerLastName) {
    return {type: SET_CUSTOMER_LAST_NAME, name, payload: {customerLastName}};
}

export function setOrderCustomerEmail(name, customerEmail) {
    return {type: SET_CUSTOMER_EMAIL, name, payload: {customerEmail}};
}

export function setOrderCustomDeliveryPrice(name, endpoint, customDeliveryPrice) {
    return {type: SET_CUSTOM_DELIVERY_PRICE, name, endpoint, payload: {customDeliveryPrice}};
}

export function setOrderDataField(name, endpoint, fieldName, fieldValue, shouldRecalculate = false) {
    return {type: SET_ORDER_DATA_FIELD, name, endpoint, payload: {
        fieldName,
        fieldValue,
        shouldRecalculate,
    }};
}

export function saveOrder(name, endpoint) {
    return {type: SAVE, name, endpoint};
}

/*
 * Selector. The query depends by the state shape
 */
export const getNewOrderState = (state) => state.newOrder;
export const getEditOrderState = (state) => state.editOrder;

// side effects, only as applicable
// e.g. thunks, epics, etc
function* calculateOrderSaga(action) {
    try {
        const orderState = action.name === NAME_NEW_ORDER ? yield select(getNewOrderState) : yield select(getEditOrderState);
        const orderData = orderState.orderData;
        const endpoint = get(action, 'endpoint', API_CALCULATE_ORDER);

        if (orderState.isCalculating) {
            const response = yield call(calculateOrder, endpoint, orderData);

            if (response.success) {
                yield put({
                    type: CALCULATE_SUCCEEDED,
                    name: action.name,
                    //order: response.data,
                    order: response.data.order,
                    orderData: response.data.orderData,
                });
            } else {
                yield put({
                    type: CALCULATE_FAILED,
                    name: action.name,
                    payload: {errors: response.errors}
                });
            }
        }
    } catch (e) {
        yield put({
            type: CALCULATE_FAILED,
            name: action.name,
            payload: {errors: [e.message]}
        });
    }
}

function* saveOrderSaga(action) {
    try {
        const orderState = action.name === NAME_NEW_ORDER ? yield select(getNewOrderState) : yield select(getEditOrderState);
        const orderData = orderState.orderData;
        const endpoint = get(action, 'endpoint', API_ORDERS);
        const response = yield call(apiCreateOrder, endpoint,orderData);

        yield put({
            type: SAVE_SUCCEEDED,
            order: response.data,
            name: action.name,
        });
    } catch (e) {
        yield put({
            type: SAVE_FAILED,
            name: action.name,
            payload: {message: e.message}
        });
    }
}

export function* orderSaga() {
    yield takeLatest(ADD_PRODUCT, calculateOrderSaga);
    yield takeLatest(UPDATE_PRODUCT, calculateOrderSaga);
    yield takeLatest(REMOVE_PRODUCT, calculateOrderSaga);
    yield takeLatest(SET_CUSTOMER_ID, calculateOrderSaga);
    yield takeLatest(SET_ORDER_DATA_FIELD, calculateOrderSaga);
    yield takeLatest(SET_CUSTOM_DELIVERY_PRICE, calculateOrderSaga);
    yield takeLatest(SAVE, saveOrderSaga);
}