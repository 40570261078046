export function getDefaultArticleTemplate() {
    return [
        {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '6',
                    components: [
                        {
                            type: "text",
                            variant: 'h4',
                            content: "Lorem ipsum dolor sit amet.",
                        },
                        {
                            type: "text",
                            variant: 'body1',
                            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget nulla elit. Nulla aliquet mollis faucibus.",
                        },
                        {
                            type: "material-button",
                            buttonVariant: 'outlined',
                            buttonColor: 'empty',
                            buttonText: "Button",
                        },
                    ]
                },
            ]
        },
        {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '6',
                    components: [
                        {
                            type: "fancy-image-container",
                            imageWidth: '',
                        },
                    ]
                },
                {
                    type: 'grid-item',
                    mdWidth: '6',
                    components: [
                        {
                            type: "text",
                            variant: 'h4',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet.",
                        },
                        {
                            type: "text",
                            variant: 'body1',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget nulla elit. Nulla aliquet mollis faucibus.",
                        },
                        {
                            type: "material-button",
                            buttonVariant: 'contained',
                            buttonColor: 'primary',
                            buttonText: "Button",
                        },
                    ]
                },
            ]
        },
        {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '4',
                    components: [
                        {
                            type: "text",
                            variant: 'h4',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet",
                        },
                        {
                            type: "text",
                            variant: 'body1',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget nulla elit. Nulla aliquet mollis faucibus.",
                        },
                    ]
                },
                {
                    type: 'grid-item',
                    mdWidth: '4',
                    components: [
                        {
                            type: "text",
                            variant: 'h4',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet",
                        },
                        {
                            type: "text",
                            variant: 'body1',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget nulla elit. Nulla aliquet mollis faucibus.",
                        },
                    ]
                },
                {
                    type: 'grid-item',
                    mdWidth: '4',
                    components: [
                        {
                            type: "text",
                            variant: 'h4',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet",
                        },
                        {
                            type: "text",
                            variant: 'body1',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget nulla elit. Nulla aliquet mollis faucibus.",
                        },
                    ]
                },
            ]
        },
        {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '6',
                    components: [
                        {
                            type: "text",
                            variant: 'h4',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet.",

                        },
                        {
                            type: "text",
                            variant: 'body1',
                            align: 'center',
                            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget nulla elit. Nulla aliquet mollis faucibus.",
                        },
                        {
                            type: "material-button",
                            buttonVariant: 'contained',
                            buttonColor: 'secondary',
                            buttonText: "Button",
                        },
                    ]
                },
                {
                    type: 'grid-item',
                    mdWidth: '6',
                    components: [
                        {
                            type: "fancy-image-container",
                            imageWidth: '',
                        },
                    ]
                },
            ]
        },
        {
            type: 'grid-container',
            components: [
                {
                    type: 'grid-item',
                    mdWidth: '4',
                    components: [
                        {
                            type: "fancy-image-container",
                            imageWidth: 'full',
                        },
                    ]
                },
                {
                    type: 'grid-item',
                    mdWidth: '4',
                    components: [
                        {
                            type: "fancy-image-container",
                            imageWidth: 'full',
                        },
                    ]
                },
                {
                    type: 'grid-item',
                    mdWidth: '4',
                    components: [
                        {
                            type: "fancy-image-container",
                            imageWidth: 'full',
                        },
                    ]
                },
            ]
        },
    ];
}