import React, {useEffect} from 'react'
import {useGetMetadata} from "shared-react/api/MetadataApi";
import has from "lodash/has";
import get from "lodash/get";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'metadata/';
const FETCH_START = PREFIX + 'FETCH_START';
const FETCH_END = PREFIX + 'FETCH_END';
const ADD_METADATA = PREFIX + 'ADD_METADATA';
const LOADING_DONE = PREFIX + 'LOADING_DONE';
const SET = PREFIX + 'SET';

const initialState = {
    isLoading: false,
    metadata: {},
    hydrated: false,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }
    //console.log('got reducer action', action);

    switch (action.type) {
        case FETCH_START:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_END:
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    ...action.payload,
                },
                isLoading: false,
            };
        case ADD_METADATA:
            const currentData = get(state.metadata, action.payload.metadataName, []);
            const newData = [
                ...currentData,
                action.payload.data,
            ];

            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    [action.payload.metadataName]: newData,
                },
            };
        case LOADING_DONE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

// Action Creators
export function fetchMetadataStart(metadataNames = []) {
    return {type: FETCH_START, payload: metadataNames};
}

export function fetchMetadataEnd(metadata) {
    return {type: FETCH_END, payload: metadata};
}

export function addMetadata(metadataName, data) {
    return {type: ADD_METADATA, payload: {metadataName, data}};
}

export function hasMetadata(state = {}) {
    return (metadataNames = []) => {
        let hasMetadata = true;
        metadataNames.forEach(metadataName => {
            //console.log('has(metadata, metadataName)', metadataName, state.metadata, has(state.metadata, metadataName));
            if (!has(state.metadata, metadataName)) {
                hasMetadata = false;
            }
        });

        return hasMetadata;
    };
}

export function getMetadataById(state = {}) {
    return (metadataName = '', id = null) => {
        if (has(state.metadata, metadataName)) {
            const foundMetadata = state.metadata[metadataName].find((metadataValue) => {
                return metadataValue.id === id;
            });

            if (typeof foundMetadata !== 'undefined') {
                return foundMetadata;
            }
        }

        return null;
    };
}

function MetadataProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
    });

    //console.log('MetadataProvider change', state, dispatch);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

function useMetadataState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useMetadataState must be used within a MetadataProvider')
    }
    return context;
}

function useMetadataDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useMetadataDispatch must be used within a MetadataProvider')
    }
    return context;
}

function useMetadata(metadataNames = []) {
    const state = useMetadataState();
    const dispatch = useMetadataDispatch();

    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(metadataNames);

    useEffect(() => {
        // if (state has) {
        //
        // }
        //console.log('test MetaDataContext useEffect');
        dispatch(fetchMetadataStart(metadataNames));
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (metadata) {
            dispatch(fetchMetadataEnd(metadata));
        }
    }, [metadata]);

    return {
        metadataState: state,
        metadataDispatch: dispatch,
        hasMetadata: hasMetadata(state),
        getMetadataById: getMetadataById(state),
    }
}

export {MetadataProvider, useMetadataState, useMetadataDispatch, useMetadata}