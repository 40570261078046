import * as Yup from "yup";

export const STRING_EMAIL_REQUIRED = Yup.string()
    .email('Netinkamas el. pašto adresas')
    .required('El. pašto laukas negali būti tuščias');

export const STRING_REQUIRED = Yup.string()
    .required('Šis laukas negali būti tuščias');

export const STRING_PHONE_NUMBER_COUNTRY_CODE_REQUIRED = Yup.string()
    .oneOf(['+370', '+371'])
    .required('Būtina pasirinkti šalies kodą');

const PHONE_NUMBER_REGEX_LT = /[6]\d{7}/;
const PHONE_NUMBER_REGEX_LV = /[2]\d{7}/;

export const STRING_PHONE_NUMBER_LT_NULLABLE = Yup.string()
    .matches(PHONE_NUMBER_REGEX_LT, 'Telefono numeris turi susidaryti iš 8 skaičių ir prasidėti skaičiumi 6')
    .nullable();

export const STRING_PHONE_NUMBER_LT_REQUIRED = Yup.string()
    .matches(PHONE_NUMBER_REGEX_LT, 'Telefono numeris turi susidaryti iš 8 skaičių ir prasidėti skaičiumi 6')
    .required('Telefono nr. laukas negali būti tuščias');

export const STRING_PHONE_NUMBER_LV_NULLABLE = Yup.string()
    .matches(PHONE_NUMBER_REGEX_LV, 'Telefono numeris turi susidaryti iš 8 skaičių ir prasidėti skaičiumi 2')
    .nullable();

export const STRING_PHONE_NUMBER_LV_REQUIRED = Yup.string()
    .matches(PHONE_NUMBER_REGEX_LV, 'Telefono numeris turi susidaryti iš 8 skaičių ir prasidėti skaičiumi 2')
    .required('Telefono nr. laukas negali būti tuščias');

export const STRING_PLAIN_PASSWORD_REQUIRED = Yup.string()
    .min(8, 'Slaptažodis turi susidaryti bent iš 8 simbolių')
    .required('Šis laukas negali būti tuščias');

export const STRING_PLAIN_PASSWORD_CONFIRMATION_REQUIRED = Yup.string()
    .oneOf([Yup.ref('plainPassword'), null], 'Slaptažodžio laukai nesutampa')
    .required('Šis laukas negali būti tuščias');
