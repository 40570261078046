import {generatePath} from 'react-router-dom';

export const ROUTE_SEARCH = '/paieska/:page?';
export const ROUTE_CHECKOUT = '/krepselis';
export const ROUTE_DEFAULT = ROUTE_SEARCH;

export function getSearchPath(page = null) {
    return generatePath(ROUTE_SEARCH, {page});
}

// ------------------- PACKAGES -------------------
export const ROUTE_PACKAGES = '/pakuotes/:page?/:searchText?';
export const ROUTE_PACKAGES_NEW = '/pakuote/nauja';
export const ROUTE_PACKAGE = '/pakuote/:id';

export function getPackagesPath(page = null, searchText = null) {
    return generatePath(ROUTE_PACKAGES, {page, searchText});
}

export function getPackagePath(id) {
    return generatePath(ROUTE_PACKAGE, {id});
}

// ------------------- PRODUCTS -------------------
export const ROUTE_PRODUCTS_MERGE = '/prekiu-apjungimas/:ids';
export const ROUTE_PRODUCTS = '/prekes/:page?/:searchText?';
export const ROUTE_PRODUCTS_NEW = '/prekes/naujas';
export const ROUTE_PRODUCT = '/preke/:id';

export function getProductsMergePath(ids) {
    return generatePath(ROUTE_PRODUCTS_MERGE, {ids});
}

export function getProductsPath(page = null, searchText = null) {
    return generatePath(ROUTE_PRODUCTS, {page, searchText});
}

export function getProductPath(id) {
    return generatePath(ROUTE_PRODUCT, {id});
}

// ------------------- PRODUCT CATEGORIES -------------------
export const ROUTE_PRODUCT_CATEGORIES = '/prekiu-kategorijos/:page?';
export const ROUTE_PRODUCT_CATEGORIES_NEW = '/prekiu-kategorijos/nauja';
export const ROUTE_PRODUCT_CATEGORY = '/prekiu-kategorija/:id';

export function getProductCategoriesPath(page = null, searchText = null) {
    return generatePath(ROUTE_PRODUCT_CATEGORIES, {page, searchText});
}

export function getProductCategoryPath(id) {
    return generatePath(ROUTE_PRODUCT_CATEGORY, {id});
}

// ------------------- PRODUCT Collections -------------------
export const ROUTE_PRODUCT_COLLECTIONS = '/prekiu-kolekcijos/:page?';
export const ROUTE_PRODUCT_COLLECTIONS_NEW = '/prekiu-kolekcijos/nauja';
export const ROUTE_PRODUCT_COLLECTION = '/prekiu-kolekcija/:id';

export function getProductCollectionsPath(page = null, searchText = null) {
    return generatePath(ROUTE_PRODUCT_COLLECTIONS, {page, searchText});
}

export function getProductCollectionPath(id) {
    return generatePath(ROUTE_PRODUCT_COLLECTION, {id});
}

// ------------------- PRODUCT LOW QUANTITY -------------------
export const ROUTE_PRODUCT_LOW_QUANTITY = '/prekiu-mazas-kiekis/:page?/:selectedWarehousesIds?';

export function getProductLowQuantityPath(page = null, selectedWarehousesIds = null) {
    return generatePath(ROUTE_PRODUCT_LOW_QUANTITY, {page, selectedWarehousesIds});
}

// ------------------- PURCHASE ORDERS -------------------
export const ROUTE_PURCHASE_ORDERS_NEW = '/uzsakymai/naujas';
export const ROUTE_PURCHASE_ORDERS = '/uzsakymai/:page?';
export const ROUTE_PURCHASE_ORDER = '/uzsakymas/:id';

export function getPurchaseOrdersPath(page = null) {
    return generatePath(ROUTE_PURCHASE_ORDERS, {page});
}

export function getPurchaseOrderPath(id) {
    return generatePath(ROUTE_PURCHASE_ORDER, {id});
}


// ------------------- PRODUCT TRANSFERS -------------------
export const ROUTE_PRODUCT_TRANSFERS_NEW = '/prekiu-pervezimai/naujas';
export const ROUTE_PRODUCT_TRANSFERS = '/prekiu-pervezimai/:page?';
export const ROUTE_PRODUCT_TRANSFER = '/prekiu-pervezimas/:id';

export function getProductTransfersPath(page = null) {
    return generatePath(ROUTE_PRODUCT_TRANSFERS, {page});
}

export function getProductTransferPath(id) {
    return generatePath(ROUTE_PRODUCT_TRANSFER, {id});
}



// ------------------- SALE ORDERS -------------------
export const ROUTE_SALE_ORDERS = '/pardavimai';
export const ROUTE_SALE_ORDER = '/pardavimas/:id';
export const ROUTE_SALE_ORDER_EDIT = '/pardavimas/:id/redagavimas';

export function getSaleOrdersPath(page = null) {
    return generatePath(ROUTE_SALE_ORDERS, {page});
}

export function getSaleOrderPath(id) {
    return generatePath(ROUTE_SALE_ORDER, {id});
}

export function getSaleOrderEditPath(id) {
    return generatePath(ROUTE_SALE_ORDER_EDIT, {id});
}

// ------------------- USERS -------------------
export const ROUTE_USERS_NEW = '/klientai/naujas';
export const ROUTE_USERS = '/klientai/:page?/:searchText?';
export const ROUTE_USER = '/klientas/:id';

export function getUsersPath(page = null, searchText = null) {
    return generatePath(ROUTE_USERS, {page, searchText});
}

export function getUserPath(id) {
    return generatePath(ROUTE_USER, {id});
}

export const ROUTE_USER_GROUPS_NEW = '/klientu-grupes/nauja';
export const ROUTE_USER_GROUPS = '/klientu-grupes/:page?';
export const ROUTE_USER_GROUP = '/klientu-grupe/:id';

export function getUserGroupsPath(page = null) {
    return generatePath(ROUTE_USER_GROUPS, {page});
}

export function getUserGroupPath(id) {
    return generatePath(ROUTE_USER_GROUP, {id});
}

export const ROUTE_USER_PERMISSION_GROUPS_NEW = '/klientu-teisiu-grupes/nauja';
export const ROUTE_USER_PERMISSION_GROUPS = '/klientu-teisiu-grupes/:page?';
export const ROUTE_USER_PERMISSION_GROUP = '/klientu-teisiu-grupe/:id';

export function getUserPermissionGroupsPath(page = null) {
    return generatePath(ROUTE_USER_PERMISSION_GROUPS, {page});
}

export function getUserPermissionGroupPath(id) {
    return generatePath(ROUTE_USER_PERMISSION_GROUP, {id});
}

// ------------------- INVOICES -------------------
export const ROUTE_INVOICES = '/saskaitos/:invoiceId?';
export const ROUTE_INVOICE = '/saskaita/:id';
export const ROUTE_PRINT_INVOICE = '/saskaitos-spausdinimas';
export const ROUTE_PRINT_RECEIPT = '/kvitas-spausdinti';

export function getInvoicesPath(page = null) {
    return generatePath(ROUTE_INVOICES);
}

export function getInvoicePath(invoiceId) {
    return generatePath(ROUTE_INVOICES, {invoiceId});
}

export function getPrintInvoicePath() {
    return generatePath(ROUTE_PRINT_INVOICE);
}

export function getPrintReceiptPath() {
    return generatePath(ROUTE_PRINT_RECEIPT);
}

// ------------------- CAROUSELS -------------------
export const ROUTE_CAROUSEL_NEW_SLIDE = '/karusele/nauja-slaide';
export const ROUTE_CAROUSEL_SLIDES = '/karusele/slaides/:page?';
export const ROUTE_CAROUSEL_SLIDE = '/karusele/slaide/:id';

export function getCarouselSlidesPath(page = null) {
    return generatePath(ROUTE_CAROUSEL_SLIDES, {page});
}

export function getCarouselSlidePath(id) {
    return generatePath(ROUTE_CAROUSEL_SLIDE, {id});
}

// ------------------- BLOG -------------------
export const ROUTE_NEWS_NEW = '/naujienos/nauja';
export const ROUTE_NEWS_PAGE = '/naujienos/:page?';
export const ROUTE_NEWS_SINGLE = '/naujiena/:id';

export function getNewsPagePath(page = null) {
    return generatePath(ROUTE_NEWS_PAGE, {page});
}

export function getNewsSinglePath(id) {
    return generatePath(ROUTE_NEWS_SINGLE, {id});
}