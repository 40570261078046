import React, {useState, useEffect} from 'react';
import isArray from 'lodash/isArray';

import { Field } from 'formik';
import * as Yup from "yup";

import RaisedCard from './RaisedCard';
import CustomerSelectField from "../formik/FormikCustomerSelectField";
import formikFieldValidate from "../utils/formikFieldValidate";

const schema = Yup.object().shape({
    sellerId: Yup.string().nullable(),
});

function Step1Seller({setOrderSellerId, sellerId, metadataUserSellers, form, ...props}) {
    useEffect(() => {
        if (
            isArray(metadataUserSellers) && metadataUserSellers.length > 0
            &&
            (
                typeof sellerId === 'undefined' ||
                sellerId === null ||
                sellerId === '' ||
                typeof metadataUserSellers.find((seller) => seller.id === sellerId) === 'undefined'
            )
        ) {
            setOrderSellerId(metadataUserSellers[0].id);
        } else if (!isArray(metadataUserSellers) || metadataUserSellers.length === 0) {
            setOrderSellerId(null);
        }
    }, []);

    console.log('Step1Seller', metadataUserSellers, sellerId);
    if (!isArray(metadataUserSellers) || metadataUserSellers.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <RaisedCard label={"1. Pardavėjas"} {...props}>
                <div style={{marginTop: 16}}>
                    <Field
                        //validate={formikFieldValidate(schema)}
                        name="sellerId"
                        label={"Pardavėjas"}
                        component={CustomerSelectField}
                        customersMetadata={metadataUserSellers}
                        onChange={(sellerId) => {
                            setOrderSellerId(sellerId);

                            //form.change('sellerId', sellerId);
                        }}
                    />
                </div>
            </RaisedCard>
        </React.Fragment>
    );
}

export default React.memo(Step1Seller);