import React, {useEffect, useState, useRef} from 'react';

import {API_PRODUCTS, useGetProducts} from '../api/ProductApi';
import {MaterialReactAsyncSelect} from 'shared-react/formik/MaterialReactSelect';
import {API_URL} from "../api/Api";

function SearchBuyableProductsReactSelect({ onProductChosen, customerId, style = {}, adittionalQueryParams = {}, ...props }) {
    const perPage = 10;
    const page = 1;
    const [searchText, setSearchText] = useState('');
    const reactSelectCallback = useRef(null);
    const queryParams = {
        page,
        perPage,
        q: searchText,
        customerId,
        onlyVariants: 1,
        ...adittionalQueryParams,
    };
    const [products, totalCount, isProductsLoading, productsErrors, fetchProducts] = useGetProducts(API_URL + API_PRODUCTS, queryParams);

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value);

        if (e.target.value.length > 2) {
            fetchProducts();
        }
    };

    const onChooseOption = (product) => {
        onProductChosen(product);
        setSearchText('');
    };

    // const filterColors = (inputValue: string) => {
    //     return colourOptions.filter(i =>
    //         i.label.toLowerCase().includes(inputValue.toLowerCase())
    //     );
    // };

    const loadOptions = (inputValue, callback) => {
        //if (reactSelectCallback.current === null) {
            reactSelectCallback.current = callback;
        //}

        fetchProducts();
        //setSearchText(inputValue);

        //console.log('load options', inputValue, callback);
        // if (inputValue.length > 2) {
        //     fetchProducts();
        // }

        // setTimeout(() => {
        //     callback(filterColors(inputValue));
        // }, 1000);
        //callback([]);
    };

    useEffect(() => {
        if (products !== null && reactSelectCallback.current) {
            const options = products.map((product, productIndex) => {
                return {
                    label: '(' + product.code + ') ' + product.name,
                    value: productIndex,
                    product: product,
                };
            });

            //console.log('set options', options);
            reactSelectCallback.current(options);
        }


        // label: customer.firstName + ' ' + customer.lastName,
        // value: customer.id,
    }, [products]);

    return (
        <div style={style}>
            <MaterialReactAsyncSelect
                loadOptions={loadOptions}
                onInputChange={(inputValue) => {setSearchText(inputValue)}}
                onChange={(option) => {
                    console.log('onChange', products, option);
                    onProductChosen(option.product);
                }}
                label={"Prekės pavadinimas/kodas"}
                {...props}
            />
        </div>
    );
}

export default SearchBuyableProductsReactSelect;