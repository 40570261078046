import React, {useEffect, useState} from 'react';
import { Redirect } from 'react-router-dom';
import {Formik} from 'formik';

import Typography from '@material-ui/core/Typography';

import ProductForm, {ProductSchema} from 'products/ProductForm'
import useProductForm, {
    submitProductForm,
    ProductFormProvider
} from "products/ProductFormContext";
import LinkBack from "utils/LinkBack";

import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import ProgressButton from 'shared-react/utils/ProgressButton';
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {parseProductToFormData} from "../schemas/product";
import {getProductPath, getProductsPath} from 'shared-react/utils/routes';
import {useGetMetadata} from "shared-react/api/MetadataApi";
import {useSaveProduct} from "shared-react/api/ProductApi";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import {useMetadata} from 'shared-react/metadata/MetadataContext';
import {useDispatch} from "react-redux";
import {setSearchText} from "shared-react/ducks/shop";

const metadataToLoadNames = ['categories', 'categoriesTree', 'userGroups', 'warehouses', 'userWarehouses',  'packages', 'variationOptions', 'variationOptionValues']; //, 'salesChannels'

function ProductAddEditPage({product = {variants: [{}]}, onSaveSuccess}) {
    const [productData, setProductData] = useState(null);
    const [savedProduct, isSubmittingProduct, productErrors, saveProduct] = useSaveProduct(productData);
    const {metadataState, metadataDispatch, hasMetadata} = useMetadata(metadataToLoadNames);
    const [productFormState, productFormDispatch] = useProductForm();
    const dispatch = useDispatch();

    useEffect(() => {
        if (savedProduct) {
            const isNew = !(productData !== null && typeof productData === 'object'
                            && 'id' in productData && productData['id'] !== null);
            if (isNew) {
                dispatch(setSearchText(savedProduct.name));
            }

            if (typeof onSaveSuccess === 'function') {
                onSaveSuccess(savedProduct);
            }

            //return (<Redirect push to={getProductPath(savedProduct.id)}/>);
        }
    }, [savedProduct]);

    const metadata = metadataState.metadata;

    if (!hasMetadata(metadataToLoadNames)) {
        return (<DataLoadingIndicator />);
    }

    if (savedProduct) {
        return (<Redirect push to={getProductsPath()}/>);
    }

    //console.log('parse product', product);

    const handleSubmitMyForm = (e) => {
        productFormDispatch(submitProductForm());
    };

    const onSaveForm = (values) => {
        const newValues = {
            ...values,
        };

        setProductData(newValues);
        saveProduct();
    };

    const initialFormData = parseProductToFormData(product, metadata.userWarehouses);

    return (
        <React.Fragment>
            <Formik
                initialValues={initialFormData}
                validationSchema={ProductSchema}
                //enableReinitialize
                onSubmit={(values) => {
                    //setProductData(values);
                    onSaveForm(values);
                }}
            >
                {({
                      errors,
                      isSubmitting,
                  }) => {
                    //console.log('errors?', isSubmitting, Object.keys(errors).length, errors);
                    return (
                        <FillHeightContainer header={
                            <>
                                <div style={{
                                    display: 'flex',
                                    minHeight: 54,
                                    alignItems: 'center',
                                    margin: '0 16px',
                                }}>
                                    <LinkBack to={getProductsPath(null, null)} text={"Atgal"}/>
                                    <Typography component="span" variant="h6">
                                        {typeof product.name === 'undefined' ? "Nauja prekė" : product.name + " redagavimas"}
                                    </Typography>
                                    <span style={{flex: 1}} />
                                    <ProgressButton
                                        loading={isSubmittingProduct}
                                        text={"Išsaugoti"}
                                        onClick={(e) => {
                                            handleSubmitMyForm(e);
                                        }}
                                        color="primary"
                                    />
                                </div>
                                <ResponseErrors errors={productErrors}/>
                                {productFormState.formHasErrors ? (
                                    <ResponseErrors errors={['Ne visi laukai teisingai užpildyti']}/>
                                ) : null}
                            </>
                        }>
                            <div style={{margin: 24}}>
                                <ProductForm
                                    metadata={metadata}
                                    isSubmittingRequest={isSubmittingProduct}
                                />
                            </div>
                        </FillHeightContainer>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}

export default ProductAddEditPageWithProductForm;

function ProductAddEditPageWithProductForm({...props}) {
    return (
        <ProductFormProvider>
            <ProductAddEditPage {...props} />
        </ProductFormProvider>
    );
};

