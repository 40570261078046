import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {StyledListItemText} from './ListItemText';

const ListItemKeyValue = ({title, value, ...props}) => {
    return (
        <ListItem {...props}>
            <StyledListItemText flex={false} gutterRight width={100} primary={title} style={{marginTop: 0, marginBottom: 0}} />
            <ListItemText primary={<b>{value ? value : '--'}</b>} style={{
                marginTop: 0,
                marginBottom: 0,
                wordBreak: 'break-word',
            }} />
        </ListItem>
    );
};

export default ListItemKeyValue;