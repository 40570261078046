import React from "react";
import get from "lodash/get";
import { useField, Field, FieldArray, useFormikContext } from 'formik';

import Typography from "@material-ui/core/Typography";

import {useFormBuilder} from "./FormBuilderContext";
import {
    FormDatePickerField,
    FormMaterialSelectField,
    FormMaterialTextField,
    MaterialRadioGroup
} from "../FormikMaterialFields";
import FormImages from "shared-react/utils/FormImages/FormImages";
import {MaterialDatePickerField} from "../MaterialFields";
import FormError from "shared-react/formik/FormError";
import CKEditorField from "shared-react/formik/CKEditorField";
import {ListIndexNumber, ListItemTextSmallIconButton} from "cms/src/utils/List";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {API_URL} from "../../api/Api";
import {API_PRODUCTS} from "../../api/ProductApi";
import SearchBuyableProductsAutocomplete from "../../shop/SearchBuyableProductsAutocomplete";
import ListProductImageAvatar from "../../shop/ListProductImageAvatar";

const FormBuilder = ({ name, fullWidth = true, ...restProps }) => {
    //const [field, meta, helpers] = useField(name);
    const [formBuilderState, formBuilderDispatch] = useFormBuilder();
    const { values, setFieldValue, touched, errors } = useFormikContext();

    const formProperties = get(formBuilderState.formSchema, 'properties', null);

    const formFieldGetter = name.split('.').join('.properties.');
    //console.log('formFieldGetter', formFieldGetter);
    const fieldSchema = get(formProperties, formFieldGetter, null);

    //console.log('FormBuilder', name, get(values, name), formProperties, fieldSchema, formBuilderState, options, widget);

    if (fieldSchema === null) {
        return null;
    }

    const type = get(fieldSchema, 'type', null);
    const options = get(fieldSchema, 'options.enum_titles', null);
    const widget = get(fieldSchema, 'widget', null);
    const title = get(fieldSchema, 'title', '');
    const label = get(restProps, 'label', title);

    //value label
    const optionsValues = options !== null ? options.map((title, key) => {
        return {
            value: get(fieldSchema, ['enum', key], ''),
            label: title,
        };
    }) : [];

    return (
        <>
            {
                options !== null && widget === 'choice-expanded' ? (
                    <Field
                        name={name}
                        label={label}
                        component={MaterialRadioGroup}
                        options={optionsValues}
                        skipFormikSet={true}
                        {...restProps}
                    />
                ) : options !== null ? (
                    <Field
                        name={name}
                        label={label}
                        component={FormMaterialSelectField}
                        options={optionsValues}
                        fullWidth={fullWidth}
                        nomargin={1}
                        margin="dense"
                        {...restProps}
                    />
                ) : widget === 'password' ? (
                    <Field
                        name={name}
                        label={label}
                        margin="dense"
                        fullWidth={fullWidth}
                        component={FormMaterialTextField}
                        nomargin={1}
                        type={'password'}
                        {...restProps}
                    />
                ) : widget === 'collectionProducts' ? (
                    <>
                        <FieldArray
                            name={name}
                            render={arrayHelpers => {
                                return (
                                    <React.Fragment>
                                        {get(values, name, []).length > 0 ? (
                                            <List dense={true}>
                                                {get(values, name, []).map((productCollection, productCollectionIndex) => (
                                                    <ListItem
                                                        key={productCollectionIndex}
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <ListProductImageAvatar
                                                            productMainImage={productCollection.product.mainImage}
                                                        />
                                                        <ListItemText
                                                            style={{padding: 0}}
                                                            disableTypography
                                                            primary={productCollection.product.name}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        style={{fontSize: 'inherit'}}
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                    >
                                                                        {productCollection.product.code}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <ListItemTextSmallIconButton>
                                                            <IconButton onClick={() => arrayHelpers.remove(productCollectionIndex)}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </ListItemTextSmallIconButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : null}
                                        <div>
                                            <SearchBuyableProductsAutocomplete
                                                style={{
                                                    marginTop: 8,
                                                    marginBottom: 4,
                                                }}
                                                endpoint={API_URL + API_PRODUCTS}
                                                label="Ieškoti prekės"
                                                onProductChosen={(product) => {
                                                    arrayHelpers.push({
                                                        product: product,
                                                    });
                                                }}
                                                onlyVariants={1}
                                            />
                                            {/*<PurchaseOrderAddProduct currentCount={values.orderProducts.length} arrayHelpers={arrayHelpers}/>*/}
                                        </div>
                                    </React.Fragment>
                                )
                            }}
                        />
                    </>
                ) : widget === 'date' ? (
                    <Field
                        name={name}
                        label={label}
                        margin="dense"
                        fullWidth={fullWidth}
                        component={FormDatePickerField}
                        invalidDateMessage={"Neegzistuojanti data"}
                        nomargin={1}
                        clearable
                        {...restProps}
                    />
                ) : widget === 'ckeditor' ? (
                    <>
                        <Typography variant="body2" gutterBottom>
                            {label}
                        </Typography>
                        <CKEditorField
                            name={name}
                            placeholder={label}
                            {...restProps}
                        />
                    </>
                ) : widget === 'image' ? (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {label}
                        </Typography>
                        <FormImages
                            images={get(values, name) ? [get(values, name)] : []}
                            maxFiles={1}
                            onImagesUpdated={(images) => {
                                if (images.length > 0) {
                                    setFieldValue(name, images[0]);
                                } else {
                                    setFieldValue(name, null);
                                }
                            }}
                            {...restProps}
                        />
                        <FormError name={name} />
                    </>
                ) : widget === 'images' ? (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {label}
                        </Typography>
                        <FormImages
                            images={get(values, name) ? get(values, name) : []}
                            onImagesUpdated={(images) => {
                                setFieldValue(name, images);
                            }}
                            {...restProps}
                        />
                        <FormError name={name} />
                    </>
                ) : type === 'string' ? (
                    <Field
                        name={name}
                        label={label}
                        margin="dense"
                        fullWidth={fullWidth}
                        component={FormMaterialTextField}
                        nomargin={1}
                        {...restProps}
                    />
                ) : type === 'integer' ? (
                    <Field
                        name={name}
                        label={label}
                        margin="dense"
                        type="number"
                        fullWidth={fullWidth}
                        component={FormMaterialTextField}
                        nomargin={1}
                        {...restProps}
                    />
                ) : null
            }
        </>
    );
};

export default FormBuilder;