import React, {useEffect, useState, useRef} from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import {MaterialTextField} from '../formik/MaterialFields';
import {useMakeRequestArrayResponse, API_URL} from "shared-react/api/Api";
import useDebouncedCallback from "shared-react/useDebounce/callback";
import {getProductImagePath, TYPE_THUMBNAIL, URL_TYPE_RELATIVE} from "shared-react/utils/images";
import ListProductImageAvatar from "shared-react/shop/ListProductImageAvatar";

function SearchBuyableProductsAutocomplete({
    page = 1,
    perPage = 20,
    onProductChosen,
    endpoint,
    label = 'Prekės pavadinimas/kodas',
    style = {},
    o = 'pop',
    onlyVariants = 1,
    adittionalQueryParams = {},
    textFieldProps = {},
    ...restOfComponentProps
}) {
    const [localSearchText, setLocalSearchText] = useState('');
    const [isGoingToLoadSoon, setIsGoingToLoadSoon] = useState(false);
    const [searchText, setSearchText] = useState('');
    const queryParams = {
        page,
        perPage,
        q: searchText,
        o,
        onlyVariants,
        ...adittionalQueryParams,
    };
    const [products, totalCount, isProductsLoading, productsErrors, fetchProducts] = useMakeRequestArrayResponse('get', endpoint, null, {}, queryParams);
    const [setDebouncedSearchText] = useDebouncedCallback(
        (value) => {
            setSearchText(value);

            if (value.length > 2) {
                setIsGoingToLoadSoon(true);
            }
        },
        500,
        []
    );

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const loading = open && (isProductsLoading || isGoingToLoadSoon);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        if (!isProductsLoading) {
            setIsGoingToLoadSoon(false);
        }
    }, [isProductsLoading]);

    React.useEffect(() => {
        if (searchText.length > 2) {
            fetchProducts();
        } else {
            setOptions([]);
        }
    }, [searchText]);

    React.useEffect(() => {
        if (value) {
            if (typeof onProductChosen === 'function') {
                onProductChosen(value);
            }
            setSearchText('');
            setLocalSearchText('');
        }
    }, [value]);

    useEffect(() => {
        if (products !== null) {
            // const options = products.map((product, productIndex) => {
            //     return {
            //         label: '(' + product.code + ') ' + product.name,
            //         value: productIndex,
            //         product: product,
            //     };
            // });

            setOptions(products);
        }
    }, [products]);

    return (
        <div key={value ? value.id : 0} style={style}>
            {/*<MaterialReactAsyncSelect*/}
                {/*loadOptions={loadOptions}*/}
                {/*onInputChange={(inputValue) => {setSearchText(inputValue)}}*/}
                {/*onChange={(option) => {*/}
                    {/*console.log('onChange', products, option);*/}
                    {/*onProductChosen(option.product);*/}
                {/*}}*/}
                {/*label={"Produkto pavadinimas/kodas"}*/}
                {/*{...props}*/}
            {/*/>*/}

            <Autocomplete
                size={"small"}
                open={open}
                value={null}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                noOptionsText={searchText.length > 2 ? "Prekių pagal šią paiešką rasti nepavyko" : "Įveskite bent 3 simbolius"}
                loadingText={"Ieškoma..."}
                getOptionSelected={(option, value) => option.id === value.id}
                options={options}
                loading={loading}
                renderOption={(product, { selected, inputValue, ...rest }) => {
                    const matches = match(product.name, inputValue);
                    const parts = parse(product.name, matches);

                    const matches2 = match(product.code, inputValue);
                    const parts2 = parse(product.code, matches2);

                    return (
                        <>
                            <ListItem
                                key={product.id}
                                // selected={isHighlighted}
                                // alignItems="flex-start"
                                dense
                                component="div"
                            >
                                <ListProductImageAvatar
                                    productMainImage={product.mainImage}
                                />
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <span style={{
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                display: 'block',
                                            }}>
                                                {parts.map(part => (
                                                    <span key={part.text} style={{
                                                        fontWeight: part.highlight ? 500 : 400,
                                                    }}>
                                                    {part.text}
                                                </span>
                                                ))}
                                            </span>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        onlyVariants ? (
                                            <React.Fragment>
                                                <span style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    display: 'block',
                                                }}>
                                                    {parts2.map(part => (
                                                        <span key={part.text} style={{
                                                            fontWeight: part.highlight ? 500 : 400,
                                                        }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </span>
                                            </React.Fragment>
                                        ) : null
                                    }
                                />
                            </ListItem>
                        </>
                    )
                }}
                onChange={(event, product) => {
                    setValue(product);

                    {/*if (option) {*/}
                        {/*onProductChosen(option.product);*/}
                    {/*}*/}

                    //setOptions([]);

                    //event.preventDefault();
                    //event.stopPropagation();
                    //setSearchText('');
                    //onProductChosen(option.product);
                }}
                getOptionLabel={(product) => {
                    return product.name;
                }}
                onInputChange={(event, value) => {
                    {/*setSearchText(value);*/}

                    {/*if (value.length > 2) {*/}
                        {/*fetchProducts();*/}
                    {/*}*/}
                }}
                filterOptions={(options) => {
                    return options;
                }}
                // popupIcon={<SearchIcon />}
                renderInput={params => (
                    <MaterialTextField
                        {...params}
                        value={localSearchText}
                        onChange={(e) => {
                            setLocalSearchText(e.target.value);
                            setDebouncedSearchText(e.target.value);
                        }}
                        label={label}
                        fullWidth
                        nomargin={1}
                        {...textFieldProps}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {/*<InputAdornment position="end">*/}
                                    {/*    <IconButton edge="end" type="submit">*/}
                                    {/*        <SearchIcon fontSize="small" />*/}
                                    {/*    </IconButton>*/}
                                    {/*</InputAdornment>*/}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
}

export default SearchBuyableProductsAutocomplete;