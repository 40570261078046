import React from "react";
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

const FancyImage = ({src = null, fullWidth = true, ...props}) => {
    const updatedSrc = src == null ? '/img/not-found.png' : src;

    return (
        <React.Fragment>
            <div style={{
                position: 'relative',
                maxHeight: '100%',
                textAlign: 'center',
                ...fullWidth === true && {width: '100%',},
            }} {...props}>
                <>
                    <img src={updatedSrc} alt="" style={{
                        ...fullWidth === true && {width: '100%',},
                        boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
                        borderRadius: 6,
                        position: 'relative',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        zIndex: 1,
                    }} />
                    <div style={{
                        backgroundImage: 'url("' + updatedSrc + '")',
                        top: 12,
                        width: '100%',
                        filter: 'blur(12px)',
                        height: '100%',
                        opacity: 1,
                        position: 'absolute',
                        transform: 'scale(0.94)',
                        transition: 'opacity .45s',
                        backgroundSize: 'cover',
                    }} />
                </>
            </div>
        </React.Fragment>
    )
};

export default FancyImage;