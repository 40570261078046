import React from "react";
import { useField } from 'formik';

import FormHelperText from '@material-ui/core/FormHelperText';

const FormError = ({ name, ...restProps }) => {
    const [field, meta, helpers] = useField(name);

    return (
        <>
            {meta.touched && meta.error &&
                <FormHelperText id={"form-error-" + name} error={true} {...restProps}>
                    {meta.error}
                </FormHelperText>
            }
        </>
    );
};

export default FormError;