import React from 'react';

import Hidden from '@material-ui/core/Hidden';

import StatusChipInvoice from 'shared-react/shop/statusChips/StatusChipInvoice';

function StatusChipInvoiceGroup({order, fixedWidth = 130}) {
    return (
        <>
            <Hidden xsDown>
                <StatusChipInvoice invoice={order.invoice} fixedWidth={fixedWidth}/>
            </Hidden>
            <Hidden smUp>
                <StatusChipInvoice invoice={order.invoice} small={true}/>
            </Hidden>
        </>
    );
}

export default StatusChipInvoiceGroup;