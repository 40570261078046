import React, {useState, useEffect} from 'react';
import {Link, Route, Switch, Redirect} from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ImageIcon from '@material-ui/icons/Image';
import Avatar from "@material-ui/core/Avatar";
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';

import {useGetPurchaseOrder} from 'shared-react/api/PurchaseOrderApi';
import PurchaseOrderForm from "purchaseOrders/PurchaseOrderForm";
import LinkBack from "utils/LinkBack";
import PageTitle from "utils/PageTitle";
import {ROUTE_PURCHASE_ORDERS, ROUTE_PURCHASE_ORDER, getPurchaseOrdersPath, getPurchaseOrderPath} from "shared-react/utils/routes";
import LinkWrapper from "../utils/LinkWrapper";
import {ListIndexNumber, ListItemPriceText, ListItemAmountText, ListItemSymbolText} from "../utils/List";
import Currency from 'shared-react/utils/Currency';
import PurchaseOrderFormInputs from "./PurchaseOrderFormInputs";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

function PurchaseOrderPage({ match }) {
    const theme = useTheme();
    const purchaseOrderId = match.params.id;
    const [purchaseOrder, setPurchaseOrder, isPurchaseOrderLoading, purchaseOrderErrors, fetchPurchaseOrder] = useGetPurchaseOrder(purchaseOrderId);

    useEffect(() => {
        fetchPurchaseOrder();
    }, []);

    if (!purchaseOrder) {
        return (<DataLoadingIndicator />);
    }

    return (
        <div>
            <Route path={`${match.path}`} exact render={() => (
                <React.Fragment>
                    <FillHeightContainer header={
                        <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: 54,
                            padding: '0 16px',
                        }}>
                            <LinkBack to={getPurchaseOrdersPath()} text={"Atgal"}/>
                            <PageTitle title={purchaseOrder.title}/>
                            <span style={{flex: 1}}/>
                            <Button to={`${match.url}/redagavimas`} variant="outlined" color="primary" component={LinkWrapper}>
                                Redaguoti
                            </Button>
                        </div>
                        </>
                    }>
                        <Paper elevation={0} square>
                            <List dense={true}>
                                <ListSubheader component="div" style={{display: 'flex'}} disableSticky>
                                    {purchaseOrder.warehouse.name}
                                    <span style={{flex: 1}}></span>
                                    {purchaseOrder.receivedAt}
                                </ListSubheader>
                                {purchaseOrder.orderProducts.map((orderProduct, productIndex) => (
                                    <ListItem key={"pp"+productIndex}>
                                        <ListIndexNumber paddingRight theme={theme}>
                                            {productIndex+1}
                                        </ListIndexNumber>
                                        <Hidden xsDown>
                                            <ListItemAvatar  style={{marginRight: 16}}>
                                                <Avatar>
                                                    <ImageIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                        </Hidden>
                                        <ListItemText
                                            style={{padding: 0}}
                                            disableTypography
                                            primary={orderProduct.product.name}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        style={{fontSize: 'inherit'}}
                                                        variant="body2"
                                                        color="textSecondary"
                                                    >
                                                        {orderProduct.product.code}
                                                    </Typography>
                                                    <Hidden smUp>
                                                        <div style={{display: 'flex'}}>
                                                            <div style={{flex: 1}}></div>
                                                            <ListItemAmountText>
                                                                {orderProduct.amount}
                                                            </ListItemAmountText>
                                                            <ListItemPriceText theme={theme} marginLeft>
                                                                <Currency value={orderProduct.price} />
                                                            </ListItemPriceText>
                                                            <ListItemPriceText theme={theme} marginLeft>
                                                                <Currency value={orderProduct.total} />
                                                            </ListItemPriceText>
                                                        </div>
                                                    </Hidden>
                                                </React.Fragment>
                                            }
                                        />
                                        <Hidden xsDown>
                                            <ListItemAmountText>
                                                {orderProduct.amount}
                                            </ListItemAmountText>
                                            <ListItemPriceText theme={theme} marginLeft>
                                                <Currency value={orderProduct.price} />
                                            </ListItemPriceText>
                                            <ListItemPriceText theme={theme} marginLeft>
                                                <Currency value={orderProduct.total} />
                                            </ListItemPriceText>
                                        </Hidden>
                                    </ListItem>
                                ))}
                                <ListItem>
                                    <ListItemText
                                        primary={""}
                                    />
                                    <ListItemPriceText>
                                        <Currency value={purchaseOrder.total} />
                                    </ListItemPriceText>
                                </ListItem>
                            </List>
                        </Paper>
                    </FillHeightContainer>
                </React.Fragment>
            )} />
            <Route path={`${match.path}/redagavimas`} render={() => (
                <React.Fragment>
                    <FillHeightContainer header={
                        <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: 54,
                            padding: '0 16px',
                        }}>
                            <LinkBack to={`${match.url}`} text={"Atgal"}/>
                            <div style={{display: 'flex', marginBottom: 8}}>
                                <PageTitle title={purchaseOrder.title + " redagavimas"}/>
                            </div>
                        </div>
                        </>
                    }>
                        <div style={{padding: 12}}>
                            <PurchaseOrderForm
                                purchaseOrder={purchaseOrder}
                                onSaveSuccess={setPurchaseOrder}
                            />
                        </div>
                    </FillHeightContainer>
                </React.Fragment>
            )} />
        </div>
    );
}

export default PurchaseOrderPage;