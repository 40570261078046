import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray, ErrorMessage, FastField, useFormikContext} from 'formik';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {FormMaterialSelectField, FormMaterialTextField, FormDatePickerField} from "shared-react/formik/FormikMaterialFields";
import Typography from "@material-ui/core/Typography";
import FormImages from "shared-react/utils/FormImages/FormImages";
import FormBuilder from "shared-react/formik/FormBuilder/FormBuilder";

export const carouselSlideSchema = Yup.object().shape({
    title: Yup.string()
        .required('Šis laukas negali būti tuščias'),
});

function CarouselSlideForm() {
    const { values, setFieldValue } = useFormikContext();
    //console.log('values', values);
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder name={'title'} />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder name={'publishedAt'} />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder
                            name={'imageLandscape'}
                            imageType={'carouselSlideLandscape'}
                            allowedAspectRatios={['carouselLandscape']}
                            forceCropping={true}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder
                            name={'imagePortrait'}
                            imageType={'carouselSlidePortrait'}
                            allowedAspectRatios={['carouselPortrait']}
                            forceCropping={true}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper style={{padding: 16}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormBuilder name={'primaryText'} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormBuilder name={'secondaryText'} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormBuilder name={'buttonText'} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormBuilder name={'buttonLink'} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default CarouselSlideForm;