import React, {useEffect, useState} from 'react';
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListIemKeyValue from "shared-react/utils/ListIemKeyValue";
import {WEB_SERVER_URL} from "shared-react/api/Api";
import DeleteOmnivaParcel from "./DeleteOmnivaParcel";

function OmnivaLabels({ fulfillment, fetchOrder }) {
    return (
        <React.Fragment>
            {fulfillment.omnivaParcels.length > 0 ? (
                <>
                    <List
                        dense
                        style={{
                            paddingTop: 20,
                            paddingBottom: 4,
                        }}
                    >
                        <ListSubheader
                            component="div"
                            disableSticky
                            style={{
                                lineHeight: '12px',
                            }}
                        >
                            Omniva lipdukai
                        </ListSubheader>
                    </List>
                    {fulfillment.omnivaParcels.map((omnivaParcel, index) => (
                        <List key={index} dense style={{
                            padding: 0,
                            paddingBottom: 20,
                        }}>
                            <ListIemKeyValue dense title={"Gavėjas"} value={<div style={{display: 'flex'}}>
                                {omnivaParcel.receiverName}
                                <span style={{flex: 1}}></span>
                                <DeleteOmnivaParcel
                                    omnivaParcel={omnivaParcel}
                                    onDeleted={() => {
                                        fetchOrder();
                                    }}
                                />
                            </div>} />
                            <ListIemKeyValue dense title={"Tel. numeris"} value={omnivaParcel.receiverPhone} />
                            <ListIemKeyValue dense title={"El. paštas"} value={omnivaParcel.receiverEmail} />
                            <ListIemKeyValue dense title={"Šalis"} value={omnivaParcel.receiverCountryCode} />
                            <ListIemKeyValue dense title={"Paštomatas"} value={omnivaParcel.receiverPickupPointName} />
                            <ListIemKeyValue dense title={"Lipdukas"} value={
                                <>
                                    <a target="_blank" href={WEB_SERVER_URL + '/pdfs/omniva/' + omnivaParcel.pdfFileName}>PDF</a>
                                </>
                            } />
                        </List>
                    ))}
                </>
            ) : null}
        </React.Fragment>
    );
}

export default OmnivaLabels;