import React from 'react';
import { __RouterContext as RouterContext } from "react-router";

import LinkRoute from "./LinkRoute";
import LinkText from "./LinkText";

function LinkDynamic({routerTo, linkHref, ...props}) {
    return (
        <RouterContext.Consumer>
            {context => {
                if (typeof context === 'undefined') {
                    return (
                        <LinkText href={linkHref} {...props} />
                    );
                } else {
                    return (
                        <LinkRoute to={routerTo} {...props} />
                    );
                }
            }}
        </RouterContext.Consumer>
    );
}
export default LinkDynamic;