import React, {useEffect, useState} from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import {Link, Redirect} from 'react-router-dom'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';

import {useGetMetadata} from "shared-react/api/MetadataApi";
import {useSaveUserPermissionGroup} from "shared-react/api/UserPermissionGroupApi";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {parseUserPermissionGroupToFormData} from "schemas/userPermissionGroup";
import ProgressButton from "shared-react/utils/ProgressButton";
import {getSaleOrderPath, getUserPermissionGroupPath, getUserPermissionGroupsPath} from 'shared-react/utils/routes';
import {FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";
import PageTitle from "../utils/PageTitle";

// function Checkbox(props) {
//     return (
//         <Field name={props.name}>
//             {({ field, form }) => (
//                 <label>
//                     <input
//                         type="checkbox"
//                         {...props}
//                         checked={field.value.includes(props.value)}
//                         onChange={() => {
//                             if (field.value.includes(props.value)) {
//                                 const nextValue = field.value.filter(
//                                     value => value !== props.value
//                                 );
//                                 form.setFieldValue(props.name, nextValue);
//                             } else {
//                                 const nextValue = field.value.concat(props.value);
//                                 form.setFieldValue(props.name, nextValue);
//                             }
//                         }}
//                     />
//                     {props.text}
//                 </label>
//             )}
//         </Field>
//     );
// }

function UserPermissionGroupForm({userPermissionGroup, onSavePermissionGroup}) {
    const initialFormData = parseUserPermissionGroupToFormData(userPermissionGroup);
    const [userPermissionGroupData, setUserPermissionGroupData] = useState(initialFormData);
    const [savedUserPermissionGroup, isSubmitting, userPermissionGroupErrors, saveUserPermissionGroup] = useSaveUserPermissionGroup(userPermissionGroupData);
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(
        ['permissionTypes']
    );

    useEffect(() => {
        fetchMetadata();
    }, []);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    if (savedUserPermissionGroup) {
        onSavePermissionGroup(savedUserPermissionGroup);
        return (<Redirect push to={getUserPermissionGroupPath(savedUserPermissionGroup.id)}/>);
    }

    return (
        <Formik
            initialValues={initialFormData}
            onSubmit={(values) => {
                setUserPermissionGroupData(values);
                saveUserPermissionGroup(values);
            }}
        >
            {({values, handleSubmit}) => {
                return (
                    <Form>
                        <Paper style={{padding: 16}}>
                            <Field
                                name="title"
                                label="Pavadinimas"
                                margin="dense"
                                fullWidth
                                component={FormMaterialTextField}
                            />
                            <FieldArray
                                name="permissions"
                                render={arrayHelpers => (
                                    <div>
                                        {metadata.permissionTypes.map(permissionType => (
                                            <div key={'permissionType' + permissionType.id}>
                                                {permissionType.name}
                                                <FormGroup row>
                                                    {permissionType.permissions.map(permission => (
                                                        <FormControlLabel
                                                            key={'permission' + permission.id}
                                                            control={
                                                                <Checkbox
                                                                    checked={values.permissions.includes(permission.id)}
                                                                    onChange={e => {
                                                                        if (e.target.checked) arrayHelpers.push(permission.id);
                                                                        else {
                                                                            const idx = values.permissions.indexOf(permission.id);
                                                                            arrayHelpers.remove(idx);
                                                                        }
                                                                    }}
                                                                    value={String(permission.id)}
                                                                />
                                                            }
                                                            label={permission.name}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            />
                            <div style={{display: 'flex'}}>
                                <span style={{flex: 1}} />
                                <ProgressButton
                                    loading={isSubmitting}
                                    text={"Išsaugoti"}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </Paper>
                    </Form>
                )
            }}
        </Formik>
    );
}

export default UserPermissionGroupForm;