import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";

import {getCurrencyString} from 'shared-react/utils/Currency';

const columns = [
    {
        name: "key",
        label: "Eil. nr.",
        options: {
            filter: false,
            sort: false,
            align: 'right',
        }
    },
    {
        name: "name",
        label: "Pavadinimas",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "amount",
        label: "Kiekis",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "cost",
        label: "Kaina su PVM",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "total",
        label: "Suma su PVM",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "costWithoutTax",
        label: "Kaina be PVM",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "totalWithoutTax",
        label: "Suma be PVM",
        options: {
            filter: false,
            sort: false,
        }
    },
];

function InvoiceDialog({invoice}) {
     if (!invoice) {
        return null;
    }

    const orderProductsData = invoice.orderProducts.map((orderProduct, key) => [
        key+1, orderProduct.name, orderProduct.amount, getCurrencyString(orderProduct.cost),
        getCurrencyString(orderProduct.total), getCurrencyString(orderProduct.costWithoutTax),
        getCurrencyString(orderProduct.totalWithoutTax)
    ]);

    return (
        <React.Fragment>
            <MUIDataTable
                title={false}
                data={orderProductsData}
                columns={columns}
                options={{
                    pagination: false,
                    selectableRows: 'none',
                    disableToolbarSelect: true,
                    sort: false,
                    filter: false,
                    print: false,
                    download: false,
                    search: false,
                    viewColumns: false,
                    elevation: 0,
                    rowHover: false,
                }}
            />
        </React.Fragment>
    );
}

export default InvoiceDialog;