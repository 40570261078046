import React, {useState, useEffect} from 'react';
import {Link, Route, Switch, Redirect} from 'react-router-dom'

import './custom.css';

import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ImageIcon from '@material-ui/icons/Image';
import Avatar from "@material-ui/core/Avatar";
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';

import {useGetProductTransfer} from 'shared-react/api/ProductTransferApi';
import ProductTransferForm from "productTransfers/ProductTransferForm";
import LinkBack from "utils/LinkBack";
import PageTitle from "utils/PageTitle";
import {ROUTE_PURCHASE_ORDERS, ROUTE_PURCHASE_ORDER, getProductTransfersPath, getProductTransferPath} from "shared-react/utils/routes";
import LinkWrapper from "../utils/LinkWrapper";
import {ListIndexNumber, ListItemPriceText, ListItemAmountText, ListItemSymbolText} from "../utils/List";
import Currency from 'shared-react/utils/Currency';
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import IconButton from "@material-ui/core/IconButton";

function ProductTransferPage({ match }) {
    const theme = useTheme();
    const productTransferId = match.params.id;
    const [productTransfer, setProductTransfer, isProductTransferLoading, productTransferErrors, fetchProductTransfer] = useGetProductTransfer(productTransferId);

    const onPrint = () => {
        window.print();
    };

    useEffect(() => {
        fetchProductTransfer();
    }, []);

    if (!productTransfer) {
        return (<DataLoadingIndicator />);
    }

    return (
        <div>
            <Route path={`${match.path}`} exact render={() => (
                <React.Fragment>
                    <FillHeightContainer header={
                        <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: 54,
                            padding: '0 16px',
                        }}>
                            <LinkBack to={getProductTransfersPath()} text={"Atgal"}/>
                            {/*<PageTitle title={productTransfer.id}/>*/}
                            <span style={{flex: 1}}/>
                            <IconButton onClick={onPrint} style={{ marginRight: 5 }}>
                                <PrintIcon />
                            </IconButton>
                            <Button to={`${match.url}/redagavimas`} variant="outlined" color="primary" component={LinkWrapper}>
                                Redaguoti
                            </Button>
                        </div>
                        </>
                    }>
                        <Paper elevation={0} square id="section-to-print">
                            <List dense={true}>
                                <ListSubheader component="div" style={{display: 'flex'}} disableSticky>
                                    {productTransfer.warehouseFrom.name} -> {productTransfer.warehouseTo.name}
                                    <span style={{flex: 1}}></span>
                                    {productTransfer.day} {productTransfer.time}
                                </ListSubheader>
                                {productTransfer.products.map((transferProduct, productIndex) => (
                                    <ListItem key={"pp"+productIndex}>
                                        <ListIndexNumber paddingRight theme={theme}>
                                            {productIndex+1}
                                        </ListIndexNumber>
                                        <Hidden xsDown>
                                            <ListItemAvatar style={{marginRight: 16}} id="non-printable">
                                                <Avatar>
                                                    <ImageIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                        </Hidden>
                                        <ListItemText
                                            style={{padding: 0}}
                                            disableTypography
                                            primary={transferProduct.product.name}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        style={{fontSize: 'inherit'}}
                                                        variant="body2"
                                                        color="textSecondary"
                                                    >
                                                        {transferProduct.product.code}
                                                    </Typography>
                                                    <Hidden smUp>
                                                        <div style={{display: 'flex'}}>
                                                            <div style={{flex: 1}}></div>
                                                            <ListItemAmountText>
                                                                {transferProduct.amount}
                                                            </ListItemAmountText>
                                                        </div>
                                                    </Hidden>
                                                </React.Fragment>
                                            }
                                        />
                                        <Hidden xsDown>
                                            <ListItemAmountText>
                                                {transferProduct.amount}
                                            </ListItemAmountText>
                                        </Hidden>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </FillHeightContainer>
                </React.Fragment>
            )} />
            <Route path={`${match.path}/redagavimas`} render={() => (
                <React.Fragment>
                    <FillHeightContainer header={
                        <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: 54,
                            padding: '0 16px',
                        }}>
                            <LinkBack to={`${match.url}`} text={"Atgal"}/>
                            <div style={{display: 'flex'}}>
                                <PageTitle title={"Redagavimas"}/>
                            </div>
                        </div>
                        </>
                    }>
                        <div style={{padding: 12}}>
                            <ProductTransferForm
                                productTransfer={productTransfer}
                                onSaveSuccess={setProductTransfer}
                            />
                        </div>
                    </FillHeightContainer>
                </React.Fragment>
            )} />
        </div>
    );
}

export default ProductTransferPage;