import React, {useEffect} from 'react';

import UserForm, {customerSchema} from "shared-react/user/UserForm";
import PageTitle from "utils/PageTitle";
import LinkBack from "utils/LinkBack";
import {getUserPath, getUsersPath} from 'shared-react/utils/routes';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import useFormikForm from "shared-react/formik/useFormikForm";
import {getUserMethod, getUserUrl} from "shared-react/api/UserApi";
import {parseUserToFormData} from "shared-react/schemas/user";

function NewUserPage({history}) {
    const initialFormData = parseUserToFormData({});
    const [savedUser, UserFormikWrapper, userFormProps, UserFormErrors, userFormErrorsProps, UserFormSubmitButton, userFormSubmitButtonProps] = useFormikForm({
        method: getUserMethod(initialFormData),
        url: getUserUrl(initialFormData),
        formData: initialFormData,
        validationSchema: customerSchema,
        submitButtonText: 'Išsaugoti',
    });

    useEffect(() => {
        if (savedUser) {
            history.push(getUsersPath());
        }
    }, [savedUser]);

    return (
        <React.Fragment>
            <UserFormikWrapper {...userFormProps}>
                <FillHeightContainer header={
                    <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: 54,
                            padding: '0 16px',
                        }}>
                            <LinkBack to={getUsersPath()} text={"Atgal"}/>
                            <PageTitle title={"Naujas klientas"} gutterBottom/>
                            <span style={{flex: 1}} />
                            <UserFormSubmitButton {...userFormSubmitButtonProps}/>
                        </div>
                        <UserFormErrors {...userFormErrorsProps}/>
                    </>
                }>
                    <div style={{margin: 24}}>
                        <UserForm
                            wrapIntoPaper={true}
                        />
                    </div>
                </FillHeightContainer>
            </UserFormikWrapper>
        </React.Fragment>
    );
}

export default NewUserPage;