import { useState, useEffect } from "react";
import useDidMountEffect from "shared-react/utils/useDidMountEffect";

const useScrollToElement = () => {
    const [selector, setSelector] = useState(null);
    const [block, setBlock] = useState('center');

    const scrollToElement = (id, block = 'center') => {
        setBlock(block);
        setSelector(id);
    };

    useDidMountEffect(() => {
        if (selector) {
            let element = document.querySelector(selector);

            if (!element) {
                element = document.getElementById(selector);
            }
// console.log('element', element);
            if (element) {

                const fillHeightContainerElement = document.getElementById('fill-height-container');

                if (fillHeightContainerElement) {
                    scrollParentToChild(fillHeightContainerElement, element);
                } else {
                    element.scrollIntoView({behavior: "smooth", block: block});
                }
            }

            setSelector(null);
        }
    }, [selector]);

    return scrollToElement;
};

export function scrollParentToChild(parent, child) {
    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);

    // if you can't see the child try to scroll parent
    if (!isViewable) {
        // scroll by offset relative to parent
        parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
    }
}

export default useScrollToElement;