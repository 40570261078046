import React from "react";

import CustomerSelect from '../user/CustomerSelect';

function FormikCustomerSelectField({ field, form: { touched, errors }, ...props }) {
    return (
        <CustomerSelect
            helperText={touched[field.name] ? errors[field.name] : ""}
            error={touched[field.name] && Boolean(errors[field.name])}
            {...field}
            {...props}
            customerId={field.value}
        />
    );
}

export default FormikCustomerSelectField;