import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom'

import {saveUserGroup} from 'shared-react/api/UserGroupApi';
import UserGroupForm from 'userGroups/UserGroupForm'

function NewUserGroupPage() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userGroup, setUserGroup] = useState(null);

    const onSaveUserGroup = (values) => {
        setIsSubmitting(true);

        saveUserGroup(values)
            .then(function (userGroup) {
                setUserGroup(userGroup);
                setIsSubmitting(false);
            })
            .catch(function (error) {
                // handle error

                setIsSubmitting(false);
            });
    };

    if (userGroup) {
        return <Redirect to={"/klientu-grupes/" + userGroup.id}/>;
    }

    return (
        <div>
            <Link to={"/klientu-grupes"}>Atgal</Link> <br/>
            <br/>
            <UserGroupForm
                initialValues={{
                    title: '',
                }}
                onSaveUserGroup={onSaveUserGroup}
                isSubmitting={isSubmitting}
            />
        </div>
    );
}

export default NewUserGroupPage;