import React, {useState} from 'react';

import { Field, FastField } from 'formik';
import isArray from 'lodash/isArray';
import * as Yup from "yup";

import RaisedCard from './RaisedCard';
import CustomerSelectField from "../formik/FormikCustomerSelectField";
import {
    FormMaterialSelectField,
    FormMaterialTextField,
    MaterialRadioGroup,
    MaterialRadioGroupButton
} from "../formik/FormikMaterialFields";
import {initialState} from "../ducks/order";

function Step2Seller({cmsForm, setOrderDataField, metadataCustomers, formValues, ...props}) {
    //console.log('Step2Seller formValues', formValues);

    return (
        <React.Fragment>
            <RaisedCard label={<>{cmsForm ? '2' : '1'}. Pirkėjas</>} {...props}>
                <div style={{marginTop: 8}}>
                    <React.Fragment>
                        {cmsForm ? (
                            <Field
                                //validate={formikFieldValidate(schema)}
                                name="customerId"
                                label={"Pirkėjas"}
                                creatable
                                component={CustomerSelectField}
                                customersMetadata={metadataCustomers}
                                onChange={(customerId, customer) => {
                                    if (customer !== null) {
                                        setOrderDataField('customerIsCompany', customer.isCompany != null ? customer.isCompany : initialState.orderData.customerIsCompany, false);
                                        setOrderDataField('customerFirstName', customer.firstName != null ? customer.firstName : initialState.orderData.customerFirstName, false);
                                        setOrderDataField('customerLastName', customer.lastName != null ? customer.lastName : initialState.orderData.customerLastName, false);
                                        setOrderDataField('customerEmail', customer.username != null ? customer.username : initialState.orderData.customerEmail, false);
                                        setOrderDataField('customerPhoneNumberCountryCode', customer.phoneNumberCountryCode != null ? customer.phoneNumberCountryCode : initialState.orderData.customerPhoneNumberCountryCode, false);
                                        setOrderDataField('customerPhoneNumber', customer.phoneNumber != null ? customer.phoneNumber : initialState.orderData.customerPhoneNumber, false);
                                        setOrderDataField('customerAddress', customer.address != null ? customer.address : initialState.orderData.customerAddress, false);
                                        setOrderDataField('customerPvmCode', customer.pvmCode != null ? customer.pvmCode : initialState.orderData.customerPvmCode, false);
                                        setOrderDataField('customerCode', customer.code != null ? customer.code : initialState.orderData.customerCode, false);
                                    }

                                    setOrderDataField('customerId', customerId, true);
                                    //form.change('customerId', customerId);
                                }}
                                showAdditionalInfo={cmsForm && formValues.id === null}
                                showEditButton={cmsForm}
                                showAddNew={true}
                            />
                        ) : null}
                    </React.Fragment>
                    {!cmsForm || formValues.id !== null ? (
                        <React.Fragment>
                            <Field
                                name="customerIsCompany"
                                component={MaterialRadioGroupButton}
                                onChange={(e, value) => {
                                    //console.log('form change to ', e.target.value);
                                    //form.change('customerIsCompany', e.target.value);
                                    setOrderDataField('customerIsCompany', value === '1' ? true : false);
                                }}
                                options={[
                                    {
                                        value: '0',
                                        label: 'Fizinis asmuo',
                                    },
                                    {
                                        value: '1',
                                        label: 'Juridinis asmuo',
                                    },
                                ]}
                                value={formValues.customerIsCompany === true ? '1' : '0'}
                            />
                            {formValues.customerIsCompany === false ? (
                                <React.Fragment>
                                    <FastField
                                        key="customerFirstName_1"
                                        name="customerFirstName"
                                        label={'Vardas'}
                                        margin="dense"
                                        fullWidth
                                        component={FormMaterialTextField}
                                        onChange={(e) => {
                                            //form.change('customerFirstName',  e.target.value);
                                            setOrderDataField('customerFirstName', e.target.value);
                                            console.log('change');
                                        }}
                                        skipFormikSet
                                    />
                                    <FastField
                                        key="customerLastName_1"
                                        name="customerLastName"
                                        label={'Pavardė'}
                                        margin="dense"
                                        fullWidth
                                        component={FormMaterialTextField}
                                        onChange={(e) => setOrderDataField('customerLastName', e.target.value)}
                                        skipFormikSet
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <FastField
                                        key="customerFirstName_2"
                                        name="customerFirstName"
                                        label={'Pavadinimas'}
                                        margin="dense"
                                        fullWidth
                                        component={FormMaterialTextField}
                                        onChange={(e) => setOrderDataField('customerFirstName', e.target.value)}
                                        skipFormikSet
                                    />
                                    <FastField
                                        key="code_1"
                                        name="customerCode"
                                        label={'Įmonės kodas'}
                                        margin="dense"
                                        fullWidth
                                        component={FormMaterialTextField}
                                        onChange={(e) => setOrderDataField('customerCode', e.target.value)}
                                        skipFormikSet
                                    />
                                    <FastField
                                        name="customerPvmCode"
                                        label={'PVM mokėtojo kodas'}
                                        margin="dense"
                                        fullWidth
                                        component={FormMaterialTextField}
                                        onChange={(e) => setOrderDataField('customerPvmCode', e.target.value)}
                                        skipFormikSet
                                    />
                                </React.Fragment>
                            )}
                            <FastField
                                label="El. pašto adresas"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="customerEmail"
                                component={FormMaterialTextField}
                                onChange={(e) => setOrderDataField('customerEmail', e.target.value)}
                                skipFormikSet
                            />
                            <div style={{display: 'flex'}}>
                                <FastField
                                    name="customerPhoneNumberCountryCode"
                                    label={''}
                                    margin="dense"
                                    options={[
                                        {
                                            value: '+370',
                                            label: '+370',
                                        },
                                        {
                                            value: '+371',
                                            label: '+371',
                                        },
                                    ]}
                                    fullWidth={false}
                                    component={FormMaterialSelectField}
                                    onChange={(e) => setOrderDataField('customerPhoneNumberCountryCode', e.target.value, false)}
                                    displayEmpty={false}
                                />
                                <FastField
                                    name="customerPhoneNumber"
                                    label={'Telefono numeris'}
                                    margin="dense"
                                    fullWidth={false}
                                    type="number"
                                    component={FormMaterialTextField}
                                    onChange={(e) => setOrderDataField('customerPhoneNumber', e.target.value, false)}
                                    skipFormikSet
                                    style={{
                                        marginLeft: 8,
                                        flex: 1,
                                    }}
                                />
                            </div>
                            <FastField
                                label="Adresas"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="customerAddress"
                                component={FormMaterialTextField}
                                onChange={(e) => setOrderDataField('customerAddress', e.target.value)}
                                skipFormikSet
                            />
                            {/*<Field*/}
                            {/*label="Telefono numeris (neprivalomas laukas)"*/}
                            {/*margin="dense"*/}
                            {/*variant="outlined"*/}
                            {/*fullWidth*/}
                            {/*name="phone"*/}
                            {/*component={FormFormMaterialTextField}*/}
                            {/*/>*/}
                        </React.Fragment>
                    ) : null}
                </div>
            </RaisedCard>
        </React.Fragment>
    );
}

export default React.memo(Step2Seller);