import React, {useState} from 'react';
import {connect} from "react-redux";
import {API_CALCULATE_ORDER} from "shared-react/api/OrderApi";

// import SearchBuyableProductsReactSelect from 'shared-react/shop/SearchBuyableProductsReactSelect';
import SearchBuyableProductsAutocomplete from 'shared-react/shop/SearchBuyableProductsAutocomplete';
import {NAME_EDIT_ORDER, addOrderProduct} from "shared-react/ducks/order";
import {API_PRODUCTS} from "shared-react/api/ProductApi";
import {API_URL} from 'shared-react/api/Api';

function AddProductsToOrder({warehouseId, addOrderProduct}) {
    const onProductChosen = (product) => {
        console.log('onProductChosen', product);
        addOrderProduct(product.id, warehouseId, 1);
    };

    return (
        <div>
            <SearchBuyableProductsAutocomplete
                endpoint={API_URL + API_PRODUCTS}
                onProductChosen={onProductChosen}
                onlyVariants={1}
                adittionalQueryParams={{
                    onlyVariants: 1,
                }}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    warehouseId: state.shop.warehouseId,
});

const mapDispatchToProps = (dispatch) => ({
    addOrderProduct: (productId, warehouseId, quantity) => {
        dispatch(addOrderProduct(NAME_EDIT_ORDER, API_CALCULATE_ORDER, productId, warehouseId, quantity));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddProductsToOrder);