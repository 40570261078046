import qs from "qs";
import axios from "axios";
import isEmpty from "lodash/isEmpty";

import {API_URL, getAuthTokenHeaders, useMakeRequest} from './Api';

const API_USER_PERMISSION_GROUPS = '/user-permission-groups';

export function useGetUserPermissionGroups(queryParams) {
    const queryString = !isEmpty(queryParams) ? '?' + qs.stringify(queryParams) : '';
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_USER_PERMISSION_GROUPS + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetUserPermissionGroup(userPermissionGroupId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_USER_PERMISSION_GROUPS + '/' + userPermissionGroupId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveUserPermissionGroup(userPermissionGroupData) {
    const isNew = !(userPermissionGroupData !== null && typeof userPermissionGroupData === 'object'
        && 'id' in userPermissionGroupData && userPermissionGroupData['id'] !== null);
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + userPermissionGroupData.id;
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_USER_PERMISSION_GROUPS + url, userPermissionGroupData);

    return [ data, isLoading, errors, doRequest ];
}


export function getPermissionGroups(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);
    const authTokenHeaders = getAuthTokenHeaders();

    return axios.get(
        API_URL + '/permission-groups' + queryString,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function getPermissionGroup(permissionGroupId) {
    const authTokenHeaders = getAuthTokenHeaders();
    return axios.get(
        API_URL + '/permission-groups/' + permissionGroupId,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function getAllPermissions() {
    const authTokenHeaders = getAuthTokenHeaders();
    return axios.get(
        API_URL + '/permissions',
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function getPermissionGroupAndAllPermissions(permissionGroupId) {
    return axios.all([getPermissionGroup(permissionGroupId), getAllPermissions()])
        .then(axios.spread(function (permissionGroup, permissions) {
            return [permissionGroup, permissions];
        }));
}

export function savePermissionGroup(groupPermission) {
    const authTokenHeaders = getAuthTokenHeaders();
    if ("id" in groupPermission) {
        return axios.put(API_URL + '/permission-groups/' + groupPermission.id, {
            ...groupPermission
        }, {
            headers: {...authTokenHeaders}
        }).then((response) => {
            return response.data;
        });
    }
    //todo[as]: creating new one
}