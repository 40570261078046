import React, {useState, useEffect} from 'react';

import {useGetPackages} from "shared-react/api/PackageApi";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import TextField from '@material-ui/core/TextField';

import LinkWrapper from "utils/LinkWrapper";
import {MaterialTextField} from "shared-react/formik/MaterialFields"
import ListTablePagination from 'utils/ListTablePagination';
import {
    ROUTE_PACKAGES_NEW,
    getPackagesPath,
    getPackagePath,
    getPurchaseOrdersPath,
    ROUTE_PURCHASE_ORDERS_NEW
} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import LinkBack from "../utils/LinkBack";
import PageTitle from "../utils/PageTitle";
import Button from "@material-ui/core/Button";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import useDebouncedCallback from "shared-react/useDebounce/callback";

function PackagesPage({match, history}) {
    const searchText = typeof match.params.searchText === 'undefined' ? '' : match.params.searchText;
    const [localSearchText, setLocalSearchText] = useState(searchText);
    const perPage = 30;
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const queryParams = {
        'per_page': perPage,
        'page': page,
        'q': searchText,
    };
    const [packages, totalCount, arePackagesLoading, packagesErrors, fetchPackages] = useGetPackages(queryParams);

    useEffect(() => {
        fetchPackages();
    }, [page, searchText]);

    useEffect(() => {
        setLocalSearchText(searchText);
    }, [searchText]);

    const [setDebouncedSearchText] = useDebouncedCallback(
        (value) => {
            //console.log('setDebouncedSearchText', value);

            if (value.length === 0) {
                value = null;
            }

            history.push(getPackagesPath(1, value));
        },
        500,
        []
    );

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <React.Fragment>
                            <MaterialTextField
                                margin="dense"
                                fullWidth
                                nomargin={1}
                                onChange={(e) => {
                                    setLocalSearchText(e.target.value);
                                    setDebouncedSearchText(e.target.value);
                                }}
                                label="Paieška"
                                value={localSearchText}
                            />
                        </React.Fragment>
                        <span style={{flex: 1}} />
                        <Fab to={ROUTE_PACKAGES_NEW} color="primary" aria-label="add" size="small" style={{marginLeft: 12}} component={LinkWrapper}>
                            <AddIcon />
                        </Fab>
                    </div>
                </>
            }>
                <Paper elavation="0" square>
                    {!packages || arePackagesLoading ? (
                        <DataLoadingIndicator />
                    ) : (
                        <>
                            <List dense={true}>
                                {packages.map((aPackage) =>
                                    <ListItem
                                        key={aPackage.id}
                                        button
                                        to={getPackagePath(aPackage.id)}
                                        component={LinkWrapper}
                                    >
                                        <ListItemText
                                            primary={aPackage.title}
                                        />
                                    </ListItem>
                                )}
                            </List>
                            <ListTablePagination
                                perPage={perPage}
                                totalCount={totalCount}
                                page={page}
                                onChangePage={(event, page) => {
                                    history.push(getPackagesPath(page));
                                }}
                            />
                        </>
                    )}
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default PackagesPage;