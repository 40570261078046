import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray, ErrorMessage, FastField} from 'formik';
import {Link, Redirect} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Yup from 'yup';
import datefnsFormat from 'date-fns/format'

import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ImageIcon from '@material-ui/icons/Image';
import Avatar from "@material-ui/core/Avatar";
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

import {FormMaterialSelectField, FormMaterialTextField, FormDatePickerField} from "shared-react/formik/FormikMaterialFields";
import PrintInvoiceAddProduct from "./PrintInvoiceAddProduct";
import {useGetMetadata} from "shared-react/api/MetadataApi";

import {ListAmountInput, ListPriceInput, ListItemTextSmallIconButton, ListIndexNumber} from "utils/List";
import ProgressButton from "shared-react/utils/ProgressButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {issuersOptions} from "../saleOrders/PrintInvoiceReceiptV2";
import FormikCustomerSelectField from "shared-react/formik/FormikCustomerSelectField";
import PrintInvoiceFormInputs from "./PrintInvoiceFormInputs";
import {usePrintCustomInvoice, usePrintFakeInvoice} from "shared-react/api/InvoiceApi";
import {API_SERVER_URL} from "shared-react/api/Api";

const PurchaseOrderSchema = Yup.object().shape({
    invoiceIssuerId: Yup.string().nullable().required('Laukas yra privalomas'),
    customerId: Yup.string().nullable().required('Pirkėjo laukas yra privalomas'),
    invoiceDate: Yup.mixed().required('Sąskaitos datos laukas yra privalomas'),
    invoiceNumber: Yup.string().required('Laukas privalomas'),
    orderNumber: Yup.string().required('Laukas privalomas'),
    orderProducts: Yup.array().required('Sąskaitoje turi būti bent viena prekė')
});

const grid = 8;
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "none",
    //padding: grid,
    width: '100%',
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    //margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "none",

    // styles we need to apply on draggables
    ...draggableStyle
});

function PrintInvoiceForm() {
    const theme = useTheme();
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(
        ['userCustomers']
    );

    const initialValues = {
        invoiceIssuerId: "2",
        customerId: null,
        reason: "Užsakymas Nr. ",
        deliveryPrice: 0,
        invoiceDate: new Date(),
        orderNumber: "",
        invoiceNumber: "",
        invoiceDueDate: null,
        orderProducts: []
    };
    const [invoiceData, setInvoiceData] = useState(initialValues);
    const [createdPdf, setCreatedPdf, isPrinting, errors, createInvoicesPdf] = usePrintFakeInvoice(invoiceData);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (createdPdf) {
            window.open(API_SERVER_URL + '/pdfs/' + createdPdf, "_blank");

            setCreatedPdf(null);
        }
    }, [createdPdf]);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    const onDragEnd = (result, arrayHelpers) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        //console.log('onDragEnd', result.source.index, result.destination.index, result);

        if (result.source.index !== result.destination.index) {
            arrayHelpers.move(result.source.index, result.destination.index);
        }
    };

    return (
        <React.Fragment>
            {/*<PageTitle title={pageTitle}/>*/}
            {/*<span style={{flex: 1}}></span>*/}
            {/*<Button to={buttonRoute} variant="outlined" color="primary" component={LinkWrapper}>*/}
                {/*Asd*/}
            {/*</Button>*/}
            <Formik
                initialValues={initialValues}
                validationSchema={PurchaseOrderSchema}
                onSubmit={(values) => {
                    // console.log('values', values);

                    const valuesToSubmit = {
                        ...values,
                        invoiceDate: values.invoiceDate ? datefnsFormat(values.invoiceDate, 'yyyy-MM-dd') : null,
                        invoiceDueDate: values.invoiceDueDate ? datefnsFormat(values.invoiceDueDate, 'yyyy-MM-dd') : null,
                    };
                    setInvoiceData(valuesToSubmit);
                    // console.log('valuesToSubmit', valuesToSubmit);
                    createInvoicesPdf();
                }}
            >
                {({values, errors, setFieldValue}) => {
                    const total = values.orderProducts.reduce((total, orderProducts) => {
                         return total + parseFloat((orderProducts.amount * orderProducts.price).toFixed(2));
                     }, parseFloat(values.deliveryPrice || 0));
                    // console.log('total', total.toFixed(2));

                    return (
                        <React.Fragment>
                            <Form>
                                <Paper style={{marginBottom: 16}}>
                                    <div style={{padding: 16}}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="invoiceIssuerId"
                                                    label="Išrašo"
                                                    component={FormMaterialSelectField}
                                                    fullWidth
                                                    // nomargin={1}
                                                    margin="dense"
                                                    options={issuersOptions}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    name="customerId"
                                                    label={"Pirkėjas"}
                                                    component={FormikCustomerSelectField}
                                                    creatable
                                                    customersMetadata={metadata.userCustomers}
                                                    onChange={(customerId) => {
                                                        //setOrderCustomerId(option === null ? '' : option.value);
                                                        //handleChange(e);
                                                        setFieldValue('customerId', customerId);
                                                    }}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    //menuPortalTarget={document.body}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="invoiceDate"
                                                    label={'Sąskaitos data'}
                                                    component={FormDatePickerField}
                                                    invalidDateMessage={"Neegzistuojanti data"}
                                                    clearable
                                                    fullWidth
                                                    nomargin={1}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="invoiceNumber"
                                                    label={'Sąskaitos numeris'}
                                                    nomargin={1}
                                                    margin="dense"
                                                    type="number"
                                                    inputProps={{ min: "0", step: "1" }}
                                                    fullWidth
                                                    component={FormMaterialTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="orderNumber"
                                                    label={'Užsakymo numeris'}
                                                    nomargin={1}
                                                    margin="dense"
                                                    type="number"
                                                    inputProps={{ min: "0", step: "1" }}
                                                    fullWidth
                                                    component={FormMaterialTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="deliveryPrice"
                                                    label={'Pristatymo mokestis'}
                                                    nomargin={1}
                                                    margin="dense"
                                                    type="number"
                                                    inputProps={{ min: "0", step: "0.01" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                    }}
                                                    fullWidth
                                                    component={FormMaterialTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="invoiceDueDate"
                                                    label={'Apmokėti iki'}
                                                    component={FormDatePickerField}
                                                    invalidDateMessage={"Neegzistuojanti data"}
                                                    clearable
                                                    fullWidth
                                                    nomargin={1}
                                                    margin="dense"
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Paper style={{marginBottom: 16}}>
                                    <FieldArray
                                        name={`orderProducts`}
                                        render={arrayHelpers => {
                                            return (
                                                <React.Fragment>
                                                    {values.orderProducts.length > 0 ? (
                                                        <DragDropContext onDragEnd={(result) => {
                                                            onDragEnd(result, arrayHelpers);
                                                        }}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        <List dense={true}>
                                                                            {values.orderProducts.map((orderProduct, productIndex) => (
                                                                                <Draggable key={productIndex} draggableId={'dragganle-' + productIndex} index={productIndex}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            key={productIndex}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            <ListItem
                                                                                                style={{paddingRight: 0}}
                                                                                            >
                                                                                                <ListIndexNumber paddingRight theme={theme}>
                                                                                                    {productIndex+1}
                                                                                                </ListIndexNumber>
                                                                                                <Hidden xsDown>
                                                                                                    <ListItemAvatar  style={{marginRight: 16}}>
                                                                                                        <Avatar>
                                                                                                            <ImageIcon />
                                                                                                        </Avatar>
                                                                                                    </ListItemAvatar>
                                                                                                </Hidden>
                                                                                                <ListItemText
                                                                                                    style={{padding: 0}}
                                                                                                    disableTypography
                                                                                                    primary={orderProduct.product.id ? orderProduct.product.name : (
                                                                                                        <div style={{padding: '8px 8px 8px 0'}}>
                                                                                                            <Grid container spacing={1}>
                                                                                                                <Grid item xs={12}>
                                                                                                                    <FastField
                                                                                                                        name={`orderProducts[${productIndex}].product.name`}
                                                                                                                        label={'Pavadinimas'}
                                                                                                                        nomargin={1}
                                                                                                                        margin="dense"
                                                                                                                        fullWidth
                                                                                                                        component={FormMaterialTextField}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12} sm={6}>
                                                                                                                    <FastField
                                                                                                                        name={`orderProducts[${productIndex}].product.code`}
                                                                                                                        label={'Kodas'}
                                                                                                                        nomargin={1}
                                                                                                                        margin="dense"
                                                                                                                        fullWidth
                                                                                                                        component={FormMaterialTextField}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12} sm={6}>
                                                                                                                    <FastField
                                                                                                                        name={`orderProducts[${productIndex}].product.price`}
                                                                                                                        label={'Kaina'}
                                                                                                                        nomargin={1}
                                                                                                                        margin="dense"
                                                                                                                        type="number"
                                                                                                                        inputProps={{ min: "0", step: "0.01" }}
                                                                                                                        InputProps={{
                                                                                                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                                                                                        }}
                                                                                                                        fullWidth
                                                                                                                        component={FormMaterialTextField}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    secondary={
                                                                                                        <React.Fragment>
                                                                                                            {orderProduct.product.id ? (
                                                                                                                <>
                                                                                                                    <Typography
                                                                                                                        style={{fontSize: 'inherit'}}
                                                                                                                        variant="body2"
                                                                                                                        color="textSecondary"
                                                                                                                    >
                                                                                                                        {orderProduct.product.code}
                                                                                                                    </Typography>
                                                                                                                    <Hidden smUp>
                                                                                                                        <div style={{display: 'flex', marginTop: 8}}>
                                                                                                                            {/*<div style={{flex: 1}}></div>*/}
                                                                                                                            <PrintInvoiceFormInputs
                                                                                                                                arrayHelpers={arrayHelpers}
                                                                                                                                productIndex={productIndex}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </Hidden>
                                                                                                                </>
                                                                                                            ) : null}
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                />
                                                                                                <Hidden xsDown>
                                                                                                    <PrintInvoiceFormInputs
                                                                                                        arrayHelpers={arrayHelpers}
                                                                                                        productIndex={productIndex}
                                                                                                    />
                                                                                                </Hidden>
                                                                                                <ListItemTextSmallIconButton>
                                                                                                    <IconButton onClick={() => arrayHelpers.remove(productIndex)}>
                                                                                                        <DeleteIcon/>
                                                                                                    </IconButton>
                                                                                                </ListItemTextSmallIconButton>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </List>
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    ) : null}
                                                    <div style={{
                                                        padding: '16px 52px 16px 16px',
                                                        textAlign: 'right',
                                                        fontWeight: 'bold',
                                                    }}>
                                                        Bendra suma: {total.toFixed(2)}
                                                    </div>
                                                    <div style={{padding: '16px 16px 16px 16px'}}>
                                                        <PrintInvoiceAddProduct currentCount={values.orderProducts.length} arrayHelpers={arrayHelpers}/>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </Paper>
                                <div style={{display: 'flex'}}>
                                    <ul style={{flex: 1, paddingLeft: 16, paddingRight: 16, margin: 0}}>
                                        <ErrorMessage
                                            name="orderProducts"
                                            component={FormHelperText}
                                            variant="filled"
                                            error
                                        />
                                        {!isPrinting && errors.length > 0
                                            ?
                                            (
                                             errors.map((error, errorIndex) => (
                                                    <li key={errorIndex}><FormHelperText error>{error}</FormHelperText></li>
                                                ))
                                            )

                                            : null
                                        }
                                    </ul>
                                    <div>
                                        <ProgressButton
                                            loading={isPrinting}
                                            text={"Spausdinti"}
                                            type={"submit"}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    );
}

export default PrintInvoiceForm;