import React, {useEffect, useState} from 'react';
import SortableTree, { getVisibleNodeCount } from 'react-sortable-tree';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {useGetProductCategories, useGetProductCategoriesTree, useSaveProductCategoriesTree} from "shared-react/api/ProductCategoryApi";
import {
    ROUTE_PRODUCT_CATEGORIES_NEW,
    getProductCategoriesPath,
    getProductCategoryPath,
    ROUTE_PURCHASE_ORDERS_NEW
} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import PageTitle from "../utils/PageTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

function ProductCategoriesPage({match, history}) {
    //const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    //const perPage = 100;
    //const [productCategories, totalCount, areProductCategoriesLoading, productCategoriesErrors, fetchProductCategories] = useGetProductCategories(perPage, page);


    const [productCategoriesTree, treeTotalCount, isProductCategoriesTreeLoading, productCategoriesTreeErrors, fetchProductCategoriesTree] = useGetProductCategoriesTree();

    // const initialTreeData = [{ id:1, title: 'Chicken', children: [] }, { title: 'Chicken2', children: [{ title: 'Egg' }] }];
    const [treeData, setTreeData] = useState([]);
    const [saveProductCategoriesTree] = useSaveProductCategoriesTree(treeData);


    useEffect(() => {
        //fetchProductCategories();
        fetchProductCategoriesTree();
    }, []);

    useEffect(() => {
        if (productCategoriesTree !== null) {
            const expandedTree = productCategoriesTree.map((productCategory) => {
                return {
                    ...productCategory,
                    expanded: true,
                }
            });

            setTreeData(expandedTree);
        }
    }, [productCategoriesTree]);

    if (!productCategoriesTree || isProductCategoriesTreeLoading) {
        return (<DataLoadingIndicator />);
    }
    const count = getVisibleNodeCount({treeData: treeData});

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <PageTitleWithButton
                            pageTitle={"Prekių kategorijos"}
                            buttonTitle={"Pridėti naują"}
                            buttonRoute={ROUTE_PRODUCT_CATEGORIES_NEW}
                        />
                    </div>
                </>

            }>
                <Paper square elevation={0}>
                    <div style={{height: count * 62 + 5}}>
                        <SortableTree
                            treeData={treeData}
                            onChange={treeData => {
                                setTreeData(treeData);
                                saveProductCategoriesTree();
                            }}
                            generateNodeProps={rowInfo => ({
                                buttons: [
                                    <IconButton onClick={() => history.push(getProductCategoryPath(rowInfo.node.id))}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>,
                                ],
                            })}
                        />
                        {/*<IconButton onClick={() => console.log('rowInfo', rowInfo)}>*/}
                        {/*<DeleteIcon fontSize="small" />*/}
                        {/*</IconButton>*/}
                    </div>
                    {/*<List dense={true}>*/}
                    {/*{productCategories.map((productCategory) =>*/}
                    {/*<ListItem*/}
                    {/*key={productCategory.id}*/}
                    {/*button*/}
                    {/*to={getProductCategoryPath(productCategory.id)}*/}
                    {/*component={LinkWrapper}*/}
                    {/*>*/}
                    {/*<ListItemText*/}
                    {/*primary={productCategory.title}*/}
                    {/*/>*/}
                    {/*</ListItem>*/}
                    {/*)}*/}
                    {/*</List>*/}
                    {/*<ListTablePagination*/}
                    {/*perPage={perPage}*/}
                    {/*totalCount={totalCount}*/}
                    {/*page={page}*/}
                    {/*onChangePage={(event, page) => {*/}
                    {/*history.push(getProductCategoriesPath(page));*/}
                    {/*}}*/}
                    {/*/>*/}
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default ProductCategoriesPage;