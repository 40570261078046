import {useMakeRequest, API_URL, METHOD_POST, METHOD_PUT} from './Api';
import qs from "qs";

export const API_ENDPOINT_NEWS = '/news';
export const API_ENDPOINT_PRODUCT_COLLECTIONS = '/collections';

export function useGetObjects(apiEndpoint, queryParams) {
    const queryString = '?' + qs.stringify(queryParams);

    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + apiEndpoint + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetObject(apiEndpoint, objectId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + apiEndpoint + '/' + objectId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveObject(apiEndpoint, objectData) {
    const method = getObjectMethod(objectData);
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + getObjectUrl(apiEndpoint, objectData), objectData);

    return [ data, setData, isLoading, errors, doRequest ];
}

function isNew(objectData) {
    return !(objectData !== null && typeof objectData === 'object'
             && 'id' in objectData && objectData['id'] !== null);
}

export function getObjectUrl(apiEndpoint, objectData) {
    return API_URL + apiEndpoint + (isNew(objectData) ? '' : '/' + objectData.id);
}

export function getObjectMethod(objectData) {
    return isNew(objectData) ? METHOD_POST : METHOD_PUT;
}