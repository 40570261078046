import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import isObject from 'lodash/isObject';
import {scrollParentToChild} from 'shared-react/utils/useScrollToElement';

export const keyify = (obj, prefix = '') => {
    if (isObject(obj)) {
        return Object.keys(obj).reduce((res, el) => {
            if (Array.isArray(obj[el])) {
                const arrayKeys = obj[el].reduce((res2, value, index) => {
                    return [...res2, ...keyify(value, prefix + el + '.' + index + '.')];
                }, []);

                return [...res, ...arrayKeys];
            } else if (typeof obj[el] === 'object' && obj[el] !== null) {
                return [...res, ...keyify(obj[el], prefix + el + '.')];
            } else {
                return [...res, prefix + el];
            }
        }, []);
    } else {
        return [];
    }
};

const FocusError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();
    //console.log('FocusError', errors, isSubmitting, isValidating);
    useEffect(() => {
        if (isSubmitting && !isValidating) {

            const keys = keyify(errors);
            //console.log('FocusError', errors, keys);

            //let keys = Object.keys(errors);
            if (keys.length > 0) {
                //const selector = `[name=${keys[0]}]`;
                const selector = `[name="${keys[0]}"]`;
                let errorElement = document.querySelector(selector);

                // if input field is not there or it's a hidden input, try finding an error element
                if (!errorElement || errorElement.getAttribute('type') === 'hidden') {
                    errorElement = document.getElementById('form-error-' + keys[0]);
                }
                //console.log('errorElement', errorElement);
                if (errorElement) {
                    errorElement.parentNode.scrollTop = errorElement.offsetTop;

                    const fillHeightContainerElement = document.getElementById('fill-height-container');

                    if (fillHeightContainerElement) {
                        scrollParentToChild(fillHeightContainerElement, errorElement);
                    } else {
                        errorElement.scrollIntoView({behavior: "smooth", block: 'center'});
                    }
                }
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
};



export default FocusError;