import React, {useState, useEffect} from 'react';
import {Link, Route, Switch, Redirect} from 'react-router-dom'

import {useGetPackage} from 'shared-react/api/PackageApi';
import PackageForm from "packages/PackageForm";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import LinkBack from "../utils/LinkBack";
import {getPackagesPath, ROUTE_PACKAGES} from 'shared-react/utils/routes';
import PageTitle from "../utils/PageTitle";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import PageTitleWithButton from "../utils/PageTitleWithButton";
import BackToLastLocationButton from "../utils/BackToLastLocationButton";

function PackageEditPage({ match, history }) {
    const aPackageId = match.params.id;
    const [aPackage, setPackage, isPackageLoading, packageErrors, fetchPackage] = useGetPackage(aPackageId);

    useEffect(() => {
        fetchPackage();
    }, []);

    if (!aPackage) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <BackToLastLocationButton path={getPackagesPath()} fallback={getPackagesPath()}/>
                        <PageTitle title={aPackage.title + " redagavimas"} />
                    </div>
                </>
            }>
                <PackageForm
                    isNew={false}
                    aPackage={aPackage}
                />
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default PackageEditPage;