import React from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';

function Checkbox(props) {
    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <label>
                    <input
                        type="checkbox"
                        {...props}
                        checked={field.value.includes(props.value)}
                        onChange={() => {
                            if (field.value.includes(props.value)) {
                                const nextValue = field.value.filter(
                                    value => value !== props.value
                                );
                                form.setFieldValue(props.name, nextValue);
                            } else {
                                const nextValue = field.value.concat(props.value);
                                form.setFieldValue(props.name, nextValue);
                            }
                        }}
                    />
                    {props.text}
                </label>
            )}
        </Field>
    );
}

function UserGroupForm({initialValues, onSaveUserGroup, isSubmitting}) {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                onSaveUserGroup(values);
            }}
        >
            {() => (
                <Form>
                    <Field
                        type="text"
                        name="title"
                    />
                    <Button fullWidth variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                        Save
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

export default UserGroupForm;