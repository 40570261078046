import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useTheme} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const useChipStyles = makeStyles({
    root: props => ({
        height: 26,
        backgroundColor: props.status ? props.theme.palette.grey[300] : '#ffc58b',
        marginRight: 5,
        justifyContent: 'left',
        width: props.fixedWidth ? props.fixedWidth : 'auto',
        minWidth: props.fixedWidth ? props.fixedWidth : 'auto',
        border: props.status ? '1px solid rgba(0, 0, 0, 0.23)' : '1px solid rgb(255, 166, 78)',
        fontSize: 'inherit',
    }),
    avatar: {
        marginLeft: '0 !important',
    }
});

const useAvatarStyles = makeStyles({
    root: props => ({
        marginLeft: 0,

        backgroundColor: props.status ? props.theme.palette.grey[400] : '#ffa64e',
        color: '#616161',

        height: '24px',
        width: '24px',

        fontSize: '0.75rem',
    }),
});

const useIconStyles = makeStyles({
    root: props => ({
        width: 16,
        height: 16,
        color: '#616161',
    }),
});


function StatusChip({icon, small = 0, status=0, label = null, fixedWidth = 130}) {
    const theme = useTheme();
    const chipStyles = useChipStyles({
        status,
        theme,
        fixedWidth,
    });
    const avatarStyles = useAvatarStyles({
        status,
        theme,
    });
    const iconStyles = useIconStyles({
        status
    });

    return (
        <React.Fragment>
            {/*<span style={{*/}
            {/*    // width: 128,*/}
            {/*    // minWidth: 128,*/}
            {/*    display: 'inline-block',*/}
            {/*    // textAlign: 'left',*/}
            {/*    // marginRight: 5,*/}
            {/*}}>*/}
                {!small ? (
                    <Chip
                        classes={chipStyles}
                        // variant="outlined"
                        style={{
                            marginRight: 5,
                        }}
                        avatar={
                            <Avatar
                                classes={avatarStyles}
                            >
                                {
                                    React.cloneElement(icon, {
                                        classes: iconStyles,
                                    })
                                }
                            </Avatar>
                        }
                        label={label}
                        variant="outlined"
                    />
                ) : (
                     // <span style={{
                     //     marginRight: 5,
                     //     display: 'inline-block',
                     // }}>
                        <Avatar
                            classes={avatarStyles}
                            style={{
                                marginRight: 5,
                            }}
                        >
                            {
                                React.cloneElement(icon, {
                                    classes: iconStyles,
                                })
                            }
                        </Avatar>
                    // </span>
                 )}
            {/*</span>*/}
        </React.Fragment>
    );
}

export default StatusChip;