import schema from "schm";
import translate from "schm-translate";
import {parseFormDate} from "shared-react/utils/form";

const productSchema = schema({
    id: { type: Number, default: ""},
    name: { type: String, default: ""},
    code: { type: String, default: ""},
    price: { type: Number, default: 0},
    lastPurchasePrice: { type: Number, default: 0},
});

const purchaseOrderProductServerToFormSchema = schema({
    id: { type: Number, default: ""},
    amount: { type: Number, default: 1},
    price: { type: Number, default: 0},
    product: { type: productSchema},
});

const purchaseOrderProductFormToServerSchema = schema({
    id: { type: Number, default: ""},
    amount: { type: Number, default: 1},
    price: { type: Number, default: 0},
    //productId: { type: Number, default: ""},
    product: { type: productSchema},
}, translate({
    //productId: 'product.id',
}));

const warehouseSchema = schema({
    id: { type: Number, default: ""},
    name: { type: String, default: ""},
});

const purchaseOrderServerToFormSchema = schema({
    id: { type: Number, default: null},
    title: { type: String, default: ""},
    receivedAt: { type: Date },
    // total: { type: Number, default: 0},
    orderProducts: { type: [purchaseOrderProductServerToFormSchema], default: []},
    warehouse: { type: warehouseSchema },
    warehouseId: { type: Number, default: ""},
}, translate({
    warehouseId: 'warehouse.id',
}));

const purchaseOrderFormToServerSchema = schema({
    id: { type: Number, default: null},
    title: { type: String, default: ""},
    receivedAt: { type: String, default: "0000-00-00"},
    // total: { type: Number, default: 0},
    orderProducts: { type: [purchaseOrderProductFormToServerSchema], default: []},
    warehouseId: { type: Number, default: ""},
});

export function parsePurchaseOrderServerToFormData(purchaseOrderData, defaultWarehouse) {
    if (typeof purchaseOrderData !== 'object' || purchaseOrderData === null) {
        purchaseOrderData = {};
    }

    if (!('warehouse' in purchaseOrderData)) {
        purchaseOrderData['warehouse'] = defaultWarehouse;
    }

    purchaseOrderData['receivedAt'] = parseFormDate(purchaseOrderData['receivedAt']);

    return purchaseOrderServerToFormSchema.parse(purchaseOrderData);
}

export function parsePurchaseOrderProductServerToFormData(purchaseOrderProductData) {
    if (typeof purchaseOrderProductData !== 'object' || purchaseOrderProductData === null) {
        purchaseOrderProductData = {};
    }

    return purchaseOrderProductServerToFormSchema.parse(purchaseOrderProductData);
}

export function parsePurchaseOrderFormToServerData(purchaseOrderData) {
    if (typeof purchaseOrderData !== 'object' || purchaseOrderData === null) {
        purchaseOrderData = {};
    }

    return purchaseOrderFormToServerSchema.parse(purchaseOrderData);
}