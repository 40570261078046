import React from 'react';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";

function ListTablePagination({perPage, totalCount, page, onChangePage, ...props}) {
    const calculatedTotalCount = (totalCount === null) ? 0 : totalCount;
    const zeroBasedPage = page - 1;

    console.log('ListTablePagination', perPage, totalCount, page, props);

    return (
        <TablePagination
            style={{padding:0,border:0}}
            rowsPerPageOptions={[perPage]}
            labelDisplayedRows={({from, to, count}) => `${from}-${to} iš ${count}`}
            labelRowsPerPage={null}
            count={calculatedTotalCount}
            rowsPerPage={perPage}
            page={zeroBasedPage}
            SelectProps={{
                native: true,
            }}
            onChangePage={(event, page) => {
                onChangePage(event, page + 1);
            }}
            component="div"
            {...props}
            // onChangeRowsPerPage={this.handleChangeRowsPerPage}
            // ActionsComponent={TablePaginationActionsWrapped}
        />
    );
}

{/*<Table padding={"none"}>*/}
    {/*<TableFooter>*/}
        {/*<TableRow>*/}
            {/*<TablePagination*/}
                {/*rowsPerPageOptions={[perPage]}*/}
                {/*labelDisplayedRows={({from, to, count}) => `${from}-${to} iš ${count}`}*/}
                {/*labelRowsPerPage={null}*/}
                {/*count={calculatedTotalCount}*/}
                {/*rowsPerPage={perPage}*/}
                {/*page={zeroBasedPage}*/}
                {/*SelectProps={{*/}
                    {/*native: true,*/}
                {/*}}*/}
                {/*onChangePage={(event, page) => {*/}
                    {/*onChangePage(event, page + 1);*/}
                {/*}}*/}
                {/*// onChangeRowsPerPage={this.handleChangeRowsPerPage}*/}
                {/*// ActionsComponent={TablePaginationActionsWrapped}*/}
            {/*/>*/}
        {/*</TableRow>*/}
    {/*</TableFooter>*/}
{/*</Table>*/}

export default ListTablePagination;