import React, {useState, useEffect} from 'react';
import {Link, Route} from 'react-router-dom';

import UserPermissionGroupForm from 'userPermissionGroups/UserPermissionGroupForm'
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {useGetUserPermissionGroup} from "shared-react/api/UserPermissionGroupApi";
import LinkBack from "utils/LinkBack";
import {
    getUserPermissionGroupPath,
    getUserPermissionGroupsPath
} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import PageTitle from "utils/PageTitle";

function UserPermissionGroupPage({ match }) {
    const userPermissionGroupId = match.params.id;
    const [userPermissionGroup, setUserPermissionGroup, isUserPermissionGroupLoading, userPermissionGroupErrors, fetchUserPermissionGroup] = useGetUserPermissionGroup(userPermissionGroupId);

    useEffect(() => {
        fetchUserPermissionGroup();
    }, []);

    if (!userPermissionGroup) {
        return (<DataLoadingIndicator />);
    }

    return (
        <div>
            <Route path={`${match.path}`} exact render={() => (
                <React.Fragment>
                    <LinkBack to={getUserPermissionGroupsPath()} text={"Atgal"}/>
                    <PageTitleWithButton
                        pageTitle={userPermissionGroup.title}
                        buttonTitle={"Redaguoti"}
                        buttonRoute={getUserPermissionGroupPath(userPermissionGroup.id) + '/redagavimas'}
                    />
                    <ul>
                        {userPermissionGroup.permissions.map((permission) => (
                            <li key={permission.id}>{permission.name}</li>
                        ))}
                    </ul>
                </React.Fragment>
            )} />
            <Route path={`${match.path}/redagavimas`} render={() => (
                <React.Fragment>
                    <LinkBack to={`${match.url}`} text={"Atgal"}/>
                    <div style={{display: 'flex', marginBottom: 8}}>
                        <PageTitle title={userPermissionGroup.title + " redagavimas"}/>
                    </div>
                    <UserPermissionGroupForm
                        userPermissionGroup={userPermissionGroup}
                        onSavePermissionGroup={setUserPermissionGroup}
                    />
                </React.Fragment>
            )} />
        </div>
    );
}

export default UserPermissionGroupPage;