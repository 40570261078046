import React, {useEffect, useState} from 'react';
import {useCreateOmnivaParcel} from "shared-react/api/OmnivaApi";
import {API_SERVER_URL} from 'shared-react/api/Api';
import ProgressButton from "shared-react/utils/ProgressButton";
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import ReceiptIcon from '@material-ui/icons/Receipt';

function CreateOmnivaParcel({fulfillment, onCreated}) {
    const [createdOmnivaLabel, setCreatedOmnivaLabel, isCreatingOmnivaLabel, errors, createOmnivaLavel] = useCreateOmnivaParcel(fulfillment.id);

    useEffect(() => {
        if (createdOmnivaLabel) {
            window.open(API_SERVER_URL + '/pdfs/omniva/' + createdOmnivaLabel.pdfFileName, "_blank");

            if (typeof onCreated === 'function') {
                onCreated(createdOmnivaLabel);
            }

            setCreatedOmnivaLabel(null);
        }
    }, [createdOmnivaLabel]);

    return (
        <React.Fragment>
            {fulfillment.omnivaParcels.length > 0 ? null : (
                <PermissionRequiredComponent permission={permissions.PERMISSION_INVOICES_CREATE}>
                    <div>
                        <div style={{display: 'flex'}}>
                            <span style={{flex: 1}} />
                            <ProgressButton
                                startIcon={<ReceiptIcon />}
                                loading={isCreatingOmnivaLabel}
                                text={'Sukurti naują siuntos lipduką'}
                                variant={"outlined"}
                                onClick={() => {
                                    createOmnivaLavel();
                                }}
                            />
                        </div>
                        <ResponseErrors errors={errors} />
                    </div>
                </PermissionRequiredComponent>
            )}
        </React.Fragment>
    );
}

export default CreateOmnivaParcel;