import React, {useEffect, useState} from 'react';
import qs from 'qs';
import {Redirect} from 'react-router-dom';
import {pickBy, identity} from "lodash";

import {useTheme} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import {useGetSaleOrders, useMergeSaleOrders} from "shared-react/api/SaleOrderApi";
import LinkWrapper from 'utils/LinkWrapper';
import {StyledListItemTextLeft, StyledListItemTextRight, ListItemPriceText} from 'utils/List';
import StatusChips from 'shared-react/shop/statusChips/StatusChips';
import Currency from 'shared-react/utils/Currency';
import ListTablePagination from 'utils/ListTablePagination';
import {getSaleOrdersPath, getSaleOrderPath} from 'shared-react/utils/routes';
import {MaterialTextField} from "shared-react/formik/MaterialFields";
import FiltersToChoose from "utils/FiltersToChoose";
import FiltersSelected from "utils/FiltersSelected";
import QuerySearchTextField from "utils/QuerySearchTextField";
import {SALE_ORDERS_FILTERS} from 'saleOrders/filters';
import ActionsButton from 'utils/ActionsButton';
import ActionsChoice from 'utils/ActionsChoice';
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import {StyledListItemText} from "shared-react/utils/ListItemText";
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";
import useHasPermission from "utils/useHasPermission";
import useListSelect, {
    ListSelectProvider,
    toggleSelected,
} from "utils/ListSelect/ListSelectContext";
import ListSelectActions from "../utils/ListSelect/ListSelectActions";

function SaleOrdersPage({match, history, location}) {
    const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const theme = useTheme();
    const page = typeof urlQueryParams.page === 'undefined' ? 1 : urlQueryParams.page;
    const perPage = 40;
    const queryParams = {
        ...urlQueryParams,
        'per_page': perPage,
        'page': page,
    };
    const queryString = '?' + qs.stringify(queryParams);
    const [orders, totalCount, isOrdersLoading, ordersErrors, fetchOrders] = useGetSaleOrders(queryString);
    const [selectMode, setSelectMode] = useState(false);
    const [listSelectState, listSelectDispatch] = useListSelect();
    const [mergedSaleOrder, isMergingSaleOrders, mergeSaleOrdersErrors, mergeSaleOrders] = useMergeSaleOrders(listSelectState.selected);

    const hasSaleOrdersViewPermission = useHasPermission(permissions.PERMISSION_SALE_ORDERS_VIEW);

    // const toggleSelected = (orderId) => {
    //     //     listSelectDispatch(toggleSelected(orderId));
    //     // };

    useEffect(() => {
        //setPage(routePage);

        fetchOrders();
    }, [queryString]);

    // if (!orders || isOrdersLoading) {
    //     return (<DataLoadingIndicator />);
    // }

    if (mergedSaleOrder) {
        return (<Redirect push to={getSaleOrderPath(mergedSaleOrder.id)}/>);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    height: '100%',
                    padding: '0 8px',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_MERGE}>
                            <ListSelectActions>
                                <ActionsChoice label={"Apjungti pardavimus"} onClick={() => {
                                    mergeSaleOrders();
                                }} />
                            </ListSelectActions>
                            {listSelectState.isSelecting ? (
                                <ResponseErrors errors={mergeSaleOrdersErrors}/>
                            ) : null }
                        </PermissionRequiredComponent>
                        <QuerySearchTextField
                            queryName={'order'}
                            label={'Užsakymo numeris'}
                            style={{
                                marginLeft: 8,
                                marginRight: 8,
                                flex: 1,
                            }}
                        />
                        <FiltersToChoose filters={SALE_ORDERS_FILTERS} />
                    </div>
                    <FiltersSelected filters={SALE_ORDERS_FILTERS}/>
                </div>
                </>
            }>
                {!orders || isOrdersLoading ? (
                    <DataLoadingIndicator />
                ) : (
                    <Paper elavation={0} square>
                        {/*<AppBar position="static" color="default">*/}
                        {/*<Tabs*/}
                        {/*value={0}*/}
                        {/*onChange={()=>{}}*/}
                        {/*indicatorColor="primary"*/}
                        {/*textColor="primary"*/}
                        {/*>*/}
                        {/*<Tab label="Visi" />*/}
                        {/*<Tab label="Item Two" />*/}
                        {/*<Tab label="Item Three" />*/}
                        {/*</Tabs>*/}
                        {/*</AppBar>*/}
                        <List dense={true} style={{padding: 0}}>
                            {orders.map((order) =>
                                <ListItem key={order.id} button={hasSaleOrdersViewPermission} component={hasSaleOrdersViewPermission && !selectMode ? LinkWrapper : "div"} to={getSaleOrderPath(order.id)} onClick={(e) => {
                                    if (listSelectState.isSelecting) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        listSelectDispatch(toggleSelected(order.id));
                                        // toggleSelected(order.id);
                                    } else {
                                        //history.push(getSaleOrderPath(order.id));
                                        //to={getSaleOrderPath(order.id)} component={LinkWrapper}
                                    }
                                }}>
                                    {listSelectState.isSelecting ? (
                                        <Checkbox
                                            checked={listSelectState.selected.indexOf(order.id) > -1}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                // toggleSelected(order.id)
                                                listSelectDispatch(toggleSelected(order.id));
                                            }}
                                            onChange={(e, value) => {
                                                //e.preventDefault();
                                                // toggleSelected(order.id)
                                                //listSelectDispatch(toggleSelected(order.id));
                                            }}
                                        />
                                    ) : null}
                                    <StyledListItemTextLeft
                                        theme={theme}
                                        primary={<React.Fragment>#{order.id}</React.Fragment>}
                                        secondary={<React.Fragment>{order.day}<Hidden mdDown> {order.time}</Hidden></React.Fragment>}
                                    />
                                    <StyledListItemText
                                        gutterLeft
                                        disableTypography={true}
                                        primary={
                                            <React.Fragment>
                                                {order.seller ? order.seller.firstName + ' ' + order.seller.lastName : ''}
                                                &nbsp;-&nbsp;
                                                {order.customer ? order.customer.firstName + ' ' + order.customer.lastName : ''}
                                            </React.Fragment>
                                        }
                                        secondary={
                                            <div>
                                                <Hidden mdUp>
                                                    <StatusChips order={order}/>
                                                </Hidden>
                                                {/*<Link to={"/pardavimai/" + order.id}>Redaguoti</Link>*/}
                                            </div>
                                        }
                                    />
                                    <Hidden smDown>
                                        <StyledListItemTextRight
                                            theme={theme}
                                            primary={<React.Fragment><StatusChips order={order}/></React.Fragment>}
                                        />
                                    </Hidden>
                                    <ListItemPriceText>
                                        <Currency value={order.total}/>
                                    </ListItemPriceText>
                                    {/*<ListItemSecondaryAction>*/}
                                    {/*Tekstas*/}
                                    {/*</ListItemSecondaryAction>*/}
                                    {/*<ListItemSecondaryAction>*/}
                                    {/*/!*<Fab size="small" color="secondary">*!/*/}
                                    {/*/!*<AddShoppingCartIcon />*!/*/}
                                    {/*/!*</Fab>*!/*/}
                                    {/*<IconButton onClick={() => {removeOrderProduct(orderProduct.productId, fulfillment.warehouse.id)}}>*/}
                                    {/*<DeleteIcon />*/}
                                    {/*</IconButton>*/}
                                    {/*</ListItemSecondaryAction>*/}
                                </ListItem>
                                // <SearchProduct key={product.id} warehouseId={warehouseId} product={product}/>
                            )}
                        </List>
                        <ListTablePagination
                            perPage={perPage}
                            totalCount={totalCount}
                            page={page}
                            onChangePage={(event, page) => {
                                const newUrlQueryParams = {
                                    ...urlQueryParams,
                                    ['page']: page,
                                };

                                const queryString = '?' + qs.stringify(newUrlQueryParams);

                                history.push(location.pathname + queryString);
                                //history.push(getSaleOrdersPath(page) + queryString);
                            }}
                        />
                        {/*<ul>*/}
                        {/*{orders.map((order) =>*/}
                        {/*<li key={order.id}>*/}
                        {/*{order.id} {order.total}*/}
                        {/*<Link to={"/pardavimai/" + order.id}>Redaguoti</Link>*/}
                        {/*</li>*/}
                        {/*)}*/}
                        {/*</ul>*/}
                    </Paper>
                )}
            </FillHeightContainer>
        </React.Fragment>
    );
}

function SaleOrdersPageWithProvider({...props}) {
    return (
        <ListSelectProvider>
            <SaleOrdersPage {...props} />
        </ListSelectProvider>
    );
};

export default SaleOrdersPageWithProvider;