export const PERMISSION_SALE_ORDERS_VIEW             = 'PERMISSION_SALE_ORDERS_VIEW';
export const PERMISSION_SALE_ORDERS_LIST_VIEW        = 'PERMISSION_SALE_ORDERS_LIST_VIEW';
export const PERMISSION_SALE_ORDERS_CREATE           = 'PERMISSION_SALE_ORDERS_CREATE';
export const PERMISSION_SALE_ORDERS_EDIT             = 'PERMISSION_SALE_ORDERS_EDIT';
export const PERMISSION_SALE_ORDERS_DELETE           = 'PERMISSION_SALE_ORDERS_DELETE';
export const PERMISSION_SALE_ORDERS_VIEW_ALL_SELLERS = 'PERMISSION_SALE_ORDERS_VIEW_ALL_SELLERS';
export const PERMISSION_SALE_ORDERS_MERGE            = 'PERMISSION_SALE_ORDERS_MERGE';

export const PERMISSION_PRODUCTS_VIEW      = 'PERMISSION_PRODUCTS_VIEW';
export const PERMISSION_PRODUCTS_LIST_VIEW = 'PERMISSION_PRODUCTS_LIST_VIEW';
export const PERMISSION_PRODUCTS_CREATE    = 'PERMISSION_PRODUCTS_CREATE';
export const PERMISSION_PRODUCTS_EDIT      = 'PERMISSION_PRODUCTS_EDIT';
export const PERMISSION_PRODUCTS_DELETE    = 'PERMISSION_PRODUCTS_DELETE';
export const PERMISSION_PRODUCTS_LOW_QUANTITY_LIST_VIEW = 'PERMISSION_PRODUCTS_LOW_QUANTITY_LIST_VIEW';
export const PERMISSION_PRODUCTS_CUSTOM_PRICE = 'PERMISSION_PRODUCTS_CUSTOM_PRICE';

export const PERMISSION_PRODUCT_CATEGORIES_VIEW      = 'PERMISSION_PRODUCT_CATEGORIES_VIEW';
export const PERMISSION_PRODUCT_CATEGORIES_LIST_VIEW = 'PERMISSION_PRODUCT_CATEGORIES_LIST_VIEW';
export const PERMISSION_PRODUCT_CATEGORIES_CREATE    = 'PERMISSION_PRODUCT_CATEGORIES_CREATE';
export const PERMISSION_PRODUCT_CATEGORIES_EDIT      = 'PERMISSION_PRODUCT_CATEGORIES_EDIT';
export const PERMISSION_PRODUCT_CATEGORIES_DELETE    = 'PERMISSION_PRODUCT_CATEGORIES_DELETE';

export const PERMISSION_USERS_VIEW               = 'PERMISSION_USERS_VIEW';
export const PERMISSION_USERS_LIST_VIEW          = 'PERMISSION_USERS_LIST_VIEW';
export const PERMISSION_USERS_CREATE             = 'PERMISSION_USERS_CREATE';
export const PERMISSION_USERS_EDIT               = 'PERMISSION_USERS_EDIT';
export const PERMISSION_USERS_DELETE             = 'PERMISSION_USERS_DELETE';
export const PERMISSION_USERS_VIEW_ALL_CUSTOMERS = 'PERMISSION_USERS_VIEW_ALL_CUSTOMERS';

export const PERMISSION_USER_PERMISSIONS_VIEW        = 'PERMISSION_USER_PERMISSIONS_VIEW';
export const PERMISSION_USER_PERMISSIONS_LIST_VIEW   = 'PERMISSION_USER_PERMISSIONS_LIST_VIEW';
export const PERMISSION_USER_PERMISSIONS_CREATE      = 'PERMISSION_USER_PERMISSIONS_CREATE';
export const PERMISSION_USER_PERMISSIONS_EDIT        = 'PERMISSION_USER_PERMISSIONS_EDIT';
export const PERMISSION_USER_PERMISSIONS_DELETE      = 'PERMISSION_USER_PERMISSIONS_DELETE';

export const PERMISSION_USER_GROUPS_VIEW        = 'PERMISSION_USER_GROUPS_VIEW';
export const PERMISSION_USER_GROUPS_LIST_VIEW   = 'PERMISSION_USER_GROUPS_LIST_VIEW';
export const PERMISSION_USER_GROUPS_CREATE      = 'PERMISSION_USER_GROUPS_CREATE';
export const PERMISSION_USER_GROUPS_EDIT        = 'PERMISSION_USER_GROUPS_EDIT';
export const PERMISSION_USER_GROUPS_DELETE      = 'PERMISSION_USER_GROUPS_DELETE';

export const PERMISSION_PURCHASE_ORDERS_VIEW        = 'PERMISSION_PURCHASE_ORDERS_VIEW';
export const PERMISSION_PURCHASE_ORDERS_LIST_VIEW   = 'PERMISSION_PURCHASE_ORDERS_LIST_VIEW';
export const PERMISSION_PURCHASE_ORDERS_CREATE      = 'PERMISSION_PURCHASE_ORDERS_CREATE';
export const PERMISSION_PURCHASE_ORDERS_EDIT        = 'PERMISSION_PURCHASE_ORDERS_EDIT';
export const PERMISSION_PURCHASE_ORDERS_DELETE      = 'PERMISSION_PURCHASE_ORDERS_DELETE';

export const PERMISSION_PRODUCT_TRANSFERS_VIEW        = 'PERMISSION_PRODUCT_TRANSFERS_VIEW';
export const PERMISSION_PRODUCT_TRANSFERS_LIST_VIEW   = 'PERMISSION_PRODUCT_TRANSFERS_LIST_VIEW';
export const PERMISSION_PRODUCT_TRANSFERS_CREATE      = 'PERMISSION_PRODUCT_TRANSFERS_CREATE';
export const PERMISSION_PRODUCT_TRANSFERS_EDIT        = 'PERMISSION_PRODUCT_TRANSFERS_EDIT';
export const PERMISSION_PRODUCT_TRANSFERS_DELETE      = 'PERMISSION_PRODUCT_TRANSFERS_DELETE';

export const PERMISSION_PACKAGES_VIEW        = 'PERMISSION_PACKAGES_VIEW';
export const PERMISSION_PACKAGES_LIST_VIEW   = 'PERMISSION_PACKAGES_LIST_VIEW';
export const PERMISSION_PACKAGES_CREATE      = 'PERMISSION_PACKAGES_CREATE';
export const PERMISSION_PACKAGES_EDIT        = 'PERMISSION_PACKAGES_EDIT';
export const PERMISSION_PACKAGES_DELETE      = 'PERMISSION_PACKAGES_DELETE';

export const PERMISSION_INVOICES_VIEW        = 'PERMISSION_INVOICES_VIEW';
export const PERMISSION_INVOICES_LIST_VIEW   = 'PERMISSION_INVOICES_LIST_VIEW';
export const PERMISSION_INVOICES_CREATE      = 'PERMISSION_INVOICES_CREATE';
export const PERMISSION_INVOICES_EDIT        = 'PERMISSION_INVOICES_EDIT';
export const PERMISSION_INVOICES_DELETE      = 'PERMISSION_INVOICES_DELETE';





export const USER_VIEW_PERMISSIONS = 'USER_VIEW_PERMISSIONS';

export const PERMISSION_USER_GROUPS_VIEW_PAGE = 'PERMISSION_USER_GROUPS_VIEW_PAGE';
export const PERMISSION_USER_PERMISSION_GROUPS_VIEW_PAGE = 'PERMISSION_USER_PERMISSION_GROUPS_VIEW_PAGE';

export const PERMISSION_BOOK_KEEPING_INVOICES_VIEW_PAGE = 'PERMISSION_BOOK_KEEPING_INVOICES_VIEW_PAGE';