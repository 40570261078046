import React, {useState, useEffect} from 'react';
import Checkbox from '@material-ui/core/Checkbox';

function ControlledCheckbox({checked, value, onChange}) {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);
    return (
        <Checkbox
            checked={isChecked}
            onChange={(event) => {
                setIsChecked(event.target.checked);
                //console.log('on click', rowInfo);

                onChange({
                    value: value,
                    isChecked: event.target.checked,
                });
            }}
            value={value}
        />
    );
}
export default ControlledCheckbox;