import React, {useState} from 'react';

import CheckoutProductCustomPriceDialog from './CheckoutProductCustomPriceDialog';
import Currency from '../utils/Currency';
import LinkText from "../utils/LinkText";

function CheckoutProductPrice({fulfillmentIndex, productId, warehouseId, price, customPrice, customPriceReason, updateOrderProduct}) {
    const [isCustomPriceDialogOpen, setIsCustomPriceDialogOpen] = useState(false);

    const onCloseDialog = () => {
        setIsCustomPriceDialogOpen(false);
    };

    const onApplyCustomPrice = (customPrice, reason) => {
        updateOrderProduct(fulfillmentIndex, productId, warehouseId, undefined, customPrice, reason);
        setIsCustomPriceDialogOpen(false);
    };

    return (
        <React.Fragment>
            <LinkText href={'#'} onClick={(e) => {
                e.preventDefault();
                setIsCustomPriceDialogOpen(true);
            }}>
                <Currency value={price}/>
            </LinkText>
            <CheckoutProductCustomPriceDialog
                customPrice={customPrice}
                customPriceReason={customPriceReason}
                isOpen={isCustomPriceDialogOpen}
                onCloseDialog={onCloseDialog}
                onApplyCustomPrice={onApplyCustomPrice}
            />
        </React.Fragment>
    );
}


export default CheckoutProductPrice;