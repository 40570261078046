import qs from "qs";
import {makeRequest, useMakeRequest, API_URL} from './Api';

export const API_METADATA_URL = '/metadata';

export function getMetadata(metas) {
    const queryParams = {
        'metas': metas,
    };

    const queryString = '?' + qs.stringify(queryParams);

    return makeRequest('get', API_URL + API_METADATA_URL + queryString);
}

export function useGetMetadata(metas) {
    const queryParams = {
        'metas': metas,
    };

    const queryString = '?' + qs.stringify(queryParams);

    const { data, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_METADATA_URL + queryString);

    return [data, isLoading, errors, doRequest];
}