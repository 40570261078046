import React from 'react';
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import {requestUserLogin} from 'ducks/auth';
import LoginForm from 'login/LoginForm';
import {ROUTE_DEFAULT} from 'shared-react/utils/routes';

function LoginPage({token, isSubmitting, showLoginError, onRequestUserLogin, location}) {
    const { from } = location.state || { from: { pathname: ROUTE_DEFAULT } };

    if (token) {
        return <Redirect to={from} />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <div style={{
                marginTop: 32
            }}>
                <LoginForm onRequestUserLogin={onRequestUserLogin} isSubmitting={isSubmitting}/>
                {showLoginError
                    ?
                    <div>Nepavyko prisijungti su šia vardo ir slaptažodžio kombinacija</div>
                    : null
                }
            </div>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    isSubmitting: state.auth.isSubmitting,
    showLoginError: state.auth.showLoginError,
});

const mapDispatchToProps = (dispatch) => ({
    onRequestUserLogin: (username, password) => {
        dispatch(requestUserLogin(username, password))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);