import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import LastLocationContext from './LastLocationContext';
import {getInvoicesPath, getPackagesPath, ROUTE_SALE_ORDERS} from 'shared-react/utils/routes';

const routeToTrack = [
    getInvoicesPath(),
    getPackagesPath(),
    ROUTE_SALE_ORDERS
];

function LastLocationProvider({children}) {
    const location = useLocation();
    const [lastLocation, setLastLocation] = useState({});

    useEffect(() => {
        const matchingRouteToTrack = getMatchingRouteToTrack(location.pathname);

        if (location && matchingRouteToTrack !== null) {
            setLastLocation({
                ...lastLocation,
                [matchingRouteToTrack]: location,
            });
        }
    }, [location]);

    return (
        <React.Fragment>
            <LastLocationContext.Provider value={lastLocation}>{children}</LastLocationContext.Provider>
        </React.Fragment>
    );
}

function getMatchingRouteToTrack(locationPathName) {
    for (let i = 0; i < routeToTrack.length; i++) {
        if (locationPathName.includes(routeToTrack[i])) {
            return routeToTrack[i];
        }
    }

    return null;
}

export default LastLocationProvider;