import {makeRequest, useMakeRequest, API_URL} from './Api';

const API_OMNIVA = '/v2/omniva';

export function useCreateOmnivaParcel(fulfillmentId) {
    const method = 'post';
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_OMNIVA + '/' + fulfillmentId, null);

    return [ data, setData, isLoading, errors, doRequest ];
}

export function useDeleteOmnivaParcel(parcelId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('delete', API_URL + API_OMNIVA + '/' + parcelId);

    return [ data, isLoading, errors, doRequest ];
}