import React, {useState} from 'react';
import { styled } from '@material-ui/core/styles';

import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from '@material-ui/core/Hidden';
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ImageIcon from '@material-ui/icons/Image';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import RaisedCard from './RaisedCard';
import LinkDynamic from "../utils/LinkDynamic";
import {getProductPath} from "../utils/routes";
import ConfirmationDialog from "../utils/ConfirmationDialog";
import CheckoutPageAmountChanger from '../shop/CheckoutPageAmountChanger';
import CheckoutProductPrice from './CheckoutProductPrice';
import Currency from 'shared-react/utils/Currency';
import OrderTotalsTable from 'shared-react/shop/OrderTotalsTable';
import WarehouseSelect from "shared-react/shop/WarehouseSelect";
import AddProductsToOrder from "shared-react/shop/AddProductsToOrder";
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";
import ListProductImageAvatar from "./ListProductImageAvatar";

export const StyledListItemTextTotals = styled(ListItemText)({
    minWidth: 70,
    paddingLeft: 0,
    paddingRight: 0,
    flex: 'none',
    textAlign: 'right',
});

const StyledListItemTextAmount = styled(ListItemText)({
    paddingLeft: 0,
    paddingRight: 16,
    width: 95,
    flex: '0 1 auto',
});

export const StyledListItemTextPrice = styled(ListItemText)({
    paddingLeft: 0,
    paddingRight: 16,
    textAlign: 'right',
    minWidth: 7,
    flex: '0 1 auto',
});

function Step5Review({
                        cmsForm,
                        showAddProducts,
                        metadata,
                        isUpdating,
                        isSubmitting,
                        order,
                        removeOrderProduct,
                        updateOrderProduct,
                        orderUuid,
                        ...props
}) {
    return (
        <React.Fragment>
            <RaisedCard contentPadding={0} label={<>{cmsForm ? '5' : '4'}. Prekės</>} {...props}>
                <List
                    dense={true}
                >
                    {order.fulfillments.map((fulfillment, index) => {
                        // console.log('fulfillment', fulfillment);
                        return (
                            <React.Fragment key={index}>
                                {cmsForm ? (
                                    <ListSubheader disableSticky={true} component="div"> {fulfillment.warehouse.name}</ListSubheader>
                                ) : null}
                                {fulfillment.orderProducts.map((orderProduct, orderProductIndex) => {
                                    return (
                                        <ListItem key={orderProductIndex + '-' + orderProduct.productId}>
                                            <ListProductImageAvatar
                                                productMainImage={orderProduct.productMainImage}
                                            />
                                            <ListItemText
                                                primaryTypographyProps={{component: 'div'}}
                                                secondaryTypographyProps={{component: 'div'}}
                                                primary={
                                                    <React.Fragment>
                                                        <LinkDynamic
                                                            routerTo={getProductPath(orderProduct.productParentId) + '/redagavimas'}
                                                            linkHref={'/prekes/' + orderProduct.productParentPath}
                                                        >
                                                            {orderProduct.name}
                                                        </LinkDynamic>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        {orderProduct.code}
                                                        <br/>
                                                        {cmsForm && orderProduct.amountLeft ? (
                                                            <>
                                                                Kiekis: {orderProduct.amountLeft}
                                                            </>
                                                         ) : null}
                                                        <Hidden smUp implementation="css" >
                                                            <div style={{
                                                                display: 'flex',
                                                                width: '100%',
                                                                position: 'relative',
                                                                boxSizing: 'border-box',
                                                                textAlign: 'left',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                            }}>
                                                                <ConfirmationDialog
                                                                    dialogContent={"Ar tikrai norite pašalinti šią prekę iš krepšelio"}
                                                                    confirmationFunction={() => removeOrderProduct(index, orderProduct.productId, fulfillment.warehouse.id)}
                                                                >
                                                                    <IconButton>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ConfirmationDialog>
                                                                <div style={{width: 80, marginRight: 16}}>
                                                                    <CheckoutPageAmountChanger
                                                                        key={orderUuid}
                                                                        fulfillmentIndex={index}
                                                                        initialValue={orderProduct.amount}
                                                                        productId={orderProduct.productId}
                                                                        warehouseId={fulfillment.warehouse.id}
                                                                        updateOrderProduct={updateOrderProduct}
                                                                    />
                                                                </div>
                                                                <div style={{
                                                                    flex: '1 0 auto',
                                                                    textAlign: 'left'
                                                                }}>
                                                                    <Typography variant="inherit" component="span" color="textPrimary">
                                                                        <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_CUSTOM_PRICE} fallback={<Currency value={orderProduct.cost}/>}>
                                                                            <CheckoutProductPrice
                                                                                fulfillmentIndex={index}
                                                                                productId={orderProduct.productId}
                                                                                warehouseId={fulfillment.warehouse.id}
                                                                                customPrice={orderProduct.customPrice}
                                                                                customPriceReason={orderProduct.customPriceReason}
                                                                                price={orderProduct.cost}
                                                                                updateOrderProduct={updateOrderProduct}
                                                                            />
                                                                        </PermissionRequiredComponent>
                                                                    </Typography>
                                                                    {orderProduct.price > 0 && orderProduct.cost !== orderProduct.price ? (
                                                                        <React.Fragment>
                                                                            <s><Currency value={orderProduct.price}/></s>
                                                                        </React.Fragment>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Hidden>
                                                    </React.Fragment>
                                                }
                                            />

                                            <Hidden xsDown implementation="css">
                                                <StyledListItemTextAmount
                                                    primary={
                                                        <React.Fragment>
                                                            <ConfirmationDialog
                                                                dialogContent={"Ar tikrai norite pašalinti šią prekę iš krepšelio"}
                                                                confirmationFunction={() => removeOrderProduct(index, orderProduct.productId, fulfillment.warehouse.id)}
                                                            >
                                                                <IconButton>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ConfirmationDialog>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </Hidden>
                                            <Hidden xsDown implementation="css">
                                                <StyledListItemTextAmount
                                                    primary={
                                                        <React.Fragment>
                                                            <CheckoutPageAmountChanger
                                                                key={orderUuid}
                                                                initialValue={orderProduct.amount}
                                                                fulfillmentIndex={index}
                                                                productId={orderProduct.productId}
                                                                warehouseId={fulfillment.warehouse.id}
                                                                updateOrderProduct={updateOrderProduct}
                                                            />
                                                        </React.Fragment>
                                                    }
                                                />
                                            </Hidden>
                                            <Hidden xsDown implementation="css">
                                                <StyledListItemTextPrice
                                                    primary={
                                                        <React.Fragment>
                                                            <span>
                                                                <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_CUSTOM_PRICE} fallback={<Currency value={orderProduct.cost}/>}>
                                                                    <CheckoutProductPrice
                                                                        fulfillmentIndex={index}
                                                                        productId={orderProduct.productId}
                                                                        warehouseId={fulfillment.warehouse.id}
                                                                        customPrice={orderProduct.customPrice}
                                                                        customPriceReason={orderProduct.customPriceReason}
                                                                        price={orderProduct.cost}
                                                                        updateOrderProduct={updateOrderProduct}
                                                                    />
                                                                </PermissionRequiredComponent>
                                                                {/*<Currency value={orderProduct.cost}/>*/}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                    secondary={
                                                        orderProduct.price > 0 && orderProduct.cost !== orderProduct.price ? (
                                                            <React.Fragment>
                                                                <s><Currency value={orderProduct.price}/></s>
                                                            </React.Fragment>
                                                        ) : null
                                                    }
                                                />
                                            </Hidden>
                                            {/*<Hidden xsDown implementation="css">*/}
                                            <StyledListItemTextTotals
                                                primary={
                                                    <React.Fragment>
                                                        <Currency value={orderProduct.total}/>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    )
                                })}
                            </React.Fragment>
                        )
                    })}
                </List>
                {showAddProducts ? (
                    <>
                        <List dense={true} style={{padding: 0}}>
                            <ListSubheader disableSticky={true} component="div" style={{lineHeight: '16px'}}>Pridėti prekę</ListSubheader>
                        </List>
                        <div style={{padding: 12}}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <WarehouseSelect
                                        metadata={metadata}
                                        isDisabled={isUpdating || isSubmitting}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <AddProductsToOrder />
                                </Grid>
                            </Grid>
                        </div>
                    </>
                ) : null}
                <OrderTotalsTable order={order} />
            </RaisedCard>
        </React.Fragment>
    );
}

export default React.memo(Step5Review);