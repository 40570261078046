import React, {useEffect, useState} from 'react';
import isArray from 'lodash/isArray';
import { Formik, Form, Field, FieldArray } from 'formik';
import { styled } from '@material-ui/core/styles';
import * as Yup from "yup";

import Currency from 'shared-react/utils/Currency';
import ListIemKeyValue from 'shared-react/utils/ListIemKeyValue';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import InputAdornment from '@material-ui/core/InputAdornment';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography/Typography";
import Hidden from '@material-ui/core/Hidden';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import TextField from '@material-ui/core/TextField';
import {FormMaterialTextField} from "../formik/FormikMaterialFields";
// import {FormCustomerSelectField} from 'utils/FormikMaterialFields';
// import {MaterialTextField} from "utils/MaterialFields";
// import CheckoutProductPrice from 'shop/CheckoutProductPrice';
// import Currency from "utils/Currency";
// import CheckoutPageAmountChanger from 'shop/CheckoutPageAmountChanger';
import FormikCustomerSelectField from "../formik/FormikCustomerSelectField";
import CheckoutPageAmountChanger from '../shop/CheckoutPageAmountChanger';
import {MaterialRadioGroupButton} from '../formik/FormikMaterialFields';
// import LinkRoute from 'utils/LinkRoute';
import ProgressButton from '../utils/ProgressButton';
import {darkTheme} from '../utils/theme';
import LinkDynamic from "../utils/LinkDynamic";
import {getProductPath} from "../utils/routes";
import ConfirmationDialog from "../utils/ConfirmationDialog";
import CheckoutProductPrice from './CheckoutProductPrice';
import CheckoutDeliveryMethods from './CheckoutDeliveryMethods';
import CheckoutPaymentMethods from './CheckoutPaymentMethods';

const deliveryMethodCodeValidation = {
    deliveryMethodCode: Yup.string().required('Būtina pasirinkti pristatymą'),
    omnivaPickupPointId: Yup.string()
        .when('deliveryMethodCode', {
            is: (val) => val === "OMNIVA_LT",
            then: Yup.string().required('Paštomato laukas negali būti tuščias'),
            otherwise: Yup.string().nullable(),
        }),
};

const CheckoutSchema1 = Yup.object().shape({
    sellerId: Yup.string().required('Būtina pasirinkti pardavėją'),
    customerId: Yup.string().required('Būtina pasirinkti pirkėją'),
});

const CheckoutSchema2 = Yup.object().shape({
    sellerId: Yup.string().nullable(),

    customerId: Yup.string().nullable(),
    customerIsCompany: Yup.string().required('Būtina pasirinkti pirkėjo tipą'),
    customerEmail: Yup.string().required('El. pašto laukas negali būti tuščias'),
    customerPhoneNumber: Yup.string().required('Telefono nr. laukas negali būti tuščias'),
    customerFirstName: Yup.string()
        .when('customerIsCompany', {
            is: (val) => val === "1",
            then: Yup.string().required('Pavadinimo laukas negali būti tuščias'),
            otherwise: Yup.string().required('Vardo laukas negali būti tuščias'),
        }),
    customerLastName: Yup.string()
        .when('customerIsCompany', {
            is: (val) => val === "1",
            then: Yup.string().nullable(),
            otherwise: Yup.string().required('Pavardės laukas negali būti tuščias'),
        }),
    customerPvmCode: Yup.string()
        .when('customerIsCompany', {
            is: (val) => val === "1",
            then: Yup.string().required('PVM mokėtojo kodo laukas negali būti tuščias'),
            otherwise: Yup.string().nullable(),
        }),
    customerCode: Yup.string()
        .when('customerIsCompany', {
            is: (val) => val === "1",
            then: Yup.string().required('Įmonės kodo laukas negali būti tuščias'),
            otherwise: Yup.string().nullable(),
        }),

    ...deliveryMethodCodeValidation,
});

const CheckoutSchema = Yup.object().shape({
    sellerId: Yup.string()
        .when(['customerFirstName', 'customerLastName', 'customerEmail', 'customerPhone', 'code', 'pvmCode'], {
            is: (customerFirstName, customerLastName, customerEmail, customerPhoneNumber, code, pvmCode) => isBig && isSpecial,
            then: Yup.number().required('Būtina pasirinkti pardavėją'),
        }),
    customerId: Yup.string()
        .nullable(),
    deliveryMethodCode: Yup.string()
        .required('Būtina pasirinkti pristatymą'),
    customDeliveryPrice: Yup.number()
        .when('deliveryMethodCode', {
            is: (val) => val === "CUSTOM", //todo[as]: use const
            then: Yup.number()
                .required('Pristatymo kainos laukas negali būti tuščias'),
        })
});

const StyledListItemTextAmount = styled(ListItemText)`
  && {
    padding-left: 0;
    padding-right: 16px;
    width: 70px;
    flex: 0 1 auto;
  }
`;

export const StyledListItemTextPrice = styled(ListItemText)`
  && {
    padding-left: 0;
    padding-right: 16px;
    text-align: right;
    min-width: 70px;
    flex: 0 1 auto;
  }
`;

export const StyledListItemTextTotals = styled(ListItemText)`
  && {
    width: 40px;
    padding-left: 0;
    padding-right: 0;
    flex: none;
    text-align: right;
  }
`;

function OrderForm({
                       onSubmitOrderForm,
                       order, orderData,
                       orderUuid, isUpdating,
                       isSubmitting,
                       showAddProducts,
                       setOrderCustomDeliveryPrice,
                       setOrderCustomerId,
                       setOrderSellerId,
                       availablePayseraPayments,
                       availableOmnivaMachines,
                       metadataUserDeliveryMethods,
                       metadataUserPaymentMethods,
                       setOrderCustomerFirstName,
                       setOrderCustomerLastName,
                       setOrderCustomerEmail,
                       setOrderDataField,
                       updateOrderProduct,
                       removeOrderProduct,
                       metadataUserSellers,
                       metadataCustomers
}) {
    //customerId or customerFields
    //orderData.
    // firstName: '',
    // lastName: '',
    // email: '',
    // phone: '',

    const cmsForm = isArray(metadataUserSellers) && metadataUserSellers.length;
    const selectedCustomer = isArray(metadataCustomers) && metadataCustomers.length > 0 && orderData.customerId ? metadataCustomers.find((customer) => customer.id === orderData.customerId) : undefined;

    console.log('initialValues', orderData);

    return (
        <Formik
            initialValues={orderData}
            //enableReinitialize
            validationSchema={CheckoutSchema2}
            onSubmit={(values) => {
                console.log('form values', values);
                //onSubmitOrderForm(values);
            }}
            render={({
                         values,
                         errors,
                         status,
                         touched,
                         handleBlur,
                         handleChange,
                         handleSubmit,
                         setFieldValue,
                     }) => {
                //console.log('Formik values', values);
                //console.log('Formik errors', errors);

                //console.log('Formik metadataSellers', metadataSellers);
                //console.log('Formik metadataCustomers', metadataCustomers);

                return (
                    <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={3}>
                                {isArray(metadataUserSellers) && metadataUserSellers.length > 0 ? (
                                    <Paper elevation={7} style={{padding: 12, marginTop: 12, marginBottom: 36}}>
                                        <ThemeProvider theme={darkTheme}>
                                            <Paper style={{
                                                // color: '#FFF',
                                                // background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
                                                marginTop: '-24px',
                                                marginBottom: 16,
                                                padding: '4px 15px',
                                            }}>
                                                <Typography variant="h6">
                                                    1. Pardavėjas
                                                </Typography>
                                            </Paper>
                                        </ThemeProvider>
                                        <Field
                                            name="sellerId"
                                            label={"Pardavėjas"}
                                            fullWidth
                                            component={FormikCustomerSelectField}
                                            customersMetadata={metadataUserSellers}
                                            onChange={(sellerId) => {
                                                setOrderSellerId(sellerId);

                                                setFieldValue('sellerId', sellerId);
                                            }}
                                        />
                                    </Paper>
                                ) : null}
                                <Paper elevation={7} style={{padding: 12, marginTop: 12}}>
                                    <ThemeProvider theme={darkTheme}>
                                        <Paper style={{
                                            // color: '#FFF',
                                            // background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
                                            marginTop: '-24px',
                                            marginBottom: 16,
                                            padding: '4px 15px',
                                        }}>
                                            <Typography variant="h6">
                                                {cmsForm ? '2' : '1'}. Pirkėjo informacija
                                            </Typography>
                                        </Paper>
                                    </ThemeProvider>
                                    {isArray(metadataCustomers) && metadataCustomers.length > 0 ? (
                                        <React.Fragment>
                                            <Field
                                                name="customerId"
                                                label={"Pirkėjas"}
                                                fullWidth
                                                creatable
                                                component={FormikCustomerSelectField}
                                                customersMetadata={metadataCustomers}
                                                onChange={(customerId) => {
                                                    setOrderCustomerId(customerId);

                                                    setFieldValue('customerId', customerId);
                                                }}
                                            />
                                            {selectedCustomer ? (
                                                <List dense style={{
                                                    paddingBottom: 0,
                                                }}>
                                                    <ListIemKeyValue dense title={"El. paštas"} value={selectedCustomer.username} />
                                                    <ListIemKeyValue dense title={"Tel. numeris"} value={selectedCustomer.phoneNumber} />
                                                    <ListIemKeyValue dense title={"Adresas"} value={selectedCustomer.address} />
                                                    {selectedCustomer.isCompany ? (
                                                        <React.Fragment>
                                                            <ListIemKeyValue dense title={"Įmonės kodas"} value={selectedCustomer.code} />
                                                            <ListIemKeyValue dense title={"PVM mokėtojo kodas"} value={selectedCustomer.pvmCode} />
                                                        </React.Fragment>
                                                    ) : null}
                                                </List>
                                            ) : null}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Field
                                                name="customerIsCompany"
                                                component={MaterialRadioGroupButton}
                                                onChange={(e) => {
                                                    setFieldValue('customerIsCompany',  e.target.value);
                                                    setOrderDataField('customerIsCompany', e.target.value);
                                                }}
                                                options={[
                                                    {
                                                        value: '0',
                                                        label: 'Fizinis asmuo',
                                                    },
                                                    {
                                                        value: '1',
                                                        label: 'Juridinis asmuo',
                                                    },
                                                ]}
                                            />
                                            {values.customerIsCompany === "0" ? (
                                                <React.Fragment>
                                                    <Field
                                                        key="customerFirstName_1"
                                                        name="customerFirstName"
                                                        label={'Vardas'}
                                                        margin="dense"
                                                        fullWidth
                                                        component={FormMaterialTextField}
                                                        // onChange={(e) => {
                                                        //     setFieldValue('customerFirstName',  e.target.value);
                                                        //     //setOrderDataField('customerFirstName', e.target.value);
                                                        // }}
                                                    />
                                                    <Field
                                                        key="customerLastName_1"
                                                        name="customerLastName"
                                                        label={'Pavardė'}
                                                        margin="dense"
                                                        fullWidth
                                                        component={FormMaterialTextField}
                                                        onChange={(e) => setOrderDataField('customerLastName', e.target.value)}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Field
                                                        key="customerFirstName_2"
                                                        name="customerFirstName"
                                                        label={'Pavadinimas'}
                                                        margin="dense"
                                                        fullWidth
                                                        component={FormMaterialTextField}
                                                        onChange={(e) => setOrderDataField('customerFirstName', e.target.value)}
                                                    />
                                                    <Field
                                                        key="code_1"
                                                        name="customerCode"
                                                        label={'Įmonės kodas'}
                                                        margin="dense"
                                                        fullWidth
                                                        component={FormMaterialTextField}
                                                        onChange={(e) => setOrderDataField('customerCode', e.target.value)}
                                                    />
                                                    <Field
                                                        name="customerPvmCode"
                                                        label={'PVM mokėtojo kodas'}
                                                        margin="dense"
                                                        fullWidth
                                                        component={FormMaterialTextField}
                                                        onChange={(e) => setOrderDataField('customerPvmCode', e.target.value)}
                                                    />
                                                </React.Fragment>
                                            )}
                                            {/*<Field*/}
                                            {/*label="Vardas"*/}
                                            {/*margin="dense"*/}
                                            {/*variant="outlined"*/}
                                            {/*fullWidth*/}
                                            {/*name="customerFirstName"*/}
                                            {/*component={FormMaterialTextField}*/}
                                            {/*onChange={(e) => setOrderCustomerFirstName(e.target.value)}*/}
                                            {/*/>*/}
                                            {/*<Field*/}
                                            {/*label="Pavardė"*/}
                                            {/*margin="dense"*/}
                                            {/*variant="outlined"*/}
                                            {/*fullWidth*/}
                                            {/*name="customerLastName"*/}
                                            {/*component={FormMaterialTextField}*/}
                                            {/*onChange={(e) => setOrderCustomerLastName(e.target.value)}*/}
                                            {/*/>*/}
                                            <Field
                                                label="El. pašto adresas"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                                name="customerEmail"
                                                component={FormMaterialTextField}
                                                onChange={(e) => setOrderCustomerEmail(e.target.value)}
                                            />
                                            <Field
                                                label="Telefono numeris"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                                name="customerPhone"
                                                component={FormMaterialTextField}
                                                type="number"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+370</InputAdornment>,
                                                }}
                                                onChange={(e) => setOrderDataField('customerPhone', e.target.value, false)}
                                                //todo[as] onChange={(e) => setOrderCustomerEmail(e.target.value)}
                                            />
                                            {/*<Field*/}
                                            {/*label="Telefono numeris (neprivalomas laukas)"*/}
                                            {/*margin="dense"*/}
                                            {/*variant="outlined"*/}
                                            {/*fullWidth*/}
                                            {/*name="phone"*/}
                                            {/*component={FormMaterialTextField}*/}
                                            {/*/>*/}
                                        </React.Fragment>
                                    )}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Paper elevation={7} style={{padding: 16, marginTop: 12, marginBottom: 24}}>
                                    <ThemeProvider theme={darkTheme}>
                                        <Paper style={{
                                            // color: '#FFF',
                                            // background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
                                            marginTop: '-28px',
                                            marginBottom: 16,
                                            padding: '4px 15px',
                                        }}>
                                            <Typography variant="h6">
                                                {cmsForm ? '3' : '2'}. Pristatymas
                                            </Typography>
                                        </Paper>
                                    </ThemeProvider>
                                    <CheckoutDeliveryMethods
                                        metadataUserDeliveryMethods={metadataUserDeliveryMethods}
                                        availableOmnivaMachines={availableOmnivaMachines}
                                        orderData={orderData}
                                        setOrderDataField={setOrderDataField}
                                        setFieldValue={setFieldValue}
                                    />
                                    {/*<Field*/}
                                        {/*name="omnivaPickupPointId"*/}
                                        {/*component={OmnivaMachineOptions}*/}
                                        {/*// onChange={(e) => console.log('MaterialRadioGroupButton changed ', e.target.value)}*/}
                                        {/*options={availableOmnivaMachines}*/}
                                        {/*onChange={(e) => setOrderDataField('omnivaPickupPointId', e.target.value, true)}*/}
                                    {/*/>*/}
                                    {/*<FormControl component="fieldset">*/}
                                        {/*<RadioGroup*/}
                                            {/*aria-label="Gender"*/}
                                            {/*name="gender1"*/}
                                        {/*>*/}
                                            {/*<FormControlLabel value="1" control={<Radio />} label={*/}
                                                {/*<React.Fragment>*/}
                                                    {/*Atsiėmimas Panevėžio salone*/}
                                                    {/*<br/>*/}
                                                    {/*<Typography variant="caption">*/}
                                                        {/*Ramygalo g. 46-26*/}
                                                    {/*</Typography>*/}
                                                {/*</React.Fragment>*/}
                                            {/*} />*/}
                                            {/*<FormControlLabel value="1" control={<Radio />} label={*/}
                                                {/*<React.Fragment>*/}
                                                    {/*Atsiėmimas Vilniaus salone*/}
                                                    {/*<br/>*/}
                                                    {/*<Typography variant="caption">*/}
                                                        {/*Krivių g. 5*/}
                                                    {/*</Typography>*/}
                                                {/*</React.Fragment>*/}
                                            {/*} />*/}
                                            {/*<FormControlLabel value="1" control={<Radio />} label={*/}
                                                {/*<React.Fragment>*/}
                                                    {/*Atsiėmimas Omniva paštomate*/}
                                                    {/*<br/>*/}
                                                    {/*<Typography variant="caption">*/}
                                                    {/*</Typography>*/}
                                                {/*</React.Fragment>*/}
                                            {/*} />*/}
                                            {/*/!*<FormControlLabel value="male" control={<Radio />} label="Atsiėmimas Vilniaus salone" />*!/*/}
                                            {/*/!*<FormControlLabel value="other" control={<Radio />} label="Omniva paštomatas" />*!/*/}
                                        {/*</RadioGroup>*/}
                                    {/*</FormControl>*/}
                                    {/*<Button variant="outlined" fullWidth style={{*/}
                                        {/*textTransform: 'none',*/}
                                        {/*paddingLeft: 0,*/}
                                        {/*paddingRight: 8,*/}
                                        {/*justifyContent: 'start',*/}
                                    {/*}}>*/}
                                        {/*<Radio style={{padding: 8}} />*/}
                                        {/*<div style={{*/}
                                            {/*textAlign: 'left',*/}
                                        {/*}}>*/}
                                            {/*<Typography variant="subtitle2">*/}
                                                {/*Atsiėmimas Panevėžio salone*/}
                                            {/*</Typography>*/}
                                            {/*<div>*/}
                                                {/*<StoreMallDirectoryIcon fontSize="small"/>*/}
                                                {/*<Typography variant="caption">*/}
                                                    {/*Krivių g. 5*/}
                                                {/*</Typography>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</Button>*/}
                                    {/*<Button variant="outlined" fullWidth style={{*/}
                                        {/*textTransform: 'none',*/}
                                        {/*paddingLeft: 0,*/}
                                        {/*paddingRight: 8,*/}
                                        {/*justifyContent: 'start',*/}
                                        {/*marginBottom: 16,*/}
                                    {/*}}>*/}
                                        {/*<Radio style={{padding: 8}} />*/}
                                        {/*<div style={{*/}
                                            {/*textAlign: 'left',*/}
                                        {/*}}>*/}
                                            {/*<Typography variant="subtitle2">*/}
                                                {/*Atsiėmimas Panevėžio salone*/}
                                            {/*</Typography>*/}
                                            {/*<Typography variant="caption" component="div" style={{color: 'red'}}>*/}
                                                {/*<Currency value={0} />*/}
                                            {/*</Typography>*/}
                                            {/*<Typography variant="caption" component="div">*/}
                                                {/*Ramygalo g. 46-26*/}
                                            {/*</Typography>*/}
                                        {/*</div>*/}
                                    {/*</Button>*/}
                                    {/*<Button variant="outlined" fullWidth style={{*/}
                                        {/*textTransform: 'none',*/}
                                        {/*paddingLeft: 0,*/}
                                        {/*paddingRight: 8,*/}
                                        {/*justifyContent: 'start',*/}
                                        {/*marginBottom: 16,*/}
                                    {/*}}>*/}
                                        {/*<Radio style={{padding: 8}} />*/}
                                        {/*<div style={{*/}
                                            {/*textAlign: 'left',*/}
                                        {/*}}>*/}
                                            {/*<Typography variant="subtitle2">*/}
                                                {/*Atsiėmimas Vilniaus salone*/}
                                            {/*</Typography>*/}
                                            {/*<Typography variant="caption" component="div" style={{color: 'red'}}>*/}
                                                {/*<Currency value={0} />*/}
                                            {/*</Typography>*/}
                                            {/*<Typography variant="caption" component="div">*/}
                                                {/*Krivių g. 5*/}
                                            {/*</Typography>*/}
                                        {/*</div>*/}
                                    {/*</Button>*/}
                                    {/*<Button variant="outlined" fullWidth style={{*/}
                                        {/*textTransform: 'none',*/}
                                        {/*paddingLeft: 0,*/}
                                        {/*paddingRight: 8,*/}
                                        {/*justifyContent: 'start',*/}
                                    {/*}}>*/}
                                        {/*<Radio style={{padding: 8}} />*/}
                                        {/*<div style={{*/}
                                            {/*textAlign: 'left',*/}
                                        {/*}}>*/}
                                            {/*<Typography variant="subtitle2">*/}
                                                {/*Atsiėmimas Omniva paštomate*/}
                                            {/*</Typography>*/}
                                            {/*<Typography variant="caption" component="div" style={{color: 'red'}}>*/}
                                                {/*<Currency value={1.99} />*/}
                                            {/*</Typography>*/}
                                        {/*</div>*/}
                                    {/*</Button>*/}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <Paper style={{padding: 16, marginTop: 12}}>
                                    <ThemeProvider theme={darkTheme}>
                                        <Paper style={{
                                            // color: '#FFF',
                                            // background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
                                            marginTop: '-24px',
                                            marginBottom: 16,
                                            padding: '4px 15px',
                                        }}>
                                            <Typography variant="h6">
                                                {cmsForm ? '4' : '3'}. Apmokėjimas
                                            </Typography>
                                        </Paper>
                                    </ThemeProvider>
                                    <CheckoutPaymentMethods
                                        metadataUserPaymentMethods={metadataUserPaymentMethods}
                                        availablePayseraPayments={availablePayseraPayments}
                                        orderData={orderData}
                                        setOrderDataField={setOrderDataField}
                                        setFieldValue={setFieldValue}
                                    />
                                    {/*<Button variant="outlined" fullWidth style={{*/}
                                        {/*textTransform: 'none',*/}
                                        {/*paddingLeft: 0,*/}
                                        {/*paddingRight: 8,*/}
                                        {/*justifyContent: 'start',*/}
                                        {/*marginBottom: 16,*/}
                                    {/*}}>*/}
                                        {/*<Radio style={{padding: 8}} />*/}
                                        {/*<div style={{*/}
                                            {/*textAlign: 'left',*/}
                                        {/*}}>*/}
                                            {/*<Typography variant="subtitle2">*/}
                                                {/*El. Bankininkystė*/}
                                            {/*</Typography>*/}
                                            {/*<Typography variant="caption" component="div">*/}
                                                {/*Atsiskaitykite pasirinktu banku...*/}
                                            {/*</Typography>*/}
                                        {/*</div>*/}
                                    {/*</Button>*/}
                                    {/*<Button variant="outlined" color="primary" fullWidth style={{*/}
                                        {/*textTransform: 'none',*/}
                                        {/*paddingLeft: 0,*/}
                                        {/*paddingRight: 8,*/}
                                        {/*justifyContent: 'start',*/}
                                        {/*marginBottom: 16,*/}
                                    {/*}}>*/}
                                        {/*<Radio style={{padding: 8}} />*/}
                                        {/*<div style={{*/}
                                            {/*textAlign: 'left',*/}
                                        {/*}}>*/}
                                            {/*<Typography variant="subtitle2" color="primary">*/}
                                                {/*Atsiskaitymas atsiimamt produktus*/}
                                            {/*</Typography>*/}
                                            {/*<Typography variant="caption" component="div" color="primary">*/}
                                                {/*Galima atsiskaityti tik <b>grynaisiais</b> pinigais*/}
                                            {/*</Typography>*/}
                                        {/*</div>*/}
                                    {/*</Button>*/}
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{marginTop: 24}}>
                                    <ThemeProvider theme={darkTheme}>
                                        <Paper style={{
                                            // color: '#FFF',
                                            // background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
                                            margin: '-24px 12px 4px 12px',
                                            padding: '4px 15px',
                                        }}>
                                            <Typography variant="h6">
                                                4. Užsakymo peržiūra
                                            </Typography>
                                        </Paper>
                                    </ThemeProvider>
                                    {showAddProducts ? (
                                        <Paper style={{padding: 16, marginBottom: 24}}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={4}>
                                                    <WarehouseSelect
                                                        metadataWarehouses={metadata.warehouses}
                                                        metadata={metadata}
                                                        isDisabled={isUpdating || isSubmitting}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <AddProductsToOrder />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    ) : null}
                                    <List
                                        dense={true}
                                    >
                                        {order.fulfillments.map((fulfillment, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ListSubheader component="div"> {fulfillment.warehouse.name}</ListSubheader>
                                                    {fulfillment.orderProducts.map((orderProduct, orderProductIndex) => {
                                                        return (
                                                            <ListItem key={orderProductIndex + '-' + orderProduct.productId}>
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        <ImageIcon />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primaryTypographyProps={{component: 'div'}}
                                                                    secondaryTypographyProps={{component: 'div'}}
                                                                    primary={
                                                                        <React.Fragment>
                                                                            <LinkDynamic
                                                                                routerTo={getProductPath(orderProduct.productId)}
                                                                                linkHref={'/prekes/' + orderProduct.productPath}
                                                                            >
                                                                                {orderProduct.name}
                                                                            </LinkDynamic>
                                                                        </React.Fragment>
                                                                    }
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            {orderProduct.code}
                                                                            <br/>
                                                                            Kiekis: {orderProduct.amountLeft}
                                                                            <Hidden smUp implementation="css" >
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    width: '100%',
                                                                                    position: 'relative',
                                                                                    boxSizing: 'border-box',
                                                                                    textAlign: 'left',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'flex-start',
                                                                                }}>
                                                                                    <ConfirmationDialog
                                                                                        dialogContent={"Ar tikrai norite pašalinti šią prekę"}
                                                                                        confirmationFunction={() => removeOrderProduct(orderProduct.productId, fulfillment.warehouse.id)}
                                                                                    >
                                                                                        <IconButton>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </ConfirmationDialog>
                                                                                    <div style={{width: 70, marginRight: 16}}>
                                                                                        <CheckoutPageAmountChanger
                                                                                            key={orderUuid}
                                                                                            initialValue={orderProduct.amount}
                                                                                            productId={orderProduct.productId}
                                                                                            warehouseId={fulfillment.warehouse.id}
                                                                                            updateOrderProduct={updateOrderProduct}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{
                                                                                        flex: '1 0 auto',
                                                                                        textAlign: 'left'
                                                                                    }}>
                                                                                        <Typography variant="inherit" component="span" color="textPrimary">
                                                                                            todo[as]:Fix
                                                                                            <CheckoutProductPrice
                                                                                                productId={orderProduct.productId}
                                                                                                warehouseId={fulfillment.warehouseId}
                                                                                                customPrice={orderProduct.customPrice}
                                                                                                customPriceReason={orderProduct.customPriceReason}
                                                                                                price={orderProduct.cost}
                                                                                                updateOrderProduct={updateOrderProduct}
                                                                                            />
                                                                                            {/*<Currency value={orderProduct.cost}/>*/}
                                                                                        </Typography>
                                                                                        {orderProduct.price > 0 && orderProduct.cost < orderProduct.price ? (
                                                                                            <React.Fragment>
                                                                                                <s><Currency value={orderProduct.price}/></s>
                                                                                            </React.Fragment>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </Hidden>
                                                                        </React.Fragment>
                                                                    }
                                                                />

                                                                <Hidden xsDown implementation="css">
                                                                    <StyledListItemTextAmount
                                                                        primary={
                                                                            <React.Fragment>
                                                                                <ConfirmationDialog
                                                                                    dialogContent={"Ar tikrai norite pašalinti šią prekę"}
                                                                                    confirmationFunction={() => removeOrderProduct(orderProduct.productId, fulfillment.warehouse.id)}
                                                                                >
                                                                                    <IconButton>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </ConfirmationDialog>
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </Hidden>
                                                                <Hidden xsDown implementation="css">
                                                                    <StyledListItemTextAmount
                                                                        primary={
                                                                            <React.Fragment>
                                                                                <CheckoutPageAmountChanger
                                                                                    key={orderUuid}
                                                                                    initialValue={orderProduct.amount}
                                                                                    productId={orderProduct.productId}
                                                                                    warehouseId={fulfillment.warehouse.id}
                                                                                    updateOrderProduct={updateOrderProduct}
                                                                                />
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                </Hidden>
                                                                <Hidden xsDown implementation="css">
                                                                    <StyledListItemTextPrice
                                                                        primary={
                                                                            <React.Fragment>
                                                                                    <span>
                                                                                        todo[as]:Fix
                                                                                        <CheckoutProductPrice
                                                                                            productId={orderProduct.productId}
                                                                                            warehouseId={fulfillment.warehouse.id}
                                                                                            customPrice={orderProduct.customPrice}
                                                                                            customPriceReason={orderProduct.customPriceReason}
                                                                                            price={orderProduct.cost}
                                                                                            updateOrderProduct={updateOrderProduct}
                                                                                        />
                                                                                        {/*<Currency value={orderProduct.cost}/>*/}
                                                                                    </span>
                                                                            </React.Fragment>
                                                                        }
                                                                        secondary={
                                                                            orderProduct.price > 0 && orderProduct.cost < orderProduct.price ? (
                                                                                <React.Fragment>
                                                                                    <s><Currency value={orderProduct.price}/></s>
                                                                                </React.Fragment>
                                                                            ) : null
                                                                        }
                                                                    />
                                                                </Hidden>
                                                                {/*<Hidden xsDown implementation="css">*/}
                                                                <StyledListItemTextTotals
                                                                    primary={
                                                                        <React.Fragment>
                                                                            <Currency value={orderProduct.total}/>
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        })}
                                    </List>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell rowSpan={3} />
                                                <TableCell>Prekių suma</TableCell>
                                                <TableCell align="right"><Currency value={order.subTotal}/></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Pristatymas
                                                </TableCell>
                                                <TableCell align="right"><Currency value={order.deliveryPrice}/></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><b>Iš viso</b></TableCell>
                                                <TableCell align="right"><Currency value={order.total}/></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                        <br/>
                        <div style={{display: 'flex'}}>
                            <FormControlLabel
                                control={
                                    <Checkbox value="checkedA" />
                                }
                                label="Patvirtinu jog susipažinau su pirkimo taisyklėmis"
                            />
                            <span style={{flex: 1}}/>
                            <ProgressButton
                                loading={isUpdating || isSubmitting}
                                text={"Pateikti užsakymą"}
                                onClick={handleSubmit}
                                color="secondary"
                                fullWidth
                            />
                            {/*<Button variant="contained" color="primary" type="submit" disabled={isUpdating || isSubmitting}>*/}
                            {/*{orderData.id ? (*/}
                            {/*<span>Išsaugoti</span>*/}
                            {/*) : (*/}
                            {/*<span>Parduoti</span>*/}
                            {/*)}*/}
                            {/*</Button>*/}
                        </div>
                    </Form>
                )
            }}
        />
    );
}

export default OrderForm;