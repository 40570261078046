import React, {useState, useCallback, useEffect} from 'react';
import Cropper from 'react-easy-crop';
import isEmpty from 'lodash/isEmpty';

import Slider from '@material-ui/core/Slider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import CropIcon from '@material-ui/icons/Crop';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
    useImageEditorDialog,
    closeDialog,
    previousSlide,
    nextSlide,
    startCropping,
    cancelCropping,
    finishCropping,
    finishDeleting,
} from 'shared-react/utils/FormImages/ImageEditorContext';
import {getLabelByPaymentStatus} from "shared-react/utils/paymentAndDelivery";
import ProgressButton from "shared-react/utils/ProgressButton";
import {useCropImage, useDeleteProductImage} from "shared-react/api/ImageApi";
import ConfirmationDialog from "shared-react/utils/ConfirmationDialog";
import ProgressIconButton from "shared-react/utils/ProgressIconButton";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import {getImagePath, TYPE_ORIGINAL} from "shared-react/utils/images";
import {darkTheme} from "shared-react/utils/theme";

const aspectRatiosList = [
    {
        key: 'square',
        label: 'Kvadratinis',
        ratio: 1/1,
    },
    {
        key: 'carouselLandscape',
        label: 'Karuselė peizažas',
        ratio: 16/5,
    },
    {
        key: 'carouselPortrait',
        label: 'Karuselė portretas',
        ratio: 10/12,
    },
    {
        key: '16.9',
        label: '16:9',
        ratio: 16/9,
    },
    {
        key: '4.3',
        label: '4:3',
        ratio: 4/3,
    },
    {
        key: '3.4',
        label: '3:4',
        ratio: 3/4,
    },
    {
        key: '1.91.1',
        label: '1.91:1',
        ratio: 1.91/1,
    },
];

function ImageEditorDialog({imageType, allowedAspectRatios = []}) {
    const [state, dispatch] = useImageEditorDialog();
    const classes = useStyles();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    let aspectRatios = [];
    if (!isEmpty(allowedAspectRatios)) {
        aspectRatios = aspectRatiosList.filter((aspectRatio) => {
            return allowedAspectRatios.includes(aspectRatio.key);
        });
    } else {
        aspectRatios = aspectRatiosList;
    }
    const [selectedAspectRatio, setSelectedAspectRatio] = useState(isEmpty(aspectRatios) ? {key: 'default', ratio: 1, label: 'Default'} : aspectRatios[0]);

    const selectedImageName = (typeof state.images[state.imageIndex] === 'undefined' || typeof state.images[state.imageIndex].name === 'undefined') ? '' : state.images[state.imageIndex].name;
    //console.log('imageIndex', state.imageIndex, selectedImageName);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        //console.log('imageIndex2', state.imageIndex, state.images);
        setCropImageData({
            name: selectedImageName,
            x: croppedAreaPixels.x,
            y: croppedAreaPixels.y,
            width: croppedAreaPixels.width,
            height: croppedAreaPixels.height,
        });
    }, [selectedImageName, state.imageIndex, state.images.length]);

    const [cropImageData, setCropImageData] = useState({
        name: '',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });

    const [croppingData, isCropping, croppingErrors, cropImage] = useCropImage(imageType, cropImageData);
    const [deleteProductImageData, isDeleting, deleteProductImageErrors, deleteProductImage] = useDeleteProductImage(imageType, selectedImageName);

    const handleClose = () => {
        dispatch(closeDialog());
    };

    useEffect(() => {
        if (croppingData != null) {
            dispatch(finishCropping(state.imageIndex, croppingData));
        }
    }, [croppingData]);

    useEffect(() => {
        if (deleteProductImageData != null) {
            dispatch(finishDeleting(state.imageIndex));
        }
    }, [deleteProductImageData]);

    //console.log('state', state);

    if (state.images.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen={true}
                open={state.isOpen}
                onClose={() => dispatch(closeDialog())}
            >
                <AppBar style={{position: 'relative',}}>
                    <Toolbar>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                        }}>
                            <Typography variant="h6">
                                Paveikslėlių redagavimas
                            </Typography>
                            <Typography variant="caption">
                                {state.imageIndex + 1} iš {state.images.length}
                            </Typography>
                        </div>
                        {state.isCropping ? (
                            <>
                                <Button variant="outlined" color="inherit" onClick={() => dispatch(cancelCropping())} style={{marginRight: 12}}>
                                    Atšaukti
                                </Button>
                                <ProgressButton
                                    loading={isCropping}
                                    text={'Patvirtinti'}
                                    variant={"contained"}
                                    color={"secondary"}
                                    onClick={() => {
                                        cropImage();
                                    }}
                                />
                            </>
                        ) : (
                            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
                <DialogContent
                    style={{
                        padding: 50,
                        backgroundColor: '#000',

                        position: 'relative',
                        overflow: 'hidden',
                    }}
                   classes={{
                       root: classes.root,
                   }}
                >
                    {!state.isCropping ? (
                        <>
                            <IconButton color="inherit" onClick={() => dispatch(previousSlide())} style={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                transform: 'translate(0, -50%)',
                            }}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <IconButton color="inherit" onClick={() => dispatch(nextSlide())} style={{
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                transform: 'translate(0, -50%)',
                            }}>
                                <ChevronRightIcon />
                            </IconButton>
                        </>
                    ) : (
                        <div style={{
                            position: 'absolute',
                            right: 0,
                            zIndex: 99999,
                        }}>
                            <ThemeProvider theme={darkTheme}>
                                <List
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            Iškirpimas
                                        </ListSubheader>
                                    }
                                >
                                    {aspectRatios.map((aspectRatio) => (
                                        <ListItem
                                            key={aspectRatio.key}
                                            button
                                            selected={selectedAspectRatio.key === aspectRatio.key}
                                            onClick={(event) => setSelectedAspectRatio(aspectRatio)}
                                        >
                                            <ListItemText primary={aspectRatio.label} />
                                        </ListItem>
                                    ))}
                                </List>
                            </ThemeProvider>
                        </div>
                    )}
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',

                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {state.isCropping ? (
                            <Cropper
                                image={getImagePath(TYPE_ORIGINAL, state.images[state.imageIndex].folder, state.images[state.imageIndex].versionedName)}
                                crop={crop}
                                zoom={zoom}
                                aspect={selectedAspectRatio.ratio}
                                restrictPosition={true}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                style={{
                                    containerStyle: {
                                        overflow: 'visible',
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                    }}
                                    src={getImagePath(TYPE_ORIGINAL, state.images[state.imageIndex].folder, state.images[state.imageIndex].versionedName)}
                                />
                            </>
                        )}
                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        display: 'flex',
                    }}
                    classes={{
                        root: classes.root,
                    }}
                >
                    {state.isCropping ? (
                        <Slider
                            value={zoom}
                            min={1} //0.4
                            max={3}
                            step={0.05}
                            onChange={(e, zoom) => setZoom(zoom)}
                            style={{
                                color: 'inherit',
                            }}
                        />
                    ) : (
                        <>
                            <IconButton onClick={() => dispatch(startCropping())} color="inherit">
                                <CropIcon />
                            </IconButton>
                            <ResponseErrors errors={croppingErrors} />
                            <ResponseErrors errors={deleteProductImageErrors} />
                            <span style={{flex: 1}}></span>
                            <ConfirmationDialog
                                dialogContent={"Ar tikrai norite pašalinti šį paveikslėlį?"}
                                confirmationFunction={() => {
                                    //deleteProductImage();
                                    dispatch(finishDeleting(state.imageIndex));
                                }}
                            >
                                <ProgressIconButton
                                    buttonColor={'inherit'}
                                    circularColor={'inherit'}
                                    loading={isDeleting}
                                    size={'medium'}
                                />
                            </ConfirmationDialog>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default ImageEditorDialog;
