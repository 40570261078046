import React, {useEffect, useState} from 'react';

import Paper from '@material-ui/core/Paper';

import {
    ROUTE_PRODUCT_COLLECTIONS_NEW,
    getProductCollectionsPath,
    getProductCollectionPath, ROUTE_PURCHASE_ORDERS_NEW,
} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import PageTitle from "../utils/PageTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import {useGetObjects, API_ENDPOINT_PRODUCT_COLLECTIONS} from "shared-react/api/ObjectApi";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import LinkWrapper from "../utils/LinkWrapper";
import ListImageAvatar from "shared-react/shop/ListImageAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {StyledListItemText} from "shared-react/utils/ListItemText";
import StatusChipPublishedGroup from "shared-react/shop/statusChips/StatusChipPublishedGroup";
import get from "lodash/get";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
function ProductCollectionsPage({match, history}) {
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const perPage = 20;
    const queryParams = {
        page,
        perPage,
    };
    const [productCollections, totalCount, areOrdersLoading, productCollectionsErrors, fetchProductCollections] = useGetObjects(API_ENDPOINT_PRODUCT_COLLECTIONS, queryParams);

    console.log('productCollections', productCollections, productCollectionsErrors);

    useEffect(() => {
        fetchProductCollections();
    }, [page]);

    if (productCollectionsErrors.length > 0) {
        return (<ResponseErrors errors={productCollectionsErrors} />);
    }

    if (!productCollections || areOrdersLoading) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <PageTitle title={"Prekių kolekcijos"}/>
                        <span style={{flex: 1}} />
                        <Fab href={ROUTE_PRODUCT_COLLECTIONS_NEW} color="primary" aria-label="add" size="small" style={{marginLeft: 12}}>
                            <AddIcon />
                        </Fab>
                    </div>
                </>

            }>
                <Paper square elevation={0}>
                    {productCollections.length > 0 ? (
                        <>
                            <List dense={true}>
                                {productCollections.map((productCollection, index) => (
                                    <ListItem
                                        key={index}
                                        button to={getProductCollectionPath(productCollection.id)}
                                        component={LinkWrapper}
                                    >
                                        <ListItemText
                                            primary={productCollection.title}
                                        />
                                        <StyledListItemText
                                            gutterLeft
                                            flex={false}
                                            primary={
                                                <React.Fragment>
                                                    <StatusChipPublishedGroup publishedAt={get(productCollection, 'publishedAt', null)}/>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                        <>
                            Nėra pridėtų kolekcijų
                        </>
                    )}
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default ProductCollectionsPage;