import React, {useEffect} from 'react';

import ProductCollectionForm from "productCollections/ProductCollectionForm";
import PageTitle from "utils/PageTitle";
import LinkBack from "utils/LinkBack";
import {getProductCollectionPath, getProductCollectionsPath} from 'shared-react/utils/routes';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import useFormikForm from "shared-react/formik/useFormikForm";
import {getObjectMethod, getObjectUrl, useGetObject, API_ENDPOINT_PRODUCT_COLLECTIONS} from "shared-react/api/ObjectApi";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import datefnsFormat from "date-fns/format";
import {parseFormDate} from "shared-react/utils/form";
import {useMetadata} from "shared-react/metadata/MetadataContext";
import get from "lodash/get";
import StatusChipPublishedGroup from "shared-react/shop/statusChips/StatusChipPublishedGroup";

const metadataToLoadNames = ['productCollectionFormSchema'];

const defaultProductCollection = {
    id: null,
    title: "",
    publishedAt: null,
    collectionProducts: [],
};

function ProductCollectionAddEditPage({match, history}) {
    const productCollectionId = match.params.id;
    const [productCollection, setProductCollection, isProductCollectionLoading, productCollectionErrors, fetchProductCollection] = useGetObject(API_ENDPOINT_PRODUCT_COLLECTIONS, productCollectionId);
    const {metadataState, metadataDispatch, hasMetadata} = useMetadata(metadataToLoadNames);
    const initialFormData = productCollection ? {
        defaultProductCollection,
        ...productCollection,
        publishedAt: get(productCollection, 'publishedAt', null) != null ? parseFormDate(productCollection.publishedAt) : null,
    } : {
        ...defaultProductCollection,
    };

    const onSaveDataTransform = (values) => {
        return {
            ...values,
            publishedAt: values.publishedAt != null ? datefnsFormat(values.publishedAt, 'yyyy-MM-dd') : null,
        };
    };

    const [savedProductCollection, ProductCollectionFormikWrapper, productCollectionFormProps, ProductCollectionFormErrors, productCollectionFormErrorsProps, ProductCollectionFormSubmitButton, productCollectionFormSubmitButtonProps] = useFormikForm({
        method: getObjectMethod(initialFormData),
        url: getObjectUrl(API_ENDPOINT_PRODUCT_COLLECTIONS, initialFormData),
        formData: initialFormData,
        formSchema: get(metadataState, 'metadata.productCollectionFormSchema', null) ? get(metadataState, 'metadata.productCollectionFormSchema', null) : null,
        formValidationGroup: 'productCollectionPostPut',
        submitButtonText: 'Išsaugoti',
        onSaveDataTransform: onSaveDataTransform,
    });

    useEffect(() => {
        if (productCollectionId) {
            fetchProductCollection();
        }
    }, []);

    useEffect(() => {
        if (savedProductCollection) {
            history.push(getProductCollectionsPath());
        }
    }, [savedProductCollection]);

    if ((productCollectionId && !productCollection) || !hasMetadata(metadataToLoadNames)) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <ProductCollectionFormikWrapper {...productCollectionFormProps}>
                {({values, hasValidationErrors}) => {
                    return (
                        <FillHeightContainer header={
                            <>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: 54,
                                    padding: '0 16px',
                                }}>
                                    <LinkBack to={getProductCollectionsPath()} text={"Atgal"}/>
                                    <PageTitle title={productCollectionId ? productCollection.title : "Nauja"} gutterBottom/>
                                    <StatusChipPublishedGroup publishedAt={values.publishedAt != null ? datefnsFormat(values.publishedAt, 'yyyy-MM-dd') : null}/>
                                    <span style={{flex: 1}} />
                                    <ProductCollectionFormSubmitButton {...productCollectionFormSubmitButtonProps}/>
                                </div>
                                {hasValidationErrors ? (
                                    <ResponseErrors errors={['Ne visi laukai teisingai užpildyti']}/>
                                ) : null}
                                <ResponseErrors errors={productCollectionErrors} />
                                <ProductCollectionFormErrors {...productCollectionFormErrorsProps}/>
                            </>
                        }>
                            <div style={{margin: 24}}>
                                <ProductCollectionForm />
                            </div>
                        </FillHeightContainer>
                   );
                }}
            </ProductCollectionFormikWrapper>
        </React.Fragment>
    );
}

export default ProductCollectionAddEditPage;