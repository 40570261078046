import qs from "qs";
import {useMakeRequest, API_URL} from './Api';

const API_PRODUCT_TRANSFERS = '/product-transfers';

export function useGetProductTransfers(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);

    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_PRODUCT_TRANSFERS + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetProductTransfer(productTransferId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_PRODUCT_TRANSFERS + '/' + productTransferId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveProductTransfer(productTransferData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (productTransferData ? productTransferData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_PRODUCT_TRANSFERS + url, productTransferData);

    return [ data, isLoading, errors, doRequest ];
}