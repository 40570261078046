import React, {useEffect, useState} from 'react';

import {useDeleteOmnivaParcel} from 'shared-react/api/OmnivaApi';
import ConfirmationDialog from "shared-react/utils/ConfirmationDialog";
import ProgressIconButton from "shared-react/utils/ProgressIconButton";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";

function DeleteOmnivaParcel({ omnivaParcel, onDeleted }) {
    const [deletedOmnivaLabel, isDeleting, errors, deleteParcel] = useDeleteOmnivaParcel(omnivaParcel.id);

    useEffect(() => {
        if (deletedOmnivaLabel && typeof onDeleted === 'function') {
            onDeleted(deletedOmnivaLabel);
        }
    }, [deletedOmnivaLabel]);

    return (
        <React.Fragment>
            <PermissionRequiredComponent permission={permissions.PERMISSION_INVOICES_CREATE}>
                <ResponseErrors errors={errors} />
                <ConfirmationDialog
                    dialogContent={"Ar tikrai norite pašalinti šį Omniva lipduką?"}
                    confirmationFunction={() => {
                        deleteParcel();
                    }}
                >
                    <ProgressIconButton
                        loading={isDeleting}
                    />
                </ConfirmationDialog>
            </PermissionRequiredComponent>
        </React.Fragment>
    );
}

export default DeleteOmnivaParcel;