import React from 'react';

import ProductTransferForm from 'productTransfers/ProductTransferForm'
import PageTitle from "utils/PageTitle";
import LinkBack from "../utils/LinkBack";
import {getProductTransfersPath} from 'shared-react/utils/routes';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

function ProductTransferAddPage() {
    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 54,
                    padding: '0 16px',
                }}>
                    <LinkBack to={getProductTransfersPath()} text={"Atgal"}/>
                    <PageTitle title={"Naujas pervežimas"} gutterBottom/>
                </div>
                </>
            }>
                <div style={{padding: 12}}>
                    <ProductTransferForm
                        isNew={true}
                        productTransfer={{}}
                        onSaveSuccess={() => {}}
                    />
                </div>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default ProductTransferAddPage;