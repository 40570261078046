import React, {useEffect, useState, useRef} from 'react';
import {useFormikContext} from 'formik';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormBuilder from "shared-react/formik/FormBuilder/FormBuilder";
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import './hideEditorFileUploading.css';

// import '@ckeditor/ckeditor5-build-classic';
// import '@ckeditor/ckeditor5-build-classic/build/ckeditor.js';

import Typography from "@material-ui/core/Typography";
import StatusChipPublishedGroup from "shared-react/shop/statusChips/StatusChipPublishedGroup";
import NewsletterEditor from "website/news/NewsletterEditor";
import ArticleEditor from "website/news/ArticleEditor";
import datefnsFormat from "date-fns/format";
import "utils/grapesjs/rte/rteStyles.css";

// const myNewComponentTypes = editor => {
//     editor.DomComponents.addType('mjml-catherine-header', {
//         // Make the editor understand when to bind `my-input-type`
//         // isComponent: el => el.tagName === 'INPUT',
//         // Model definition
//         model: {
//             // Default properties
//             defaults: {
//                 // tagName: 'mjml-catherine-header',
//                 // draggable: 'form, form *', // Can be dropped only inside `form` elements
//                 droppable: false, // Can't drop other elements inside
//                 // attributes: { // Default attributes
//                 //     type: 'text',
//                 //     name: 'default-name',
//                 //     placeholder: 'Insert text here',
//                 // },
//                 components: [
//                     {
//                         type: 'image',
//                         attributes: { src: 'https://path/image' },
//                     }, {
//                         tagName: 'span',
//                         type: 'text',
//                         attributes: { title: 'foo' },
//                         components: [{
//                             type: 'textnode',
//                             content: 'Hello world!!!'
//                         }]
//                     }
//                 ]
//             }
//         }
//     });
//
//     editor.BlockManager.add('mjml-catherine-header', {
//         label: 'Test Component',
//         content: '<div data-gjs-type="mjml-catherine-header"></div>',
//     });
// };

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tab: {
        width: '100%',
        maxWidth: '100%',
    },
}));

// let CKEditor;
// let ClassicEditor;

function NewsForm() {
    const { values, isSubmitting } = useFormikContext();
    const [showEditor, setShowEditor] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();
    const [editorsLoaded, setEditorsLoaded] = useState(false);

    // useEffect(() => {
    //     CKEditor = require("@ckeditor/ckeditor5-react");
    //     ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
    //     setEditorsLoaded(true);
    // }, []);
    //
    // if (!editorsLoaded) {
    //     return null;
    // }

    //console.log('values', values);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder name={'title'} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder
                            name={'image'}
                            imageType={'news'}
                            allowedAspectRatios={['1.91.1']}
                            forceCropping={true}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Paper>
                        <Tabs
                            orientation="vertical"
                            variant="standard"
                            value={activeTab}
                            onChange={(e, newValue) => {
                                setActiveTab(newValue);
                            }}
                            aria-label="Vertical tabs"
                            indicatorColor="primary"
                            className={classes.tabs}
                        >
                            <Tab
                                label={
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <div>Straipsnis</div>
                                            <Typography variant="caption" display="block" color="textSecondary" style={{
                                                position: 'relative',
                                            }}>
                                                <StatusChipPublishedGroup publishedAt={values.article.publishedAt != null ? datefnsFormat(values.article.publishedAt, 'yyyy-MM-dd') : null}/>
                                            </Typography>
                                        </div>
                                    </>
                                }
                                className={classes.tab}
                            />
                            <Tab
                                label={
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <div>Naujienlaiškis</div>
                                            <Typography variant="caption" display="block" color="textSecondary" style={{
                                                position: 'relative',
                                            }}>
                                                <StatusChipPublishedGroup publishedAt={values.newsletter.publishedAt != null ? datefnsFormat(values.newsletter.publishedAt, 'yyyy-MM-dd') : null}/>
                                            </Typography>
                                        </div>
                                    </>
                                }
                                className={classes.tab}
                            />
                            <Tab
                                disabled
                                label={
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <div>Facebook</div>
                                            <Typography variant="caption" display="block" color="textSecondary" style={{
                                                position: 'relative',
                                            }}>
                                                <StatusChipPublishedGroup publishedAt={null}/>
                                            </Typography>
                                        </div>
                                    </>
                                }
                                className={classes.tab}
                            />
                            <Tab
                                disabled
                                label={
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <div>Instagram</div>
                                            <Typography variant="caption" display="block" color="textSecondary" style={{
                                                position: 'relative',
                                            }}>
                                                <StatusChipPublishedGroup publishedAt={null}/>
                                            </Typography>
                                        </div>
                                    </>
                                }
                                className={classes.tab}
                            />
                        </Tabs>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={9} style={{
                    display: activeTab === 0 ? 'block' : 'none',
                }}>
                    <Paper style={{padding: 16}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormBuilder name={'article.publishedAt'} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormBuilder name={'article.shortContent'} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} style={{
                    display: activeTab === 0 ? 'block' : 'none',
                }}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder
                            name={'article.images'}
                            imageType={'newsArticle'}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} style={{
                    display: activeTab === 0 ? 'block' : 'none',
                }}>
                    <Paper style={{padding: 16}}>
                        <div>
                            <ArticleEditor />
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={9} style={{
                    display: activeTab === 1 ? 'block' : 'none',
                }}>
                    <Paper style={{padding: 16}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormBuilder name={'newsletter.publishedAt'} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} style={{
                    display: activeTab === 1 ? 'block' : 'none',
                }}>
                    <Paper style={{padding: 16}}>
                        <FormBuilder
                            name={'newsletter.images'}
                            imageType={'newsNewsletter'}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} style={{
                    display: activeTab === 1 ? 'block' : 'none',
                }}>
                    <Paper style={{padding: 16}}>
                        <NewsletterEditor />
                    </Paper>
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default NewsForm;