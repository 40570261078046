import React, {useState} from "react";
import isObject from "lodash/isObject";
import { Field, FastField } from 'formik';

import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import FormError from '../formik/FormError';

const OmnivaMachineOptions = ({
    options,
    defaultValue = '',
    defaultCountry = 'LT',
    allowCountrySelect = true,
    setOrderDataField,
}) => {
    const inputLabelCountry = React.useRef(null);
    const inputLabelCounty = React.useRef(null);
    const inputLabelMachine = React.useRef(null);
    const [labelWidthCountry, setLabelWidthCountry] = React.useState(0);
    const [labelWidthCounty, setLabelWidthCounty] = React.useState(0);
    const [labelWidthMachine, setLabelWidthMachine] = React.useState(0);
    const [selectedCountry, setSelectedCountry] = React.useState(defaultCountry);
    const [selectedCounty, setSelectedCounty] = React.useState("");

    React.useEffect(() => {
        if (inputLabelCountry.current) {
            setLabelWidthCountry(inputLabelCountry.current.offsetWidth);
        }
        if (inputLabelCounty.current) {
            setLabelWidthCounty(inputLabelCounty.current.offsetWidth);
        }
        if (inputLabelMachine.current) {
            setLabelWidthMachine(inputLabelMachine.current.offsetWidth);
        }


    }, [selectedCounty]);

    React.useEffect(() => {
        // if parcel machine default value is set, figure out country and county values
        //console.log('omnivaMachine options defaultValue, options', defaultValue, options);
        if (defaultValue) {
            Object.keys(options).forEach(function(countryKey) {
                Object.keys(options[countryKey]).forEach(function(countyKey) {
                    options[countryKey][countyKey].forEach(function(option) {
                        if (typeof option['ZIP'] !== 'undefined' && option['ZIP'] == defaultValue) {
                            setSelectedCountry(countryKey);
                            setSelectedCounty(countyKey);
                        }
                    });
                });
            });
        }
    }, []);

    if (isObject(options) && Object.keys(options).length > 0) {
        const countries = Object.keys(options);
        const counties = selectedCountry ? Object.keys(options[selectedCountry]) : [];

        return (
            <div>
                {allowCountrySelect ? (
                    <FormControl margin="dense" variant="outlined" fullWidth style={{margin: '8px 0'}}>
                        <InputLabel ref={inputLabelCountry} htmlFor="pickup-country">
                            Pasirinkite šalį
                        </InputLabel>
                        <Select
                            value={selectedCountry}
                            onChange={(e) => {
                                setOrderDataField('omnivaPickupPointId', '', false);
                                setSelectedCounty('');
                                setSelectedCountry(e.target.value)
                            }}
                            input={<OutlinedInput margin="dense" labelWidth={labelWidthCountry} id="pickup-country" />}
                        >
                            {countries.map((option, index) => (
                                <MenuItem value={option} key={index}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}
                <FormControl margin="dense" variant="outlined" fullWidth style={{margin: '8px 0'}}>
                    <InputLabel ref={inputLabelCounty} htmlFor="pickup-county">
                        Pasirinkite savivaldybę
                    </InputLabel>
                    <Select
                        value={selectedCounty}
                        onChange={(e) => {
                            setOrderDataField('omnivaPickupPointId', '', false);
                            setSelectedCounty(e.target.value);
                        }}
                        input={<OutlinedInput margin="dense" labelWidth={labelWidthCounty} id="pickup-county" />}
                    >
                        {selectedCountry && counties.map((option, index) => (
                            <MenuItem value={option} key={index}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/*{!selectedCounty ? (*/}
                    {/*<span>Norėdami pasirinkti paštomatą, pirma turite pasirinkti savivaldybę aukščiau esantčiame lauke</span>*/}
                {/*) : null}*/}

                <Field
                    name="omnivaPickupPointId"
                >
                    {({ field, form, meta }) => {
                        console.log('value', field, form);
                        return (
                            <>
                                {selectedCountry && selectedCounty && options[selectedCountry][selectedCounty].length > 0 ? (
                                    <>
                                        <FormControl margin="dense" variant="outlined" disabled={!selectedCounty} fullWidth style={{margin: '8px 0'}}>
                                            <InputLabel ref={inputLabelMachine} htmlFor="pickup-point">
                                                Pasirinkite paštomatą
                                            </InputLabel>
                                            <Select
                                                value={field.value}
                                                onChange={(e) => {
                                                    //setSelectedMachine(e.target.value);
                                                    setOrderDataField('omnivaPickupPointId', e.target.value, false);
                                                }}
                                                input={<OutlinedInput margin="dense" labelWidth={labelWidthMachine} id="pickup-point" name={field.name} />}
                                            >
                                                {options[selectedCountry][selectedCounty].map((option, index) => (
                                                    <MenuItem value={option['ZIP']} key={index} style={{
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                    }}>
                                                        <Typography variant="inherit" noWrap>
                                                            {option['NAME']}
                                                        </Typography>
                                                        <Typography variant="caption" display="block">{option['A2_NAME']}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </>
                                ) : null}
                                <FormError name="omnivaPickupPointId" />
                            </>
                        );
                    }}
                </Field>
            </div>
        )
    } else {
        return (
            <span>Nėra aktyvuotų Omniva paštomatų</span>
        )
    }
};

//export default Header;
export default props => <OmnivaMachineOptions {...props} />;