import qs from "qs";
import axios from "axios";
import {API_URL, getAuthTokenHeaders, useMakeRequest} from './Api';

const API_PRODUCT_CATEGORIES_URL = '/product-categories';
const API_PRODUCT_CATEGORIES_TREE_URL = '/product-categories-tree';

export function useGetProductCategories(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_PRODUCT_CATEGORIES_URL + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useSaveProductCategory(productCategoryData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (productCategoryData ? productCategoryData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_PRODUCT_CATEGORIES_URL + url, productCategoryData);

    return [ data, isLoading, errors, doRequest ];
}

export function useGetProductCategoriesTree() {
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_PRODUCT_CATEGORIES_TREE_URL);

    return [ data, totalCount, isLoading, errors, doRequest ];
}


export function useSaveProductCategoriesTree(productCategoriesTreeData) {
    const method = 'put';
    const data = {
        productCategories: productCategoriesTreeData,
    };
    const { doRequest } =  useMakeRequest(method, API_URL + API_PRODUCT_CATEGORIES_TREE_URL, data);

    return [ doRequest ];
}

export function getProductCategories(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);
    const authTokenHeaders = getAuthTokenHeaders();

    return axios.get(
        API_URL + API_PRODUCT_CATEGORIES_URL + queryString,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function getProductCategory(productCategoryId) {
    const authTokenHeaders = getAuthTokenHeaders();
    return axios.get(
        API_URL + API_PRODUCT_CATEGORIES_URL + '/' + productCategoryId,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function saveProductCategory(isNew, productCategory) {
    let method = 'post';
    let url = API_URL + API_PRODUCT_CATEGORIES_URL;
    const authTokenHeaders = getAuthTokenHeaders();

    if (!isNew) {
        method = 'put';
        url = url + '/' + productCategory.id;
    }

    return axios.request({
        url: url,
        method: method,
        headers: {...authTokenHeaders},
        data: {...productCategory},
    }).then((response) => {
        return response.data;
    });
}