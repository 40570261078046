export const defaultImage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+';
import {setupClassTrait} from 'utils/grapesjs/traits';

export const FancyImageBlock = (bm) => {
    bm.add('fancy-image-container').set({
        label: 'Image',
        attributes: { class: 'fa fa-picture-o' },
        category: 'Other',
        content: {
            type: 'fancy-image-container',
        }
    });
};

export const fancyImageContainerWidthPrefix = 'fancy-image-container-width-';
export const fancyImageContainerWidths = ['empty', 'full'];

export const FancyImageComponent = (domc, editor) => {
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;

    loadCss(editor);

    domc.addType('fancy-image-container', {
        model: defaultModel.extend({
            defaults: Object.assign({}, defaultModel.prototype.defaults, {
                'custom-name': 'FancyImageContainer',
                tagName: 'div',
                draggable: true,
                droppable: false,
                editable: true,
                classes: ['fancy-image-container'],
                components: [
                    {
                        type: 'fancy-image',
                    },
                    {
                        type: 'fancy-image-background',
                    },
                ],
                traits: [
                    {
                        id: 'imageWidth',
                        type: 'select',
                        name: 'imageWidth',
                        label: 'Image Width',
                        changeProp: 1,
                        options: fancyImageContainerWidths.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule !== 'empty' ? rule : 'Regular',
                        })),
                    }
                ],
                imageWidth: 'full',
                // attributes: { fullWidth: true },
                toolbar: [
                    {
                        attributes: {class: 'fa fa-arrows-h'},
                        command: (editor) => {
                            const component = editor.getSelected();
                            if (component) {
                                const currentValue = component.get('imageWidth') || '';
                                const newValue = currentValue !== 'empty' ? 'empty' : 'full';

                                component.set('imageWidth', newValue);
                            }
                        },
                    },
                ],
            }),
            init() {
                setupClassTrait(this, 'imageWidth', fancyImageContainerWidths, fancyImageContainerWidthPrefix, 'full');
            },
        }, {
            isComponent(el) {
                if(el && el.classList && el.classList.contains('fancy-image-container')) {
                    return {type: 'fancy-image-container'};
                }
            }
        }),
        //view: defaultView,
        view: defaultView.extend({
            tagName: 'div',
            events: {
                dblclick: 'onActive',
                click: 'innerElClick',
                load: 'onLoad',
                dragstart: 'noDrag'
            },

            innerElClick(ev) {
                console.log('click');
            },

            onActive(ev) {
                ev && ev.stopPropagation();

                console.log(editor, 'this.model', this.model);
                //var em = this.opts.config.em;
                //var editor = em ? em.get('Editor') : '';

                if (editor && this.model.get('editable')) {
                    console.log('this.model', this.model);

                    const fancyImageSearch = this.model.findType('fancy-image');
                    const fancyImageBackgroundSearch = this.model.findType('fancy-image-background');

                    let fancyImage;
                    let fancyImageBackground;

                    if (fancyImageSearch.length === 1) {
                        fancyImage = fancyImageSearch[0];
                    }

                    if (fancyImageBackgroundSearch.length === 1) {
                        fancyImageBackground = fancyImageBackgroundSearch[0];
                    }

                    editor.runCommand('open-assets', {
                        target: null,
                        types: ['image'],
                        accept: 'image/*',
                        onSelect(image) {
                            const imageSrc = image.get('src');

                            if (imageSrc && fancyImage) {
                                fancyImage.setAttributes({ src: imageSrc });
                            }

                            if (imageSrc && fancyImageBackground) {
                                fancyImageBackground.setStyle({ 'background-image': `url(${imageSrc})` });
                            }
                            //console.log('onSelect', editor, image, image.get('src'));
                            editor.Modal.close();
                            editor.AssetManager.setTarget(null);
                        }
                    });
                }
            },

            onLoad() {
                console.log('on load');
                // Used to update component tools box (eg. toolbar, resizer) once the image is loaded
                this.em.trigger('change:canvasOffset');
            },

            noDrag(ev) {
                ev.preventDefault();
                return false;
            },

            // render() {
            //     this.renderAttributes();
            //     //this.updateSrc();
            //     //const { $el, model } = this;
            //     //const cls = $el.attr('class') || '';
            //     //!model.get('src') && $el.attr('class', `${cls} ${this.classEmpty}`.trim());
            //     this.postRender();
            //
            //     return this;
            // }
        }),
    });

    domc.addType('fancy-image', {
        model: defaultModel.extend({
            defaults: Object.assign({}, defaultModel.prototype.defaults, {
                'custom-name': 'FancyImage',
                tagName: 'img',
                draggable: false,
                droppable: false,
                selectable: false,
                hoverable: false,
                classes: ['fancy-image'],
                attributes: {
                    src: defaultImage,
                }
            })
        }, {
            isComponent(el) {
                if(el && el.classList && el.classList.contains('fancy-image')) {
                    return {type: 'fancy-image'};
                }
            }
        }),
        view: defaultView,
    });

    domc.addType('fancy-image-background', {
        model: defaultModel.extend({
            defaults: Object.assign({}, defaultModel.prototype.defaults, {
                'custom-name': 'FancyImageBackground',
                tagName: 'div',
                draggable: false,
                droppable: false,
                selectable: false,
                hoverable: false,
                classes: ['fancy-image-background'],
            })
        }, {
            isComponent(el) {
                if(el && el.classList && el.classList.contains('fancy-image-background')) {
                    return {type: 'fancy-image-background'};
                }
            }
        }),
        view: defaultView,
    });
}

function loadCss(editor) {
    editor.CssComposer.setRule('.fancy-image-container', {
        position: 'relative',
        'text-align': 'center',
        display: 'inline-block',
        // 'max-height': '100%',
    });
    editor.CssComposer.setRule('.fancy-image-container.fancy-image-container-width-full', {
        width: '100%',
    });
    editor.CssComposer.setRule('.fancy-image-container.fancy-image-container-width-full .fancy-image', {
        width: '100%',
    });
    editor.CssComposer.setRule('.fancy-image', {
        'box-shadow': '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        'border-radius': '6px',
        position: 'relative',
        'max-width': '100%',
        // 'max-height': '100%',
        'z-index': 1,
    });
    editor.CssComposer.setRule('.fancy-image-background', {
        top: '12px',
        width: '100%',
        'max-width': '100%',
        filter: 'blur(12px)',
        height: '100%',
        opacity: 1,
        position: 'absolute',
        transform: 'scale(0.94)',
        transition: 'opacity .45s',
        'background-size': 'cover',
    });
}