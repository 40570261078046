import React, {useEffect, useState} from 'react';
import { useFormikContext, FieldArray } from 'formik';

import Grid from '@material-ui/core/Grid';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function UserFormPriceGroupField({metadata}) {
    const { values } = useFormikContext();

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Kliento kainų grupės</FormLabel>
                    <FieldArray
                        name="userGroups"
                        render={arrayHelpers => (
                            <div>
                                {metadata.userGroups.map(userGroup => (
                                    <div key={'userGroup' + userGroup.id}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.userGroups.includes(userGroup.id)}
                                                        onChange={e => {
                                                            if (e.target.checked) arrayHelpers.push(userGroup.id);
                                                            else {
                                                                const idx = values.userGroups.indexOf(userGroup.id);
                                                                arrayHelpers.remove(idx);
                                                            }
                                                        }}
                                                        value={String(userGroup.id)}
                                                    />
                                                }
                                                label={userGroup.title}
                                            />
                                        </FormGroup>
                                    </div>
                                ))}
                            </div>
                        )}
                    />
                </FormControl>
            </Grid>
        </React.Fragment>
    );
}

export default UserFormPriceGroupField;
