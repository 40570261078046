import React, {useEffect} from 'react';

import {useGetPurchaseOrders} from "shared-react/api/PurchaseOrderApi";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LinkWrapper from 'utils/LinkWrapper';
import {ListItemPriceText} from 'utils/List'
import ListTablePagination from "utils/ListTablePagination";
import Currency from 'shared-react/utils/Currency';

import {ROUTE_PURCHASE_ORDERS_NEW, getPurchaseOrdersPath, getPurchaseOrderPath} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import PageTitle from "../utils/PageTitle";

function PurchaseOrdersPage({match, history}) {
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const perPage = 20;
    const [purchaseOrders, totalCount, areOrdersLoading, purchaseOrdersErrors, fetchPurchaseOrders] = useGetPurchaseOrders(perPage, page);

    useEffect(() => {
        fetchPurchaseOrders();
    }, [page]);

    if (!purchaseOrders && areOrdersLoading) { // loading the page
        return (<DataLoadingIndicator />);
    } else if (!purchaseOrders) { //errors
        return (<ResponseErrors errors={purchaseOrdersErrors} />);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 54,
                    padding: '0 16px',
                }}>
                    <PageTitle title={"Užsakymai"}/>
                    <span style={{flex: 1}} />
                    <Fab href={ROUTE_PURCHASE_ORDERS_NEW} color="primary" aria-label="add" size="small" style={{marginLeft: 12}}>
                        <AddIcon />
                    </Fab>
                </div>
                </>
            }>
                <Paper elavation={0} square>
                    <List dense={true}>
                        {purchaseOrders.map((purchaseOrder) =>
                            <ListItem
                                key={purchaseOrder.id}
                                button
                                to={getPurchaseOrderPath(purchaseOrder.id)}
                                component={LinkWrapper}
                            >
                                <ListItemText
                                    primary={purchaseOrder.title}
                                    secondary={purchaseOrder.receivedAt}
                                />
                                <ListItemPriceText>
                                    <Currency value={purchaseOrder.total} />
                                </ListItemPriceText>
                            </ListItem>
                        )}
                    </List>
                    <ListTablePagination
                        perPage={perPage}
                        totalCount={totalCount}
                        page={page}
                        onChangePage={(event, page) => {
                            history.push(getPurchaseOrdersPath(page));
                        }}
                    />
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default PurchaseOrdersPage;