import React from 'react';

import Hidden from '@material-ui/core/Hidden';

import StatusChipPayment from 'shared-react/shop/statusChips/StatusChipPayment';

function StatusChips({order}) {
    return (
        <>
            <Hidden xsDown>
                <StatusChipPayment paymentStatus={order.paymentStatus}/>
            </Hidden>
            <Hidden smUp>
                <StatusChipPayment paymentStatus={order.paymentStatus} small={true}/>
            </Hidden>
        </>
    );
}

export default StatusChips;