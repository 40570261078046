import React, {useEffect, useState} from 'react';

import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import PageTitle from "../utils/PageTitle";
import PrintReceiptForm from "./PrintReceiptForm";

function PrintReceiptPage({match, history, location}) {
    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        padding: 8,
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                        height: '100%',
                    }}>
                        <PageTitle
                            title={"Spausdinti kvitą"}
                        />
                    </div>
                </>
            }>
                <div style={{padding: 12}}>
                    <PrintReceiptForm
                        isNew={true}
                        purchaseOrder={{}}
                        onSaveSuccess={() => {}}
                    />
                </div>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default PrintReceiptPage;