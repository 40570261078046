import React, {useEffect} from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';

import UserForm, {customerSchema} from './UserForm';
import {getUserMethod, getUserUrl, useGetUser} from "shared-react/api/UserApi";
import {parseUserToFormData} from "../schemas/user";
import useFormikForm from "../formik/useFormikForm";
import DataLoadingIndicator from "../utils/DataLoadingIndicator";
import AddEditCustomerDialogUserForm from "./AddEditCustomerDialogUserForm";

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
});

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function AddEditCustomerDialog({isOpen, submitErrors, onCloseDialog, onSaveUser, userId = null}) {
    const onClose = () => {
        if (typeof onCloseDialog === 'function') {
            onCloseDialog();
        }
    };

    const [user, setUser, isUserLoading, userErrors, fetchUser] = useGetUser(userId);

    useEffect(() => {
        if (isOpen && userId) {
            fetchUser();
        }
    }, [isOpen, userId]);

    const initialFormData = parseUserToFormData(user ? user : {});

    return (
        <React.Fragment>
            <Dialog
                maxWidth={'xs'}
                fullWidth
                open={isOpen}
                onClose={onClose}
            >
                {userId && !user ? (
                    <DataLoadingIndicator />
                ) : (
                    <AddEditCustomerDialogUserForm
                        initialFormData={initialFormData}
                        onSaveUser={onSaveUser}
                        onClose={onClose}
                    />
                )}

            </Dialog>
        </React.Fragment>
    );
}

export default withStyles(styles)(AddEditCustomerDialog);