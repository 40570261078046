import React from 'react';

import {useTheme} from "@material-ui/styles";

import StatusChipInvoiceGroup from 'shared-react/shop/statusChips/StatusChipInvoiceGroup';
import StatusChipPaymentGroup from 'shared-react/shop/statusChips/StatusChipPaymentGroup';
import StatusChipFulfillmentGroup from 'shared-react/shop/statusChips/StatusChipFulfillmentGroup';

function StatusChips({order, gutterBottom = false}) {
    const theme = useTheme();
    const gutterBottomMargin = gutterBottom ? theme.spacing(1) : 0;

    return (
        <div style={{marginBottom: gutterBottomMargin}}>
            <StatusChipInvoiceGroup order={order} />
            <StatusChipPaymentGroup order={order} />
            <StatusChipFulfillmentGroup order={order} />
        </div>
    );
}

export default StatusChips;