import React from 'react';
import {Field, useFormikContext} from "formik";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import ProgressButton from "shared-react/utils/ProgressButton";
import {useSaveVariationOption} from "shared-react/api/VariationOptionApi";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import {FormMaterialAutocomplete} from "shared-react/formik/FormikMaterialFields";
import {useMetadataDispatch, addMetadata} from "shared-react/metadata/MetadataContext";

const filter = createFilterOptions();

function VariationOption({index, variationOptions, alreadySelected}) {
    const { setFieldValue } = useFormikContext();
    const [dialogValue, setDialogValue] = React.useState({
        name: '',
    });
    const [open, toggleOpen] = React.useState(false);
    const [savedVariationOption, isSubmitting, errors, saveOptionVariation] = useSaveVariationOption(dialogValue, true);
    const metadataDispatch = useMetadataDispatch();

    const handleClose = () => {
        setDialogValue({
            name: '',
        });

        toggleOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // setValue({
        //     title: dialogValue.title,
        //     year: parseInt(dialogValue.year, 10),
        // });

        saveOptionVariation();
    };

    React.useEffect(() => {
        if (savedVariationOption) {
            metadataDispatch(addMetadata('variationOptions', savedVariationOption));
            setFieldValue(`variationOptions[${index}]`, savedVariationOption);
            handleClose();
        }
    }, [savedVariationOption]);

    return (
        <React.Fragment>
            <Field
                name={`variationOptions[${index}]`}
                label="Pavadinimas (pvz.: Dydis, Spalva)"
                options={variationOptions}
                optionLabelField={'name'}
                fullWidth
                component={FormMaterialAutocomplete}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                onChange={(event, newValue) => {
                    //console.log('onChange', event, newValue);

                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                name: newValue,
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            name: newValue.inputValue,
                        });
                    } else {
                        setFieldValue(`variationOptions[${index}]`, newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const filteredChosenVariationValues = filtered.filter((variationOption) => {

                        if (variationOption !== null) {
                            const foundSelected = alreadySelected.find((selected) => {
                                if (selected !== null) {
                                    return variationOption.id === selected.id;
                                }

                                return false;
                            });

                            return typeof foundSelected === 'undefined';
                        }

                        return false;
                    });

                    //console.log('filterOptions', options, params, filtered);
                    if (params.inputValue !== '') {
                        filteredChosenVariationValues.push({
                            inputValue: params.inputValue,
                            name: `Pridėti "${params.inputValue}"`,
                        });
                    }

                    return filteredChosenVariationValues;
                }}
                freeSolo
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">Pridėti varianto pasirinkimą</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.name}
                            onChange={(event) => setDialogValue({ name: event.target.value })}
                            label="Pavadinimas"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions style={{display: 'flex'}}>
                        <Button onClick={handleClose} color="primary">
                            Atšaukti
                        </Button>
                        <ResponseErrors errors={errors} />
                        <span style={{flex: 1}}></span>
                        <ProgressButton
                            loading={isSubmitting}
                            text={"Pridėti"}
                            onClick={handleSubmit}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}

export default VariationOption;