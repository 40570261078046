import schema from "schm";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import get from "lodash/get";
import {packageSchema} from "packages/schemas";

const productWarehouseSchema = schema({
    warehouseId: { type: Number, default: null},
    name: { type: String, default: ""},
    remindAt: { type: Number, default: 0},
    amount: { type: Number, default: 0},
});

const productUserGroupPriceSchema = schema({
    userGroupId: { type: Number, default: 0},
    quantity: { type: Number, default: 1},
    price: { type: Number, default: 0},
});

const productVariantOptionSchema = schema({
    id: { type: Number, default: null},
    name: { type: String, default: ""},
});

const productVariationOptionSchema = schema({
    id: { type: Number, default: ""},
    name: { type: String, default: ""},
});

const productVariantOptionValueSchema = schema({
    optionId: { type: Number, default: null},
    value: { type: String, default: ""},
});

const productVariantVariationOptionValueSchema = schema({
    id: { type: Number, default: null},
    value: { type: String, default: ''},
    variationOption: { type: productVariationOptionSchema, default: {id: null, name: ''}},
});

const mergeWarehouses = params => previous => {
    return previous.merge({
        parse: (values = {}, metadataWarehouses = []) => {
            console.log('metadataWarehouses2', metadataWarehouses, params, previous);
            const defaultWarehouses = metadataWarehouses.map((metadataWarehouse) => {
                return {
                    warehouseId: metadataWarehouse.id,
                    name: metadataWarehouse.name,
                    remindAt: 0,
                    amount: 0,
                };
            });

            const productWarehouses = "warehouses" in values ? values["warehouses"] : [];

            //merge values.warehouses if it has something set
            const newProductWarehouses = defaultWarehouses.map(defaultWarehouse => ({
                ...defaultWarehouse,
                ...productWarehouses.find((productWarehouse) => (productWarehouse.warehouseId === defaultWarehouse.warehouseId) && productWarehouse),

            }));

            const newValues = {
                ...values,
                warehouses: newProductWarehouses,
            };

            return previous.parse(newValues);
        }
    });
};

const parseRelationships = params => previous => {
    return previous.merge({
        parse: (values = {}, metadataWarehouses = []) => {
            let categories = [];
            let newVariants = [];

            if (isObject(values)) {
                // CATEGORIES
                if ('categories' in values && isArray(values['categories'])) {
                    values['categories'].forEach(function(category) {
                        if ("id" in category) {
                            categories.push(category.id);
                        }

                    });
                }

                // WAREHOUSES
                const defaultWarehouses = metadataWarehouses.map((metadataWarehouse) => {
                    return {
                        warehouseId: metadataWarehouse.id,
                        name: metadataWarehouse.name,
                        remindAt: 0,
                        amount: 0,
                    };
                });

                if ('variants' in values && isArray(values['variants'])) {
                    newVariants = values.variants.map((variant) => {
                        const productWarehouses = "warehouses" in variant ? variant["warehouses"] : [];

                        //merge values.warehouses if it has something set
                        const newProductWarehouses = defaultWarehouses.map(defaultWarehouse => ({
                            ...defaultWarehouse,
                            ...productWarehouses.find((productWarehouse) => (productWarehouse.warehouseId === defaultWarehouse.warehouseId) && productWarehouse),

                        }));

                        return {
                            ...variant,
                            warehouses: newProductWarehouses,
                            package: typeof variant.package !== 'undefined' && variant.package !== null ? packageSchema.parse(variant.package) : null,

                        };
                    });

                    //console.log('new variants', newVariants);
                }
            }

            const newValues = {
                ...values,
                categories: categories,
                variants: newVariants,
            };

            return previous.parse(newValues, metadataWarehouses);
        },
    });
};

const variantProductSchema = schema({
    id: { type: Number, default: null},
    //variantOptionValues: { type: [productVariantOptionValueSchema], default: []},
    variationOptionValues: { type: [productVariantVariationOptionValueSchema], default: []},
    code: { type: String, default: ""},
    price: { type: String, default: "0"},
    sellable: { type: Boolean, default: false},
    discountPrice: { type: String, default: ""},
    userGroupPrices: { type: [productUserGroupPriceSchema], default: []},
    warehouses: { type: [productWarehouseSchema], default: []},
    // package: typeof product.package !== 'undefined' && product.package !== null ? packageSchema.parse(product.package) : null,
    package:  { type: Object, default: null},
});

const parentProductSchema = schema({
    id: { type: Number, default: null},
    name: { type: String, default: ""},
    type: { type: String, default: "0"},
    description: { type: String, default: ""},
    categories: { type: Array, default: []},
    // variantOptions: { type: [productVariantOptionSchema], default: []},
    variationOptions: { type: [productVariationOptionSchema], default: []},
    //variationOptions: { type: Array, default: []},
    variants: { type: [variantProductSchema], default: []},
    groupedProducts: { type: Array, default: []},
    images: { type: Array, default: []},
    assignImages: { type: Array, default: []},
}, parseRelationships());

export function parseProductToFormData(product, metadataWarehouses) {
    return {
        ...parentProductSchema.parse(product, metadataWarehouses),
        //...variantProductSchema.parse(product, metadataWarehouses),
    };
}

export function parseProductUserGroupPrice(userGroupPrice = {}) {
    return productUserGroupPriceSchema.parse(userGroupPrice);
}

// const customError = prevSchema => prevSchema.merge({
//     validate(values) {
//         return prevSchema.validate(values).catch(errors => {
//             const customErrors = errors.reduce((final, error) => ({
//                 ...final,
//                 [error.param]: error
//             }), {});
//             throw customErrors;
//         })
//     }
// });
// const mySchema = schema(
//     {
//         name: { type: String, minlength: 6 }
//     },
//     customError
// );