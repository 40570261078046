import schema from "schm";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

const parsePermissions = params => previous => {
    return previous.merge({
        parse: (userPermissionGroup = {}) => {
            let permissions = [];
            if (isObject(userPermissionGroup) && 'permissions' in userPermissionGroup && isArray(userPermissionGroup['permissions'])) {
                userPermissionGroup['permissions'].forEach(function(permission) {
                    if ("id" in permission) {
                        permissions.push(permission.id);
                    }
                });
            }

            const newUserPermissionGroup = {
                ...userPermissionGroup,
                permissions: permissions,
            };

            return previous.parse(newUserPermissionGroup);
        }
    });
};

const userPermissionGroupSchema = schema({
    id: { type: Number, default: null},
    title: { type: String, default: ""},
    permissions: { type: Array, default: []},
}, parsePermissions());

export function parseUserPermissionGroupToFormData(userPermissionGroup) {
    return userPermissionGroupSchema.parse(userPermissionGroup);
}