import React, {useEffect, useState} from 'react';
import get from "lodash/get";

import {useGetCarouselSlides} from "shared-react/api/CarouselApi";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";


import LinkWrapper from 'utils/LinkWrapper';

import {
    ROUTE_CAROUSEL_NEW_SLIDE,
    getCarouselSlidePath,
} from 'shared-react/utils/routes';
import PageTitle from "utils/PageTitle";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import StatusChipPublishedGroup from "shared-react/shop/statusChips/StatusChipPublishedGroup";
import {StyledListItemText} from "shared-react/utils/ListItemText";
import ListImageAvatar from "shared-react/shop/ListImageAvatar";

function CarouselSlidesPage({match, history, location}) {
    // const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    // const page = typeof urlQueryParams.page === 'undefined' ? 1 : urlQueryParams.page;
    // const perPage = 100;
    // const queryParams = {
    //     ...urlQueryParams,
    //     'perPage': perPage,
    //     'page': page,
    // };
    // const queryString = '?' + qs.stringify(queryParams);

    const [listQueryString, setListQueryString] = useState({});
    const [carouselSlides, totalCount, areCarouselSlidesLoading, carouselSlidesErrors, fetchCarouselSlides] = useGetCarouselSlides(listQueryString);

    useEffect(() => {
        fetchCarouselSlides();
    }, [listQueryString]);

    if (!carouselSlides) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    padding: 8,
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    height: '100%',
                }}>
                    <PageTitle title={"Karuselė"}/>
                    <span style={{flex: 1}} />
                    <Fab href={ROUTE_CAROUSEL_NEW_SLIDE} color="primary" aria-label="add" size="small" style={{marginLeft: 12}}>
                        <AddIcon />
                    </Fab>
                </div>
                </>
            }>
                <Paper elavation={0} square>
                    {carouselSlides.length > 0 ? (
                        <List dense={true}>
                            {carouselSlides.map((slide, index) => (
                                <ListItem
                                    key={index}
                                    button to={getCarouselSlidePath(slide.id)}
                                    component={LinkWrapper}
                                >
                                    <ListImageAvatar image={slide.imageLandscape} />
                                    <ListImageAvatar image={slide.imagePortrait} />
                                    <ListItemText
                                        primary={slide.title}
                                    />
                                    <StyledListItemText
                                        gutterLeft
                                        flex={false}
                                        primary={
                                            <React.Fragment>
                                                <StatusChipPublishedGroup publishedAt={get(slide, 'publishedAt', null)}/>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <>
                            Karuselė tuščia
                        </>
                    )}
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default CarouselSlidesPage;