import React, {useEffect, useState} from 'react';
import { Form, Field, FieldArray, FastField, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FocusFirstFormError from "products/FocusFirstFormError";

import {
    FormMaterialTextField,
    MaterialMultiSelectChip,
    MaterialTreeSelectField,
} from 'shared-react/formik/FormikMaterialFields';


import styled from 'styled-components';

import FormImages from "shared-react/utils/FormImages/FormImages";
import CKEditorField from "shared-react/formik/CKEditorField";
import {useMetadata} from "shared-react/metadata/MetadataContext";
import VariationOption from "./VariationOption";
import {parseProductToFormData} from "../schemas/product";
import useProductForm, {
    resetProductForm,
    setFormHasErrors,
} from "./ProductFormContext";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ProductVariants from "./variants/ProductVariants";

const StyledPaper = styled(Paper)`
  && {
    ${props => ({ ...props.theme.mixins.gutters() })}
    padding-top: ${props => props.theme.spacing(2)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    margin-bottom: ${props => props.theme.spacing(3)}px;
  }
`;

export const ProductSchema = Yup.object().shape({
    name: Yup.string()
        .required('Šis laukas negali būti tuščias'),
    // code: Yup.string()
    //     .required('Šis laukas negali būti tuščias'),
    // price: Yup.number()
    //     .required('Šis laukas negali būti tuščias'),
    variants: Yup.array().of(Yup.object().shape({
        code: Yup.string()
            .trim()
            .required('Šis laukas negali būti tuščias'),

        variationOptionValues: Yup.array().of(Yup.object().shape({
            id: Yup.string()
                .required('Šis laukas negali būti tuščias').nullable(),
        }).required('Šis laukas negali būti tuščias').nullable()),
    })),
});

function ProductForm({isSubmittingRequest, metadata }) {
    const theme = useTheme();
    const [productFormState, productFormDispatch] = useProductForm();

    const { errors, submitForm, values, setFieldValue, isValidating, isSubmitting } = useFormikContext();

    //console.log('ProductForm initialFormData', initialFormData);
    //console.log('ProductForm initialProductData', initialProductData);

    //const treeData = [{ id:1, title: 'Chicken', children: [] }, { title: 'Chicken2', children: [{ title: 'Egg' }] }];
    //console.log('productData', productData, metadata);

    //console.log('Formik values', values, errors);
    //console.log('Formik metadata', metadata);

    const variationOptionIds = values.variationOptions.reduce((accumulator = [], currentValue) => {
        if (currentValue != null) {
            if (!accumulator.includes(currentValue.id)) {
                accumulator.push(currentValue.id);
            }
        }

        return accumulator
    }, []);

    useEffect(() => {
        if (productFormState.doSubmit) {
            submitForm();
            productFormDispatch(resetProductForm());
        }
    }, [productFormState.doSubmit]);

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            const productFormHasErrors = Object.keys(errors).length > 0;

            productFormDispatch(setFormHasErrors(productFormHasErrors));
        }
    }, [errors, isSubmitting, isValidating]);

    //useDidMountEffect
    useEffect(() => {
        //console.log('FormMaterialAutocomplete onChange', values);
        //add or delete variant variationOptionValues

        const newVariationOptionValues = [];

        values.variants.forEach((variant, index2) => {
            const newVariationOptionValues = [];

            values.variationOptions.forEach((variationOption, index) => {
                if (variationOption !== null) {
                    const existingVariationOptionValue = variant.variationOptionValues.find((variationOptionValue) => {
                        if (variationOptionValue !== null) {
                            return variationOptionValue.variationOption.id === variationOption.id;
                        }

                        return false;
                    });

                    if (typeof existingVariationOptionValue !== 'undefined') {
                        newVariationOptionValues.push(existingVariationOptionValue);
                    } else {
                        newVariationOptionValues.push(null);

                        // {
                        //     variationOption: {
                        //         id: variationOption.id,
                        //     },
                        //     value: '',
                        //         id: null,
                        // }
                    }
                }


                //variationOptionIds
            });

            //console.log('newVariationOptionValues', newVariationOptionValues);

            setFieldValue(`variants.${index2}.variationOptionValues`, newVariationOptionValues);
            //variationOptionIds
        });
    }, [variationOptionIds.join(''), values.variants.length]);

    return (
        <>
            <Form>
                <FocusFirstFormError/>
                {/*<StyledPaper theme={theme} elevation={1}>*/}
                {/*    <Field*/}
                {/*        name="type"*/}
                {/*        component={MaterialRadioGroup}*/}
                {/*    />*/}
                {/*</StyledPaper>*/}
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12}>
                        <StyledPaper theme={theme} elevation={1}>
                            <FastField
                                name="name"
                                label="Pavadinimas"
                                margin="dense"
                                fullWidth
                                component={FormMaterialTextField}
                            />
                            <CKEditorField name={'description'} placeholder={'Aprašymas'} />
                        </StyledPaper>
                        <StyledPaper theme={theme} elevation={1}>
                            <Typography variant="h6" gutterBottom>
                                Paveikslėliai
                            </Typography>
                            <FormImages
                                imageType={'product'}
                                images={values.images}
                                onImagesUpdated={(images) => {
                                    setFieldValue('images', images);
                                }}
                                enlargeFirstImage={true}
                            />
                        </StyledPaper>
                        {values.type === '2' ? (
                            <StyledPaper theme={theme} elevation={1}>
                                <Typography variant="h6" gutterBottom>
                                    Prekės grupėje
                                </Typography>
                                <Field
                                    name="groupedProducts"
                                    label="Ieškoti prekės"
                                    options={[
                                        {
                                            id: 847,
                                            title: '847',
                                        },
                                        {
                                            id: 848,
                                            title: '848',
                                        },
                                        {
                                            id: 849,
                                            title: '849',
                                        },
                                    ]}
                                    margin="dense"
                                    fullWidth
                                    component={MaterialMultiSelectChip}
                                />
                            </StyledPaper>
                        ) : null}

                        <StyledPaper theme={theme}>
                            <Typography variant="h6" gutterBottom>
                                Kategorijos
                            </Typography>
                            <Field
                                name="categories"
                                label="Ieškoti"
                                margin="dense"
                                options={metadata.categories}
                                treeData={metadata.categoriesTree}
                                fullWidth
                                component={MaterialTreeSelectField}
                                setFieldValue={setFieldValue}
                            />
                        </StyledPaper>

                        <React.Fragment>
                            <StyledPaper theme={theme}>
                                <Typography variant="h6" gutterBottom>
                                    Variantai
                                </Typography>
                                <FieldArray
                                    name="variationOptions"
                                    render={arrayHelpers => (
                                        <>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={values.variationOptions.length > 0}
                                                        onChange={
                                                            (event) => {
                                                                if (event.target.checked) {
                                                                    // values.variants.forEach((variant, index) => {
                                                                    //     const newValue = [
                                                                    //         ...values.variants[index].variantOptionValues,
                                                                    //         {
                                                                    //             name: '',
                                                                    //             value: '',
                                                                    //         }
                                                                    //     ];
                                                                    //     setFieldValue(`variants.${index}.variantOptionValues`, newValue);
                                                                    // });

                                                                    //setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
                                                                    arrayHelpers.push(null);
                                                                }
                                                            }
                                                        }
                                                        name="gilad" />}
                                                    label="Prekė turi skirtingų variantų, kaip skirtingi dydžiai ar spalva"
                                                />
                                            </FormGroup>
                                            { values.variationOptions.length > 0 ? (
                                                <>
                                                    {values.variationOptions.map((variationOption, index) => (
                                                        <div key={index} style={{
                                                            display: 'flex',
                                                            // margin: '10px 0',
                                                        }}>
                                                            {/*<Field*/}
                                                            {/*    name={`variationOptions[${index}].id`}*/}
                                                            {/*    label="Pavadinimas (pvz.: Dydis, Spalva)"*/}
                                                            {/*    margin="dense"*/}
                                                            {/*    fullWidth*/}
                                                            {/*    component={FormMaterialTextField}*/}
                                                            {/*/>*/}
                                                            <VariationOption
                                                                index={index}
                                                                variationOptions={metadata.variationOptions}
                                                                alreadySelected={values.variationOptions}
                                                                style={{flex: 1}}
                                                            />
                                                            <IconButton
                                                                size={'medium'}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </div>
                                                    ))}
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => {
                                                            // values.variants.forEach((variant, index) => {
                                                            //     const newValue = [
                                                            //         ...values.variants[index].variantOptionValues,
                                                            //         {
                                                            //             name: '',
                                                            //             value: '',
                                                            //         }
                                                            //     ];
                                                            //     setFieldValue(`variants.${index}.variantOptionValues`, newValue);
                                                            // });
                                                            //setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
                                                            arrayHelpers.push(null);
                                                        }}
                                                    >
                                                        Pridėti varianto tipą
                                                    </Button>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                />
                            </StyledPaper>
                            <ProductVariants
                                metadata={metadata}
                                values={values}
                            />
                        </React.Fragment>
                        {/*) : (*/}
                        {/*    <React.Fragment>*/}
                        {/*        <StyledPaper theme={theme} elevation={1}>*/}
                        {/*            <Typography variant="h6" gutterBottom>*/}
                        {/*                Kainos*/}
                        {/*            </Typography>*/}
                        {/*            <ProductFormPrices*/}
                        {/*                namePrefix={`variants.0.`}*/}
                        {/*                currentUserGroupPrices={values.variants[0].userGroupPrices}*/}
                        {/*                userGroupOptions={userGroupOptions}*/}
                        {/*            />*/}
                        {/*           */}
                        {/*        </StyledPaper>*/}
                        {/*        <StyledPaper theme={theme} elevation={1}>*/}
                        {/*            <Typography variant="h6" gutterBottom>*/}
                        {/*                Sandėliavimas*/}
                        {/*            </Typography>*/}
                        {/*            <ProductFormWarehouses*/}
                        {/*                namePrefix={`variants.0.`}*/}
                        {/*                warehouses={values.variants[0].warehouses}*/}
                        {/*            />*/}
                        {/*        </StyledPaper>*/}
                        {/*    </React.Fragment>*/}
                        {/*)}*/}
                    </Grid>
                </Grid>
                <div style={{display: 'flex'}}>
                    {/*<ResponseErrors errors={productErrors}/>*/}
                    <span style={{flex: 1}} />
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmittingRequest}>
                        Išsaugoti
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default ProductForm;

export const INITIAL_PRODUCT_DATA = {
    name: '', // creates from parent name and options

    //authenticityToken: '',
    description: '',
    categories: [],

    pictures: [],

    variantOptions: [
        // {
        //     name: 'Dydis',
        // }
        // {
        //     id: '',
        //     name: '',
        //     position: '',
        // },
    ],
    variants: [],
};

export const INITIAL_PRODUCT_VARIANT_DATA = {
    variantOptionValues: [
        // {
        //     optionId: '',
        //     name: '',
        //     value: '',
        // }
    ],

    // newFlagFrom: '',
    // newFlagTo: '',

    code: '',

    // pictureId: '',

    //prices
    price: '',
    // discountPrice: '',
    // discountPriceFrom: '',
    // discountPriceTo: '',
    // customerGroupPrices: [
    //     // {
    //     //     customerGroupId: '',
    //     //     quantity: '',
    //     //     priceType: '',
    //     //     price: '',
    //     // },
    // ],

    //warehouses
    tracked: true,
    warehouses: [
        // {
        //     warehouseId: '',
        //     remindAt: '',
        //     outOfStockAt: '', //?kada nebepardavineti online?
        // }
    ],

    // sales channels, onlineLt, onlineLv, salonai
    // salesChannels: [
    //     {
    //         channelId: '',
    //         published: true,
    //     }
    // ],

    //gpais
    // packageId: '',
};

const productDataNoVariants = {
    id: 1,
    name: 'Real product',
    description: 'Just a random real product \n with a new line',
    categories: [
        {
            id: 1,
            name: 'A specific category this product belongs to'
        }
    ],
    pictures: [
        {
            id: 1,
            altText: 'alt text of the picture',
            url: 'https://api.naildesign.lt/web/picture1.jpg',
            sort: 1
        }
    ],

    newFlagFrom: null,
    newFlagTo: '2019-03-10',

    code: '1375122230',

    //prices
    price: 12.5,
    discountPrice: null,
    discountPriceFrom: null,
    discountPriceTo: null,
    customerGroupPrices: [
        {
            customerGroupId: 1,
            quantity: 1,
            priceTypeId: 1,
            price: 11.5,
            name: 'Salonas',
        },
    ],

    //warehouses
    tracked: true,
    warehouses: [
        {
            warehouseId: 1,
            remindAt: 2,
            outOfStockAt: 1,
            amount: 10,
        }
    ],
    package: {
        id: 1,
        name: 'Indelis 5ml'
    },
    pictureId: null,
    variantOptions: [],
    variantOptionValues: [],
    variants: [],
};


const productDataWithVariants = {
    id: 2,
    name: 'Real product with variants',
    description: 'Just a random real product \n with a new line',
    categories: [
        {
            id: 1,
            name: 'A specific category this product belongs to'
        }
    ],
    pictures: [
        {
            id: 1,
            altText: 'alt text of the picture',
            url: 'https://api.naildesign.lt/web/picture1.jpg',
            sort: 1
        }
    ],

    newFlagFrom: null,
    newFlagTo: null,

    code: null,
    price: null,
    discountPrice: null,
    discountPriceFrom: null,
    discountPriceTo: null,
    customerGroupPrices: [],
    tracked: false,
    warehouses: [],
    package: null,
    pictureId: null,
    variantOptions: [
        {
            id: 1,
            name: 'Dydis',
            position: 1,
        },
        {
            id: 2,
            name: 'Spalva',
            position: 2,
        },
    ],
    variantOptionValues: [],
    variants: [
        {
            id: 3,
            variantOptionValues: [
                {
                    id: 1,
                    optionId: 1,
                    name: 'Dydis',
                    value: 'S',
                },
                {
                    id: 2,
                    optionId: 2,
                    name: 'Spalva',
                    value: 'Balta',
                },
            ],
            newFlagFrom: null,
            newFlagTo: '2019-03-22',

            code: '123456789',
            price: 12.5,
            discountPrice: 10,
            discountPriceFrom: '2019-03-04',
            discountPriceTo: null,
            customerGroupPrices: [
                {
                    customerGroupId: 1,
                    quantity: 5,
                    priceTypeId: 1,
                    price: 11.5,
                    name: 'Salonas',
                },
            ],
            tracked: true,
            warehouses: [
                {
                    warehouseId: 1,
                    remindAt: 3,
                    outOfStockAt: 1,
                    amount: 11,
                }
            ],
            package: {
                id: 1,
                name: 'Indelis 5ml'
            },
            pictureId: 1,
        }
    ],
};


// {/*==== Type ====*/}
// {/*Paprasta, Su variantais, Grupinė, Virtual (nėra kiekio, nėra pakuotės, nėra shipping size, kolkas turbūt nereikia)*/}
// {/*==== Main Info ====*/}
// {/*<br/>*/}
// {/*Pavadinimas*/}
// {/*<br/>*/}
// {/*Kodas*/}
// {/*<br/>*/}
// {/*Description su WYSIWYG*/}
// {/*<br/>*/}
// {/*Kategorijos*/}
// {/*<br/>*/}
// {/*Nustatyti kaip naują nuo iki*/}
// {/*<br/>*/}
// {/*<br/>*/}
// {/*==== Variants ====*/}
// {/*<br/>*/}
// {/*<br/>*/}
// {/*==== Availability ====*/}
// {/*<br/>*/}
// {/*Pardavinėjama online?*/}
// {/*Pardavinėjama sandėliuose?*/}
// {/*Rodyti kai out of stock?*/}
// {/*Leisti pirkti kai out of stock?*/}
// {/*<br/>*/}
// {/*<br/>*/}
// {/*==== Paveikslėliai ====*/}
// {/*add/crop/delete/alt_text,size_requirement*/}
// {/*<br/>*/}
// {/*<br/>*/}
// {/*==== Kainos ====*/}
// {/*<br/>*/}
// {/*Pagrindinė kaina*/}
// {/*<br/>*/}
// {/*Akcijos kaina (nuo iki laukai)*/}
// {/*<br/>*/}
// {/*Kainos pagal klientų grupes*/}
// {/*<br/>*/}
// {/*<br/>*/}
// {/*==== Inventorius ====*/}
// {/*<br/>*/}
// {/*Kodą perkelti čia?*/}
// {/*<br/>*/}
// {/*Priminimas kai sandėlyje kiekis mažiau nei?*/}
// {/*<br/>*/}
// {/*Nebeparduoti kai sandėlyje kiekis mažiau nei?*/}
// {/*<br/>*/}
// {/*Peržiūrėti inventoriaus istoriją*/}
// {/*<br/>*/}
// {/*Kiek kokiame sandėlyje yra*/}
// {/*<br/>*/}
// {/*<br/>*/}
// {/*==== Shipping ====*/}
// {/*<br/>*/}
// {/*Dydis*/}
// {/*<br/>*/}
// {/*Svoris*/}
// {/*<br/>*/}
// {/*<br/>*/}
// {/*==== GPAIS ====*/}
// {/*<br/>*/}
// {/*Pakuotė*/}
// {/*<select></select>*/}