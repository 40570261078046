import React, {useEffect, useState} from 'react';

import UndoIcon from '@material-ui/icons/Undo';
import MobileStepper from '@material-ui/core/MobileStepper';
import Grid from '@material-ui/core/Grid';

import {useFulfillmentPreviousStep, useFulfillmentNextStep} from "shared-react/api/SaleOrderApi";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import ProgressButton from "shared-react/utils/ProgressButton";
import ProgressIconButton from "shared-react/utils/ProgressIconButton";
import NotifyUser from "./NotifyUser";
import CreateOmnivaParcel from "../Omniva/CreateOmnivaParcel";
import ListSubheader from "@material-ui/core/ListSubheader";

function FulfillmentSteps({
                              order,
                              fulfillment,
                              onFulfillmentUpdated,
                              fetchOrder,
                          }) {
    const [updatedOrderPreviousStep, isInProgressPreviousStep, previousStepErrors, previousStep] = useFulfillmentPreviousStep(order.id, fulfillment.id);
    const [updatedOrderNextStep, isInProgressNextStep, nextStepErrors, nextStep] = useFulfillmentNextStep(order.id, fulfillment.id);

    useEffect(() => {
        if (updatedOrderPreviousStep && typeof onFulfillmentUpdated === 'function') {
            onFulfillmentUpdated(updatedOrderPreviousStep);
        }
    }, [updatedOrderPreviousStep]);

    useEffect(() => {
        if (updatedOrderNextStep && typeof onFulfillmentUpdated === 'function') {
            onFulfillmentUpdated(updatedOrderNextStep);
        }
    }, [updatedOrderNextStep]);

    return (
        <React.Fragment>
            <ResponseErrors errors={previousStepErrors} />
            <ResponseErrors errors={nextStepErrors} />
            <span style={{flex: 1}}/>
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                >
                    {/*<Grid item xs={12}>*/}
                    {/*    <MobileStepper*/}
                    {/*        variant="dots"*/}
                    {/*        steps={fulfillment.deliveryMethod.steps.length}*/}
                    {/*        position="static"*/}
                    {/*        activeStep={fulfillment.deliveryStep}*/}
                    {/*        style={{*/}
                    {/*            margin: 0,*/}
                    {/*            background: '#ffffff',*/}
                    {/*            paddingLeft: 0,*/}
                    {/*            paddingRight: 0,*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    {(['PANEVEZYS', 'RYGA', 'VILNIUS'].includes(fulfillment.deliveryMethod.code) && fulfillment.deliveryStep === 1) ||
                     (['OMNIVA_LT', 'OMNIVA_LV', 'CUSTOM'].includes(fulfillment.deliveryMethod.code) && fulfillment.deliveryStep === 2) ? (
                        <Grid item>
                            {/*{fulfillment.deliveryNotificationsSent > 0 ? (*/}
                            {/*    <StatusChip icon={<MailOutlineIcon/>} small={false} status={1} label={'Pranešta pirkėjui (' + fulfillment.deliveryNotificationsSent + ')'} fixedWidth={false}/>*/}
                            {/*) : null}*/}
                             <NotifyUser
                                 order={order}
                                 fulfillment={fulfillment}
                                 onNotificationSent={onFulfillmentUpdated}
                             />
                        </Grid>
                     ) : null}

                    {(['OMNIVA_LT', 'OMNIVA_LV'].includes(fulfillment.deliveryMethod.code) && fulfillment.deliveryStep === 1) ? (
                        <Grid item>
                            <CreateOmnivaParcel
                                fulfillment={fulfillment}
                                onCreated={() => {
                                    fetchOrder();
                                }}
                            />
                        </Grid>
                    ) : null}
                    <Grid item>
                        {fulfillment.deliveryStep <= 0 ? null : (
                            <ProgressIconButton
                                tooltipTitle={"Atžymėti atgal"}
                                loading={isInProgressPreviousStep}
                                icon={<UndoIcon fontSize="inherit" />}
                                onClick={() => {
                                    previousStep();
                                }}
                            />
                        )}

                        {/*<ProgressButton*/}
                        {/*    loading={isInProgressPreviousStep}*/}
                        {/*    text={"Atžymėti"}*/}
                        {/*    variant={"outlined"}*/}
                        {/*    onClick={() => {*/}
                        {/*        previousStep();*/}
                        {/*    }}*/}
                        {/*/>*/}

                        {(fulfillment.deliveryStep + 1) < fulfillment.deliveryMethod.steps.length ? (
                            <ProgressButton
                                loading={isInProgressNextStep}
                                text={'Pažymėti ' + fulfillment.deliveryMethod.steps[fulfillment.deliveryStep + 1].statusText}
                                variant={"contained"}
                                onClick={() => {
                                    nextStep();
                                }}
                            />
                        ) : null}
                    </Grid>
                </Grid>
            </div>

        </React.Fragment>
    );
}

export default FulfillmentSteps;