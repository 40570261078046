import qs from "qs";
import {makeRequest, useMakeRequest, API_URL} from './Api';

const API_SALE_ORDERS = '/v2/orders';

export function useGetSaleOrders(queryString) {
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_SALE_ORDERS + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetSaleOrder(saleOrderId) {
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest('get', API_URL + API_SALE_ORDERS + '/' + saleOrderId);

    return [ data, setData, isLoading, errors, doRequest ];
}

export function useFulfillOrderFulfillment(orderId, fulfillmentId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('put', API_URL + API_SALE_ORDERS + '/' + orderId + '/fulfillments/' + fulfillmentId + '/fulfill');

    return [ data, isLoading, errors, doRequest ];
}

export function useUnFillFulfillment(orderId, fulfillmentId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('delete', API_URL + API_SALE_ORDERS + '/' + orderId + '/fulfillments/' + fulfillmentId + '/fulfill');

    return [ data, isLoading, errors, doRequest ];
}

export function useMergeSaleOrders(ordersIds) {
    const postData = {
        ordersIds,
    };
    const { data, isLoading, errors, doRequest } =  useMakeRequest('post', API_URL + API_SALE_ORDERS + '/merge', postData);

    return [ data, isLoading, errors, doRequest ];
}

export function useAddOrderPayment(orderId, paymentTypeId, amount) {
    const postData = {
        paymentTypeId,
        amount,
    };
    const { data, isLoading, errors, doRequest } =  useMakeRequest('post', API_URL + API_SALE_ORDERS + '/' + orderId + '/payments', postData);

    return [ data, isLoading, errors, doRequest ];
}

export function useSaveOrderPayment(orderId, orderPaymentData) {
    const isNew = !(orderPaymentData !== null && typeof orderPaymentData === 'object'
        && 'id' in orderPaymentData && orderPaymentData['id'] !== null);
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + orderPaymentData.id;

    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_SALE_ORDERS + '/' + orderId + '/payments' + url, orderPaymentData);

    return [ data, setData, isLoading, errors, doRequest ];
}

export function useAddOrderPaymentRefund(orderId, paymentTypeId, amount) {
    const postData = {
        paymentTypeId,
        amount: amount * -1,
    };
    const { data, isLoading, errors, doRequest } =  useMakeRequest('post', API_URL + API_SALE_ORDERS + '/' + orderId + '/payments', postData);

    return [ data, isLoading, errors, doRequest ];
}

export function usePaymentSetToPaid(orderId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('put', API_URL + API_SALE_ORDERS + '/' + orderId + '/payment');

    return [ data, isLoading, errors, doRequest ];
}

export function usePaymentDelete(orderId, paymentId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('delete', API_URL + API_SALE_ORDERS + '/' + orderId + '/payment/' + paymentId);

    return [ data, isLoading, errors, doRequest ];
}

export function useFulfillmentPreviousStep(orderId, fulfillmentId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('put', API_URL + API_SALE_ORDERS + '/' + orderId + '/fulfillments/' + fulfillmentId + '/previousStep');

    return [ data, isLoading, errors, doRequest ];
}

export function useFulfillmentNextStep(orderId, fulfillmentId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('put', API_URL + API_SALE_ORDERS + '/' + orderId + '/fulfillments/' + fulfillmentId + '/nextStep');

    return [ data, isLoading, errors, doRequest ];
}

export function useFulfillmentNotifyCustomer(orderId, fulfillmentId) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('put', API_URL + API_SALE_ORDERS + '/' + orderId + '/fulfillments/' + fulfillmentId + '/notifyCustomer');

    return [ data, isLoading, errors, doRequest ];
}