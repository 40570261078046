import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useGetLowProducts} from 'shared-react/api/ProductApi';

import ListItem from "@material-ui/core/ListItem";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import ListItemText from '@material-ui/core/ListItemText';

import ListTablePagination from "utils/ListTablePagination";
import PageTitle from "../utils/PageTitle";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {useGetMetadata} from "shared-react/api/MetadataApi";
import WarehousesSelect from "productsLowQuantity/WarehousesSelect";
import WarehousesSelect3 from "productsLowQuantity/WarehousesSelect3";
import WarehousesSelect4 from "productsLowQuantity/WarehousesSelect4";
import ListItemProduct from "products/ListItemProduct";
import {getProductLowQuantityPath} from 'shared-react/utils/routes';
import {setWarehouseIds} from "ducks/lowProducts";
import {ListItemTextCustomWidth} from "utils/List";
import ProgressInputIndicator from 'shared-react/utils/ProgressInputIndicator';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

function ProductsLowQuantityPage({match, history, setWarehouseIds}) {
    //console.log('match params', match.params, typeof match.params.page);
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const selectedWarehousesIds = typeof match.params.selectedWarehousesIds === 'undefined' ? "" : match.params.selectedWarehousesIds;
    const selectedWarehousesStr = selectedWarehousesIds.length > 0 ? selectedWarehousesIds.split(',') : [];
    const selectedWarehousesInt = selectedWarehousesStr.map(function (x) {
        return parseInt(x, 10);
    });
    const perPage = 40;
    const queryParams = {
        page,
        perPage,
        warehouseIds: selectedWarehousesInt,
    };
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(
        ['warehouses']
    );
    const [products, totalCount, isProductsLoading, productsErrors, fetchProducts] = useGetLowProducts(queryParams);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (selectedWarehousesInt.length > 0) {
            fetchProducts();
        }
    }, [page, selectedWarehousesIds]);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }



    // if (!products) {
    //     return (<DataLoadingIndicator />);
    // }



    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 48,
                    padding: '8px 16px',
                }}>
                    <div style={{width: '100%'}}>
                        <WarehousesSelect4
                            metadataWarehouses={metadata.warehouses}
                            selectedWarehouses={selectedWarehousesInt}
                            onSelectedWarehouseIds={(selectedWarehousesIds) => {
                                const selectedWarehousesParam = selectedWarehousesIds.length > 0 ? selectedWarehousesIds.join(',') : null;
                                //console.log('ProductsLowQuantityPage selectedWarehousesParam', selectedWarehousesIds,selectedWarehousesParam );

                                setWarehouseIds(selectedWarehousesParam);
                                history.push(getProductLowQuantityPath(1, selectedWarehousesParam));
                            }}
                        />
                    </div>
                </div>
                {isProductsLoading ? (
                    <ProgressInputIndicator />
                ) : null}
                </>
            }>
                <div>
                    {!isProductsLoading && products && selectedWarehousesInt.length > 0 ? (
                        <React.Fragment>
                            {products.length > 0 ? (
                                <Paper elavation={0} square>
                                    <List dense>
                                        <ListItem style={{paddingRight: 4}}>
                                            <ListItemText />
                                            <ListItemTextCustomWidth
                                                width={68}
                                                textAlign={'right'}
                                                primary={
                                                    <React.Fragment>
                                                        Priminimas
                                                    </React.Fragment>
                                                }
                                            />
                                            <ListItemTextCustomWidth
                                                width={40}
                                                textAlign={'right'}
                                                primary={
                                                    <React.Fragment>
                                                        Kiekis
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        {products.map((product) => (
                                            <ListItem key={product.id}>
                                                <ListItemProduct product={product}/>
                                                <ListItemTextCustomWidth
                                                    width={120}
                                                    paddingRight
                                                    primary={
                                                        <React.Fragment>
                                                            {product.warehouses
                                                                .filter((warehouse) => selectedWarehousesInt.includes(warehouse.warehouseId))
                                                                .map((warehouse) => (
                                                                    <React.Fragment key={'name' + warehouse.warehouseId}>
                                                                        <div style={{
                                                                            textOverflow: "ellipsis",
                                                                            overflow: "hidden",
                                                                            whiteSpace: "nowrap",
                                                                        }}>{warehouse.name}</div>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    }
                                                />
                                                <ListItemTextCustomWidth
                                                    width={40}
                                                    textAlign={'right'}
                                                    primary={
                                                        <React.Fragment>
                                                            {product.warehouses
                                                                .filter((warehouse) => selectedWarehousesInt.includes(warehouse.warehouseId))
                                                                .map((warehouse) => (
                                                                    <React.Fragment key={'remindAt' + warehouse.warehouseId}>
                                                                        {warehouse.remindAt}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    }
                                                />
                                                <ListItemTextCustomWidth
                                                    width={40}
                                                    textAlign={'right'}
                                                    primary={
                                                        <React.Fragment>
                                                            {product.warehouses
                                                                .filter((warehouse) => selectedWarehousesInt.includes(warehouse.warehouseId))
                                                                .map((warehouse) => (
                                                                    <React.Fragment key={'amount' + warehouse.warehouseId}>
                                                                        {warehouse.amount}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <ListTablePagination
                                        perPage={perPage}
                                        totalCount={totalCount}
                                        page={page}
                                        onChangePage={(event, page) => {
                                            history.push(getProductLowQuantityPath(page, selectedWarehousesIds));
                                            //setPage(page+1);
                                        }}
                                    />
                                </Paper>
                            ) : (
                                <span>Pagal pasirinktus sandėlius, trūkstamų prekių nerasta.</span>
                            )}
                        </React.Fragment>
                    ) : null}
                </div>
            </FillHeightContainer>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setWarehouseIds: (warehouseIds) => {
        dispatch(setWarehouseIds(warehouseIds));
    },
});

export default connect(
    null,
    mapDispatchToProps
)(ProductsLowQuantityPage);