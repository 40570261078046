import React from 'react';
import {Field, FastField} from "formik";

import {useTheme} from "@material-ui/styles";
import InputAdornment from '@material-ui/core/InputAdornment'

import {ListAmountInput, ListItemTextSmallIconButton, ListPriceInput} from "../utils/List";
import {FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";

function PrintInvoiceFormInputs({arrayHelpers, productIndex}) {
    const theme = useTheme();

    return (
        <React.Fragment>
            <ListAmountInput
                theme={theme}
                paddingRight
            >
                <FastField
                    name={`orderProducts[${productIndex}].amount`}
                    label={'Kiekis'}
                    type={"number"}
                    inputProps={{ min: "1", step: "1" }}
                    nomargin={1}
                    margin="dense"
                    fullWidth
                    component={FormMaterialTextField}
                />
            </ListAmountInput>
            <ListPriceInput
                theme={theme}
                hasAdornment
            >
                <FastField
                    name={`orderProducts[${productIndex}].price`}
                    label={'Kaina'}
                    type={"number"}
                    inputProps={{ min: "0", step: "0.01" }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                    nomargin={1}
                    margin="dense"
                    fullWidth
                    component={FormMaterialTextField}
                />
            </ListPriceInput>
        </React.Fragment>
    );
}

export default PrintInvoiceFormInputs;