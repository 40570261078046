import {useMakeRequest, API_URL} from './Api';

const API_INVOICES_URL = '/v2/invoices';

export function useGetInvoices(queryString) {
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_INVOICES_URL + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetInvoice(invoiceId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_INVOICES_URL + '/' + invoiceId);

    return [data, setData, isLoading, errors, doRequest];
}

export function usePrintCustomInvoice(invoiceData) {
    const method = 'post';
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_INVOICES_URL + '/V2custom', invoiceData);

    return [ data, setData, isLoading, errors, doRequest ];
}

export function usePrintFakeInvoice(invoiceData) {
    const method = 'post';
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_INVOICES_URL + '/print-fake-invoice', invoiceData);

    return [ data, setData, isLoading, errors, doRequest ];
}

export function usePrintFakeReceipt(receiptData) {
    const method = 'post';
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_INVOICES_URL + '/print-fake-receipt', receiptData);

    return [ data, setData, isLoading, errors, doRequest ];
}