import schema from "schm";
import translate from "schm-translate";

const orderPaymentSchema = schema({
    id: { type: Number, default: null},
    paymentTypeId: { type: Number, default: 1},
    amount: { type: Number, default: 0},
}, translate({
    paymentTypeId: "type.id",
}));

export function parseOrderPaymentToFormData(orderPayment) {
    return orderPaymentSchema.parse(orderPayment);
}