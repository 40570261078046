import React, {useState} from 'react';

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

function WarehousesSelect({metadataWarehouses, selectedWarehouses, onSelectedWarehouseIds}) {
    const warehouseOptions = metadataWarehouses.map((warehouse) => {
        return {
            value: warehouse.id,
            label: warehouse.name,
        };
    });
    const selectedValues = warehouseOptions.filter((warehouse) => {
        return selectedWarehouses.includes(warehouse.value)
    });
    //console.log('WarehousesSelect selectedWarehouses selectedValues', selectedWarehouses, selectedValues);

    // const forceLabelShrink = selectedValues.length > 0 ? {
    //     textFieldProps: {
    //         InputLabelProps: {
    //             shrink: true,
    //         }
    //     }
    // } : {};

    //console.log('WarehousesSelect selectedValues', selectedValues);

    return (
        <React.Fragment>
            <Autocomplete
                multiple
                options={warehouseOptions}
                getOptionLabel={option => option.label}
                //defaultValue={selectedValues}
                value={selectedValues}
                filterSelectedOptions
                onChange={(event, selectedValues) => {
                    console.log('WarehousesSelect on change', event, selectedValues);
                    if (typeof onSelectedWarehouseIds === 'function') {
                        const selectedWarehouseIds = selectedValues.reduce((selectedOptionsIds, selectedOption) => {
                            selectedOptionsIds.push(selectedOption.value);

                            return selectedOptionsIds;
                        }, []);
                        console.log('WarehousesSelect selectedWarehouseIds', selectedWarehouseIds);
                        onSelectedWarehouseIds(selectedWarehouseIds);
                    }
                }}
                noOptionsText="Pasirinkimų nėra"
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Pasirinkti sandėliai"
                        //placeholder="Pasirinkti sandėliai"
                        fullWidth
                    />
                )}
            />

            {/*<MaterialMultiSelectChip*/}
                {/*label={"Pasirinkti sandėliai"}*/}
                {/*options={warehouseOptions}*/}
                {/*onChange={(e) => {*/}
                    {/*//console.log('WarehousesSelect on change', e.target.value);*/}
                    {/*if (typeof onSelectedWarehouseIds === 'function') {*/}
                        {/*const selectedWarehouseIds = e.target.value.reduce((selectedOptionsIds, selectedOption) => {*/}
                            {/*selectedOptionsIds.push(selectedOption);*/}

                            {/*return selectedOptionsIds;*/}
                        {/*}, []);*/}
                        {/*//console.log('WarehousesSelect selectedWarehouseIds', selectedWarehouseIds);*/}
                        {/*onSelectedWarehouseIds(selectedWarehouseIds);*/}
                    {/*}*/}
                {/*}}*/}
                {/*// openMenuOnClick={false}*/}
                {/*fullWidth={true}*/}
                {/*value={selectedWarehouses}*/}
                {/*//{...forceLabelShrink}*/}
            {/*/>*/}
        </React.Fragment>
    );
}

export default WarehousesSelect;