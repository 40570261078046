import React, {useState} from 'react';
import isArray from 'lodash/isArray';

import AddEditCustomerDialog from '../user/AddEditCustomerDialog';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import CustomerListInfo from 'shared-react/user/CustomerListInfo';
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";

function createCustomerOption(customer) {
    return {
        label: customer.firstName + ' ' + customer.lastName,
        value: customer.id,
        ...customer,
    };
}

function CustomerSelect({
                            customersMetadata,
                            customerId,
                            onChange,
                            creatable = false,
                            nomargin = 0,
                            showAddNew = false,
                            showAdditionalInfo = false,
                            showEditButton = false,
                            error,
                            helperText,
                            style = {},
                            ...props
}) {
    if (!isArray(customersMetadata)) {
        customersMetadata = [];
    }

    const [customersMetadataLocal, setCustomersMetadataLocal] = useState(customersMetadata);
    const customerOptionsFromMetadata = customersMetadataLocal.map((customer) => (createCustomerOption(customer)));
    const [customerOptions, setCustomerOptions] = useState(customerOptionsFromMetadata);
    const [isAddEditCustomerDialogOpen, setIsAddEditCustomerDialogOpen] = useState(false);
    const [addEditCustomer, setAddEditCustomer] = useState({});

    const selectedOptionIndex = customerOptions.findIndex((option) => option.value === customerId);
    const selectedOption = selectedOptionIndex > -1 ? customerOptions[selectedOptionIndex] : null;
    const selectedCustomer = isArray(customersMetadataLocal) && customersMetadataLocal.length > 0 && customerId ? customersMetadataLocal.find((customer) => customer.id === customerId) : null;

    //console.log('selectedCustomer', selectedCustomer, customersMetadataLocal);
    //console.log('CustomerSelect customerId selectedOptionIndex selectedOption selectedCustomer', customerId, customersMetadataLocal, selectedOptionIndex, selectedOption, selectedCustomer);
    const onCreateNewCustomerOption = () => {
        setAddEditCustomer({});
        setIsAddEditCustomerDialogOpen(true);
    };

    const onEditSelectedOption = () => {
        //const selectedCustomer = customersMetadata.find((customer) => customer.id === customerId);
        //console.log('selectedCustomer', selectedOption);

        setAddEditCustomer(selectedOption);
        setIsAddEditCustomerDialogOpen(true);
    };

    const onCloseDialog = () => {
        setIsAddEditCustomerDialogOpen(false);
    };

    const onSaveUser = (savedUser) => {
        //try finding user in metadata
        const savedUserIndex = customersMetadataLocal.findIndex((user) => user.id === savedUser.id);

        let newCustomersMetadataLocal;

        if (savedUserIndex > -1) {
            newCustomersMetadataLocal = [
                ...customersMetadataLocal.slice(0, savedUserIndex),
                savedUser,
                ...customersMetadataLocal.slice(savedUserIndex + 1)
            ];
        } else {
            newCustomersMetadataLocal = [
                ...customersMetadataLocal,
                savedUser,
            ];
        }

        const customerOptionsFromMetadata = newCustomersMetadataLocal.map((customer) => (createCustomerOption(customer)));

        // console.log('newCustomersMetadataLocal', newCustomersMetadataLocal);
        // console.log('customerOptionsFromMetadata', customerOptionsFromMetadata);

        setCustomersMetadataLocal(newCustomersMetadataLocal);
        setCustomerOptions(customerOptionsFromMetadata);



        const newCustomerOption = createCustomerOption(savedUser);

        if (onChange) {
            onChange(newCustomerOption.value, newCustomerOption);
        }

        setIsAddEditCustomerDialogOpen(false);
    };

    return (
        <React.Fragment>
            <div style={{
                ...style,
                display: 'inline-flex',
                width: '100%',
                alignItems: 'center',
            }}>
                <Autocomplete
                    {...props}
                    options={customerOptions}
                    getOptionLabel={option => option.label}
                    onChange={(e, option) => {
                        if (typeof onChange === 'function') {
                            onChange(option ? option.value : null, option ? option : null);
                        }
                    }}
                    onClose={(event) => {
                        //console.log(change.target.value);
                    }}
                    renderInput={params => (
                        <TextField {...params}
                                   margin={nomargin ? "none" : "dense"}
                                   size="small"
                                   label={typeof props.label !== 'undefined' ? props.label : "Label"}
                                   variant="outlined"
                                   fullWidth
                                   error={error}
                                   helperText={helperText}
                                   style={{
                                       margin: nomargin ? 0 : '8px 0 8px 0',
                                   }}
                        />
                    )}
                    noOptionsText={
                        creatable ? (
                            <div onMouseDown={(e) => {
                                //e.preventDefault();
                                onCreateNewCustomerOption();
                            }}>
                                Sukurti naują
                            </div>
                        ) : (
                            <>
                            Pagal įvestą paiešką - pasirinkimų nėra
                            </>
                        )

                    }
                    value={selectedOption}
                    style={{flex: 1}}
                />
                <PermissionRequiredComponent permission={permissions.PERMISSION_USERS_CREATE}>
                    {creatable && showAddNew ? (
                        <Fab onClick={onCreateNewCustomerOption} color="primary" aria-label="add" size="small" style={{marginLeft: 12}}>
                            <AddIcon />
                        </Fab>
                    ) : null}
                </PermissionRequiredComponent>
            </div>
            {/*<CreatableMaterialReactSelect*/}
                {/*{...props}*/}
                {/*suggestions={customerOptions}*/}
                {/*onChange={(option) => {*/}
                    {/*//handleChange(e);*/}
                    {/*//setFieldValue('customer', option);*/}

                    {/*if (onChange) {*/}
                        {/*onChange(option ? option.value : null);*/}
                    {/*}*/}
                {/*}}*/}
                {/*onCreateOption={onCreateNewCustomerOption}*/}
                {/*openMenuOnClick={false}*/}
                {/*value={selectedOption}*/}
                {/*formatCreateLabel={(inputValue) => "Sukurti naują"}*/}
            {/*/>*/}
            {creatable ? (
                <>
                    <AddEditCustomerDialog
                        isOpen={isAddEditCustomerDialogOpen}
                        onCloseDialog={onCloseDialog}
                        onSaveUser={onSaveUser}
                        userId={typeof addEditCustomer.id === 'undefined' ? null : addEditCustomer.id}
                    />
                    {/*{customerId ? (*/}
                        {/*<IconButton onClick={onEditSelectedOption}>*/}
                            {/*<EditIcon fontSize="small" />*/}
                        {/*</IconButton>*/}
                    {/*) : null}*/}
                </>
            ) : null}

            <PermissionRequiredComponent permission={permissions.PERMISSION_USERS_EDIT}>
                <>
                    {selectedCustomer && showAdditionalInfo ? (
                        <CustomerListInfo
                            isCompany={selectedCustomer.isCompany}
                            email={selectedCustomer.username ? selectedCustomer.username : ''}
                            phoneNumberCountryCode={selectedCustomer.phoneNumberCountryCode}
                            phoneNumber={selectedCustomer.phoneNumber}
                            address={selectedCustomer.address}
                            code={selectedCustomer.code}
                            pvmCode={selectedCustomer.pvmCode}
                        />
                    ) : null}
                <div style={{display: 'flex'}}>
                    {/*<Button variant="outlined" onClick={onCreateNewCustomerOption}>*/}
                        {/*Naujas pirkėjas*/}
                    {/*</Button>*/}
                    <span style={{flex: 1}} />
                    {selectedCustomer && showEditButton ? (
                        <Button variant="outlined" onClick={onEditSelectedOption}>
                            Redaguoti
                        </Button>
                    ) : null}
                </div>
                </>
            </PermissionRequiredComponent>

            {/*<MaterialReactSelect*/}
                {/*{...props}*/}
                {/*options={customerOptions}*/}
                {/*onChange={(option) => {*/}
                    {/*if (onChange) {*/}
                        {/*onChange(option ? option.value : null);*/}
                    {/*}*/}
                {/*}}*/}
                {/*onCreateOption={onCreateNewCustomerOption}*/}
                {/*openMenuOnClick={false}*/}
                {/*value={selectedOption}*/}
                {/*noOptionsMessage={() => "Pagal įvestą paiešką - pasirinkimų nėra"}*/}
            {/*/>*/}
        </React.Fragment>
    );
}

export default CustomerSelect;