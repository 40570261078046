import React, {useEffect, useState} from "react";
import { useFormikContext } from 'formik';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Button from '@material-ui/core/Button';

import {useUploadImage} from 'shared-react/api/ImageApi';
import {
    useImageEditorDialog,
    addImage,
    removeImageToUpload
} from "./ImageEditorContext";
import ResponseErrors from "shared-react/utils/ResponseErrors";

const ImageUpload = ({imageType, imageInfo, index, forceCropping = false}) => {
    const { values } = useFormikContext();
    const [state, dispatch] = useImageEditorDialog();
    const [image, isLoading, errors, upload] = useUploadImage(imageType, imageInfo.file);

    // console.log('ImageUpload', image, isLoading, errors);

    useEffect(() => {
        upload();
    }, []);

    useEffect(() => {
        if (image !== null) {
            dispatch(addImage(image, forceCropping));
            dispatch(removeImageToUpload(index));
            // if (!objectId) {
            //     const newAssignImages = [
            //         ...values.assignImages,
            //         image.name,
            //     ];
            //     setFieldValue("assignImages", newAssignImages);
            // }
        }
    }, [image]); //image

    return (
        <React.Fragment>
            <div style={{
                position: 'absolute',
                zIndex: 1,
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
                padding: 5,
                border: '1px solid rgb(196, 205, 213)',
                background: 'rgba(244, 246, 248, 0.9)',
            }}>
                {isLoading ? (
                    <>
                        <CircularProgress size={30} style={{
                            margin: '5px 0',
                        }}/>
                        <Typography variant="caption">
                            Įkeliamia...
                        </Typography>
                    </>
                ) : null}
                {errors.length > 0 ? (
                    <>
                        <ResponseErrors errors={errors} />
                        <Button variant="outlined" size="small" color="primary" onClick={() => {
                            dispatch(removeImageToUpload(index));
                        }}>
                            OK
                        </Button>
                    </>
                ) : null}
            </div>
            <CropOriginalIcon fontSize="large"/>
        </React.Fragment>
    )
};

export default ImageUpload;