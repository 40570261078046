import React, {useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import isInteger from 'lodash/isInteger';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import DoneIcon from '@material-ui/icons/Done';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import WarehouseAmount from "products/WarehouseAmount";
import {MaterialTextField} from "shared-react/formik/MaterialFields";
import {addOrderProduct, NAME_NEW_ORDER} from "shared-react/ducks/order";
import {API_CALCULATE_ORDER} from "shared-react/api/OrderApi";
import Currency from 'shared-react/utils/Currency';
import LinkRoute from "shared-react/utils/LinkRoute";
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";
import {getProductImagePath, TYPE_THUMBNAIL} from "shared-react/utils/images";
import ListProductImageAvatar from "shared-react/shop/ListProductImageAvatar";

import useListSelect, {toggleSelected} from "../utils/ListSelect/ListSelectContext";

const StyledNoMarginMaterialTextField = styled(MaterialTextField)`
  && {
    margin: 0;
    width: 70px;
    
    .input {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

const StyledListItemTextAmount = styled(ListItemText)`
  && {
    padding-left: 0;
    padding-right: 16px;
    width: 70px;
    flex: 0 1 auto;
  }
`;

const StyledListItemTextPrice = styled(ListItemText)`
  && {
    padding-left: 0;
    padding-right: 16px;
    text-align: right;
    width: 80px;
    min-width: 80px;
    flex: 0 1 auto;
  }
`;

const StyledListItemTextBuyButton = styled(ListItemText)`
  && {
    width: 40px;
    padding-left: 0;
    padding-right: 0;
    flex: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 8px;
    cursor: initial;
  
    :hover {
      background: none;
    }
    
    & .icon {
      font-size: 17px
    }
  }
`;


function SearchProduct({product, warehouseId, productsInCartLookup, addOrderProduct}) {
    const initialAmountToBuy = product.variants.reduce((finalObject, variant) => {
        return {
            ...finalObject,
            [variant.id]: 1,
        };
    }, {});

    const [amountToBuy, setAmountToBuy] = useState(initialAmountToBuy);
    const [listSelectState, listSelectDispatch] = useListSelect();

    return (
        <ListItem key={product.id}>
            {listSelectState.isSelecting ? (
                <Checkbox
                    checked={listSelectState.selected.indexOf(product.id) > -1}
                    onChange={() => {
                        // toggleSelected(order.id)
                        listSelectDispatch(toggleSelected(product.id));
                    }}
                />
            ) : null}
            <ListProductImageAvatar
                productMainImage={product.mainImage}
            />
            <ListItemText
                primaryTypographyProps={{component: 'div'}}
                secondaryTypographyProps={{component: 'div'}}
                primary={
                    <React.Fragment>
                        <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_EDIT} fallback={product.name}>
                            <LinkRoute to={'/preke/' + product.id + '/redagavimas'}>{product.name}</LinkRoute>
                        </PermissionRequiredComponent>
                    </React.Fragment>
                }
                secondary={
                    <React.Fragment>
                        {product.variants.map((variant) => (
                            <React.Fragment key={variant.id}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{flex: 1}}>
                                        <div>
                                            {variant.code}
                                            {variant.sellable ? (
                                                <LoyaltyIcon size={'small'} style={{
                                                    width: '0.6em',
                                                    height: '0.6em',
                                                    marginLeft: 3,
                                                }} />
                                            ) : null}
                                        </div>
                                        <WarehouseAmount
                                            productWarehouses={variant.warehouses}
                                            warehouseId={warehouseId}
                                        />
                                        <Hidden smUp implementation="css" >
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                position: 'relative',
                                                boxSizing: 'border-box',
                                                textAlign: 'left',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                            }}>
                                                <div style={{width: 70, marginRight: 16}}>
                                                    <StyledNoMarginMaterialTextField
                                                        value={amountToBuy[variant.id]}
                                                        onChange={(e) => {
                                                            if (isInteger(parseInt(e.target.value))) {
                                                                setAmountToBuy({
                                                                    ...amountToBuy,
                                                                    [variant.id]: parseInt(e.target.value),
                                                                });
                                                            } else {
                                                                setAmountToBuy({
                                                                    ...amountToBuy,
                                                                    [variant.id]: 1,
                                                                });
                                                            }
                                                        }}
                                                        margin="dense"
                                                        type="number"
                                                        InputProps={{classes: {input: 'input'}}}
                                                        inputProps={{ min: "1", step: "1" }}
                                                    />
                                                </div>
                                                <div style={{
                                                    flex: '1 1 auto',
                                                    textAlign: 'left'
                                                }}>
                                                    <Typography variant="inherit" component="span" color="textPrimary">
                                                        <Currency value={variant.availablePrice}/>
                                                    </Typography>
                                                    {variant.availablePrice > 0 && variant.availablePrice < variant.price ? (
                                                        <React.Fragment>
                                                            <s><Currency value={variant.price}/></s><br/>
                                                        </React.Fragment>
                                                    ) : null}
                                                    {variant.availableUserGroupPrices.filter((userGroupPrice) => userGroupPrice.quantity > 1).length > 0 ? (
                                                        <React.Fragment>
                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        {variant.availableUserGroupPrices.map((userGroupPrice, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {userGroupPrice.quantity} po <Currency value={userGroupPrice.price}/><br/>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <a href={'#'}>Kiekio k.</a>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Hidden>
                                    </div>
                                    <div style={{
                                        margin: '0 5px',
                                    }}>
                                        {variant.variationOptionValuesString}
                                    </div>
                                    <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_CREATE}>
                                        <Hidden xsDown implementation="css">
                                            <StyledListItemTextAmount
                                                primary={
                                                    <React.Fragment>
                                                        <StyledNoMarginMaterialTextField
                                                            value={amountToBuy[variant.id]}
                                                            onChange={(e) => {
                                                                if (isInteger(parseInt(e.target.value))) {
                                                                    setAmountToBuy({
                                                                        ...amountToBuy,
                                                                        [variant.id]: parseInt(e.target.value),
                                                                    });
                                                                } else {
                                                                    setAmountToBuy({
                                                                        ...amountToBuy,
                                                                        [variant.id]: 1,
                                                                    });
                                                                }
                                                            }}
                                                            margin="dense"
                                                            type="number"
                                                            InputProps={{classes: {input: 'input'}}}
                                                            inputProps={{ min: "1", step: "1" }}
                                                            fullWidth
                                                        />
                                                    </React.Fragment>
                                                }
                                            />
                                        </Hidden>
                                    </PermissionRequiredComponent>
                                    <Hidden xsDown implementation="css">
                                        <StyledListItemTextPrice
                                            primary={
                                                <React.Fragment>
                                                    <Currency value={variant.availablePrice}/>
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    {variant.availablePrice > 0 && variant.availablePrice < variant.price ? (
                                                        <React.Fragment>
                                                            <s><Currency value={variant.price}/></s><br/>
                                                        </React.Fragment>
                                                    ) : null}
                                                    {variant.availableUserGroupPrices.filter((userGroupPrice) => userGroupPrice.quantity > 1).length > 0 ? (
                                                        <React.Fragment>
                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        {variant.availableUserGroupPrices.map((userGroupPrice, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {userGroupPrice.quantity} po <Currency value={userGroupPrice.price}/><br/>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <a href={'#'}>Kiekio k.</a>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    ) : null}
                                                </React.Fragment>
                                            }
                                        />
                                    </Hidden>
                                    <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_CREATE}>
                                        <StyledListItemTextBuyButton
                                            primary={
                                                <React.Fragment>
                                                    {productsInCartLookup.includes(warehouseId + '-' + variant.id) ? (
                                                        <React.Fragment>
                                                            <StyledIconButton disableRipple>
                                                                <DoneIcon />
                                                            </StyledIconButton>
                                                        </React.Fragment>
                                                    ) : (
                                                        <>
                                                            {isInteger(warehouseId) && isInteger(variant.id) && isInteger(amountToBuy[variant.id]) ? (
                                                                <Fab
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => {addOrderProduct(variant.id, warehouseId, amountToBuy[variant.id])}}
                                                                >
                                                                    <AddShoppingCartIcon />
                                                                </Fab>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            }
                                        />
                                    </PermissionRequiredComponent>
                                </div>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}

const mapDispatchToProps = (dispatch) => ({
    addOrderProduct: (productId, warehouseId, quantity) => {
        dispatch(addOrderProduct(NAME_NEW_ORDER, API_CALCULATE_ORDER, productId, warehouseId, quantity));
    },
});

export default connect(
    null,
    mapDispatchToProps
)(SearchProduct);