import React, {useEffect} from 'react';
import {Link, Redirect, Route} from 'react-router-dom'

import Typography from '@material-ui/core/Typography';

import ProductForm from 'products/ProductForm';
import ProductAddEditPage from 'products/ProductAddEditPage';
import LinkBack from "utils/LinkBack";
import PageTitle from "utils/PageTitle";
import {useGetProduct} from "shared-react/api/ProductApi";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import PageTitleWithButton from "utils/PageTitleWithButton";
import {getProductsPath, getProductPath} from 'shared-react/utils/routes';
import RoutePermission from 'utils/RoutePermission';
import * as permissions from 'shared-react/constants/permissions';


const VariantPage = ({ match }) => {
    const productId = match.params.id;
    const variantId = match.params.variantId;

    return (
        <div>
            <h2>VariantPage</h2>
            <h2>productId {productId}</h2>
            <h2>variantId {variantId}</h2>
            <Link to={"/prekes/" + productId}>Atgal i preke</Link> <br/>
        </div>
    );
};

function ProductPage({ match }) {
    const productId = match.params.id;
    const [product, setProduct, isProductLoading, productErrors, fetchProduct] = useGetProduct(productId);

    useEffect(() => {
        fetchProduct();
    }, [productId]);

    if (!product || isProductLoading) {
        return (<DataLoadingIndicator />);
    }

    if (product.isVariant) {
        return <Redirect to={getProductPath(product.parentId) + '/redagavimas'} />;
    }

    return (
        <div>
            <Route path={`${match.path}`} exact render={() => (
                <React.Fragment>
                    <LinkBack to={getProductsPath()} text={"Atgal"}/>
                    <PageTitleWithButton
                        pageTitle={product.name}
                        buttonTitle={"Redaguoti"}
                        buttonRoute={getProductPath(product.id) + '/redagavimas'}
                    />
                </React.Fragment>
            )} />

            {/*<Typography component="h2" variant="h1" gutterBottom>*/}
                {/*h1. Heading*/}
            {/*</Typography>*/}
            {/*<Link to={"/produktai/" + productId  + "/variantas/1"}>Variantas</Link> <br/>*/}
            {/*<Link to={"/produktas/" + productId  + "/edit"}>Edit</Link> <br/>*/}
            {/*{product.id} {product.name}*/}

            {/*<Switch>*/}
                {/*<Route path={"/variantas/:variantId"} component={VariantPage}/>*/}
                {/*<Route component={ProductForm}/>*/}
            {/*</Switch>*/}
            {/*<Route path="/prekes/:id/variantas/:variantId" component={VariantPage}/>*/}
            <Route path={`${match.path}/variantas/:variantId`} component={VariantPage}/>
            {/*<Route exact path={`${match.path}`} render={ProductForm}/>*/}
            <RoutePermission permission={permissions.PERMISSION_PRODUCTS_EDIT} path={`${match.path}/redagavimas`} render={() => (
                <React.Fragment>
                    <ProductAddEditPage product={product} onSaveSuccess={setProduct} />
                    {/*<LinkBack to={`${match.url}`} text={"Atgal"}/>*/}
                    {/*<div style={{display: 'flex', marginBottom: 8}}>*/}
                        {/*<PageTitle title={product.name + " redagavimas"}/>*/}
                    {/*</div>*/}
                    {/*<ProductForm*/}
                        {/*product={product}*/}
                        {/*onSaveSuccess={setProduct}*/}
                    {/*/>*/}
                </React.Fragment>
            )} />

            {/*<ProductForm/>*/}
        </div>
    );
}

export default ProductPage;