import React, {useContext} from 'react';
import { get } from "lodash";

import LastLocationContext from './LastLocationContext';

function useLastLocation(path) {
    const lastLocations = useContext(LastLocationContext);

    return get(lastLocations, path, null);
}

export default useLastLocation;