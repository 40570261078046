import React from 'react';
import {Formik, Form} from 'formik';
import {FormBuilderProvider} from 'shared-react/formik/FormBuilder/FormBuilderContext';
import getValidationSchema from 'shared-react/formik/FormBuilder/getValidationSchema';
import FocusError from "./FocusError";
import FormikFormWrapper from "./FormikFormWrapper";

function FormikForm({initialValues, validationSchema, bindSubmitForm, handleSubmit, children, formSchema = null, formValidationGroup = 'Default'}) {
    let validationSchemaToUse = null;
    if (validationSchema) {
        validationSchemaToUse = validationSchema;
    } else if (formSchema) {
        validationSchemaToUse = getValidationSchema(formSchema, formValidationGroup);
    }

    //console.log('formSchema', formSchema);
    //console.log('validationSchemaToUse', validationSchemaToUse);

    return (
        <FormBuilderProvider formSchema={formSchema}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaToUse}
                onSubmit={(values) => {
                    if (typeof handleSubmit === 'function') {
                        handleSubmit(values);
                    }
                }}
            >
                {({submitForm}) => {
                    if (typeof bindSubmitForm === 'function') {
                        bindSubmitForm(submitForm);
                    }

                    return (
                        <FormikFormWrapper>
                            {children}
                        </FormikFormWrapper>
                    )
                }}
            </Formik>
        </FormBuilderProvider>
    );
}

export default FormikForm;