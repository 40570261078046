import React, {useEffect} from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LinkWrapper from 'utils/LinkWrapper';
import {ListItemPriceText} from 'utils/List'
import ListTablePagination from "utils/ListTablePagination";

import Currency from 'shared-react/utils/Currency';
import {useGetProductTransfers} from "shared-react/api/ProductTransferApi";
import {ROUTE_PRODUCT_TRANSFERS_NEW, getProductTransfersPath, getProductTransferPath} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

function ProductTransfersPage({match, history}) {
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const perPage = 20;
    const [productTransfers, totalCount, areProductTransfersLoading, productTransfersErrors, fetchProductTransfers] = useGetProductTransfers(perPage, page);

    useEffect(() => {
        fetchProductTransfers();
    }, [page]);

    if (!productTransfers && areProductTransfersLoading) { // loading the page
        return (<DataLoadingIndicator />);
    } else if (!productTransfers) { //errors
        return (<ResponseErrors errors={productTransfersErrors} />);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 54,
                    padding: '0 16px',
                }}>
                    <span style={{flex: 1}} />
                    <Fab href={ROUTE_PRODUCT_TRANSFERS_NEW} color="primary" aria-label="add" size="small" style={{marginLeft: 12}}>
                        <AddIcon />
                    </Fab>
                </div>
                </>
            }>
                <Paper elavation={0} square>
                    <List dense={true}>
                        {productTransfers.map((productTransfer) =>
                            <ListItem
                                key={productTransfer.id}
                                button
                                to={getProductTransferPath(productTransfer.id)}
                                component={LinkWrapper}
                            >
                                <ListItemText
                                    primary={productTransfer.warehouseFrom.name + ' -> ' + productTransfer.warehouseTo.name}
                                    secondary={productTransfer.day + ' ' + productTransfer.time}
                                />
                                <ListItemPriceText>
                                    {productTransfer.productsCount}
                                </ListItemPriceText>
                            </ListItem>
                        )}
                    </List>
                    <ListTablePagination
                        perPage={perPage}
                        totalCount={totalCount}
                        page={page}
                        onChangePage={(event, page) => {
                            history.push(getProductTransfersPath(page));
                        }}
                    />
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default ProductTransfersPage;