
export const spacingTopPrefix = 'grid-spacing-padding-top-';
export const spacingBottomPrefix = 'grid-spacing-padding-bottom-';
export const spacingValues = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const loadSpacingCss = (editor) => {

    spacingValues.forEach((spacingValue) => {
        if (spacingValue) {
            editor.CssComposer.setRule('.' + spacingTopPrefix + spacingValue, {
                'padding-top': (parseInt(spacingValue, 10) * 8) + 'px !important',
            });
            editor.CssComposer.setRule('.' + spacingBottomPrefix + spacingValue, {
                'padding-bottom': (parseInt(spacingValue, 10) * 8) + 'px !important',
            });
        }
    });
};