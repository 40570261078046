import React from 'react'
import arrayMove from "array-move";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'image-editor/';
const OPEN_DIALOG = PREFIX + 'OPEN_DIALOG';
const CLOSE_DIALOG = PREFIX + 'CLOSE_DIALOG';
const PREVIOUS_SLIDE = PREFIX + 'PREVIOUS_SLIDE';
const NEXT_SLIDE = PREFIX + 'NEXT_SLIDE';
const START_CROPPING = PREFIX + 'START_CROPPING';
const CANCEL_CROPPING = PREFIX + 'CANCEL_CROPPING';
const FINISH_CROPPING = PREFIX + 'FINISH_CROPPING';
const FINISH_DELETING = PREFIX + 'FINISH_DELETING';
const IMAGES_ADD = PREFIX + 'IMAGES_ADD';
const IMAGES_TO_UPLOAD_REMOVE = PREFIX + 'IMAGES_TO_UPLOAD_REMOVE';
const IMAGES_TO_UPLOAD_ADD = PREFIX + 'IMAGES_TO_UPLOAD_ADD';
const SORT_END = PREFIX + 'SORT_END';

const initialState = {
    isOpen: false,
    isCropping: false,
    images: [],
    imagesToUpload: [],
    imageIndex: 0,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }
    //console.log('got reducer action', action);

    switch (action.type) {
        case OPEN_DIALOG:
            return {
                ...state,
                isOpen: true,
                imageIndex: action.payload.imageIndex,
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                isOpen: false,
                isCropping: false,
            };
        case PREVIOUS_SLIDE:
            return {
                ...state,
                imageIndex: state.imageIndex - 1 < 0 ? state.images.length - 1 : state.imageIndex - 1,
            };
        case NEXT_SLIDE:
            return {
                ...state,
                imageIndex: state.imageIndex + 1 > state.images.length - 1 ? 0 : state.imageIndex + 1,
            };
        case START_CROPPING:
            return {
                ...state,
                isCropping: true,
            };
        case CANCEL_CROPPING:
            return {
                ...state,
                isCropping: false,
            };
        case FINISH_CROPPING:
            const newImages = Object.assign([], state.images, {
                [action.payload.imageIndex]: {
                    ...state.images[action.payload.imageIndex],
                    ...action.payload.newImage,
                    //name: state.images[action.payload.imageIndex].name + '?' + new Date().getTime(),
                }
            });
            return {
                ...state,
                images: newImages,
                isCropping: false,
            };
        case FINISH_DELETING:
            const newImagesAfterDeletion = [
                ...state.images.slice(0, action.payload.imageIndex),
                ...state.images.slice(action.payload.imageIndex + 1),
            ];

            let newIsOpen = true;
            let newImageIndex = 0;

            if (newImagesAfterDeletion.length > 0) {
                newImageIndex = state.imageIndex - 1 < 0 ? 0 : state.imageIndex -1;
            } else {
                newIsOpen = false;
            }

            //console.log('FINISH_DELETING', newImagesAfterDeletion, newIsOpen, newImageIndex);

            return {
                ...state,
                images: newImagesAfterDeletion,
                isOpen: newIsOpen,
                imageIndex: newImageIndex,
            };
        case IMAGES_ADD:
            let forceCropping = {};
            if (action.payload.forceCropping && !state.isCropping) {
                forceCropping = {
                    isOpen: true,
                    isCropping: true,
                    imageIndex: state.images.length,
                };
            }

            return {
                ...state,
                images: [
                    ...state.images,
                    action.payload.image,
                ],
                ...forceCropping,
            };
        case IMAGES_TO_UPLOAD_ADD:
            return {
                ...state,
                imagesToUpload: [
                    ...state.imagesToUpload,
                    ...action.payload.imagesToUpload,
                ],
            };
        case IMAGES_TO_UPLOAD_REMOVE:
            return {
                ...state,
                imagesToUpload: state.imagesToUpload.filter((_, i) => i !== action.payload.imageIndex),
            };
        case SORT_END:
            return {
                ...state,
                images: arrayMove(state.images, action.payload.oldIndex, action.payload.newIndex),
            };
        default:
            return state;
    }
}

// Action Creators
export function openDialog(imageIndex) {
    return {type: OPEN_DIALOG, payload: {imageIndex}};
}

export function closeDialog() {
    return {type: CLOSE_DIALOG, payload: {}};
}

export function previousSlide() {
    return {type: PREVIOUS_SLIDE, payload: {}};
}

export function nextSlide() {
    return {type: NEXT_SLIDE, payload: {}};
}

export function startCropping() {
    return {type: START_CROPPING, payload: {}};
}

export function cancelCropping() {
    return {type: CANCEL_CROPPING, payload: {}};
}

export function finishCropping(imageIndex, newImage) {
    return {type: FINISH_CROPPING, payload: {imageIndex, newImage}};
}

export function finishDeleting(imageIndex) {
    return {type: FINISH_DELETING, payload: {imageIndex}};
}

export function addImage(image, forceCropping = false) {
    return {type: IMAGES_ADD, payload: {image, forceCropping}};
}

export function removeImageToUpload(imageIndex) {
    return {type: IMAGES_TO_UPLOAD_REMOVE, payload: {imageIndex}};
}

export function addImagesToUpload(imagesToUpload) {
    return {type: IMAGES_TO_UPLOAD_ADD, payload: {imagesToUpload}};
}

export function sortEnded(oldIndex, newIndex) {
    return {type: SORT_END, payload: {oldIndex, newIndex}};
}

function ImageEditorDialogProvider({images = [], children}) {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        images: images,
    });

    //console.log('change', state, dispatch);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

function useImageEditorDialogState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useImageEditorState must be used within a DialogProvider')
    }
    return context
}

function useImageEditorDialogDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useImageEditorDialogDispatch must be used within a ImageEditorDialogProvider')
    }
    return context
}

function useImageEditorDialog() {
    return [useImageEditorDialogState(), useImageEditorDialogDispatch()]
}

export {ImageEditorDialogProvider, useImageEditorDialogState, useImageEditorDialogDispatch, useImageEditorDialog}
