import React from 'react';
import styled from 'styled-components';

import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const StyledTooltip = styled(props => (
    <Tooltip
        classes={{ popper: props.className, tooltip: "tooltip" }}
        {...props}
    />
))`
  & .tooltip {
    background-color: ${props => props.theme.palette.common.white};
    color: rgba(0, 0, 0, 0.87);
    box-shadow: ${props => props.theme.shadows[1]};
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 0;
    margin-left: 5px;
    
    :hover {
      background: none;
    }
    
    & .info-icon {
      font-size: 17px
    }
  }
`;


function WarehouseAmount({productWarehouses, warehouseId}) {
    const theme = useTheme();
    const selectedProductWarehouseIndex = productWarehouses.findIndex((productWarehouse) => productWarehouse.warehouseId === warehouseId);
//console.log('WarehouseAmount', productWarehouses, warehouseId, selectedProductWarehouseIndex);
    return (
        <React.Fragment>
            Kiekis: {selectedProductWarehouseIndex > -1 ? productWarehouses[selectedProductWarehouseIndex].amount : 0}
            {/*<Tooltip*/}
                {/*title={*/}
                    {/*<React.Fragment>*/}
                        {/*{productWarehouses.map((productWarehouse, index) => (*/}
                            {/*<React.Fragment key={index}>*/}
                                {/*{productWarehouse.name} {productWarehouse.amount} <br/>*/}
                                {/*{productWarehouse.name} {productWarehouse.amount} <br/>*/}
                            {/*</React.Fragment>*/}
                        {/*))}*/}
                    {/*</React.Fragment>*/}
                {/*}*/}
                {/*theme={theme}*/}
            {/*>*/}
                {/*<StyledIconButton disableRipple>*/}
                    {/*<InfoOutlinedIcon classes={{root: 'info-icon'}} />*/}
                {/*</StyledIconButton>*/}
            {/*</Tooltip>*/}
        </React.Fragment>
    );
}

export default WarehouseAmount;