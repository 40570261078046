import {API_URL, getAuthTokenHeaders, useMakeRequest} from './Api';

const API_VARIATION_OPTIONS_URL = '/variationOptions';
const API_VARIATION_OPTION_VALUES_URL = '/variationOptionValues';

export function useSaveVariationOption(variationOptionData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (variationOptionData ? variationOptionData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_VARIATION_OPTIONS_URL + url, variationOptionData);

    return [ data, isLoading, errors, doRequest ];
}

export function useSaveVariationOptionValue(variationOptionValueData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (variationOptionValueData ? variationOptionValueData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_VARIATION_OPTION_VALUES_URL + url, variationOptionValueData);

    return [ data, isLoading, errors, doRequest ];
}