import React, {useEffect, useState} from 'react';
import get from 'lodash/get';

import {useGetObjects, API_ENDPOINT_NEWS} from "shared-react/api/ObjectApi";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";

import LinkWrapper from 'utils/LinkWrapper';
import {
    ROUTE_NEWS_NEW,
    getNewsSinglePath,
} from 'shared-react/utils/routes';
import PageTitle from "utils/PageTitle";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import StatusChipPublishedGroup from "shared-react/shop/statusChips/StatusChipPublishedGroup";
import {StyledListItemText} from "shared-react/utils/ListItemText";
import ListImageAvatar from "shared-react/shop/ListImageAvatar";

function NewsPage({match, history, location}) {
    // const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    // const page = typeof urlQueryParams.page === 'undefined' ? 1 : urlQueryParams.page;
    // const perPage = 100;
    // const queryParams = {
    //     ...urlQueryParams,
    //     'perPage': perPage,
    //     'page': page,
    // };
    // const queryString = '?' + qs.stringify(queryParams);

    const [listQueryString, setListQueryString] = useState({});
    const [news, totalCount, areNewsLoading, NewsErrors, fetchNews] = useGetObjects(API_ENDPOINT_NEWS, listQueryString);

    useEffect(() => {
        fetchNews();
    }, [listQueryString]);

    if (!news) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                <div style={{
                    padding: 8,
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    height: '100%',
                }}>
                    <PageTitle title={"Naujienos"}/>
                    <span style={{flex: 1}} />
                    <Fab href={ROUTE_NEWS_NEW} color="primary" aria-label="add" size="small" style={{marginLeft: 12}}>
                        <AddIcon />
                    </Fab>
                </div>
                </>
            }>
                <Paper elavation={0} square>
                    {news.length > 0 ? (
                        <List dense={true}>
                            {news.map((singleNews, index) => (
                                <ListItem
                                    key={index}
                                    button to={getNewsSinglePath(singleNews.id)}
                                    component={LinkWrapper}
                                >
                                    <ListImageAvatar image={singleNews.image} />
                                    <ListItemText
                                        primary={singleNews.title}
                                    />
                                    <StyledListItemText
                                        gutterLeft
                                        flex={false}
                                        primary={
                                            <React.Fragment>
                                                <StatusChipPublishedGroup publishedAt={get(singleNews, 'article.publishedAt', null)}/>
                                                <StatusChipPublishedGroup publishedAt={get(singleNews, 'newsletter.publishedAt', null)}/>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <>
                            Naujienų nėra
                        </>
                    )}
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default NewsPage;