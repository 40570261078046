import React from "react";
import PropTypes from 'prop-types';
import useLastLocation from 'utils/lastLocation/useLastLocation';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function BackToLastLocationButton({path, fallback}) {
    const lastLocation = useLastLocation(path);
    const history = useHistory();

    return (
        <React.Fragment>
            <IconButton style={{marginRight: 8}} onClick={() => {
                if (lastLocation !== null) {
                    history.push(lastLocation.pathname + lastLocation.search);
                } else {
                    history.push(fallback);
                }
            }}>
                <ArrowBackIcon />
            </IconButton>
        </React.Fragment>
    );
}

export default BackToLastLocationButton;

BackToLastLocationButton.propTypes = {
    path: PropTypes.string.isRequired,
    fallback: PropTypes.string.isRequired,
};