import React, {useEffect, useState} from 'react';
import {Field, FieldArray, Form, Formik} from "formik";
import datefnsFormat from 'date-fns/format';
import * as Yup from "yup";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';

import {
    FormDatePickerField,
    FormMaterialSelectField,
    FormCheckboxField,
} from "shared-react/formik/FormikMaterialFields";
import FormikCustomerSelectField from "shared-react/formik/FormikCustomerSelectField";
import {useGetMetadata} from "shared-react/api/MetadataApi";
import {usePrintCustomInvoice} from "shared-react/api/InvoiceApi";
import {API_SERVER_URL} from 'shared-react/api/Api';
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ProgressButton from "shared-react/utils/ProgressButton";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import Currency, {getCurrencyString} from 'shared-react/utils/Currency';
import {
    PAYMENT_METHOD_CASH,
} from 'shared-react/utils/paymentAndDelivery';
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";
import {parseFormDate} from "shared-react/utils/form";

export const issuersOptions = [
    {
        value: "2",
        label: "Direktorė Jūratė Šukionienė"
    },
    {
        value: "139",
        label: "Inga Preidienė"
    },
    {
        value: "9",
        label: "Gabija Šukionytė"
    }
];

const PrintInvoiceReceiptSchema = Yup.object().shape({
    invoiceIssuerId: Yup.string()
        .when('invoiceId', {
            is: null,
            then: Yup.string()
                .required('Laukas yra privalomas'),
        }),
    customerId: Yup.string()
        .when('invoiceId', {
            is: null,
            then: Yup.string()
                .required('Pirkėjo laukas yra privalomas'),
        }),
    invoiceDate: Yup.mixed()
        .required('Sąskaitos datos laukas yra privalomas'),
    dueDate: Yup.mixed()
        .when('invoiceDate', (invoiceDate, schema) => {
            return invoiceDate !== null ? Yup.date().nullable().min(invoiceDate, "Apmokėjimo data negali būti mažesnė už sąskaitos datą") : Yup.mixed().nullable();
        }),
    receipts: Yup.array().of(Yup.object().shape({
        date: Yup.date()
            .when('print', {
                is: true,
                then: Yup.date()
                    .typeError('Kvito datos laukas yra privalomas')
                    .required('Kvito datos laukas yra privalomas'),
                otherwise: Yup.date().nullable(),
            })
    })),
    // receiptDate: Yup.date()
    //     .when('createReceipt', {
    //         is: true,
    //         then: Yup.date()
    //             .typeError('Kvito datos laukas yra privalomas')
    //             .required('Kvito datos laukas yra privalomas'),
    //     })
});

const buildReceiptsInitialValues = (order) => {
    let receipts = [];

    if (order.invoice !== null && order.invoice.orderReceipts.length > 0) {
        const existingReceipts = order.invoice.orderReceipts.map((orderReceipt) => {
            return {
                print: false,
                date: parseFormDate(orderReceipt.day),
                id: orderReceipt.id,
                label: "Spausdinti kvitą " + orderReceipt.serie + orderReceipt.number,
            };
        });

        receipts = [
            ...existingReceipts,
        ];
    }

    if ((order.leftToPay > 0 && order.paymentMethod.code === PAYMENT_METHOD_CASH) || order.hasCashPaymentsWithoutReceipt) {
        receipts.push({
            print: false,
            date: parseFormDate(null),
            id: null,
            label: "Pridėti naują Pinigų Priėmimo Kvitą", // (" + getCurrencyString(order.leftToPay) + ")
        });
    }

    return receipts;
};

function PrintInvoiceReceipt({order, onCreated}) {
    const [isOpen, setIsOpen] = useState(false);
    const [metadata, isLoading, errors, fetchMetadata] = useGetMetadata(['userCustomers']);

    const [invoiceData, setInvoiceData] = useState({});
    const [createdPdf, setCreatedPdf, isSavingInvoice, errors2, createInvoicesPdf] = usePrintCustomInvoice(invoiceData, true);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (createdPdf) {
            window.open(API_SERVER_URL + '/pdfs/' + createdPdf, "_blank");

            if (typeof onCreated === 'function') {
                onCreated(createdPdf);
            }

            setCreatedPdf(null);
            setIsOpen(false);
        }
    }, [createdPdf]);

    const hasInvoice = order.invoice !== null;

    console.log('order invoice', order);
    const initialValues = {
        invoiceIssuerId: "2",
        customerId: order.customer.id,
        orderId: order.id,
        printInvoice: order.invoice === null,
        invoiceDate: parseFormDate(order.invoice === null ? null : order.invoice.day),
        createReceipt: order.hasCashPaymentsWithoutReceipt,
        invoiceDueDate: order.invoice === null || !order.invoice.dueDate ? null : parseFormDate(order.invoice.dueDate),
        receipts: buildReceiptsInitialValues(order),
        copies: 1,
        invoiceId: order.invoice !== null ? order.invoice.id : null,
    };
    //console.log('order invoice initialValues', initialValues);

    const isThereAnActionNeeded = order.invoice === null || order.paymentStatus.code !== 'STATUS_PAID' || order.invoice.needsPrinting;

    return (
        <React.Fragment>
            <PermissionRequiredComponent permission={permissions.PERMISSION_INVOICES_CREATE}>
                <Button variant={isThereAnActionNeeded ? "contained" : "outlined"} color="primary" onClick={() => setIsOpen(!isOpen)}>
                    {isThereAnActionNeeded ? (<span>Išrašyti</span>) : (<span>Spausdinti</span>)}&nbsp;<span>sąskaitą, kvitą</span>
                </Button>
            </PermissionRequiredComponent>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <React.Fragment>
                    {!metadata ? (
                        <DataLoadingIndicator />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={PrintInvoiceReceiptSchema}
                            onSubmit={(values) => {
                                console.log('values', values);
                                const receipts = values.receipts.filter((receipt) => receipt.print).map((receipt) => ({
                                    print: receipt.print,
                                    date: receipt.date ? datefnsFormat(receipt.date, 'yyyy-MM-dd') : null,
                                    id: receipt.id,
                                }));

                                const valuesToSubmit = {
                                    ...values,
                                    invoiceDate: values.invoiceDate ? datefnsFormat(values.invoiceDate, 'yyyy-MM-dd') : null,
                                    invoiceDueDate: values.invoiceDueDate ? datefnsFormat(values.invoiceDueDate, 'yyyy-MM-dd') : null,
                                    receipts: receipts,
                                };
                                setInvoiceData(valuesToSubmit);
                                console.log('valuesToSubmit', valuesToSubmit);
                                createInvoicesPdf();
                            }}
                        >
                            {({values, errors, setFieldValue, handleSubmit}) => {
                                console.log('values', values);
                                console.log('errors', errors);
                                return (
                                    <Form>
                                        <DialogTitle>Sąskaitos, pinigų priėmimo kvito išrašymas</DialogTitle>
                                        <DialogContent>
                                            <React.Fragment>
                                                <div style={{padding: 8}}>
                                                    <Grid container spacing={4}>
                                                        {order.invoice !== null ? (
                                                            <Grid item xs={12} >
                                                                <Field
                                                                    name="printInvoice"
                                                                    label={"Spausdinti sąskaitą " + order.invoice.serie + order.invoice.number}
                                                                    component={FormCheckboxField}
                                                                />
                                                            </Grid>
                                                        ) : null}
                                                        {values.printInvoice ? (
                                                            <React.Fragment>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Field
                                                                        name="invoiceIssuerId"
                                                                        label="Išrašo"
                                                                        component={FormMaterialSelectField}
                                                                        fullWidth
                                                                        // nomargin={1}
                                                                        margin="dense"
                                                                        options={issuersOptions}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Field
                                                                        name="customerId"
                                                                        label={"Pirkėjas"}
                                                                        component={FormikCustomerSelectField}
                                                                        creatable
                                                                        customersMetadata={metadata.userCustomers}
                                                                        onChange={(customerId) => {
                                                                            //setOrderCustomerId(option === null ? '' : option.value);
                                                                            //handleChange(e);
                                                                            setFieldValue('customerId', customerId);
                                                                        }}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        //menuPortalTarget={document.body}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Field
                                                                        name="invoiceDate"
                                                                        label={'Sąskaitos data'}
                                                                        component={FormDatePickerField}
                                                                        invalidDateMessage={"Neegzistuojanti data"}
                                                                        clearable
                                                                        fullWidth
                                                                        nomargin={1}
                                                                        margin="dense"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Field
                                                                        name="invoiceDueDate"
                                                                        label={'Apmokėti iki'}
                                                                        component={FormDatePickerField}
                                                                        invalidDateMessage={"Neegzistuojanti data"}
                                                                        clearable
                                                                        fullWidth
                                                                        nomargin={1}
                                                                        margin="dense"
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        ) : null}
                                                        {(order.leftToPay > 0 && order.paymentMethod.code === PAYMENT_METHOD_CASH) || values.createReceipt === true || (order.invoice !== null && order.invoice.orderReceipts.length > 0) ? (
                                                            <React.Fragment>
                                                                <ListSubheader component="div" style={{lineHeight: '18px', marginTop: 18, padding: 0, width: '100%'}} disableSticky>
                                                                    Kvitai
                                                                </ListSubheader>
                                                            </React.Fragment>
                                                        ) : null}
                                                        <FieldArray
                                                            name="receipts"
                                                            render={arrayHelpers => (
                                                                <React.Fragment>
                                                                    {values.receipts && values.receipts.length > 0 ? (
                                                                        values.receipts.map((orderReceipt, index) => {
                                                                            return (
                                                                                <React.Fragment key={'orderReceipt' + orderReceipt.id}>
                                                                                    <Grid item xs={12} sm={6} >
                                                                                        <FormGroup row>
                                                                                            <Field
                                                                                                name={`receipts.${index}.print`}
                                                                                                label={orderReceipt.label}
                                                                                                component={FormCheckboxField}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Grid>
                                                                                    {orderReceipt.print ? (
                                                                                        <Grid item xs={12} sm={6} >
                                                                                            <Field
                                                                                                name={`receipts.${index}.date`}
                                                                                                label={'Kvito data'}
                                                                                                component={FormDatePickerField}
                                                                                                invalidDateMessage={"Neegzistuojanti data"}
                                                                                                clearable
                                                                                                fullWidth
                                                                                                nomargin={1}
                                                                                                margin="dense"
                                                                                            />
                                                                                        </Grid>
                                                                                    ) : null}
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    ) : null }
                                                                </React.Fragment>
                                                            )}
                                                        />
                                                    </Grid>
                                                </div>
                                            </React.Fragment>
                                        </DialogContent>
                                        <DialogActions style={{display: 'flex'}}>
                                            <Button onClick={() => setIsOpen(false)} color="primary">
                                                Uždaryti
                                            </Button>
                                            <ResponseErrors errors={errors2} />
                                            <span style={{flex: 1}}/>
                                            <ProgressButton
                                                loading={isSavingInvoice}
                                                text={"Išrašyti"}
                                                onClick={handleSubmit}
                                            />
                                        </DialogActions>
                                    </Form>
                                )
                            }}
                        </Formik>
                    )}
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );
}

export default PrintInvoiceReceipt;