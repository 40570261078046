import React from 'react';
import {connect} from 'react-redux';

import List from '@material-ui/core/List';
import DraftsIcon from '@material-ui/icons/Drafts';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PeopleIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Category';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LayersIcon from '@material-ui/icons/Layers';
import RepeatIcon from '@material-ui/icons/Repeat';
import WebIcon from '@material-ui/icons/Web';

import ListItemNestedLink from "menu/ListItemNestedLink";
import ListItemLink from "menu/ListItemLink";
import ListItemLogout from "menu/ListItemLogout";

import PermissionRequiredComponent from 'shared-react/utils/PermissionRequiredComponent';

import {
    getProductsPath,
    getProductCategoriesPath,
    getSaleOrdersPath,
    getPurchaseOrdersPath,
    getPackagesPath,
    getUsersPath,
    getUserGroupsPath,
    getUserPermissionGroupsPath,
    getProductLowQuantityPath,
    getInvoicesPath,
    getCarouselSlidesPath,
    getNewsPagePath,
    ROUTE_SALE_ORDERS,
    ROUTE_PRODUCTS,
    ROUTE_PRODUCT_CATEGORIES,
    ROUTE_PRODUCT_LOW_QUANTITY,
    ROUTE_PRODUCT,
    ROUTE_PRODUCT_CATEGORY,
    ROUTE_SALE_ORDER,
    ROUTE_USERS,
    ROUTE_USER,
    ROUTE_USER_GROUPS,
    ROUTE_USER_GROUP,
    ROUTE_USER_PERMISSION_GROUPS,
    ROUTE_USER_PERMISSION_GROUP,
    ROUTE_USER_PERMISSION_GROUPS_NEW,
    ROUTE_USER_GROUPS_NEW,
    ROUTE_USERS_NEW,
    ROUTE_PURCHASE_ORDERS,
    ROUTE_PURCHASE_ORDER,
    ROUTE_PURCHASE_ORDERS_NEW,
    ROUTE_PACKAGES,
    ROUTE_PACKAGE,
    ROUTE_PACKAGES_NEW,
    ROUTE_INVOICES,
    ROUTE_INVOICE,
    ROUTE_PRODUCT_CATEGORIES_NEW,
    ROUTE_PRODUCTS_NEW,
    ROUTE_SALE_ORDER_EDIT,
    ROUTE_CAROUSEL_SLIDES,
    ROUTE_CAROUSEL_SLIDE,
    ROUTE_CAROUSEL_NEW_SLIDE,
    ROUTE_NEWS_NEW,
    ROUTE_NEWS_PAGE,
    ROUTE_NEWS_SINGLE,
    getProductCollectionsPath,
    ROUTE_PRODUCT_COLLECTIONS,
    ROUTE_PRODUCT_COLLECTION,
    ROUTE_PRODUCT_COLLECTIONS_NEW,
    getPrintInvoicePath,
    getPrintReceiptPath,
    ROUTE_PRINT_INVOICE,
    ROUTE_PRINT_RECEIPT,
} from 'shared-react/utils/routes';
import * as routes from 'shared-react/utils/routes';
import * as permissions from "shared-react/constants/permissions";

function MenuLinks({match, lowProductsWarehouseIds}) {
    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <List component="nav" style={{padding: 0, display: 'flex', flexDirection: 'column', height: '100%'}}>
                {/*<ListItemLink to="/" primary="Home" icon={<DashboardIcon />} />*/}
                {/*<ListItemLink to="/paieska" primary="Paieška" icon={<DraftsIcon />} />*/}
                {/*<ListItemLink to="/krepselis" primary="Krepšelis" icon={<DraftsIcon />} />*/}
                <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_LIST_VIEW}>
                    <ListItemLink
                        primary="Pardavimai"
                        to={getSaleOrdersPath()}
                        pathsToMatch={[ROUTE_SALE_ORDERS, ROUTE_SALE_ORDER, ROUTE_SALE_ORDER_EDIT]}
                        icon={<ShoppingCartIcon />} />
                </PermissionRequiredComponent>
                <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_LIST_VIEW}>
                    <ListItemNestedLink
                        primary="Prekės"
                        to={getProductsPath()}
                        pathsToMatch={[
                            ROUTE_PRODUCTS, ROUTE_PRODUCT, ROUTE_PRODUCTS_NEW,
                            ROUTE_PRODUCT_CATEGORIES, ROUTE_PRODUCT_CATEGORY, ROUTE_PRODUCT_CATEGORIES_NEW,
                            ROUTE_PRODUCT_COLLECTIONS, ROUTE_PRODUCT_COLLECTION, ROUTE_PRODUCT_COLLECTIONS_NEW,
                            ROUTE_PRODUCT_LOW_QUANTITY
                        ]} icon={<CategoryIcon />}
                    >
                        <ListItemLink
                            primary="Visos prekės"
                            nested
                            to={getProductsPath()}
                            pathsToMatch={[ROUTE_PRODUCTS, ROUTE_PRODUCT, ROUTE_PRODUCTS_NEW]}
                            icon="" />
                        <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCT_CATEGORIES_LIST_VIEW}>
                            <ListItemLink
                                primary="Kategorijos"
                                nested
                                to={getProductCategoriesPath()}
                                pathsToMatch={[ROUTE_PRODUCT_CATEGORIES, ROUTE_PRODUCT_CATEGORY, ROUTE_PRODUCT_CATEGORIES_NEW]}
                                icon="" />
                        </PermissionRequiredComponent>
                        <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCT_CATEGORIES_LIST_VIEW}>
                            <ListItemLink
                                primary="Kolekcijos"
                                nested
                                to={getProductCollectionsPath()}
                                pathsToMatch={[ROUTE_PRODUCT_COLLECTIONS, ROUTE_PRODUCT_COLLECTION, ROUTE_PRODUCT_COLLECTIONS_NEW]}
                                icon="" />
                        </PermissionRequiredComponent>
                        <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_LOW_QUANTITY_LIST_VIEW}>
                            <ListItemLink
                                primary="Trūkstamos prekės"
                                nested
                                to={getProductLowQuantityPath(1, lowProductsWarehouseIds)} pathsToMatch={ROUTE_PRODUCT_LOW_QUANTITY}
                                icon="" />
                        </PermissionRequiredComponent>
                    </ListItemNestedLink>
                </PermissionRequiredComponent>
                <PermissionRequiredComponent permission={permissions.PERMISSION_USERS_LIST_VIEW}>
                    <ListItemNestedLink
                        primary="Klientai"
                        to={getUsersPath()}
                        pathsToMatch={[
                            ROUTE_USERS, ROUTE_USER, ROUTE_USERS_NEW,
                            ROUTE_USER_GROUPS, ROUTE_USER_GROUP, ROUTE_USER_GROUPS_NEW,
                            ROUTE_USER_PERMISSION_GROUPS, ROUTE_USER_PERMISSION_GROUP, ROUTE_USER_PERMISSION_GROUPS_NEW
                        ]}
                        icon={<PeopleIcon />}
                    >
                        <ListItemLink
                            primary="Visi klientai"
                            nested
                            to={getUsersPath()}
                            pathsToMatch={[ROUTE_USERS, ROUTE_USER, ROUTE_USERS_NEW]}
                            icon="" />
                        <PermissionRequiredComponent permission={permissions.PERMISSION_USER_GROUPS_LIST_VIEW}>
                            <ListItemLink
                                primary="Grupės"
                                nested
                                to={getUserGroupsPath()}
                                pathsToMatch={[ROUTE_USER_GROUPS, ROUTE_USER_GROUP, ROUTE_USER_GROUPS_NEW]}
                                icon=""
                            />
                        </PermissionRequiredComponent>
                        <PermissionRequiredComponent permission={permissions.PERMISSION_USER_PERMISSIONS_LIST_VIEW}>
                            <ListItemLink
                                primary="Teisės"
                                nested
                                to={getUserPermissionGroupsPath()}
                                pathsToMatch={[ROUTE_USER_PERMISSION_GROUPS, ROUTE_USER_PERMISSION_GROUP, ROUTE_USER_PERMISSION_GROUPS_NEW]}
                                icon=""
                            />
                        </PermissionRequiredComponent>
                    </ListItemNestedLink>
                </PermissionRequiredComponent>
                <PermissionRequiredComponent permission={permissions.PERMISSION_PURCHASE_ORDERS_LIST_VIEW}>
                    <ListItemLink
                        primary="Užsakymai"
                        to={getPurchaseOrdersPath()}
                        pathsToMatch={[ROUTE_PURCHASE_ORDERS, ROUTE_PURCHASE_ORDER, ROUTE_PURCHASE_ORDERS_NEW]}
                        icon={<LocalShippingIcon />}
                    />
                </PermissionRequiredComponent>
                <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCT_TRANSFERS_LIST_VIEW}>
                    <ListItemLink
                        primary="Pervežimai"
                        to={routes.getProductTransfersPath()}
                        pathsToMatch={[routes.ROUTE_PRODUCT_TRANSFERS, routes.ROUTE_PRODUCT_TRANSFER, routes.ROUTE_PRODUCT_TRANSFERS_NEW]}
                        icon={<RepeatIcon />}
                    />
                </PermissionRequiredComponent>
                <PermissionRequiredComponent permission={permissions.PERMISSION_PACKAGES_LIST_VIEW}>
                    <ListItemLink
                        primary="Pakuotės"
                        to={getPackagesPath()}
                        pathsToMatch={[ROUTE_PACKAGES, ROUTE_PACKAGE, ROUTE_PACKAGES_NEW]}
                        icon={<LayersIcon />}
                    />
                </PermissionRequiredComponent>
                <PermissionRequiredComponent permission={permissions.PERMISSION_INVOICES_LIST_VIEW}>
                    <ListItemNestedLink
                        primary="Buhalterija"
                        to={getInvoicesPath()}
                        pathsToMatch={[
                            ROUTE_INVOICES, ROUTE_INVOICE, ROUTE_PRINT_INVOICE, ROUTE_PRINT_RECEIPT
                        ]}
                        icon={<PeopleIcon />}
                    >
                        <ListItemLink
                            primary="Sąskaitos"
                            nested
                            to={getInvoicesPath()}
                            pathsToMatch={[ROUTE_INVOICES, ROUTE_INVOICE]}
                            icon=""
                        />
                        <ListItemLink
                            primary="Spausdinti sąskaitą"
                            nested
                            to={getPrintInvoicePath()}
                            pathsToMatch={[ROUTE_PRINT_INVOICE]}
                            icon=""
                        />
                        <ListItemLink
                            primary="Spausdinti kvitą"
                            nested
                            to={getPrintReceiptPath()}
                            pathsToMatch={[ROUTE_PRINT_RECEIPT]}
                            icon=""
                        />
                    </ListItemNestedLink>
                </PermissionRequiredComponent>
                <PermissionRequiredComponent permission={permissions.PERMISSION_INVOICES_LIST_VIEW}>
                    <ListItemNestedLink
                        primary="Svetainė"
                        to={getCarouselSlidesPath()}
                        pathsToMatch={[
                            ROUTE_CAROUSEL_SLIDES, ROUTE_CAROUSEL_SLIDE, ROUTE_CAROUSEL_NEW_SLIDE,
                            ROUTE_NEWS_PAGE, ROUTE_NEWS_NEW, ROUTE_NEWS_SINGLE,
                        ]}
                        icon={<WebIcon />}
                    >
                        <ListItemLink
                            primary="Karuselė"
                            nested
                            to={getCarouselSlidesPath()}
                            pathsToMatch={[ ROUTE_CAROUSEL_SLIDES, ROUTE_CAROUSEL_SLIDE, ROUTE_CAROUSEL_NEW_SLIDE ]}
                            icon=""
                        />
                        <ListItemLink
                            primary="Naujienos"
                            nested
                            to={getNewsPagePath()}
                            pathsToMatch={[ ROUTE_NEWS_PAGE, ROUTE_NEWS_NEW, ROUTE_NEWS_SINGLE ]}
                            icon=""
                        />
                    </ListItemNestedLink>
                </PermissionRequiredComponent>
                <span style={{flex: 1}}></span>
                <ListItemLogout icon={<ArrowBackIcon />} />
            </List>

            {/*<ul>*/}
                {/*<li><Link to="/">Home</Link></li>*/}
                {/*<li><Link to="/paieska">Paieška</Link></li>*/}
                {/*<li><Link to="/krepselis">Krepšelis</Link></li>*/}
                {/*<li><Link to="/pardavimai">Pardavimai</Link></li>*/}
                {/*<li><Link to="/uzsakymai">Užsakymai</Link></li>*/}
                {/*<li><Link to="/pakuotes">Pakuotės</Link></li>*/}
                {/*<li><Link to="/login">Login</Link></li>*/}
                {/*<li><Link to="/authenticated">Authenticated</Link></li>*/}
                {/*<PermissionRequiredComponent permission={'PERMISSION_USERS_VIEW'}>*/}
                    {/*<li><Link to="/klientai">Klientai</Link></li>*/}
                {/*</PermissionRequiredComponent>*/}
                {/*<li><Link to="/klientu-teises">Klientų teisės</Link></li>*/}
                {/*<li><Link to="/klientu-grupes">Klientų grupės</Link></li>*/}
                {/*<li><Link to="/prekes">Prekės</Link></li>*/}
                {/*<li><Link to="/prekiu-kategorijos">Prekių kategorijos</Link></li>*/}
                {/*<li><Link to="/404">404</Link></li>*/}
                {/*<li><a href="#" onClick={logoutUser}>Logout</a></li>*/}
            {/*</ul>*/}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        lowProductsWarehouseIds: state.lowProducts.warehouseIds,
    }
};

export default connect(
    mapStateToProps,
    null
)(MenuLinks);