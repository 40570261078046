import React from 'react'

const StateContext = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'form-builder/';

const initialState = {
    formSchema: null,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }

    switch (action.type) {
        default:
            return state;
    }
}

// Action Creators

function FormBuilderProvider({formSchema = [], children}) {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        formSchema: formSchema,
    });

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

function useFormBuilderState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useImageEditorState must be used within a DialogProvider')
    }
    return context
}

function useFormBuilderDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useFormBuilderDispatch must be used within a FormBuilderProvider')
    }
    return context
}

function useFormBuilder() {
    return [useFormBuilderState(), useFormBuilderDispatch()]
}

export {FormBuilderProvider, useFormBuilderState, useFormBuilderDispatch, useFormBuilder}
