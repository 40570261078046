import React, {useState, useEffect} from 'react';
import qs from 'qs';
import {pickBy, identity} from "lodash";
import { useHistory, useLocation } from 'react-router-dom';

import {MaterialTextField} from "shared-react/formik/MaterialFields";
import useDebouncedCallback from "shared-react/useDebounce/callback";

function QuerySearchTextField({queryName, label, ...props}) {
    const history = useHistory();
    const location = useLocation();
    const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [fieldValue, setFieldValue] = useState(typeof urlQueryParams[queryName] !== 'undefined' ? urlQueryParams[queryName] : '');

    useEffect(() => {
        setFieldValue(typeof urlQueryParams[queryName] !== 'undefined' ? urlQueryParams[queryName] : '');
    }, [location]);

    const [setDebounceTextValue] = useDebouncedCallback(
        (textValue) => {
            const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            const newQueryParams = {
                ...urlQueryParams,
                page: 1,
                [queryName]: textValue,
            };
            const notEmptyQueryParams = pickBy(newQueryParams, identity);
            const queryString = '?' + qs.stringify(notEmptyQueryParams);

            history.push(location.pathname + queryString);
        },
        1000,
        []
    );

    return (
        <MaterialTextField
            margin="dense"
            label={label}
            onChange={(e) => {
                setDebounceTextValue(e.target.value);
                setFieldValue(e.target.value);
            }}
            value={fieldValue}
            fullWidth
            {...props}
        />
    );
}
export default QuerySearchTextField;