import React, {useEffect,useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux'

import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";

import {useGetMetadata} from "shared-react/api/MetadataApi";
import {
    updateOrderProduct,
    removeOrderProduct,
    setOrderSellerId,
    setOrderCustomerId,
    saveOrder,
    setOrderDataField,
    setOrderCustomDeliveryPrice, NAME_NEW_ORDER,
    resetOrderAndData, NAME_EDIT_ORDER,
} from "shared-react/ducks/order";
import {useSaveOrder, API_CALCULATE_ORDER, API_ORDERS} from "shared-react/api/OrderApi";
import {getSaleOrderPath} from 'shared-react/utils/routes';
import OrderForm from 'shared-react/shop/OrderForm';
import OrderForm2 from "shared-react/shop/OrderForm2";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import ProgressButton from 'shared-react/utils/ProgressButton';
import ListIemKeyValue from 'shared-react/utils/ListIemKeyValue';

export const StyledListItemTextPrice = styled(ListItemText)`
  && {
    padding-left: 0;
    padding-right: 16px;
    text-align: right;
    min-width: 70px;
    flex: 0 1 auto;
  }
`;

export const StyledListItemTextTotals = styled(ListItemText)`
  && {
    width: 40px;
    padding-left: 0;
    padding-right: 0;
    flex: none;
    text-align: right;
  }
`;

function CheckoutPage({
                          newOrderData,
                          newOrder,
                          newOrderUpdateUuid,
                          isOrderCalculating,
                          history,
                          setOrderCustomDeliveryPrice,
                          setOrderSellerId,
                          setOrderCustomerId,
                          setOrderDataField,
                          updateOrderProduct,
                          resetOrderAndData,
                          removeOrderProduct,
                          recalculate
}) {
    // const [metadata, setMetadata] = useState(null);
    const [savedOrder, setSavedOrder, isSubmitting, orderErrors, saveOrder] = useSaveOrder(API_ORDERS, newOrderData, true);
    const [metadata, isLoading, errors, fetchMetadata] = useGetMetadata(['userCustomers', 'userSellers', 'userDeliveryMethods', 'userPaymentMethods', 'warehouses', 'availablePayseraPayments', 'availableOmnivaMachines', 'userWarehouses']);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        if (savedOrder) {
            setSavedOrder(null);
            resetOrderAndData();
            history.push(getSaleOrderPath(savedOrder.id));
        }
    }, [savedOrder]);

    let submitMyForm = null;

    const handleSubmitMyForm = (e) => {
        if (submitMyForm) {
            submitMyForm(e);
        }
    };

    const bindSubmitForm = (submitForm) => {
        submitMyForm = submitForm;
    };

    const onSubmitOrderForm = (values) => {
        saveOrder();
    };

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    if (newOrder.fulfillments.length === 0 && isOrderCalculating) {
        return (<DataLoadingIndicator />);
    }

    if (newOrder.fulfillments.length === 0) {
        return (<div>Krepšelis tuščias...</div>);
    }

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <span style={{
                            flex: 1,
                        }} />
                        {/*<List dense style={{*/}
                            {/*padding: 0,*/}
                        {/*}}>*/}
                            {/*<ListIemKeyValue dense title={"Produktų suma"} value={12.25} />*/}
                            {/*<ListIemKeyValue dense title={"Pristatymas"} value={5.00} />*/}
                        {/*</List>*/}
                        {/*<List dense style={{*/}
                            {/*padding: 0,*/}
                        {/*}}>*/}
                            {/*<ListIemKeyValue dense title={"Iš Viso"} value={546.33} />*/}
                        {/*</List>*/}
                        <ProgressButton
                            loading={isOrderCalculating || isSubmitting}
                            text={"Pateikti užsakymą"}
                            onClick={(e) => {
                                console.log('on click');
                                handleSubmitMyForm(e);
                            }}
                            color="secondary"
                        />
                    </div>
                </>
            }>
                <OrderForm2
                    onSubmitOrderForm={onSubmitOrderForm}
                    bindSubmitForm={bindSubmitForm}
                    orderData={newOrderData}
                    order={newOrder}
                    orderUuid={newOrderUpdateUuid}
                    isUpdating={isOrderCalculating}
                    isSubmitting={isSubmitting}
                    showAddProducts={false}
                    setOrderCustomDeliveryPrice={setOrderCustomDeliveryPrice}
                    setOrderSellerId={setOrderSellerId}
                    setOrderCustomerId={setOrderCustomerId}
                    updateOrderProduct={updateOrderProduct}
                    removeOrderProduct={removeOrderProduct}
                    metadataUserSellers={metadata.userSellers}
                    metadataCustomers={metadata.userCustomers}
                    metadata={metadata}
                    responseErrors={orderErrors}
                    metadataUserDeliveryMethods={metadata.userDeliveryMethods}
                    metadataUserPaymentMethods={metadata.userPaymentMethods}
                    setOrderDataField={setOrderDataField}
                    availablePayseraPayments={metadata.availablePayseraPayments}
                    availableOmnivaMachines={metadata.availableOmnivaMachines}
                />
            </FillHeightContainer>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    isOrderCalculating: state.newOrder.isCalculating,
    newOrderData: state.newOrder.orderData,
    newOrder: state.newOrder.order,
    newOrderUpdateUuid: state.newOrder.updateUuid,
});

const mapDispatchToProps = (dispatch) => ({
    updateOrderProduct: (fulfillmentIndex, productId, warehouseId, quantity, customPrice, customPriceReason) => {
        dispatch(updateOrderProduct(NAME_NEW_ORDER, API_CALCULATE_ORDER, fulfillmentIndex, productId, warehouseId, quantity, customPrice, customPriceReason));
    },
    resetOrderAndData: () => {
        dispatch(resetOrderAndData(NAME_NEW_ORDER));
    },
    removeOrderProduct: (fulfillmentIndex, productId, warehouseId) => {
        dispatch(removeOrderProduct(NAME_NEW_ORDER, API_CALCULATE_ORDER, fulfillmentIndex, productId, warehouseId));
    },
    setOrderSellerId: (sellerId) => {
        dispatch(setOrderSellerId(NAME_NEW_ORDER, API_CALCULATE_ORDER, sellerId));
    },
    setOrderCustomerId: (customerId) => {
        dispatch(setOrderCustomerId(NAME_NEW_ORDER, API_CALCULATE_ORDER, customerId));
    },
    saveOrder: () => {
        dispatch(saveOrder(NAME_NEW_ORDER, API_ORDERS));
    },
    setOrderCustomDeliveryPrice: (customDeliveryPrice) => {
        dispatch(setOrderCustomDeliveryPrice(NAME_NEW_ORDER, API_CALCULATE_ORDER, customDeliveryPrice));
    },
    setOrderDataField: (fieldName, fieldValue, shouldRecalculate = false) => {
        dispatch(setOrderDataField(NAME_NEW_ORDER, API_CALCULATE_ORDER, fieldName, fieldValue, shouldRecalculate));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutPage);