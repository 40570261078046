import {setupClassTrait} from 'utils/grapesjs/traits';
import isArray from 'lodash/isArray';
import {secondaryColor} from "shared-react/utils/theme";

export const MaterialButtonBlock = (bm) => {
    bm.add('material-button').set({
        label: 'Button',
        attributes: { class: 'gjs-fonts gjs-f-button' },
        category: 'Other',
        content: {
            type: 'material-button',
            buttonText: 'Button',
        }
    });
};

export const materialButtonColorPrefix = 'material-button-color-';
export const materialButtonColorValues = ['primary', 'secondary', 'empty'];

export const materialButtonVariantPrefix = 'material-button-variant-';
export const materialButtonVariantValues = ['contained', 'outlined'];

export const MaterialButtonComponent = (domc, editor) => {
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;

    loadCss(editor);

    domc.addType('material-button', {
        model: defaultModel.extend({
            defaults: Object.assign({}, defaultModel.prototype.defaults, {
                'custom-name': 'MaterialButton',
                tagName: 'a',
                draggable: true,
                droppable: false,
                classes: ['material-button'],
                attributes: {
                    href: '#',
                    target: '_self',
                },
                content: "Button",
                traits: [
                    {
                        type: 'content',
                        name: 'buttonText',
                        label: 'Text',
                        changeProp: 1,
                    },
                    {
                        name: 'href',
                        type: 'text',
                        label: 'Link',
                    },
                    {
                        id: 'target',
                        name: 'target',
                        type: 'select',
                        options: [
                            {
                                id: '_self',
                                value: '_self',
                                name: 'Same Window',
                            },
                            {
                                id: '_blank',
                                value: '_blank',
                                name: 'New Window',
                            },
                        ],
                        label: 'Link Target',
                    },
                    {
                        name: 'buttonColor',
                        type: 'select',
                        label: 'Color',
                        changeProp: 1,
                        options: materialButtonColorValues.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule !== 'empty' ? rule : 'Regular',
                        })),
                    },
                    {
                        name: 'buttonVariant',
                        type: 'select',
                        label: 'Variant',
                        changeProp: 1,
                        options: materialButtonVariantValues.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule !== 'empty' ? rule : 'Regular',
                        })),
                    },
                ],
                buttonText: 'Button',
                buttonColor: 'primary',
                buttonVariant: 'contained',
            }),
            init() {
                setupClassTrait(this, 'buttonColor', materialButtonColorValues, materialButtonColorPrefix, 'primary');
                setupClassTrait(this, 'buttonVariant', materialButtonVariantValues, materialButtonVariantPrefix, 'contained');

                this.on('change:buttonText', this.handleContentChange);
                const currentValue = typeof this.get('buttonText') !== 'undefined' ? this.get('buttonText') : 'Button';
                this.handleContentChange(this, currentValue);
            },
            handleContentChange(component, newValue) {
                component.set('content', newValue);
            },
        }, {
            isComponent(el) {
                if(el && el.classList && el.classList.contains('material-button')) {
                    return {type: 'material-button'};
                }
            }
        }),
        //view: defaultView,
        view: defaultView,
    });
}

function loadCss(editor) {
    editor.CssComposer.setRule('.material-button', {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '6px 16px',
        'font-size': '0.875rem',
        'min-width': '64px',
        'box-sizing': 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': '500',
        'line-height': '1.75',
        'border-radius': '4px',
        'letter-spacing': '0.02857em',
        'text-transform': 'uppercase',
        border: '0',
        cursor: 'pointer',
        display: 'inline-flex',
        outline: '0',
        position: 'relative',
        'align-items': 'center',
        'user-select': 'none',
        'vertical-align': 'middle',
        'justify-content': 'center',
        'text-decoration': 'none',
        'background-color': 'transparent',
        '-webkit-appearance': 'none',
        '-webkit-tap-highlight-color':' transparent',
    });

    editor.CssComposer.setRule('.material-button:hover', {
        'text-decoration': 'none',
        'background-color': 'rgba(33, 33, 33, 0.04)',
    });

    // contained
    // contained default
    editor.CssComposer.setRule('.material-button.material-button-variant-contained', {
        color: 'rgba(0, 0, 0, 0.87)',
        'box-shadow': '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        'background-color': '#e0e0e0',
    });
    editor.CssComposer.setRule('.material-button.material-button-variant-contained:hover', {
        'box-shadow': '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        'background-color': '#d5d5d5',
    });
    // contained primary
    editor.CssComposer.setRule('.material-button.material-button-variant-contained.material-button-color-primary', {
        color: '#fff',
        'background-color': '#424242',
    });
    editor.CssComposer.setRule('.material-button.material-button-variant-contained.material-button-color-primary:hover', {
        'background-color': 'rgb(46, 46, 46)',
    });
    // contained secondary
    editor.CssComposer.setRule('.material-button.material-button-variant-contained.material-button-color-secondary', {
        color: 'rgba(33, 33, 33, 0.87)',
        'background-color': secondaryColor,
    });
    editor.CssComposer.setRule('.material-button.material-button-variant-contained.material-button-color-secondary:hover', {
        'background-color': '#b19725',
    });

    // outlined
    // outlined default
    editor.CssComposer.setRule('.material-button.material-button-variant-outlined', {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: '5px 15px',
    });
    editor.CssComposer.setRule('.material-button.material-button-variant-outlined:hover', {
        'text-decoration': 'none',
        'background-color': 'rgba(33, 33, 33, 0.04)',
    });
    // outlined primary
    editor.CssComposer.setRule('.material-button.material-button-variant-outlined.material-button-color-primary', {
        color: '#424242',
        border: '1px solid rgba(66, 66, 66, 0.5)',
    });
    editor.CssComposer.setRule('.material-button.material-button-variant-outlined.material-button-color-primary:hover', {
        border: '1px solid #424242',
        'background-color': 'rgba(66, 66, 66, 0.04)',
    });
    // outlined secondary
    editor.CssComposer.setRule('.material-button.material-button-variant-outlined.material-button-color-secondary', {
        color: secondaryColor,
        border: '1px solid rgba(253, 216, 53, 0.5)',
    });
    editor.CssComposer.setRule('.material-button.material-button-variant-outlined.material-button-color-secondary:hover', {
        border: '1px solid ' + secondaryColor,
        'background-color': 'rgba(253, 216, 53, 0.04)',
    });
}