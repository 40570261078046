import React, {useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {Link, Redirect} from 'react-router-dom'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {useSaveProductCategory} from "shared-react/api/ProductCategoryApi";
import {getProductCategoriesPath} from 'shared-react/utils/routes';
import {FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";
import ProgressButton from "shared-react/utils/ProgressButton";
import CKEditorField from "shared-react/formik/CKEditorField";
import ResponseErrors from "shared-react/utils/ResponseErrors";

function ProductCategoryForm({initialValues, isNew}) {
    const [productCategoryData, setProductCategoryData] = useState(null);
    const [savedProductCategory, isSubmitting, productCategoryErrors, submitProductCategory] = useSaveProductCategory(productCategoryData, isNew);

    const onSaveProductCategory = (values) => {
        setProductCategoryData(values);
        submitProductCategory();
    };

    if (savedProductCategory) {
        return (<Redirect push to={getProductCategoriesPath(1)}/>);
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    onSaveProductCategory(values);
                }}
            >
                {({handleSubmit}) => (
                    <Form>
                        <Paper style={{padding: 16, marginBottom: 16}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        name="title"
                                        label="Pavadinimas"
                                        margin="dense"
                                        fullWidth
                                        nomargin={1}
                                        component={FormMaterialTextField}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CKEditorField name={'description'} placeholder={'Aprašymas'} />
                                </Grid>
                            </Grid>
                        </Paper>
                        <div style={{display: 'flex'}}>
                            <ResponseErrors errors={productCategoryErrors} />
                            <span style={{flex: 1}} />
                            <ProgressButton
                                loading={isSubmitting}
                                text={"Išsaugoti"}
                                onClick={handleSubmit}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
}

export default ProductCategoryForm;