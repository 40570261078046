// Actions
const PREFIX            = 'my-app/lowProducts/';
const SET_WAREHOUSE_IDS = PREFIX + 'SET_WAREHOUSE_IDS';

const initialState = {
    warehouseIds: null,
    hydrated: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = { ...initialState, ...state, hydrated: true };
    }

    switch (action.type) {
        case SET_WAREHOUSE_IDS:
            return {
                ...state,
                warehouseIds: action.payload.warehouseIds,
            };
        default:
            return state;
    }
}

// Action Creators
export function setWarehouseIds(warehouseIds) {
    return {type: SET_WAREHOUSE_IDS, payload: {warehouseIds}};
}