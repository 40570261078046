import {createStore, applyMiddleware, combineReducers, compose} from 'redux'
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import throttle from 'lodash/throttle';
import merge from 'lodash/merge';

import auth from 'ducks/auth';
import shop from 'shared-react/ducks/shop';
import lowProducts from 'ducks/lowProducts';
import {createOrderReducer, NAME_NEW_ORDER, NAME_EDIT_ORDER, initialState as initialOrderState} from 'shared-react/ducks/order';
import rootSaga from 'rootSaga';
import {loadState as loadLocalStorageState, saveState as saveLocalStorageState} from './localStorage'
import {setAuthToken} from 'shared-react/api/Api';

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const persistedState = loadLocalStorageState();
    const middlewares = [sagaMiddleware];

    if (process.env.NODE_ENV !== 'production') {
        middlewares.push(logger);
    }

    const appReducers = combineReducers({
        auth,
        shop,
        lowProducts,
        newOrder: createOrderReducer(NAME_NEW_ORDER),
        editOrder: createOrderReducer(NAME_EDIT_ORDER),
    });

    // const composeEnhancers =
    //     typeof window === 'object' &&
    //     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    //         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    //             // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    //         }) : compose;

    const enhancer = compose(
        applyMiddleware(...middlewares),
    );

    const initialPersistedState = persistedState !== undefined ? {
        ...persistedState,
        newOrder: merge({}, initialOrderState, persistedState.newOrder),
        editOrder: merge({}, initialOrderState, persistedState.newOrder),
    } : {};

    //console.log('initialPersistedState', initialOrderState, persistedState, initialPersistedState);

    const store = createStore(
        appReducers,
        initialPersistedState,
        enhancer
    );

    setAuthToken(store.getState().auth.token); //todo[as]

    // store.subscribe(() => {
    //     console.log('size');
    //     const size = new TextEncoder().encode(JSON.stringify(store.getState())).length
    //     const kiloBytes = size / 1024;
    //     const megaBytes = kiloBytes / 1024;
    //     console.log('kb, mb', kiloBytes, kiloBytes);
    // });

    store.subscribe(throttle(() => {
        setAuthToken(store.getState().auth.token);

        saveLocalStorageState({
            auth: {
                token: store.getState().auth.token,
                permissions: store.getState().auth.permissions,
                defaultWarehouseId: store.getState().auth.defaultWarehouseId,
            },
            newOrder: {
                orderData: store.getState().newOrder.orderData,
                order: store.getState().newOrder.order,
            },
            editOrder: {
                orderData: store.getState().editOrder.orderData,
                order: store.getState().editOrder.order,
            },
            shop: {
                warehouseId: store.getState().shop.warehouseId,
                searchText: store.getState().shop.searchText,
                page: store.getState().shop.page,
            },
            lowProducts: {
                warehouseIds: store.getState().lowProducts.warehouseIds,
            }
        });
    }, 1000));

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureStore();

