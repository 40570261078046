import {setupClassTrait} from "./traits";

export const TextBlock = (bm, editor) => {
    bm.add('text2').set({
        label: 'Text',
        attributes: { class: 'fa fa-font' },
        category: 'Other',
        content: {
            "tagName": "p",
            "type": "text",
            variant: 'h5',
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
    });
};

export const textVariantPrefix = 'text-variant-';
export const textVariantOptions = ['h4', 'h5', 'h6', 'body1'];
export const textAlignPrefix = 'text-align-';
export const textAlignOptions = ['left', 'center', 'justify', 'right'];

export const TextComponent = (domc, editor) => {
    const defaultType = domc.getType('text');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;

    loadCss(editor);

    domc.addType('text', {
        model: defaultModel.extend({
            defaults: Object.assign({}, defaultModel.prototype.defaults, {
                tagName: "p",
                traits: [
                    {
                        name: 'variant',
                        type: 'select',
                        label: 'Variant',
                        changeProp: 1,
                        options: textVariantOptions.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule,
                        }))
                    },
                    {
                        name: 'align',
                        type: 'select',
                        label: 'Align',
                        changeProp: 1,
                        options: textAlignOptions.map(rule => ({
                            id: rule,
                            value: rule,
                            name: rule,
                        }))
                    },
                ],
                variant: 'h4',
                align: 'left',
            }),
            init() {
                setupClassTrait(this, 'variant', textVariantOptions, textVariantPrefix, 'h4');
                setupClassTrait(this, 'align', textAlignOptions, textAlignPrefix, 'left');
            },
        }, {
            isComponent(el) {
                if(el && el.classList && el.classList.contains('grid-container')) {
                    return {type: 'grid-container'};
                }
            }
        }),
        view: defaultView
    });
};

function loadCss(editor) {
    editor.CssComposer.setRule('.text-variant-h4', {
        'color': '#3C4858',
        'font-size': '2.25rem',
        'font-family': '"Roboto Slab", "Roboto", "Times New Roman", serif',
        'font-weight': '700',
        'line-height': '1.235',
        'letter-spacing': '0.00735em',
        'margin-top': 0,
        'margin-bottom': '0.35em',
    });
    editor.CssComposer.setRule('.text-variant-h5', {
        'color': '#3C4858',
        'font-size': '1.5625rem',
        'font-family': '"Roboto Slab", "Roboto", "Times New Roman", serif',
        'font-weight': '700',
        'line-height': '1.334',
        'letter-spacing': '0em',
        'margin-top': 0,
        'margin-bottom': '0.35em',
    });
    editor.CssComposer.setRule('.text-variant-h6', {
        'color': '#3C4858',
        'font-size': '1.125rem',
        'font-family': '"Roboto Slab", "Roboto", "Times New Roman", serif',
        'font-weight': '700',
        'line-height': '1.3',
        'letter-spacing': '0.0075em',
        'margin-top': 0,
        'margin-bottom': '0.35em',
    });
    editor.CssComposer.setRule('.text-variant-body1', {
        'color': '#787878',
        'font-size': '1rem',
        'font-family': '"Roboto Slab", "Roboto", "Times New Roman", serif',
        'font-weight': '300',
        'line-height': '1.5',
        'letter-spacing': '0.00938em',
        'margin-top': 0,
        'margin-bottom': '0.35em',
    });
    editor.CssComposer.setRule('.text-align-justify', {
        'text-align': 'justify',
    });
    editor.CssComposer.setRule('.text-align-left', {
        'text-align': 'left',
    });
    editor.CssComposer.setRule('.text-align-right', {
        'text-align': 'right',
    });
    editor.CssComposer.setRule('.text-align-center', {
        'text-align': 'center',
    });
}