import {useMakeRequest, API_URL, METHOD_POST, METHOD_PUT} from './Api';
import qs from "qs";

const API_CAROUSEL_SLIDES = '/carouselSlides';

export function useGetCarouselSlides(queryParams) {
    const queryString = '?' + qs.stringify(queryParams);

    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_CAROUSEL_SLIDES + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetCarouselSlide(carouselSlideId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_CAROUSEL_SLIDES + '/' + carouselSlideId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveCarouselSlide(carouselSlideData) {
    const isNew = !(carouselSlideData !== null && typeof carouselSlideData === 'object'
        && 'id' in carouselSlideData && carouselSlideData['id'] !== null);
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + carouselSlideData.id;
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_CAROUSEL_SLIDES + url, carouselSlideData);

    return [ data, setData, isLoading, errors, doRequest ];
}

function isNew(carouselSlideData) {
    return !(carouselSlideData !== null && typeof carouselSlideData === 'object'
             && 'id' in carouselSlideData && carouselSlideData['id'] !== null);
}

export function getCarouselSlideUrl(carouselSlideData) {
    return API_URL + API_CAROUSEL_SLIDES + (isNew(carouselSlideData) ? '' : '/' + carouselSlideData.id);
}

export function getCarouselSlideMethod(carouselSlideData) {
    return isNew(carouselSlideData) ? METHOD_POST : METHOD_PUT;
}