import React, {useState, useEffect} from 'react';
import {Field, useFormikContext} from 'formik';
import { NoSsr } from '@material-ui/core';

let CKEditor;
let ClassicEditor;

function CKEditorField({name = '', placeholder = 'Aprašymas'}) {
    const { values, setFieldValue } = useFormikContext();
    const [editorsLoaded, setEditorsLoaded] = useState(false);

    useEffect(() => {
        CKEditor = require("@ckeditor/ckeditor5-react");
        ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
        setEditorsLoaded(true);
    }, []);

    //console.log('CKEditor', CKEditor, ClassicEditor);

    if (!editorsLoaded) {
        return null;
    }

    return (
        <React.Fragment>
            <style dangerouslySetInnerHTML={{__html: `
              .ck-editor__editable_inline { min-height: 100px !important; }
            `}} />
            <div style={{margin: '8px 0'}}>
                <NoSsr>
                    <CKEditor
                        editor={ ClassicEditor }
                        data={values[name]}
                        config={{
                            placeholder: placeholder,
                            language: 'lt',
                            // extraPlugins: [ Alignment, ],
                            toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'indent', 'outdent', '|', 'link', '|', 'undo', 'redo' ],
                        }}
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                            //console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );

                            setFieldValue(name, data);
                        } }
                        onBlur={ ( event, editor ) => {
                            //console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            //console.log( 'Focus.', editor );
                        } }
                    />
                </NoSsr>
            </div>
        </React.Fragment>
    )
}
export default CKEditorField;