import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

function LinkText({children, ...props}) {
    return (
        <Typography
            variant={'body1'}
            // className={classNames(classes.primary, { [classes.textDense]: dense })}
            component="span"
            style={{fontSize: 'inherit'}}
        >
            <Link {...props}>
                {children}
            </Link>
        </Typography>
    );
}
export default LinkText;