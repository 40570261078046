import React, {useEffect, useState} from 'react';

import {usePaymentDelete} from 'shared-react/api/SaleOrderApi';
import ConfirmationDialog from "shared-react/utils/ConfirmationDialog";
import ProgressIconButton from "shared-react/utils/ProgressIconButton";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";

function PaymentDelete({ order, payment, onPaymentDeleted }) {
    const [updatedOrder, isInProgress, errors, deletePayment] = usePaymentDelete(order.id, payment.id);

    useEffect(() => {
        if (updatedOrder && typeof onPaymentDeleted === 'function') {
            onPaymentDeleted(updatedOrder);
        }
    }, [updatedOrder]);

    return (
        <React.Fragment>
            <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_EDIT}>
                <ResponseErrors errors={errors} />
                <ConfirmationDialog
                    dialogContent={"Ar tikrai norite pašalinti šį apmokėjimą?"}
                    confirmationFunction={() => {
                        deletePayment();
                    }}
                >
                    <ProgressIconButton
                        loading={isInProgress}
                    />
                </ConfirmationDialog>
            </PermissionRequiredComponent>
        </React.Fragment>
    );
}

export default PaymentDelete;