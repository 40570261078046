import React, {useState, useEffect} from 'react';
import {Link, Route} from 'react-router-dom';

import {useGetUserGroup} from "shared-react/api/UserGroupApi";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import UserGroupForm from 'userGroups/UserGroupForm'
import LinkBack from "utils/LinkBack";
import {
    getUserGroupPath,
    getUserGroupsPath
} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import PageTitle from "utils/PageTitle";

function UserGroupPage({ match }) {
    const userGroupId = match.params.id;
    const [userGroup, setUserGroup, isUserGroupLoading, userGroupErrors, fetchUserGroup] = useGetUserGroup(userGroupId);

    useEffect(() => {
        fetchUserGroup();
    }, []);

    if (!userGroup) {
        return (<DataLoadingIndicator />);
    }

    return (
        <div>
            <Route path={`${match.path}`} exact render={() => (
                <React.Fragment>
                    <LinkBack to={getUserGroupsPath()} text={"Atgal"}/>
                    <PageTitleWithButton
                        pageTitle={userGroup.title}
                        buttonTitle={"Redaguoti"}
                        buttonRoute={getUserGroupPath(userGroup.id) + '/redagavimas'}
                    />
                </React.Fragment>
            )} />
            <Route path={`${match.path}/redagavimas`} render={() => (
                <React.Fragment>
                    <LinkBack to={`${match.url}`} text={"Atgal"}/>
                    <div style={{display: 'flex', marginBottom: 8}}>
                        <PageTitle title={userGroup.title + " redagavimas"}/>
                    </div>
                    <UserGroupForm
                        userGroup={userGroup}
                        onSaveGroup={setUserGroup}
                    />
                </React.Fragment>
            )} />
        </div>
    );
}

export default UserGroupPage;