import {API_SERVER_URL} from "shared-react/api/Api";

const PRODUCT_IMAGES_DIR = '/images/productImages';

export const URL_TYPE_ABSOLUTE = 'absolute';
export const URL_TYPE_RELATIVE = 'relative';

export const TYPE_THUMBNAIL = 'thumbnail';
export const TYPE_ORIGINAL = 'original';
export const TYPE_SMALL  = 'small';
export const TYPE_MEDIUM = 'medium';
export const TYPE_LARGE = 'large';

export function getProductImagePath(type, imageName,  urlType= URL_TYPE_ABSOLUTE) {
    if (imageName === null) {
        return imageName;
    }

    const imagePath = '/' + type + '/' + imageName;

    if (urlType === URL_TYPE_ABSOLUTE) {
        return API_SERVER_URL + PRODUCT_IMAGES_DIR + imagePath;
    } else {
        return PRODUCT_IMAGES_DIR + imagePath;
    }
}

export function getImagePath(sizeType, imageFolder, imageName,  urlType= URL_TYPE_ABSOLUTE) {
    if (imageName === null) {
        return imageName;
    }

    const imagePath = '/images/' + imageFolder + '/' + sizeType + '/' + imageName;

    if (urlType === URL_TYPE_ABSOLUTE) {
        return API_SERVER_URL + imagePath;
    } else {
        return imagePath;
    }
}