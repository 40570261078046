import React, {useState} from "react";
import isArray from "lodash/isArray";

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import FormError from '../formik/FormError';
import { ErrorMessage } from 'formik';

const PayseraPaymentOptions = ({ options, payseraPaymentId, setOrderDataField }) => {
    const [selectedPayseraPayment, setSelectedPayseraPayment] = useState(payseraPaymentId);

    if (isArray(options) && options.length > 0) {
        return (
            <div>
                <div>
                    <Typography variant="subtitle2" gutterBottom>
                        Pasirinkite savo banką
                    </Typography>
                </div>
                <Grid container spacing={2}>
                    {options.map((option, index) => (
                        <Grid
                            item
                            xs={6} sm={4} md={6}
                            key={index}
                        >
                            <Button
                                variant={option.key === selectedPayseraPayment ? "contained" : "outlined"}
                                disabled={option.key === selectedPayseraPayment}
                                fullWidth
                                onClick={() => {
                                    setSelectedPayseraPayment(option.key);
                                    setOrderDataField('payseraPaymentId', option.key, false);
                                    //setFieldValue('payseraPaymentId', option.key);
                                }}
                            >
                                <img src={option.logoUrl} style={{
                                    width: '100%',
                                    maxWidth: '100px',
                                    // display: 'block',
                                }} />
                            </Button>
                        </Grid>
                    ))}
                </Grid>
                <FormError name="payseraPaymentId" margin="dense" />
            </div>
        )
    } else {
        return (
            <span>Nėra aktyvuotų Paysera atsiskaitymo būdų</span>
        )
    }
};

//export default Header;
export default props => <PayseraPaymentOptions {...props} />;