import {makeRequest, useMakeRequest, API_URL} from './Api';

export const API_CALCULATE_ORDER = API_URL + '/v2/orders/calculate';
export const API_ORDERS = API_URL + '/v2/orders';

export function calculateOrder(endpoint, orderData) {
    return makeRequest('post', endpoint, orderData);
}

export function saveOrder(endpoint, orderData) {
    const method = 'id' in orderData ? 'put' : 'post';
    const url = 'id' in orderData ? '/' + orderData.id : '';

    return makeRequest(method, endpoint + url, orderData);
}

export function useSaveOrder(endpoint, orderData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + orderData.id;
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, endpoint + url, orderData);

    return [ data, setData, isLoading, errors, doRequest ];
}