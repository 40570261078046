import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

function ConfirmationDialog({dialogTitle = "Veiksmo patvirtinimas", dialogContent, confirmationFunction, children}) {
    const [isOpen, setIsOpen] = useState(false);

    const childrenWithExtraProp = React.Children.map(children, child => {
        return React.cloneElement(child, {
            onClick: (event) => {
                setIsOpen(!isOpen);
            },
        });
    });

    return (
        <React.Fragment>
            {childrenWithExtraProp}
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
                <DialogActions style={{display: 'flex'}}>
                    <Button onClick={() => setIsOpen(false)} color="primary">
                        Atšaukti
                    </Button>
                    <span style={{flex: 1}}/>
                    <Button onClick={() => {
                        if (typeof confirmationFunction === 'function') {
                            setIsOpen(false);
                            confirmationFunction();
                        }
                    }} color="primary">
                        Tvirtinti
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default ConfirmationDialog;