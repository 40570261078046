import React from 'react';
import {FieldArray} from "formik";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import VariantTabs from "products/variants/VariantTabs";
import VariantDetails from "products/variants/VariantDetails";
import {useProductFormState} from "products/ProductFormContext";

function ProductVariants({
                             metadata,
                             values,

}) {

    const variantsArrayHelpersRef = React.useRef();

    const productFormState = useProductFormState();
    const currentVariantIndex = productFormState.variantIndex;

    const hasDescription = values.description.trim().length > 0;
    const hasImage = values.images.length > 0;
    const hasCategory = values.categories.length > 0;

    return (
        <React.Fragment>
            <FieldArray
                name="variants"
                render={(arrayHelpers) => {
                    variantsArrayHelpersRef.current = arrayHelpers;

                    return (
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Paper>
                                        <VariantTabs
                                            metadata={metadata}
                                            variants={values.variants}
                                            arrayHelpersRef={variantsArrayHelpersRef}
                                        />
                                    </Paper>
                                </Grid>

                                {values.variants.length > 0 ? (
                                    <Grid item xs={12} sm={8}>
                                        <VariantDetails
                                            metadata={metadata}
                                            variant={values.variants[currentVariantIndex]}
                                            variationOptions={values.variationOptions}
                                            index={currentVariantIndex}
                                            arrayHelpersRef={variantsArrayHelpersRef}
                                            hasDescription={hasDescription}
                                            hasImage={hasImage}
                                            hasCategory={hasCategory}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </div>
                    );
                }}
            />
        </React.Fragment>
    );
}

export default ProductVariants;