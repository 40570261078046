import React from 'react';

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DoneIcon from "@material-ui/icons/Done";
import {green, red} from "@material-ui/core/colors";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";



function ReadyChecklist({list}) {
    return (
        <React.Fragment>
            <List dense disablePadding>
                {list.map((listItem, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            {listItem.done ? (
                                <DoneIcon style={{ color: green[500] }} />
                            ) : (
                                <CloseIcon style={{ color: red[500] }} />
                            )}

                        </ListItemIcon>
                        <ListItemText primary={listItem.title} />
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    );
}

export default ReadyChecklist;