import React, {useEffect} from 'react';

import NewsForm from "website/news/NewsForm";
import PageTitle from "utils/PageTitle";
import LinkBack from "utils/LinkBack";
import {getNewsPagePath} from 'shared-react/utils/routes';
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import useFormikForm from "shared-react/formik/useFormikForm";
import {getObjectMethod, getObjectUrl, useGetObject, API_ENDPOINT_NEWS} from "shared-react/api/ObjectApi";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import datefnsFormat from "date-fns/format";
import {parseFormDate} from "shared-react/utils/form";
import {useMetadata} from "shared-react/metadata/MetadataContext";
import get from "lodash/get";
import StatusChipPublishedGroup from "shared-react/shop/statusChips/StatusChipPublishedGroup";

const metadataToLoadNames = ['newsFormSchema'];

const defaultNews = {
    id: null,
    title: "",
    image: null,
};

const defaultArticle = {
    images: [],
    publishedAt: null,
    shortContent: '',
    gjsComponents: '',
    gjsStyles: '',
    gjsHtml: '',
    gjsCss: '',
};

const defaultNewsletter = {
    images: [],
    publishedAt: null,
    gjsComponents: '',
    gjsStyles: '',
    gjsHtml: '',
    gjsCss: '',
    fullHtml: '',
};

function AddEditNewsPage({match, history}) {
    const newsId = match.params.id;
    const [news, setNews, isNewsLoading, newsErrors, fetchNews] = useGetObject(API_ENDPOINT_NEWS, newsId);
    const {metadataState, metadataDispatch, hasMetadata} = useMetadata(metadataToLoadNames);
    const initialFormData = news ? {
        defaultNews,
        ...news,
        article: {
            ...defaultArticle,
            ...get(news, 'article', {}),
            publishedAt: get(news, 'article.publishedAt', null) != null ? parseFormDate(news.article.publishedAt) : null,
        },
        newsletter: {
            ...defaultNewsletter,
            ...get(news, 'newsletter', {}),
            publishedAt: get(news, 'newsletter.publishedAt', null) != null ? parseFormDate(news.newsletter.publishedAt) : null,
        },
    } : {
        ...defaultNews,

        article: {
            ...defaultArticle
        },

        newsletter: {
            ...defaultNewsletter
        },
    };

    const onSaveDataTransform = (values) => {
        return {
            ...values,
            article: {
                ...values.article,
                publishedAt: values.article.publishedAt != null ? datefnsFormat(values.article.publishedAt, 'yyyy-MM-dd') : null,
            },
            newsletter: {
                ...values.newsletter,
                publishedAt: values.newsletter.publishedAt != null ? datefnsFormat(values.newsletter.publishedAt, 'yyyy-MM-dd') : null,
            },
        };
    };

    const [savedNews, NewsFormikWrapper, newsFormProps, NewsFormErrors, newsFormErrorsProps, NewsFormSubmitButton, newsFormSubmitButtonProps] = useFormikForm({
        method: getObjectMethod(initialFormData),
        url: getObjectUrl(API_ENDPOINT_NEWS, initialFormData),
        formData: initialFormData,
        formSchema: get(metadataState, 'metadata.newsFormSchema', null) ? get(metadataState, 'metadata.newsFormSchema', null) : null,
        formValidationGroup: 'newsPostPut',
        submitButtonText: 'Išsaugoti',
        onSaveDataTransform: onSaveDataTransform,
    });

    useEffect(() => {
        if (newsId) {
            fetchNews();
        }
    }, []);

    useEffect(() => {
        if (savedNews) {
            history.push(getNewsPagePath());
        }
    }, [savedNews]);

    if ((newsId && !news) || !hasMetadata(metadataToLoadNames)) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <NewsFormikWrapper {...newsFormProps}>
                {({values, hasValidationErrors}) => {
                    return (
                        <FillHeightContainer header={
                            <>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: 54,
                                    padding: '0 16px',
                                }}>
                                    <LinkBack to={getNewsPagePath()} text={"Atgal"}/>
                                    <PageTitle title={newsId ? news.title : "Nauja"} gutterBottom/>
                                    <span style={{flex: 1}} />
                                    <NewsFormSubmitButton {...newsFormSubmitButtonProps}/>
                                </div>
                                {hasValidationErrors ? (
                                    <ResponseErrors errors={['Ne visi laukai teisingai užpildyti']}/>
                                ) : null}
                                <ResponseErrors errors={newsErrors} />
                                <NewsFormErrors {...newsFormErrorsProps}/>
                            </>
                        }>
                            <div style={{margin: 24}}>
                                <NewsForm />
                            </div>
                        </FillHeightContainer>
                   );
                }}
            </NewsFormikWrapper>
        </React.Fragment>
    );
}

export default AddEditNewsPage;