import qs from "qs";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import {API_URL, getAuthTokenHeaders, makeRequest, useMakeRequest} from './Api';

export const API_PRODUCTS = '/v2/products';
const API_MERGE_PRODUCTS = '/v2/products-merge';
const API_PRODUCTS_LOW = '/v2/low-products';

export function useGetProducts(endpoint, queryParams) {
    const queryString = !isEmpty(queryParams) ? '?' + qs.stringify(queryParams) : '';
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', endpoint + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetMergeProductsByIds(productIds) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_MERGE_PRODUCTS + '/' + productIds);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveMergedProduct(productData, productIds) {
    const { data, isLoading, errors, doRequest } =  useMakeRequest('post', API_URL + API_MERGE_PRODUCTS + '/' + productIds, productData);

    return [ data, isLoading, errors, doRequest ];
}

export function useGetProduct(productId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_PRODUCTS + '/' + productId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSaveProduct(productData) {
    const isNew = !(productData !== null && typeof productData === 'object'
        && 'id' in productData && productData['id'] !== null);
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (productData ? productData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_PRODUCTS + url, productData);

    return [ data, isLoading, errors, doRequest ];
}



export function useGetLowProducts(queryParams) {
    const queryString = !isEmpty(queryParams) ? '?' + qs.stringify(queryParams) : '';
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_PRODUCTS_LOW + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function getProducts(perPage, page, searchText = null, userId = null) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    if (searchText !== null) {
        queryParams['q'] = searchText;
    }

    if (userId !== null) {
        queryParams['userId'] = userId;
    }

    const queryString = '?' + qs.stringify(queryParams);

    return makeRequest('get', API_URL + API_PRODUCTS + queryString);
}

export function getProduct(productId) {
    const authTokenHeaders = getAuthTokenHeaders();
    return axios.get(
        API_URL + API_PRODUCTS + '/' + productId,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function saveProduct(productData) {
    let method = 'post';
    let url = API_URL + API_PRODUCTS;
    const authTokenHeaders = getAuthTokenHeaders();

    if ("id" in productData && productData['id'] !== null) {
        method = 'put';
        url = url + '/' + productData.id;
    }

    return axios.request({
        url: url,
        method: method,
        headers: {...authTokenHeaders},
        data: {...productData},
    }).then((response) => {
        // if (productData.uploadImages.length > 0) {
        //     return uploadProductImages(productData, response.data.id).then((imagesResponse) => {
        //         return response.data;
        //     });
        // } else {
        //     return response.data;
        // }
    });
}