import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {connect} from 'react-redux'

function RoutePermission({ component: Component, render, userPermissions, permission, ...rest }) {

    // if (userPermissions.includes(permission)) {
    //
    // } else {
    //     return (
    //         <Redirect
    //             to={{
    //                 pathname: "/",
    //                 state: { from: props.location }
    //             }}
    //         />
    //     );
    // }


    return (
        <Route
            {...rest}
            render={props => {
                if (userPermissions.includes(permission)) {
                    if (render) {
                        return render();
                    }

                    if (Component) {
                        return (
                            <Component {...props} />
                        );
                    }
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location }
                            }}
                        />
                    );
                }
            }}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        userPermissions: state.auth.permissions,
    }
};

export default connect(
    mapStateToProps,
    null
)(RoutePermission);