import React, {useEffect, useRef, useState} from 'react';
import grapesjs from "grapesjs";
import grapesJSMJML from "grapesjs-mjml";
import mjml2html from 'mjml-browser';
import get from "lodash/get";
import isArray from "lodash/isArray";

import {getImagePath, TYPE_MEDIUM} from "shared-react/utils/images";
import {useFormikContext} from "formik";
import {getPreMjMl, getPostMjMl, getDefaultNewsletterTemplate} from "./newsletterMjmlDefaults";
import {defaultImage} from 'utils/grapesjs/FancyImage';

export function mjmlConvert (mjml, fonts) {
    let options = {
        useMjmlConfigOptions: false,
        mjmlConfigPath: null,
        filePath: null
    };

    // Check that fonts parameter is not empty for add to options
    if (fonts && (Object.keys(fonts).length > 0 && fonts.constructor === Object)) {
        options.fonts = fonts;
    }

    return mjml2html(mjml, options);
}

function updateNewsletterFields(editorRef, values, setFieldValue)
{
    const editor = editorRef.current;

    if (editor) {
        //console.log('content change', editor.getHtml());
        //console.log('content change2', values.image, editor.runCommand('mjml-get-code'));

        //const fullHtml = editor.runCommand('mjml-get-code').html;
        //console.log(editor.runCommand('test123'));

        const mjml = editor.getHtml();
        const allMjml = getPreMjMl(
            values.title, (get(values, 'image.folder') && get(values, 'image.versionedName')) ? getImagePath(TYPE_MEDIUM, values.image.folder, values.image.versionedName) : defaultImage)
                        + mjml
                        + getPostMjMl();
        const fullHtml = mjmlConvert(allMjml, {
            fonts: {
                Roboto: 'https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700&display=swap',
            },
        }).html;
        // console.log('run test123 2', allMjml);
        // console.log(mjmlConvert(allMjml, {}));


        // console.log('updateNewsletterFields', JSON.stringify(editor.getComponents()), JSON.stringify(editor.getStyle()), editor.getHtml(), editor.getCss());

        setFieldValue('newsletter.gjsComponents', JSON.stringify(editor.getComponents()));
        setFieldValue('newsletter.gjsStyles', JSON.stringify(editor.getStyle()));
        setFieldValue('newsletter.gjsHtml', editor.getHtml());
        setFieldValue('newsletter.gjsCss', editor.getCss());
        setFieldValue('newsletter.fullHtml', fullHtml);


        //setHtml(fullHtml);
        //const doc = new DOMParser().parseFromString(fullHtml, "text/html");
        //console.log('mainBody', doc, doc.getElementsByClassName("main-content"))
    }
}

function loadCanvasCss(editor) {
    editor.Config.canvasCss += `
    /* Layout */
    .gjs-dashed [data-gjs-type="mj-wrapper"] {
        padding: 10px;
    }
  `
}

function NewsletterEditor() {
    const editorRef = useRef(null);
    const { values, setFieldValue, isSubmitting } = useFormikContext();
    const [count, setCount] = useState(0);
    const [html, setHtml] = useState(null);

    React.useEffect(() => {
        if (isSubmitting) {
            //console.log('isSubmitting!!!', values);
            updateNewsletterFields(editorRef, values, setFieldValue);
        }
    }, [isSubmitting]);

    useEffect(() => {
        let components = [];
        let style = [];

        try {
            components = JSON.parse(values.newsletter.gjsComponents);
            style = JSON.parse(values.newsletter.gjsStyles);
        } catch(e) {
        }

        const images = values.newsletter.images.map((image) => {
            return getImagePath(TYPE_MEDIUM, image.folder, image.versionedName);
        });

        console.log('components', components, getDefaultNewsletterTemplate());

        const editor = grapesjs.init({
            assetManager: {
                assets: images,
                upload: false,
                dropzone: false,
            },
            // Indicate where to init the editor. You can also pass an HTMLElement
            container: '#gjs-newsletter',
            fromElement: false,
            components: isArray(components) && components.length > 0 ? components : getPreMjMl(values.title, (get(values, 'image.folder') && get(values, 'image.versionedName')) ? getImagePath(TYPE_MEDIUM, values.image.folder, values.image.versionedName) : defaultImage) + getDefaultNewsletterTemplate() + getPostMjMl(),
            style: style || '',
            // Size of the editor
            // height: '800px',
            // width: 'auto',
            storageManager: false,
            // storageManager: {
            //     type: 'remote',
            //     stepsBeforeSave: 1,
            //     urlStore: 'http://store/endpoint',
            //     urlLoad: 'http://load/endpoint',
            //     params: {}, // Custom parameters to pass with the remote storage request, eg. CSRF token
            //     headers: {}, // Custom headers for the remote storage request
            // },
            // Avoid any default panel
            avoidInlineStyle: false,
            // panels: { defaults: [] },

            //     blockManager: {
            //         appendTo: '#blocks',
            //         blocks: [
            //             {
            //                 id: 'section', // id is mandatory
            //                 label: '<b>Section</b>', // You can use HTML/SVG inside labels
            //                 attributes: { class:'gjs-block-section' },
            //                 content: `<section>
            //   <h1>This is a simple title</h1>
            //   <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
            // </section>`,
            //             }, {
            //                 id: 'text',
            //                 label: 'Text',
            //                 content: '<div data-gjs-type="text">Insert your text here</div>',
            //             }, {
            //                 id: 'image',
            //                 label: 'Image',
            //                 // Select the component once it's dropped
            //                 select: true,
            //                 // You can pass components as a JSON instead of a simple HTML string,
            //                 // in this case we also use a defined component type `image`
            //                 content: { type: 'image' },
            //                 // This triggers `active` event on dropped components and the `image`
            //                 // reacts by opening the AssetManager
            //                 activate: true,
            //             }
            //         ]
            //     },
            plugins: [grapesJSMJML],
            pluginsOpts: {
                [grapesJSMJML]: {
                    //preMjml: getPreMjMl((get(values, 'image.folder') && get(values, 'image.versionedName')) ? getImagePath(TYPE_MEDIUM, values.image.folder, values.image.versionedName) : ''),
                    preMjml: null,
                    //postMjml: getPostMjMl(),
                    postMjml: null,
                    fonts: {
                        Roboto: 'https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700&display=swap',
                    }
                },
                // [grapesJsPluginCkeditor]: {
                //     language: 'lt',
                // },
            }
        });

        // editor.on('load', () => {
        //     editor.StyleManager.removeProperty('dimension', 'padding');
        //     editor.StyleManager.addProperty('dimension', {
        //         property: 'padding',
        //         type: 'composite',
        //         properties: [
        //             { name: 'Top', property: 'padding-top', type: 'integer', units: ['px', '%', 'em'] },
        //             { name: 'Right', property: 'padding-right', type: 'integer', units: ['px', '%', 'em'] },
        //             { name: 'Bottom', property: 'padding-bottom', type: 'integer', units: ['px', '%', 'em'] },
        //             { name: 'Left', property: 'padding-left', type: 'integer', units: ['px', '%', 'em'] },
        //         ],
        //     });
        // });

        editor.on('change:changesCount', e => {
            setCount(prev => {
                return prev + 1;
            });
        });

        const domComponents = editor.DomComponents;

        // domComponents.addType('mj-wrapper', {
        //     view: {
        //         attributes: {
        //             style: 'pointer-events: all; display: table; width: 100%; padding: 20px 0;',
        //         },
        //     },
        // });

        const panelManager = editor.Panels;
        panelManager.removeButton("options", 'sw-visibility');
        panelManager.removeButton("options", 'export-template');
        panelManager.removeButton("options", 'mjml-import');

        const blockManager = editor.BlockManager;
        //const blocks = blockManager.getAll();
        blockManager.remove('mj-navbar');
        blockManager.remove('mj-navbar-link');
        blockManager.remove('mj-raw');

        loadCanvasCss(editor);

        //'#header-image-section,#header-image-column'
        editor.on('load', () => {
            //const component = editor.DomComponents.getWrapper().find('id');
            //component.components(`<div>Add some content inside</div>`);
            // console.log('comComponents', domComponents.getComponents());
            // console.log('comComponents1', editor.DomComponents.getWrapper().find('[css-class*="gjs-mjml-disable"'));
            editor.DomComponents.getWrapper().find('[css-class*="gjs-mjml-disable"').forEach(
                (component) => {
                    //console.log('inner component', component);
                    component.set({
                        draggable: false,
                        droppable: false,
                        editable: false,
                        hoverable: false,
                        selectable: false,
                    });
                }
            );

            editor.DomComponents.getWrapper().find('[css-class*="gjs-mjml-no-delete"').forEach(
                (component) => {
                    //console.log('inner component', component);
                    component.set({
                        // draggable: false,
                        // droppable: false,
                        // editable: false,
                        // hoverable: false,
                        removable: false,
                        // attributes: {
                        //     style: 'pointer-events: all; display: table; width: 100%; min-height: 50px',
                        // },
                    });
                    //console.log('inner component2', component);

                    //component.setStyle({'padding-top': '20px', 'padding-bottom': '20px'});
                }
            );

            // modelComponent.find(`.query-string[example=value]`).forEach(
            //     inner => console.log(inner.props())
            // );
        });

        // editor.addComponents(`<div>
        //   <span title="foo">Hello world!!!</span>
        // </div>`);

        console.log('editor', editor);
        console.log('editor commands', editor.Commands.getAll());
        console.log('plugins', grapesJSMJML, grapesjs.plugins.getAll());
        editor.runCommand('sw-visibility');

        editorRef.current = editor;

        return () => {
            editor.destroy();
        }
    }, []);

    useEffect(() => {
        const editor = editorRef.current;

        if (editor) {
            const assetManager = editor.AssetManager;

            //console.log('image changed', values.newsletter.images, assetManager.getAll(), assetManager.getAllVisible());

            let imagesToRemove = [];
            assetManager.getAll().each((item, index) => {
                imagesToRemove.push(item.get('src'));
            });

            imagesToRemove.forEach((imageSrc, index) => {
                assetManager.remove(imageSrc);
            });

            values.newsletter.images.forEach((image, index) => {
                //console.log('image', image, index);
                assetManager.add(getImagePath(TYPE_MEDIUM, image.folder, image.versionedName));
            });
        }
    }, [values.newsletter.images]);


    useEffect(() => {
        updateNewsletterFields(editorRef, values, setFieldValue);

        console.log('count update!!!');

    }, [count]);

    useEffect(() => {
        if (values.image && editorRef.current) {
            const heroImageComponentSearch = editorRef.current.DomComponents.getWrapper().find('[css-class*="header-hero-image"');

            if (heroImageComponentSearch.length === 1) {
                const heroImageComponent = heroImageComponentSearch[0];
                // console.log('heroImageComponent', heroImageComponent);

                heroImageComponent.set({
                    src: getImagePath(TYPE_MEDIUM, values.image.folder, values.image.versionedName),
                });

                // editorRef.current.set('options', [{ preMjml: 'val1' }]);
            }
        }

        // console.log('editor image changes', editorRef.current);
    }, [values.image]);

    return (
        <>
            <div
                id="gjs-newsletter"
                style={{
                    display: 'block',
                }}
          //       dangerouslySetInnerHTML={{
          //           //__html2: localContent,
          //           __html: localContent ? localContent : `
          // <mj-section background-color="#ffffff" padding-left="15px" padding-right="15px">
          //   <mj-column>
          //     <mj-text font-weight="bold" font-size="20px">
          //       Sveiki,
          //     </mj-text>
          //     <mj-text>
          //       Dėkojame, kad užsiregistravote
          //       <a classname="text-link" target="_blank" href="https://catherine.lt">catherine.lt</a> sistemoje.
          //       Prieš pradedant naudotis savo paskyra, prašome patvirtinti el.
          //       pašto adresą mygtuku apačioje:
          //     </mj-text>
          //   </mj-column>
          // </mj-section>
          //                           `,
          //       }}
            >
            </div>
        </>
    )
}

export default NewsletterEditor;