import {call, put, takeLatest} from 'redux-saga/effects'

import {loginUser} from 'shared-react/api/Api'

// Actions
const PREFIX          = 'my-app/auth/';
const LOGIN_REQUEST   = PREFIX + 'LOGIN_REQUEST';
const LOGIN_SUCCEEDED = PREFIX + 'LOGIN_SUCCEEDED';
const LOGIN_FAILED    = PREFIX + 'LOGIN_FAILED';
const LOGOUT          = PREFIX + 'LOGOUT';

const initialState = {
    token: null,
    permissions: [],
    defaultWarehouseId: 1,
    isSubmitting: false,
    showLoginError: false,
    hydrated: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = { ...initialState, ...state, hydrated: true };
    }

    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                showLoginError: false,
            };
        case LOGIN_SUCCEEDED:
            return {
                ...state,
                isSubmitting: false,
                token: action.payload.token,
                permissions: action.payload.permissions,
                defaultWarehouseId: action.payload.defaultWarehouseId,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isSubmitting: false,
                showLoginError: true,
            };
        case LOGOUT:
            return {
                ...state,
                token: null,
                permissions: [],
            };
        default:
            return state;
    }
}

// Action Creators
export function requestUserLogin(username, password) {
    return {type: LOGIN_REQUEST, payload: {username, password}};
}

export function logoutUser() {
    return {type: LOGOUT};
}

// side effects, only as applicable
// e.g. thunks, epics, etc
function* loginUserSaga(action) {
    try {
        const response = yield call(loginUser, action.payload.username, action.payload.password);
        yield put({
            type: LOGIN_SUCCEEDED,
            payload: {
                token: response.data.token,
                permissions: response.data.permissions,
                defaultWarehouseId: response.data.defaultWarehouseId,
            }
        });
    } catch (e) {
        yield put({type: LOGIN_FAILED, payload: {message: e.message}});
    }
}

export function* authSaga() {
    yield takeLatest(LOGIN_REQUEST, loginUserSaga);
}