import qs from "qs";
import {useMakeRequest, API_URL} from './Api';

const API_PURCHASE_ORDERS = '/purchase-orders';
const API_PURCHASE_ORDERS_PARSE = '/parse';
const API_PURCHASE_ORDERS_PARSE_PDF = '/parse-pdf';

export function useGetPurchaseOrders(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);

    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_PURCHASE_ORDERS + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetPurchaseOrder(purchaseOrderId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_PURCHASE_ORDERS + '/' + purchaseOrderId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSavePurchaseOrder(purchaseOrderData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (purchaseOrderData ? purchaseOrderData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_PURCHASE_ORDERS + url, purchaseOrderData);

    return [ data, isLoading, errors, doRequest ];
}

export function useParsePurchaseOrder(parsePurchaseOrderData) {
    const method = 'post';
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_PURCHASE_ORDERS + API_PURCHASE_ORDERS_PARSE, parsePurchaseOrderData);

    return [ data, isLoading, errors, doRequest ];
}

export function useParsePurchaseOrderPdf(parsePurchaseOrderData) {
    const formData = new FormData();
    formData.append("pdf", parsePurchaseOrderData.pdf);

    const method = 'post';
    const { data, isLoading, errors, doRequest } = useMakeRequest(
        method,
        API_URL + API_PURCHASE_ORDERS + API_PURCHASE_ORDERS_PARSE_PDF,
        formData,
        {'Content-Type': 'multipart/form-data',}
    );

    return [ data, isLoading, errors, doRequest ];
}