import React from 'react';

import PackageForm from 'packages/PackageForm'
import LinkBack from "utils/LinkBack";
import BackToLastLocationButton from "utils/BackToLastLocationButton";
import {getPackagesPath, ROUTE_PACKAGES} from 'shared-react/utils/routes';
import PageTitle from "utils/PageTitle";
import FillHeightContainer from "shared-react/utils/FillHeightContainer"


function PackageAddPage() {
    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <BackToLastLocationButton path={getPackagesPath()} fallback={getPackagesPath()}/>
                        <PageTitle title={"Nauja pakuotė"} />
                    </div>
                </>
            }>
                <PackageForm
                    isNew={true}
                    aPackage={{}}
                />
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default PackageAddPage;