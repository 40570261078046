import {FILTER_TYPE_NUMBER, FILTER_TYPE_CUSTOMER, FILTER_TYPE_DATE, FILTER_TYPE_SELECT, FILTER_TYPE_SELLER} from 'utils/FiltersToChoose';

export const SALE_ORDERS_FILTERS = [
    {
        name: 'Užsakymo numeris',
        qs: 'order',
        type: FILTER_TYPE_NUMBER,
        defaultValue: '',
        cast: String,
    },
    {
        name: 'Pardavėjas',
        qs: 'seller',
        type: FILTER_TYPE_SELLER,
        defaultValue: null,
        cast: Number,
    },
    {
        name: 'Pirkėjas',
        qs: 'customer',
        type: FILTER_TYPE_CUSTOMER,
        defaultValue: null,
        cast: Number,
    },
    {
        name: 'Data nuo',
        qs: 'date_from',
        type: FILTER_TYPE_DATE,
        defaultValue: null,
        cast: String,
    },
    {
        name: 'Data iki',
        qs: 'date_to',
        type: FILTER_TYPE_DATE,
        defaultValue: null,
        cast: String,
    },
    {
        name: 'Apmokėjimas',
        qs: 'paid',
        type: FILTER_TYPE_SELECT,
        values: [
            {
                value: '',
                label: 'Visi'
            },
            {
                value: '0',
                label: 'Neapmokėti'
            },
            {
                value: '1',
                label: 'Apmokėti'
            },
        ],
        defaultValue: '',
        cast: String,
    },
    {
        name: 'Pristatymas',
        qs: 'delivered',
        type: FILTER_TYPE_SELECT,
        values: [
            {
                value: '',
                label: 'Visi'
            },
            {
                value: '0',
                label: 'Nepristatyti'
            },
            {
                value: '1',
                label: 'Pristatyti'
            },
        ],
        defaultValue: '',
        cast: String,
    },
    {
        name: 'Sąskaitos būsena',
        qs: 'invoiced',
        type: FILTER_TYPE_SELECT,
        values: [
            {
                value: '',
                label: 'Visi'
            },
            {
                value: '0',
                label: 'Neišrašyta'
            },
            {
                value: '1',
                label: 'Išrašyta'
            },
        ],
        defaultValue: '',
        cast: String,
    },
    {
        name: 'Sąskaitos numeris',
        qs: 'invoice',
        type: FILTER_TYPE_NUMBER,
        defaultValue: '',
        cast: String,
    },
];

// * @Annotations\QueryParam(
// *  name="customer",
// *  requirements="\d+",
// *  nullable=true,
// *  description="Filter orders with customer id"
//     * )
// * @Annotations\QueryParam(
// *  name="seller",
// *  requirements="\d+",
// *  nullable=true,
// *  description="Filter orders with employee id"
//     * )

// * @Annotations\QueryParam(
// *  name="used",
// *  nullable=true,
// *  description="Filter orders by used flag"
//     * )

// * @Annotations\QueryParam(
// *  name="invoiced",
// *  nullable=true,
// *  description="Filter orders by invoiced status"
//     * )