import React from "react";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            width: '100%',
            paddingBottom: '100%',
        }
    },
}));

const ForcedSquare = ({children}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                }}>
                    {children}
                </div>
            </div>
        </React.Fragment>
    )
};

export default ForcedSquare;