import React from 'react';

import Hidden from '@material-ui/core/Hidden';

import StatusChipFulfillment from 'shared-react/shop/statusChips/StatusChipFulfillment';
import {getLabelByDeliveryCodeAndStatus} from "../../utils/paymentAndDelivery";

function StatusChipFulfillmentGroup({order = null, fulfillment = null, fixedWidth = 130}) {
    const statusCheck = fulfillment === null ? order.delivered : fulfillment.deliveryMethod.steps.length === (fulfillment.deliveryStep + 1);

    let label = '';
    if (order) {
        if (statusCheck) {
            label = 'Atlikta';
        } else {
            label = 'Neatlikta';
        }
    } else {
        label = fulfillment.deliveryMethod.steps[fulfillment.deliveryStep].statusText;
    }

    //console.log('StatusChipFulfillmentGroup', order.delivery.code);
    return (
        <>
            <Hidden xsDown>
                <StatusChipFulfillment status={statusCheck ? 1 : 0} fixedWidth={fixedWidth} label={label}/>
            </Hidden>
            <Hidden smUp>
                <StatusChipFulfillment status={statusCheck ? 1 : 0} small={true}/>
            </Hidden>
        </>
    );
}

export default StatusChipFulfillmentGroup;