import React, {useState} from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";

import { ThemeProvider } from '@material-ui/core/styles';
import {useTheme} from "@material-ui/styles";

import {darkTheme} from '../utils/theme';

function RaisedCard({label, children, contentPadding, ...props}) {
    const theme = useTheme();
    const headerSpacing = theme.spacing(3);
    contentPadding = typeof contentPadding === 'undefined' ? headerSpacing / 2 : contentPadding;

    return (
        <React.Fragment>
            <Paper elevation={5} {...props}>
                <div style={{padding: `${headerSpacing/2}px ${headerSpacing/2}px 0 ${headerSpacing/2}px`, marginTop: (headerSpacing / 2)}}>
                    <ThemeProvider theme={darkTheme}>
                        <Paper style={{
                            marginTop: -1 * headerSpacing,
                            padding: '4px 15px',
                        }}>
                            <Typography variant="h6">
                                {label}
                            </Typography>
                        </Paper>
                    </ThemeProvider>
                </div>
                <div style={{padding: contentPadding, paddingTop: 0}}>
                    {children}
                </div>
            </Paper>
        </React.Fragment>
    );
}

export default RaisedCard;