import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray, ErrorMessage, FastField} from 'formik';
import * as Yup from 'yup';
import datefnsFormat from 'date-fns/format'

import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

import {FormMaterialSelectField, FormMaterialTextField, FormDatePickerField} from "shared-react/formik/FormikMaterialFields";
import {useGetMetadata} from "shared-react/api/MetadataApi";

import ProgressButton from "shared-react/utils/ProgressButton";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {issuersOptions} from "../saleOrders/PrintInvoiceReceiptV2";
import FormikCustomerSelectField from "shared-react/formik/FormikCustomerSelectField";
import {usePrintFakeReceipt} from "shared-react/api/InvoiceApi";
import {API_SERVER_URL} from "shared-react/api/Api";

const PurchaseOrderSchema = Yup.object().shape({
    invoiceIssuerId: Yup.string().nullable().required('Laukas yra privalomas'),
    customerId: Yup.string().nullable().required('Pirkėjo laukas yra privalomas'),
    receiptDate: Yup.mixed().required('Sąskaitos datos laukas yra privalomas'),
    receiptNumber: Yup.string().required('Laukas privalomas'),
    invoiceNumber: Yup.string().required('Laukas privalomas'),
    receiptTotal: Yup.string().required('Laukas privalomas'),
});

function PrintReceiptForm() {
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(
        ['userCustomers']
    );

    const initialValues = {
        invoiceIssuerId: "2",
        customerId: null,
        receiptDate: new Date(),
        receiptNumber: "",
        invoiceNumber: "",
        receiptTotal: 0,
    };
    const [receiptData, setReceiptData] = useState(initialValues);
    const [createdPdf, setCreatedPdf, isPrinting, errors, createReceiptPdf] = usePrintFakeReceipt(receiptData);

    useEffect(() => {
        fetchMetadata();
    }, []);

    console.log("createdPdf", createdPdf);
    useEffect(() => {
        if (createdPdf) {
            window.open(API_SERVER_URL + '/pdfs/' + createdPdf, "_blank");

            setCreatedPdf(null);
        }
    }, [createdPdf]);

    if (!metadata) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={PurchaseOrderSchema}
                onSubmit={(values) => {
                    // console.log('values', values);

                    const valuesToSubmit = {
                        ...values,
                        receiptDate: values.receiptDate ? datefnsFormat(values.receiptDate, 'yyyy-MM-dd') : null,
                    };
                    setReceiptData(valuesToSubmit);
                    // console.log('valuesToSubmit', valuesToSubmit);
                    createReceiptPdf();
                }}
            >
                {({values, errors, setFieldValue}) => {
                    return (
                        <React.Fragment>
                            <Form>
                                <Paper style={{marginBottom: 16}}>
                                    <div style={{padding: 16}}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="invoiceIssuerId"
                                                    label="Išrašo"
                                                    component={FormMaterialSelectField}
                                                    fullWidth
                                                    // nomargin={1}
                                                    margin="dense"
                                                    options={issuersOptions}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    name="customerId"
                                                    label={"Pirkėjas"}
                                                    component={FormikCustomerSelectField}
                                                    creatable
                                                    customersMetadata={metadata.userCustomers}
                                                    onChange={(customerId) => {
                                                        //setOrderCustomerId(option === null ? '' : option.value);
                                                        //handleChange(e);
                                                        setFieldValue('customerId', customerId);
                                                    }}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    //menuPortalTarget={document.body}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="receiptDate"
                                                    label={'Kvito data'}
                                                    component={FormDatePickerField}
                                                    invalidDateMessage={"Neegzistuojanti data"}
                                                    clearable
                                                    fullWidth
                                                    nomargin={1}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="receiptNumber"
                                                    label={'Kvito numeris'}
                                                    nomargin={1}
                                                    margin="dense"
                                                    type="number"
                                                    inputProps={{ min: "0", step: "1" }}
                                                    fullWidth
                                                    component={FormMaterialTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="invoiceNumber"
                                                    label={'Užsakymo numeris'}
                                                    nomargin={1}
                                                    margin="dense"
                                                    type="number"
                                                    inputProps={{ min: "0", step: "1" }}
                                                    fullWidth
                                                    component={FormMaterialTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    name="receiptTotal"
                                                    label={'Suma'}
                                                    nomargin={1}
                                                    margin="dense"
                                                    type="number"
                                                    inputProps={{ min: "0", step: "0.01" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                    }}
                                                    fullWidth
                                                    component={FormMaterialTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <div style={{display: 'flex'}}>
                                    <ul style={{flex: 1, paddingLeft: 16, paddingRight: 16, margin: 0}}>
                                        <ErrorMessage
                                            name="orderProducts"
                                            component={FormHelperText}
                                            variant="filled"
                                            error
                                        />
                                        {!isPrinting && errors.length > 0
                                            ?
                                            (
                                             errors.map((error, errorIndex) => (
                                                    <li key={errorIndex}><FormHelperText error>{error}</FormHelperText></li>
                                                ))
                                            )

                                            : null
                                        }
                                    </ul>
                                    <div>
                                        <ProgressButton
                                            loading={isPrinting}
                                            text={"Spausdinti"}
                                            type={"submit"}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    );
}

export default PrintReceiptForm;