import React from 'react';

import { useFormikContext, Formik, Form, Field } from 'formik';

import {FormMaterialSelectField, FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";

function FormField() {
    return (
        <Field
            name="phoneNumberCountryCode"
            label={''}
            margin="dense"
            options={[
                {
                    value: '+370',
                    label: '+370',
                },
                {
                    value: '+371',
                    label: '+371',
                },
            ]}
            fullWidth={false}
            component={FormMaterialSelectField}
            nomargin={1}
        />
    );
}

export default FormField;