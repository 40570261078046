import React from 'react';

import StatusChipGroupSameSize from "./StatusChipGroupSameSize";
import ShippingIcon from '@material-ui/icons/LocalShipping';


const Icon = () => {
    return (<div>1</div>);
}

function StatusChipFulfillmentStepsGroup({fulfillment = null}) {
    const label = fulfillment.deliveryMethod.steps[0].statusText;
    const label2 = fulfillment.deliveryMethod.steps[1].statusText;
    const label3 = fulfillment.deliveryMethod.steps[2].statusText;

    return (
        <>
            {fulfillment.deliveryStep === (fulfillment.deliveryMethod.steps.length - 1) ? (
                <>
                    <StatusChipGroupSameSize
                        small={false}
                        icon={<ShippingIcon />}
                        status={true}
                        trueValueText={fulfillment.deliveryMethod.steps[(fulfillment.deliveryStep)].statusText}
                        falseValueText={''}
                        fixedWidth={false}
                    />
                </>
            ) : (
                <>
                    {fulfillment.deliveryMethod.steps.map((step, index) => (
                        <StatusChipGroupSameSize
                            key={index}
                            small={fulfillment.deliveryStep !== index}
                            icon={<div>{index + 1}</div>}
                            status={fulfillment.deliveryStep >= index}
                            trueValueText={step.statusText}
                            falseValueText={step.statusText}
                            fixedWidth={false}
                        />
                    ))}
                </>
            )}
        </>
    );
}

export default StatusChipFulfillmentStepsGroup;