import {defaultImage} from "../../utils/grapesjs/FancyImage";
import {secondaryColor} from "shared-react/utils/theme";

export function getPreMjMl (title, imageSrc) {
    return `
<mjml>
  <mj-head>
    <mj-font name="Roboto" href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700&display=swap"/>
    <mj-title>${title}</mj-title>
    <mj-attributes>
      <mj-all font-family="'Roboto', 'Times New Roman', serif;"></mj-all>
      <mj-text font-weight="300" font-size="16px" color="#333" line-height="24px"></mj-text>
    </mj-attributes>
  </mj-head>
  <mj-body background-color="#f9f8f6">
    <mj-section full-width="full-width" padding="20px 0">
      <mj-column>
        <mj-image src="https://catherine.lt/img/catherine-logo-black-large-glow.png" alt="" align="center" width="200px" />
      </mj-column>
    </mj-section>
    <mj-wrapper padding-top="0" padding-bottom="0" background-color="#ffffff">
      <mj-section padding-left="15px" padding-right="15px">
        <mj-column>
            <mj-image css-class="header-hero-image" src="${imageSrc}"/>
        </mj-column>
      </mj-section>
    `;
}


export function getPostMjMl () {
    return `
      <mj-section padding-left="15px" padding-right="15px">
        <mj-column>
          <mj-text font-family="'Roboto', 'Times New Roman', serif;">
            Linkėjimai, 
            <br>
            <a target="_blank" href="https://catherine.lt">catherine.lt</a> komanda
          </mj-text>
          <mj-text font-family="'Roboto', 'Times New Roman', serif;">
            Kilus klausimams, mielai į juos atsakysime el. paštu 
            <a target="_blank" href="mailto:info@catherine.lt">info@catherine.lt</a> 
            <br>
            arba telefonu: <br>
            +37052618877, Vilnius <br/>
            +37045431406, Panevėžys<br/>
            +37068013223, Direktorė <br/>
            +37069098615, Vadybininkė <br/>
            ( I–V 9:00 – 18:00 )
          </mj-text>
        </mj-column>
      </mj-section>
    </mj-wrapper>
    <mj-wrapper full-width="full-width">
      <mj-section>
        <mj-column width="100%" padding="0">
          <mj-social font-size="15px" icon-size="30px" mode="horizontal" padding="0" align="center">
            <mj-social-element name="facebook" href="https://www.facebook.com/NDesignSalon/" background-color="#A1A0A0">
            </mj-social-element>
            <mj-social-element name="instagram" href="https://www.instagram.com/catherine.lithuania" background-color="#A1A0A0">
            </mj-social-element>
          </mj-social>
          <mj-text color="#999999" font-size="12px" align="center" font-family="'Roboto', 'Times New Roman', serif;">
            UAB "Nagų Dizainas", Oficialus Catherine atstovas Lietuvoje <br>
            Gėlainių g. 31, LT-36252, Panevėžys, Lietuva <br>
            Įmonės kodas: 14851369, PVM mokėtojo kodas: LT100001870212
          </mj-text>
          
            <mj-image src="https://catherine.lt/img/nDesign-glow.png" alt="" align="center" width="100px"></mj-image>
    
          <mj-text color="#999999" font-size="12px" align="center" line-height="18px" font-family="'Roboto', 'Times New Roman', serif;">
            © Visos teisės saugomos
          </mj-text>
          <mj-text color="#999999" font-size="12px" align="center" line-height="18px" font-family="'Roboto', 'Times New Roman', serif;">
            <a href="https://catherine.lt/newsletter/unsubscribe" target="_blank">Atsisakyti naujienlaiškio</a>
          </mj-text>
        </mj-column>
      </mj-section>
    </mj-wrapper>
  </mj-body>
</mjml>   
    `
}

export function getDefaultNewsletterTemplate() {
    return `
     <mj-wrapper padding-top="0" padding-bottom="0" css-class="gjs-mjml-no-delete">
            <mj-section background-color="#ffffff" padding-left="15px" padding-right="15px">
                <mj-column>
                  <mj-text font-weight="bold" font-size="20px" color="#626262" font-family="'Roboto', 'Times New Roman', serif;">
                    Sveiki,
                  </mj-text>
                  <mj-text color="#626262" font-family="'Roboto', 'Times New Roman', serif;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget
                  nulla elit. Nulla aliquet mollis faucibus.
                  </mj-text>
                </mj-column>
            </mj-section>
            <mj-section background-url="https://catherine.lt/img/MatchaBG.jpg" background-size="cover" background-repeat="no-repeat">
              <mj-column>
                <mj-text align="center" color="#fff" font-size="40px" font-family="'Roboto', 'Times New Roman', serif;">Slogan here</mj-text>
                <mj-button
                    background-color="${secondaryColor}"
                    color="rgba(33, 33, 33, 0.87)"
                    href="#"
                    border-radius="4px"
                    text-transform="uppercase"
                    padding="6px 16px"
                    font-weight="500"
                    font-family="'Roboto', 'Times New Roman', serif;"
                >
                    Sužinokite daugiau
                </mj-button>
              </mj-column>
            </mj-section>
            <mj-section>
              <mj-column width="400px">
                <mj-text font-style="italic" font-size="20px" color="#626262" font-family="'Roboto', 'Times New Roman', serif;">My Awesome Text</mj-text>
                <mj-text color="#525252" font-family="'Roboto', 'Times New Roman', serif;">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus, sit amet suscipit nibh. Proin nec commodo purus. Sed eget
                  nulla elit. Nulla aliquet mollis faucibus.
                </mj-text>
                <mj-button
                    background-color="#424242"
                    color="#fff"
                    href="#"
                    border-radius="4px"
                    text-transform="uppercase"
                    padding="6px 16px"
                    font-weight="500"
                    font-family="'Roboto', 'Times New Roman', serif;"
                >
                    Sužinokite daugiau
                </mj-button>
              </mj-column>
            </mj-section>
            <mj-section background-color="white">
              <mj-column>
                <mj-image width="200px" src="https://catherine.lt/img/homepage/nagu-stiprinimas.jpg"/>
              </mj-column>
              <mj-column>
                <mj-text font-style="italic" font-size="20px" color="#626262" font-family="'Roboto', 'Times New Roman', serif;">
                  Find amazing places
                </mj-text>
                <mj-text color="#525252" font-family="'Roboto', 'Times New Roman', serif;">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rutrum enim eget magna efficitur, eu semper augue semper. Aliquam erat volutpat. Cras id dui lectus. Vestibulum sed finibus lectus.
                </mj-text>
              </mj-column>
            </mj-section>
            <mj-section background-color="#fbfbfb">
              <mj-column>
                <mj-image width="100px" src="https://catherine.lt/img/homepage/priauginimas.jpg"/>
              </mj-column>
              <mj-column>
                <mj-image width="100px" src="https://catherine.lt/img/homepage/priauginimas.jpg"/>
              </mj-column>
              <mj-column>
                <mj-image width="100px" src="https://catherine.lt/img/homepage/priauginimas.jpg"/>
              </mj-column>
            </mj-section>
            <mj-section>
              <mj-column>
                <mj-button 
                    color="rgba(33, 33, 33, 0.87)"
                    href="#"
                    border="1px solid rgba(0, 0, 0, 0.23)"
                    border-radius="4px"
                    background-color="none"
                    text-transform="uppercase"
                    padding="6px 16px"
                    font-weight="500"
                    font-family="'Roboto', 'Times New Roman', serif;"
                >
                    Papildomas mygtukas
                </mj-button>
              </mj-column>
            </mj-section>
      </mj-wrapper>
    `;
}