import React, {useState, useMemo} from 'react';
import isArray from 'lodash/isArray';
import { Field, FastField } from 'formik';
import * as Yup from "yup";

import Typography from '@material-ui/core/Typography';

import RaisedCard from './RaisedCard';
import useDebouncedCallback from '../useDebounce/callback';
import Currency from '../utils/Currency';
import {FormMaterialTextField, MaterialRadioGroupButton} from '../formik/FormikMaterialFields';
import OmnivaMachineOptions from './OmnivaMachineOptions';
import {
    DELIVERY_METHOD_CUSTOM,
    DELIVERY_METHOD_PANEVEZYS,
    DELIVERY_METHOD_RYGA,
    OMNIVA_LT,
} from 'shared-react/utils/paymentAndDelivery';

const schema = Yup.object().shape({
    deliveryMethodCode: Yup.string().required('Būtina pasirinkti pristatymo būdą'),
    omnivaPickupPointId: Yup.string()
        .when('deliveryMethodCode', {
            is: (val) => val === OMNIVA_LT,
            then: Yup.string().required('Paštomato laukas negali būti tuščias'),
            otherwise: Yup.string().nullable(),
        }),
});

function Step3Delivery({cmsForm, setOrderDataField, deliveryMethodCode, omnivaPickupPointId, availableOmnivaMachines, metadataUserDeliveryMethods, setFieldValue, ...props}) {
    if (!isArray(metadataUserDeliveryMethods) || metadataUserDeliveryMethods.length === 0) {
        return null;
    }

    //const [localCustomDeliveryPrice, setLocalCustomDeliveryPrice] = useState(orderData.customDeliveryPrice);
    const [setDebouncedCustomDeliveryPrice] = useDebouncedCallback(
        (value) => {
            //setOrderCustomDeliveryPrice(value);
            setOrderDataField('customDeliveryPrice', value, true)
        },
        500,
        []
    );

    const userDeliveryMethodsOptions = useMemo(() => {
        return metadataUserDeliveryMethods.map((userDeliveryMethod) => {
            return {
                value: userDeliveryMethod.code,
                label: (
                    <React.Fragment>
                        <Typography variant="subtitle2">
                            {userDeliveryMethod.name}
                        </Typography>
                        {userDeliveryMethod.code !== DELIVERY_METHOD_CUSTOM ? (
                            <Typography variant="caption" component="div" style={{color: 'red'}}>
                                <Currency value={userDeliveryMethod.total} />
                            </Typography>
                        ) : null}
                        {userDeliveryMethod.infoText ? (
                            <Typography variant="caption" component="div">
                                {userDeliveryMethod.infoText}
                            </Typography>
                        ) : null}
                    </React.Fragment>
                ),
            };
        });
    }, [metadataUserDeliveryMethods]);

    React.useEffect(() => {
        if (
            isArray(metadataUserDeliveryMethods) && metadataUserDeliveryMethods.length > 0
            &&
            (
                typeof deliveryMethodCode === 'undefined' ||
                deliveryMethodCode === null ||
                deliveryMethodCode === '' ||
                typeof metadataUserDeliveryMethods.find((deliveryMethod) => deliveryMethod.code === deliveryMethodCode) === 'undefined'
            )
        ) {
            setOrderDataField('deliveryMethodCode', metadataUserDeliveryMethods[0].code, true);
        }
    }, []);

    // console.log('Step3Delivery deliveryMethodCode, omnivaPickupPointId, cmsForm', deliveryMethodCode, omnivaPickupPointId, cmsForm);

    return (
        <React.Fragment>
            <RaisedCard label={<>{cmsForm ? '3' : '2'}. Pristatymas</>} {...props}>
                <div style={{marginTop: 8}}>
                    <FastField
                        //validate={formikFieldValidate(schema)}
                        name="deliveryMethodCode"
                        component={MaterialRadioGroupButton}
                        // onChange={(e) => console.log('MaterialRadioGroupButton changed ', e.target.value)}
                        options={userDeliveryMethodsOptions}
                        onChange={(e) => setOrderDataField('deliveryMethodCode', e.target.value, true)}
                    />
                    {deliveryMethodCode === DELIVERY_METHOD_CUSTOM ? (
                        <FastField
                            name="customDeliveryPrice"
                            onChange={(e) => {
                                setFieldValue('customDeliveryPrice', e.target.value);
                                setDebouncedCustomDeliveryPrice(e.target.value);
                            }}
                            //defaultValue=""
                            label={'Kaina'}
                            margin="dense"
                            type="number"
                            inputProps={{ min: "0", step: "0.01" }}
                            fullWidth
                            component={FormMaterialTextField}
                        />
                    ) : null}
                    {deliveryMethodCode === OMNIVA_LT ? (
                        <OmnivaMachineOptions
                            options={availableOmnivaMachines}
                            defaultValue={omnivaPickupPointId}
                            setOrderDataField={setOrderDataField}
                            allowCountrySelect={cmsForm}
                        />
                    ) : null}
                    {/*<Field*/}
                    {/*name="omnivaPickupPointId"*/}
                    {/*component={OmnivaMachineOptions}*/}
                    {/*// onChange={(e) => console.log('FormMaterialRadioGroupButton changed ', e.target.value)}*/}
                    {/*options={availableOmnivaMachines}*/}
                    {/*onChange={(e) => setOrderDataField('omnivaPickupPointId', e.target.value, true)}*/}
                    {/*/>*/}
                    {/*<FormControl component="fieldset">*/}
                    {/*<RadioGroup*/}
                    {/*aria-label="Gender"*/}
                    {/*name="gender1"*/}
                    {/*>*/}
                    {/*<FormControlLabel value="1" control={<Radio />} label={*/}
                    {/*<React.Fragment>*/}
                    {/*Atsiėmimas Panevėžio salone*/}
                    {/*<br/>*/}
                    {/*<Typography variant="caption">*/}
                    {/*Ramygalo g. 46-26*/}
                    {/*</Typography>*/}
                    {/*</React.Fragment>*/}
                    {/*} />*/}
                    {/*<FormControlLabel value="1" control={<Radio />} label={*/}
                    {/*<React.Fragment>*/}
                    {/*Atsiėmimas Vilniaus salone*/}
                    {/*<br/>*/}
                    {/*<Typography variant="caption">*/}
                    {/*Krivių g. 5*/}
                    {/*</Typography>*/}
                    {/*</React.Fragment>*/}
                    {/*} />*/}
                    {/*<FormControlLabel value="1" control={<Radio />} label={*/}
                    {/*<React.Fragment>*/}
                    {/*Atsiėmimas Omniva paštomate*/}
                    {/*<br/>*/}
                    {/*<Typography variant="caption">*/}
                    {/*</Typography>*/}
                    {/*</React.Fragment>*/}
                    {/*} />*/}
                    {/*/!*<FormControlLabel value="male" control={<Radio />} label="Atsiėmimas Vilniaus salone" />*!/*/}
                    {/*/!*<FormControlLabel value="other" control={<Radio />} label="Omniva paštomatas" />*!/*/}
                    {/*</RadioGroup>*/}
                    {/*</FormControl>*/}
                    {/*<Button variant="outlined" fullWidth style={{*/}
                    {/*textTransform: 'none',*/}
                    {/*paddingLeft: 0,*/}
                    {/*paddingRight: 8,*/}
                    {/*justifyContent: 'start',*/}
                    {/*}}>*/}
                    {/*<Radio style={{padding: 8}} />*/}
                    {/*<div style={{*/}
                    {/*textAlign: 'left',*/}
                    {/*}}>*/}
                    {/*<Typography variant="subtitle2">*/}
                    {/*Atsiėmimas Panevėžio salone*/}
                    {/*</Typography>*/}
                    {/*<div>*/}
                    {/*<StoreMallDirectoryIcon fontSize="small"/>*/}
                    {/*<Typography variant="caption">*/}
                    {/*Krivių g. 5*/}
                    {/*</Typography>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</Button>*/}
                    {/*<Button variant="outlined" fullWidth style={{*/}
                    {/*textTransform: 'none',*/}
                    {/*paddingLeft: 0,*/}
                    {/*paddingRight: 8,*/}
                    {/*justifyContent: 'start',*/}
                    {/*marginBottom: 16,*/}
                    {/*}}>*/}
                    {/*<Radio style={{padding: 8}} />*/}
                    {/*<div style={{*/}
                    {/*textAlign: 'left',*/}
                    {/*}}>*/}
                    {/*<Typography variant="subtitle2">*/}
                    {/*Atsiėmimas Panevėžio salone*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" component="div" style={{color: 'red'}}>*/}
                    {/*<Currency value={0} />*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" component="div">*/}
                    {/*Ramygalo g. 46-26*/}
                    {/*</Typography>*/}
                    {/*</div>*/}
                    {/*</Button>*/}
                    {/*<Button variant="outlined" fullWidth style={{*/}
                    {/*textTransform: 'none',*/}
                    {/*paddingLeft: 0,*/}
                    {/*paddingRight: 8,*/}
                    {/*justifyContent: 'start',*/}
                    {/*marginBottom: 16,*/}
                    {/*}}>*/}
                    {/*<Radio style={{padding: 8}} />*/}
                    {/*<div style={{*/}
                    {/*textAlign: 'left',*/}
                    {/*}}>*/}
                    {/*<Typography variant="subtitle2">*/}
                    {/*Atsiėmimas Vilniaus salone*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" component="div" style={{color: 'red'}}>*/}
                    {/*<Currency value={0} />*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" component="div">*/}
                    {/*Krivių g. 5*/}
                    {/*</Typography>*/}
                    {/*</div>*/}
                    {/*</Button>*/}
                    {/*<Button variant="outlined" fullWidth style={{*/}
                    {/*textTransform: 'none',*/}
                    {/*paddingLeft: 0,*/}
                    {/*paddingRight: 8,*/}
                    {/*justifyContent: 'start',*/}
                    {/*}}>*/}
                    {/*<Radio style={{padding: 8}} />*/}
                    {/*<div style={{*/}
                    {/*textAlign: 'left',*/}
                    {/*}}>*/}
                    {/*<Typography variant="subtitle2">*/}
                    {/*Atsiėmimas Omniva paštomate*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="caption" component="div" style={{color: 'red'}}>*/}
                    {/*<Currency value={1.99} />*/}
                    {/*</Typography>*/}
                    {/*</div>*/}
                    {/*</Button>*/}
                </div>
            </RaisedCard>
        </React.Fragment>
    );
}

export function getLabelByDeliveryCodeAndStatus(deliveryCode, status) {
    let labelToShow;

    switch (deliveryCode) {
        case DELIVERY_METHOD_PANEVEZYS:
        case DELIVERY_METHOD_RYGA:
            labelToShow = status ? "Atsiimta" : "Neatsiimta";
            break;
        default:
            labelToShow = status ? "Išsiųsta" : "Neišsiųsta";
    }

    return labelToShow;
}

export default React.memo(Step3Delivery);