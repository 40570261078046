import React, {useState, useEffect} from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from "@material-ui/core/TextField";

import SearchBuyableProductsAutocomplete from 'shared-react/shop/SearchBuyableProductsAutocomplete';
import {API_URL} from 'shared-react/api/Api';
import {API_PRODUCTS} from 'shared-react/api/ProductApi';

const initialProductTransferProductValues = {
    id: null,
    amount: 1,
    product: {
        id: null,
    },
};

function ProductTransferAddProduct({currentCount, arrayHelpers}) {
    const [position, setPosition] = useState(1);
    const onProductChosen = (product) => {
        addProduct(1, product);
    };

    //console.log('arrayHelpers length', currentCount);
    const addProduct = (amount, product) => {
        const transferProductToAdd = {
            id: null,
            amount: amount,
            product: product,
        };

        if (position > 0 && position <= currentCount) {
            arrayHelpers.insert(position - 1, transferProductToAdd);
        } else {
            arrayHelpers.push(transferProductToAdd);
        }
    };

    useEffect(() => {
        setPosition(currentCount + 1);
    }, [currentCount]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={4} sm={3} md={2}>
                <TextField
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    label="Pozicija"
                    variant="outlined"
                    margin="dense"
                    type="number"
                    inputProps={{ min: "1", step: "1" }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={8} sm={9} md={10}>
                <SearchBuyableProductsAutocomplete
                    style={{
                        marginTop: 8,
                        marginBottom: 4,
                    }}
                    endpoint={API_URL + API_PRODUCTS}
                    label="Ieškoti esamos prekės"
                    onProductChosen={onProductChosen}
                    onlyVariants={1}
                    adittionalQueryParams={{
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default ProductTransferAddProduct;