import React, {useEffect} from 'react';
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import {useFormikContext} from "formik";

import useScrollToElement from "shared-react/utils/useScrollToElement";
import {keyify} from "shared-react/formik/FocusError";

import useProductForm, {focusFirstFormError, resetFocusFirstFormError, setCurrentVariantIndex} from "./ProductFormContext";

function FocusFirstFormError() {
    const { values, errors, isSubmitting, isValidating } = useFormikContext();
    const scrollToElement = useScrollToElement();
    const [productFormState, productFormDispatch] = useProductForm();

    //focus first tab with error
    useEffect(() => {
        //console.log('errors, isSubmitting, isValidating', errors, isSubmitting, isValidating);
        if (isSubmitting && !isValidating) {
            if ('variants' in errors && isArray(errors['variants'])) {
                const variantIndexWithFirstError = errors['variants'].findIndex((variantError) => isObject(variantError));

                if (typeof variantIndexWithFirstError !== -1) {
                    productFormDispatch(setCurrentVariantIndex(parseInt(variantIndexWithFirstError, 10)));
                }

                //console.log('dispatch', variantIndex);
            }

            productFormDispatch(focusFirstFormError());
        }
    }, [errors, isSubmitting, isValidating]);

    useEffect(() => {
        if (productFormState.focusFirstFormError) {
            const keys = keyify(errors);
            //console.log('FocusError', errors, keys);

            //let keys = Object.keys(errors);
            if (keys.length > 0) {
                //const selector = `[name=${keys[0]}]`;
                let selector = `[name="${keys[0]}"]`;
                let errorElement = document.querySelector(selector);

                // if input field is not there or it's a hidden input, try finding an error element
                if (!errorElement || errorElement.getAttribute('type') === 'hidden') {
                    selector = 'form-error-' + keys[0];
                    errorElement = document.getElementById(selector);
                }
                //console.log('errorElement', errorElement);
                if (errorElement) {
                    scrollToElement(selector);
                    //errorElement.scrollIntoView({behavior: "smooth", block: 'center'});
                    //errorElement.focus();
                }
            }

            productFormDispatch(resetFocusFirstFormError());
        }
    }, [productFormState.focusFirstFormError]);

    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default React.memo(FocusFirstFormError);