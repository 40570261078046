import React from 'react';

import { useFormikContext, Formik, Form, Field } from 'formik';

import {FormMaterialSelectField, FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";

function FormField() {
    return (
        <Field
            name="address"
            label={'Adresas'}
            margin="dense"
            fullWidth
            component={FormMaterialTextField}
            nomargin={1}
        />
    );
}

export default FormField;