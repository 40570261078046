import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux'

import Typography from '@material-ui/core/Typography';

import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {useGetMergeProductsByIds, useSaveMergedProduct, useSaveProduct} from "shared-react/api/ProductApi";
import {useMetadata} from "shared-react/metadata/MetadataContext";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";
import {getProductsPath} from 'shared-react/utils/routes';
import ProgressButton from 'shared-react/utils/ProgressButton';
import ResponseErrors from "shared-react/utils/ResponseErrors";

import ProductForm, {ProductSchema} from "products/ProductForm";
import useProductForm, {ProductFormProvider, submitProductForm} from "./ProductFormContext";
import LinkBack from "utils/LinkBack";
import {Redirect} from "react-router-dom";
import {setSearchText} from "shared-react/ducks/shop";
import {Formik, useFormikContext} from "formik";
import {parseProductToFormData} from "../schemas/product";


//todo[as]: expott constant from ProductAddEditPage
const metadataToLoadNames = ['categories', 'categoriesTree', 'userGroups', 'warehouses', 'userWarehouses',  'packages', 'variationOptions', 'variationOptionValues']; //, 'salesChannels'

function ProductsMergePage({match, history}) {
    const productsIds = match.params.ids;

    const [productData, setProductData] = useState(null);
    const [product, setProduct, isProductLoading, productErrors, fetchProduct] = useGetMergeProductsByIds(productsIds);
    const [savedProduct, isSubmittingMerge, productErrors2, saveProduct] = useSaveMergedProduct(productData, productsIds);
    const {metadataState, metadataDispatch, hasMetadata} = useMetadata(metadataToLoadNames);
    const [productFormState, productFormDispatch] = useProductForm();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchProduct();
    }, []);

    //console.log('merge product', product);

    if (productErrors.length > 0) {
        return (<ResponseErrors errors={productErrors}/>);
    }

    if (!product || isProductLoading) {
        return (<DataLoadingIndicator />);
    }

    if (!hasMetadata(metadataToLoadNames)) {
        return (<DataLoadingIndicator />);
    }

    if (savedProduct) {
        dispatch(setSearchText(savedProduct.name));

        return (<Redirect push to={getProductsPath()}/>);
    }

    const metadata = metadataState.metadata;

    const handleSubmitMyForm = (e) => {
        productFormDispatch(submitProductForm());
    };

    const onSaveForm = (values) => {
        const newValues = {
            ...values,
        };

        //console.log('onSaveForm', newValues);

        setProductData(newValues);
        saveProduct();
    };

    //console.log('parse product', product);
    const initialFormData = parseProductToFormData(product, metadata.userWarehouses);

    return (
        <>
            <Formik
                initialValues={initialFormData}
                validationSchema={ProductSchema}
                //enableReinitialize
                onSubmit={(values) => {
                    //setProductData(values);
                    onSaveForm(values);
                }}
            >
                {({
                      errors,
                      isSubmitting
                  }) => {
                    return (
                        <FillHeightContainer header={
                            <>
                                <div style={{
                                    display: 'flex',
                                    minHeight: 54,
                                    alignItems: 'center',
                                    margin: '0 16px',
                                }}>
                                    <LinkBack to={getProductsPath(null, null)} text={"Atgal"}/>
                                    <Typography component="span" variant="h6">
                                        Prekių apjungimas
                                    </Typography>
                                    <span style={{flex: 1}} />
                                    <ProgressButton
                                        loading={isSubmittingMerge}
                                        text={"Išsaugoti"}
                                        onClick={(e) => {
                                            handleSubmitMyForm(e);
                                        }}
                                        color="primary"
                                    />
                                </div>
                                <ResponseErrors errors={productErrors}/>
                                <ResponseErrors errors={productErrors2}/>
                                {productFormState.formHasErrors ? (
                                    <ResponseErrors errors={['Ne visi laukai teisingai užpildyti']}/>
                                ) : null}
                            </>
                        }>
                            <div style={{margin: 24}}>
                                <ProductForm
                                    metadata={metadata}
                                    isSubmittingRequest={isSubmittingMerge}
                                />
                            </div>
                        </FillHeightContainer>
                    );
                }}
            </Formik>
        </>
    );

}

export default ProductsMergePageWithProductForm;

function ProductsMergePageWithProductForm({...props}) {
    return (
        <ProductFormProvider>
            <ProductsMergePage {...props} />
        </ProductFormProvider>
    );
};

