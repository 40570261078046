import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    {
        name: "key",
        label: "Eil. nr.",
        options: {
            filter: false,
            sort: false,
            align: 'right',
        }
    },
    {
        name: "name",
        label: "Pavadinimas",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "amount",
        label: "Kiekis",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "cost",
        label: "Kaina",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "total",
        label: "Suma",
        options: {
            filter: false,
            sort: false,
        }
    },
];

function InvoiceDialog({invoice, isOpen, onClose}) {
    //const [open, setOpen] = React.useState(false);

    const handleClose = (e) => {
        //setOpen(false);

        if (typeof onClose === 'function') {
            onClose(e);
        }
    };

    if (!invoice) {
        return null;
    }

    const orderProductsData = invoice.orderProducts.map((orderProduct, key) => [
        key+1, orderProduct.name, orderProduct.amount, orderProduct.cost, orderProduct.total
    ]);

    return (
        <React.Fragment>
            <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar style={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {invoice.serie}{invoice.number}
                        </Typography>
                        {/*<Button autoFocus color="inherit" onClick={handleClose}>*/}
                            {/*save*/}
                        {/*</Button>*/}
                    </Toolbar>
                </AppBar>
                <List>
                    <MUIDataTable
                        title={false}
                        data={orderProductsData}
                        columns={columns}
                        options={{
                            pagination: false,
                            selectableRows: 'none',
                            disableToolbarSelect: true,
                            sort: false,
                            filter: false,
                            print: false,
                            download: false,
                            search: false,
                            viewColumns: false,
                            elevation: 0,
                            rowHover: false,
                        }}
                    />
                </List>
            </Dialog>
        </React.Fragment>
    );
}

export default InvoiceDialog;