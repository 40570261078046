import React, {useMemo} from 'react';
import {Field, FieldArray} from "formik";
import isArray from "lodash/isArray";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ConfirmationDialog from "shared-react/utils/ConfirmationDialog";
import {
    FormCheckboxField,
    FormMaterialAutocomplete,
    FormMaterialTextField
} from "shared-react/formik/FormikMaterialFields";

import ReadyChecklist from "products/ReadyChecklist";
import ProductFormPrices from "products/ProductFormPrices";
import ProductFormWarehouses from "products/ProductFormWarehouses";
import VariationOptionValue from "products/VariationOptionValue";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import {useTheme} from "@material-ui/core/styles";
import {useProductFormDispatch, setCurrentVariantIndex} from "products/ProductFormContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const StyledPaper = styled(Paper)`
  && {
    ${props => ({ ...props.theme.mixins.gutters() })}
    padding-top: ${props => props.theme.spacing(2)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    margin-bottom: ${props => props.theme.spacing(3)}px;
  }
`;

function VariantDetails({
                            metadata,
                            variant,
                            index,
                            arrayHelpersRef,
                            variationOptions,
                            hasDescription,
                            hasImage,
                            hasCategory,
}) {
    const hasPrice = variant.price > 0;
    const sellableValid = hasDescription && hasImage && hasPrice && hasCategory;

    const theme = useTheme();
    const productFormDispatch = useProductFormDispatch();

    const metadataUserGroupOptions = metadata.userGroups.map((userGroup) => ({
        label: userGroup.title,
        value: userGroup.id,
    }));

    const userGroupOptions = [
        {
            label: "Visi klientai",
            value: 0,
        },
        ...metadataUserGroupOptions
    ];

    return (
        <div key={'variant' + index} id={'variant-paper-' + index}>
            <StyledPaper theme={theme} style={{
                position: 'relative',
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Field
                            name={`variants.${index}.code`}
                            label="Kodas"
                            margin="dense"
                            fullWidth
                            component={FormMaterialTextField}
                        />
                        <FieldArray
                            name="variationOptionValues"
                            render={arrayHelpers => (
                                <div>
                                    {variationOptions.map((variationOption, index2) => (
                                        // {variant.variationOptionValues.map((variationOptionValue, index2) => (
                                        <React.Fragment key={'variantValue' + index2}>
                                            {variationOption !== null && isArray(variant.variationOptionValues) && typeof variant.variationOptionValues[index2] !== 'undefined' ? (
                                                <div style={{
                                                    // margin: '10px 0',
                                                }}>
                                                    <VariationOptionValue
                                                        variantIndex={index}
                                                        variationOptionId={variationOption.id}
                                                        label={variationOption.name}
                                                        variationOptionValueIndex={index2}
                                                        variationOptionValues={metadata.variationOptionValues}
                                                    />
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledPaper theme={theme} elevation={2}>
                            <Typography variant="h6" gutterBottom>
                                Parduodama
                            </Typography>
                            <Field
                                name={`variants.${index}.sellable`}
                                label={'Internetinė parduotuvė'}
                                component={FormCheckboxField}
                            />
                            {variant.sellable && !sellableValid ? (
                                <ReadyChecklist list={[
                                    {
                                        'title': 'Įvestas aprašymas',
                                        'done': hasDescription,
                                    },
                                    {
                                        'title': 'Įkeltas paveikslėlis',
                                        'done': hasImage,
                                    },
                                    {
                                        'title': 'Įvesta kaina',
                                        'done': hasPrice,
                                    },
                                    {
                                        'title': 'Priskirta bent vienai kategorijai',
                                        'done': hasCategory,
                                    },
                                ]} />
                            ) : null}
                        </StyledPaper>
                    </Grid>
                </Grid>

                <StyledPaper theme={theme} elevation={2}>
                    <Typography variant="h6" gutterBottom>
                        Kainos
                    </Typography>
                    <ProductFormPrices
                        namePrefix={`variants.${index}.`}
                        currentUserGroupPrices={variant.userGroupPrices}
                        userGroupOptions={userGroupOptions}
                    />
                </StyledPaper>
                <ExpansionPanel style={{marginBottom: 24}}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6">
                            Sandėliavimas
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ProductFormWarehouses
                            namePrefix={`variants.${index}.`}
                            warehouses={variant.warehouses}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <StyledPaper theme={theme}>
                    <Typography variant="h6" gutterBottom>
                        Pakuotė
                    </Typography>
                    <Field
                        name={`variants.${index}.package`}
                        label="Ieškoti"
                        options={metadata.packages}
                        fullWidth
                        component={FormMaterialAutocomplete}
                    />
                </StyledPaper>
                {variant.code === '' ? (
                    <div style={{display: 'flex'}}>
                        <span style={{flex: 1}}></span>
                        <ConfirmationDialog
                            dialogContent={"Ar tikrai norite pašalinti šį variantą?"}
                            confirmationFunction={() => {
                                productFormDispatch(setCurrentVariantIndex(index - 1));
                                arrayHelpersRef.current.remove(index);
                            }}
                        >
                            <IconButton
                                size={'small'}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </ConfirmationDialog>
                    </div>
                ) : null}
            </StyledPaper>
            {/*<Divider variant="middle" />*/}
        </div>
    );
}

export default React.memo(VariantDetails);