import React from 'react';

import {useEndpoint} from "shared-react/api/Api";
import ProgressButton from "shared-react/utils/ProgressButton";
import ResponseErrors from "shared-react/utils/ResponseErrors";
import FormikForm from "shared-react/formik/FormikForm";

function useFormikForm({
                           method,
                           url,
                           formData,
                           submitButtonText,
                           validationSchema,
                           formSchema = null,
                           formValidationGroup = 'Default',
                           onSaveDataTransform = null,
                       }) {
    const [localFormData, setLocalFormData] = React.useState(formData);
    const [data, setData, isLoading, errors, doRequest] = useEndpoint(method, url, localFormData);

    let submitMyForm = null;

    const bindSubmitForm = (submitForm) => {
        submitMyForm = submitForm;
    };

    const formProps = {
        bindSubmitForm: bindSubmitForm,
        initialValues: formData,
        validationSchema: validationSchema,
        formSchema: formSchema,
        formValidationGroup: formValidationGroup,
        handleSubmit: (formValues) => {
            if (typeof onSaveDataTransform === 'function') {
                formValues = onSaveDataTransform(formValues);
            }

            setLocalFormData(formValues);
            doRequest();
        },
    };

    const formErrorsProps = {
        errors: errors,
    };

    const formSubmitButtonProps = {
        loading: isLoading,
        text: submitButtonText,
        onClick: (e) => {
            if (typeof submitMyForm === 'function') {
                submitMyForm(e);
            }
        }
    };

    return [
        data,

        Form,
        formProps,

        FormErrors,
        formErrorsProps,

        FormSubmitButton,
        formSubmitButtonProps,
    ];
}

export default useFormikForm;

export const Form = function ({  ...formProps }) {
    return <FormikForm {...formProps} />;
};

export const FormErrors = function ({  ...formErrorsProps }) {
    return <ResponseErrors {...formErrorsProps} />;
};

export const FormSubmitButton = function ({  ...formSubmitButtonProps }) {
    return <ProgressButton {...formSubmitButtonProps} />;
};