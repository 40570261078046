import React, {useState, useEffect} from 'react';
import qs from 'qs';
import {withRouter} from "react-router-dom";
import {pickBy, identity} from "lodash";
import { format } from 'date-fns'

import NavigationIcon from '@material-ui/icons/Navigation';
import Drawer from '@material-ui/core/Drawer';
import Button from "@material-ui/core/Button";
import Badge from '@material-ui/core/Badge';

import {useGetMetadata} from 'shared-react/api/MetadataApi';
import {MaterialDatePickerField, MaterialTextField, MaterialSelectField} from "shared-react/formik/MaterialFields";
import CustomerSelect from "shared-react/user/CustomerSelect";
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";

const getFilterValues = (filters, urlQueryParams) => {
    return filters.reduce((values, filter) => {
        if (typeof urlQueryParams[filter.qs] !== 'undefined') {
            if (filter.cast === Number) {
                values[filter.qs] = Number.parseInt(urlQueryParams[filter.qs], 10);
            } else {
                values[filter.qs] = urlQueryParams[filter.qs];
            }
        } else {
            values[filter.qs] = filter.defaultValue;
        }

        return values;
    }, {})
};

function FiltersToChoose({filters, match, location, history}) {
    const urlQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryString = '?' + qs.stringify(urlQueryParams);
    const [metadata, isMetadataLoading, metadataErrors, fetchMetadata] = useGetMetadata(['allUserCustomers', 'userSellers']);
    const [filterValues, setFilterValues] = useState(getFilterValues(filters, urlQueryParams));
    const [isOpen, setIsOpen] = useState(false);
    const selectedFiltersCount = Object.keys(pickBy(filterValues, identity)).length;

    //console.log('FiltersToChoose', match, location);

    useEffect(() => {
        fetchMetadata();
    }, []);

    useEffect(() => {
        setFilterValues(getFilterValues(filters, urlQueryParams));
    }, [queryString]);

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsOpen(!isOpen);
    };

    const closeDrawer = () => {
        setIsOpen(false);
    };

    const setFilter = (filterName, filterValue) => {
        console.log('setFilter', filterName, filterValue);

        setFilterValues({
            ...filterValues,
            [filterName]: filterValue,
        });
    };

    const applyFilters = () => {
        const newUrlQueryParams = {
            ...urlQueryParams,
            ...filterValues,
            page: 1,
        };

        const notEmptyQueryParams = pickBy(newUrlQueryParams, identity);
        const queryString = '?' + qs.stringify(notEmptyQueryParams);

        //console.log('applyFilters', newUrlQueryParams, notEmptyQueryParams, queryString);

        history.push(location.pathname + queryString);

        closeDrawer();
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={toggleDrawer}>
                <Badge badgeContent={selectedFiltersCount} color="secondary">
                    <NavigationIcon />
                </Badge>
            </Button>
            <Drawer anchor="right" open={isOpen} onClose={applyFilters}>
                <div style={{padding: 16, width: 250}}>
                    {filters.map((filter, index) =>
                        <div key={index}>
                            {filter.type === FILTER_TYPE_NUMBER ? (
                                <React.Fragment>
                                    <MaterialTextField
                                        margin="dense"
                                        type="number"
                                        label={filter.name}
                                        value={filterValues[filter.qs]}
                                        onChange={(e) => {setFilter(filter.qs, e.target.value)}}
                                        fullWidth
                                    />
                                </React.Fragment>
                            ) : null}
                            {filter.type === FILTER_TYPE_SELECT ? (
                                <MaterialSelectField
                                    margin="dense"
                                    label={filter.name}
                                    value={filterValues[filter.qs]}
                                    onChange={(e) => {
                                        setFilter(filter.qs, e.target.value);
                                    }}
                                    options={filter.values}
                                    fullWidth
                                />
                            ) : null}
                            {filter.type === FILTER_TYPE_DATE ? (
                                <MaterialDatePickerField
                                    margin="dense"
                                    label={filter.name}
                                    format="yyyy-MM-dd"
                                    value={filterValues[filter.qs]}
                                    onChange={(date) => {
                                        console.log(date);
                                        if (date instanceof Date && !isNaN(date.getTime())) {
                                            console.log(format(date, 'yyyy-MM-dd'));

                                            setFilter(filter.qs, format(date, 'yyyy-MM-dd'));
                                        } else {
                                            setFilter(filter.qs, '');
                                            //setFieldValue(field.name, null, true);
                                        }
                                    }}
                                    fullWidth
                                />
                            ) : null}
                            {filter.type === FILTER_TYPE_CUSTOMER ? (
                                <React.Fragment>
                                    {metadata ? (
                                        <CustomerSelect
                                            customersMetadata={metadata.allUserCustomers}
                                            creatable
                                            label={"Pirkėjas"}
                                            onChange={(customerId) => {
                                                setFilter(filter.qs, customerId);
                                            }}
                                            customerId={filterValues[filter.qs]}
                                        />
                                    ) : (
                                        <DataLoadingIndicator />
                                    )}
                                </React.Fragment>
                            ) : null}
                            {filter.type === FILTER_TYPE_SELLER ? (
                                <React.Fragment>
                                    {metadata ? (
                                        <CustomerSelect
                                            customersMetadata={metadata.userSellers}
                                            creatable
                                            label={"Pardavėjas"}
                                            onChange={(customerId) => {
                                                setFilter(filter.qs, customerId);
                                            }}
                                            customerId={filterValues[filter.qs]}
                                        />
                                    ) : (
                                        <DataLoadingIndicator />
                                    )}
                                </React.Fragment>
                            ) : null}
                        </div>
                    )}
                    <br/>
                    <Button variant="contained" color="primary" fullWidth onClick={applyFilters}>
                        Filtruoti
                    </Button>
                </div>
            </Drawer>
        </React.Fragment>
    );
}

export const FILTER_TYPE_SELECT = 'select';
export const FILTER_TYPE_NUMBER = 'number';
export const FILTER_TYPE_SELLER = 'seller';
export const FILTER_TYPE_CUSTOMER = 'customer';
export const FILTER_TYPE_DATE = 'date';

export default withRouter(FiltersToChoose);