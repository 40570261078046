import React from 'react';

import MoneyIcon from '@material-ui/icons/AttachMoney';

import StatusChip from 'shared-react/shop/statusChips/StatusChip';

function StatusChipPayment({small = false, paymentStatus}) {
    let labelToShow;

    switch (paymentStatus.code) {
        case 'STATUS_OVERPAID':
            labelToShow = 'Permokėta';
            break;
        case 'STATUS_MISSING_RECEIPTS':
            labelToShow = "Neišrašyti kv.";
            break;
        case 'STATUS_STALE_RECEIPTS':
            labelToShow = "Spausdinti kv.";
            break;
        case "STATUS_PAID":
            labelToShow = "Apmokėta";
            break;
        default:
            labelToShow = "Neapmokėta";
    }

    //{order.paymentStatus.code === 'STATUS_PENDING' || order.paymentStatus.code === 'STATUS_PARTIALLY_PAID' ?
    const status = paymentStatus.code === 'STATUS_PAID' ? 1 : 0;

    return (
        <StatusChip icon={<MoneyIcon/>} small={small} status={status} label={labelToShow}/>
    );
}

export default StatusChipPayment;