import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";

import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import {useGetUserPermissionGroups} from "shared-react/api/UserPermissionGroupApi";
import {
    getUserPermissionGroupPath,
    getUserPermissionGroupsPath,
    ROUTE_USER_PERMISSION_GROUPS_NEW
} from 'shared-react/utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import {MaterialTextField} from "shared-react/formik/MaterialFields";
import LinkWrapper from "utils/LinkWrapper";
import ListTablePagination from "utils/ListTablePagination";

function UserPermissionGroupsPage({match, history}) {
    const page = typeof match.params.page === 'undefined' ? 1 : match.params.page;
    const perPage = 20;
    const queryParams = {
        page,
        perPage,
    };
    const [userPermissionGroups, totalCount, isUserPermissionGroupsLoading, userPrmissionGroupsErrors, fetchUserPermissionGroups] = useGetUserPermissionGroups(queryParams);

    useEffect(() => {
        fetchUserPermissionGroups();
    }, [page]);


    if (!userPermissionGroups) {
        return (<DataLoadingIndicator />);
    }

    return (
        <React.Fragment>
            <PageTitleWithButton
                pageTitle={"Klientų teisių grupės"}
                buttonTitle={"Pridėti naują"}
                buttonRoute={ROUTE_USER_PERMISSION_GROUPS_NEW}
                gutterBottom
            />
            <Paper>
                <List dense={true}>
                    {userPermissionGroups.map((userPermissionsGroup) =>
                        <ListItem
                            key={userPermissionsGroup.id}
                            button
                            to={getUserPermissionGroupPath(userPermissionsGroup.id)}
                            component={LinkWrapper}
                        >
                            <ListItemText
                                primary={userPermissionsGroup.title}
                            />
                        </ListItem>
                    )}
                </List>
                <ListTablePagination
                    perPage={perPage}
                    totalCount={totalCount}
                    page={page}
                    onChangePage={(event, page) => {
                        history.push(getUserPermissionGroupsPath(page));
                    }}
                />
            </Paper>
        </React.Fragment>
    );
}

export default UserPermissionGroupsPage;