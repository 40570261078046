import React from "react";
import * as Yup from "yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const getValidationSchema = (formSchema, validationGroup = 'Default') => {
    const properties = get(formSchema, 'properties', {});
    const propertiesKeys = Object.keys(properties);

    //console.log('properties', properties);
    //console.log('propertiesKeys', propertiesKeys);

    const validators = propertiesKeys.length > 0 ? propertiesKeys.reduce((accumulator = {}, key) => {
        let validator = null;

        const propertyData = properties[key];
        const validators = get(propertyData, 'validators', {});
        const type = get(propertyData, 'type', null);

        if (type === 'string') {
            validator = Yup.string();
        } else if (type === 'integer') {
            validator = Yup.string();
        } else if (type === 'object') {
            validator = Yup.object().default(null);
        }

        let validatorToAdd = {};

        if (validator) {
            let constraintApplied = false;
            //console.log('propertyData', key, propertyData);
            //console.log('validators', key, validators);

            // NotBlank
            const NotBlankConstraints = getMatchingConstraints(validators, 'NotBlank', validationGroup, true);
            const NotNullConstraints = getMatchingConstraints(validators, 'NotNull', validationGroup, true);
            const NotBlankWhenConstraints = getMatchingConstraints(validators, 'NotBlankWhen', validationGroup, true);
            const RegexWhenConstraints = getMatchingConstraints(validators, 'RegexWhen', validationGroup);
            const LengthConstraints = getMatchingConstraints(validators, 'Length', validationGroup, true);
            const SameAsConstraints = getMatchingConstraints(validators, 'SameAs', validationGroup, true);
            const EmailConstraints = getMatchingConstraints(validators, 'Email', validationGroup, true);

            if (!isEmpty(NotBlankConstraints)) {
                constraintApplied = true;
                const NotBlank = NotBlankConstraints[0];
                const NotBlankMessage = get(NotBlank, 'message', 'Šis laukas negali būti tuščias');
                const NotBlankAllowNull = get(NotBlank, 'allowNull', false);

                validator = validator.required(NotBlankMessage);
            }

            if (type === 'object' && !isEmpty(NotNullConstraints)) {
                constraintApplied = true;
                const NotNull = NotNullConstraints[0];
                const NotNullMessage = get(NotNull, 'message', 'Šis laukas negali būti tuščias');

                validator = validator.nullable().required(NotNullMessage);
            }

            if (!isEmpty(NotBlankWhenConstraints)) {
                constraintApplied = true;
                const NotBlankWhen = NotBlankWhenConstraints[0];
                const NotBlankWhenMessage = get(NotBlankWhen, 'message', 'Šis laukas negali būti tuščias');
                const NotBlankWhenAllowNull = get(NotBlankWhen, 'allowNull', false);
                const NotBlankWhenField = get(NotBlankWhen, 'whenField', null);
                const NotBlankWhenValue = get(NotBlankWhen, 'whenValue', null);

                if (NotBlankWhenField && NotBlankWhenValue) {
                    const NotBlankWhenValidator = Yup.string()
                        .required(NotBlankWhenMessage);

                    if (NotBlankWhenAllowNull) {
                        validator = validator.nullable();
                    }

                    validator = validator.when(NotBlankWhenField, {
                        is: (val) => val == NotBlankWhenValue,
                        then: NotBlankWhenValidator,
                    });
                }
            }

            if (!isEmpty(RegexWhenConstraints)) {
                constraintApplied = true;
                RegexWhenConstraints.forEach((RegexWhenConstraint) => {
                    const RegexWhenMessage = get(RegexWhenConstraint, 'message', 'Šis laukas negali būti tuščias');
                    const RegexWhenField = get(RegexWhenConstraint, 'whenField', null);
                    const RegexWhenValue = get(RegexWhenConstraint, 'whenValue', null);
                    const RegexWhenPattern = get(RegexWhenConstraint, 'pattern', null);

                    if (RegexWhenField && RegexWhenValue && RegexWhenPattern) {
                        const RegexWhenValidator = Yup.string()
                            .matches(eval(RegexWhenPattern), RegexWhenMessage);

                        validator = validator.when(RegexWhenField, {
                            is: (val) => val == RegexWhenValue,
                            then: RegexWhenValidator,
                        });

                    }
                });
            }

            if (!isEmpty(LengthConstraints)) {
                constraintApplied = true;
                const Length = LengthConstraints[0];
                const min = get(Length, 'min', null);
                const max = get(Length, 'max', null);
                const minMessage = get(Length, 'minMessage', 'Šis laukas turi būti ilgenis nei {{ limit }} simbolis(-iai)');
                const maxMessage = get(Length, 'maxMessage', 'Šis laukas negali būti ilgenis nei {{ limit }} simbolis(-iai)');

                if (min) {
                    validator = validator.min(min, minMessage);
                }

                if (max) {
                    validator = validator.max(max, maxMessage);
                }
            }

            if (!isEmpty(SameAsConstraints)) {
                constraintApplied = true;
                const SameAs = SameAsConstraints[0];
                const primaryProperty = get(SameAs, 'primaryProperty', '');
                const secondaryProperty = get(SameAs, 'secondaryProperty', '');
                const message = get(SameAs, 'message', primaryProperty + ' ir ' + secondaryProperty + ' laukai nesutampa');

                if (primaryProperty && secondaryProperty) {
                    validator = validator.oneOf([Yup.ref(secondaryProperty), null], message);
                }
            }

            if (!isEmpty(EmailConstraints)) {
                constraintApplied = true;
                const Email = EmailConstraints[0];
                const message = get(Email, 'message', false);

                validator = validator.email(message);
            }

            if (constraintApplied) {
                validatorToAdd[key] = validator;
            }
        }

        return {
            ...accumulator,
            ...validatorToAdd,
        };
    }, {}) : {};

    //console.log('validators', validators);

    const validationSchema = Yup.object().shape({
        ...validators,
    });

    return validationSchema;
};

const getMatchingConstraints = (validators, validatorName, validationGroup, returnFirstFound = false) => {
    const constraints = get(validators, validatorName, []);
    let matchingConstraints = [];

    for (let i = 0; i < constraints.length; i++) {
        const groups = get(constraints[i], 'groups', []);

        if (groups.includes(validationGroup)) {
            matchingConstraints.push(constraints[i]);

            if (returnFirstFound) {
                break;
            }
        }
    }

    return matchingConstraints;
};

export default getValidationSchema;