import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import get from "lodash/get";
import {Link, Route, Switch, Redirect} from 'react-router-dom'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import {useGetSaleOrder} from 'shared-react/api/SaleOrderApi';
import ToggleFulfillmentFulfilled from 'saleOrders/ToggleFulfillmentFulfilled';
import AddEditOrderPayment from 'saleOrders/AddEditOrderPayment';

import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ListItem from "@material-ui/core/ListItem";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from '@material-ui/icons/Image';
import {useTheme} from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";

import {StyledListItemTextTotals, StyledListItemTextPrice} from 'shop/CheckoutPage';
import {ListItemAmountText, ListItemPriceText, StyledListItemTextRight} from 'utils/List';

import {getSaleOrdersPath, getSaleOrderEditPath, getProductPath, getInvoicePath, ROUTE_SALE_ORDERS} from 'shared-react/utils/routes';
import LinkBack from 'utils/LinkBack';
import BackToLastLocationButton from 'utils/BackToLastLocationButton';

import Currency from 'shared-react/utils/Currency';
import Button from '@material-ui/core/Button';
import PageTitle from "utils/PageTitle";
import {WEB_SERVER_URL} from 'shared-react/api/Api';

import SaleOrderEditPage from "saleOrders/SaleOrderEditPage";
import PaymentSetToPaid from 'saleOrders/PaymentSetToPaid';

import {
    NAME_EDIT_ORDER,
    updateOrderAndData,
} from 'shared-react/ducks/order';
import DataLoadingIndicator from "shared-react/utils/DataLoadingIndicator";
import LinkRoute from "shared-react/utils/LinkRoute";
import LinkWrapper from "utils/LinkWrapper";
import PrintInvoiceReceiptV2 from "saleOrders/PrintInvoiceReceiptV2";
import FillHeightContainer from "shared-react/utils/FillHeightContainer";

import StatusChipInvoiceGroup from 'shared-react/shop/statusChips/StatusChipInvoiceGroup';
import StatusChipPaymentGroup from 'shared-react/shop/statusChips/StatusChipPaymentGroup';
import StatusChipFulfillmentGroup from 'shared-react/shop/statusChips/StatusChipFulfillmentGroup';
import StatusChipFulfillmentStepsGroup from 'shared-react/shop/statusChips/StatusChipFulfillmentStepsGroup';

import OrderTotalsTable from 'shared-react/shop/OrderTotalsTable';
import CustomerListInfo from 'shared-react/user/CustomerListInfo';
import PaymentDelete from "saleOrders/PaymentDelete";
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";
import ListIemKeyValue from "shared-react/utils/ListIemKeyValue";
import OmnivaCard from "saleOrders/Omniva/OmnivaCard";
import useHasPermission from "../utils/useHasPermission";
import OmnivaLabels from "./Omniva/OmnivaLabels";
import FulfillmentSteps from "./Fulfillment/FulfillmentSteps";
import StatusChip from "shared-react/shop/statusChips/StatusChip";
import ShippingIcon from "@material-ui/icons/LocalShipping";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "shared-react/utils/ConfirmationDialog";

function SaleOrderPage({ match, history, updateOrderAndData }) {
    const saleOrderId = match.params.id;
    const [showAddProducts, setShowAddProducts] = useState(false);
    const [order, setOrder, isOrderLoading, orderErrors, fetchOrder] = useGetSaleOrder(saleOrderId);
    const theme = useTheme();
    const hasInvoiceViewPermission = useHasPermission(permissions.PERMISSION_INVOICES_VIEW);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        fetchOrder();
    }, []);

    useEffect(() => {
        if (order) {
            updateOrderAndData(order);
        }
    }, [order]);

    if (!order || isOrderLoading) {
        return (<DataLoadingIndicator />);
    }

    return (
        <div>
            <Route path={`${match.path}`} exact render={() => (
                <React.Fragment>
                    <FillHeightContainer header={
                        <>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                minHeight: 48,
                                padding: '4px 16px 4px 8px',
                            }}>
                                <BackToLastLocationButton path={ROUTE_SALE_ORDERS}  fallback={getSaleOrdersPath(1)} />
                                <div>
                                    <Typography component="h1" variant="h6">
                                        #{order.id}
                                    </Typography>
                                    <Typography variant="body2" component={"span"} display="inline" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                                        {order.day} {order.time}
                                    </Typography>
                                </div>
                                <span style={{flex: 1}} />
                                <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_EDIT}>
                                    <Button to={getSaleOrderEditPath(saleOrderId)} variant="outlined" color="primary" component={LinkWrapper}>
                                        Redaguoti
                                    </Button>
                                </PermissionRequiredComponent>
                                {/*<PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_EDIT}>*/}
                                {/*    <Button*/}
                                {/*        variant="outlined"*/}
                                {/*        onClick={handleClick}*/}
                                {/*        color="primary"*/}
                                {/*        endIcon={<ExpandMoreIcon />}*/}
                                {/*        style={{*/}
                                {/*            marginLeft: 8*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Daugiau veiksmų*/}
                                {/*    </Button>*/}
                                {/*    <Menu*/}
                                {/*        id="simple-menu"*/}
                                {/*        anchorEl={anchorEl}*/}
                                {/*        keepMounted*/}
                                {/*        open={Boolean(anchorEl)}*/}
                                {/*        onClose={handleClose}*/}
                                {/*    >*/}
                                {/*        <MenuItem onClick={handleClose}>*/}
                                {/*            <ConfirmationDialog*/}
                                {/*                dialogContent={"Ar tikrai norite atšaukti šį užsakymą"}*/}
                                {/*                confirmationFunction={() => {console.log('yes');}}*/}
                                {/*            >*/}
                                {/*                <div>*/}
                                {/*                    Atšaukti užsakymą*/}
                                {/*                </div>*/}
                                {/*            </ConfirmationDialog>*/}
                                {/*        </MenuItem>*/}
                                {/*    </Menu>*/}
                                {/*</PermissionRequiredComponent>*/}
                            </div>
                        </>
                    }>
                        <div style={{
                            margin: theme.spacing(2)
                        }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={8} style={{
                                    padding: theme.spacing(2)
                                }}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Paper elevation={4}>
                                                <List
                                                    dense={true}
                                                >
                                                    {order.fulfillments.map((fulfillment, fulfillmentIndex) =>
                                                        <React.Fragment key={'f'+fulfillment.id}>
                                                            <ListSubheader component="div" style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '48px',
                                                            }} disableSticky>
                                                                {/*Pristatymas*/}
                                                                <StatusChipFulfillmentStepsGroup fulfillment={fulfillment} />
                                                                {/*<StatusChipFulfillmentGroup fulfillment={fulfillment} fixedWidth={null} />*/}
                                                                {/*{fulfillment.deliveryNotificationsSent > 0 ? (*/}
                                                                {/*    <StatusChip icon={<MailOutlineIcon/>} small={false} status={1} label={'Pranešta pirkėjui (' + fulfillment.deliveryNotificationsSent + ')'} fixedWidth={false}/>*/}
                                                                {/*) : null}*/}
                                                                {/*{!fulfillment.fulfilled ? ("Neatliktas") : ("Atliktas")}*/}
                                                                <span style={{flex: 1}}></span>
                                                                <div>
                                                                    <Grid
                                                                        direction={'column'}
                                                                        container
                                                                        justify="center"
                                                                        alignItems="flex-end"
                                                                    >
                                                                        <Grid item style={{lineHeight: '20px'}}>
                                                                            {fulfillment.deliveryMethod.name}
                                                                        </Grid>
                                                                        {(fulfillment.deliveryMethod.code == 'OMNIVA_LT' || fulfillment.deliveryMethod.code == 'OMNIVA_LV') ? (
                                                                            <Grid item style={{lineHeight: '20px'}}>
                                                                                {order.omnivaPickupPointName}
                                                                            </Grid>
                                                                        ) : null}
                                                                    </Grid>
                                                                </div>
                                                            </ListSubheader>
                                                            {fulfillment.orderProducts.map((orderProduct) => {
                                                                return (
                                                                    <ListItem key={'o'+orderProduct.productId}>
                                                                        <ListItemAvatar>
                                                                            <Avatar>
                                                                                <ImageIcon />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                <LinkRoute to={getProductPath(orderProduct.productParentId) + '/redagavimas'}>{orderProduct.name}</LinkRoute>
                                                                            }
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    {orderProduct.code}<br/>
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                        <ListItemAmountText>
                                                                            {orderProduct.amount}
                                                                        </ListItemAmountText>
                                                                        <ListItemPriceText>
                                                                            <Currency value={orderProduct.cost}/>
                                                                            <br/>
                                                                            {orderProduct.price > 0 && orderProduct.cost !== orderProduct.price ? (
                                                                                <React.Fragment>
                                                                                    <s><Currency value={orderProduct.price}/></s>
                                                                                </React.Fragment>
                                                                            ) : null}
                                                                        </ListItemPriceText>
                                                                        <ListItemPriceText>
                                                                            <Currency value={orderProduct.total}/>
                                                                        </ListItemPriceText>
                                                                    </ListItem>
                                                                )
                                                            })}
                                                            {fulfillment.emailNotifications.length > 0 ? (
                                                                <React.Fragment>
                                                                    <List
                                                                        dense={true}
                                                                        style={{
                                                                            paddingTop: 20,
                                                                            paddingBottom: 0,
                                                                        }}
                                                                    >
                                                                        <ListSubheader
                                                                            component="div"
                                                                            disableSticky
                                                                            style={{
                                                                                lineHeight: '12px',
                                                                            }}
                                                                        >
                                                                            Išsiųsti laiškai
                                                                        </ListSubheader>
                                                                        {fulfillment.emailNotifications.map((emailNotification, index) => (
                                                                            <ListItem key={index}>
                                                                                <ListItemText
                                                                                    primary={emailNotification.name + ' ' + emailNotification.createdAt}
                                                                                    disableTypography
                                                                                    secondary={
                                                                                        <React.Fragment>

                                                                                        </React.Fragment>
                                                                                    }
                                                                                />
                                                                            </ListItem>
                                                                        ))}

                                                                    </List>
                                                                </React.Fragment>
                                                            ) : null}
                                                            <OmnivaLabels fulfillment={fulfillment} fetchOrder={fetchOrder} />
                                                            <DialogActions style={{
                                                                alignItems: 'flex-end',
                                                            }}>
                                                                <span style={{
                                                                    color: 'rgba(0, 0, 0, 0.54)',
                                                                    fontSize: '0.875rem',
                                                                    fontWeight: 500,
                                                                    paddingLeft: 10,
                                                                }}>Sandėlis: {fulfillment.warehouse.name}</span>
                                                                <FulfillmentSteps
                                                                    order={order}
                                                                    fulfillment={fulfillment}
                                                                    onFulfillmentUpdated={setOrder}
                                                                    fetchOrder={fetchOrder}
                                                                />
                                                            </DialogActions>
                                                        </React.Fragment>
                                                    )}
                                                </List>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper elevation={4}>
                                                <List
                                                    dense={true}
                                                >
                                                    <ListSubheader component="div" style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }} disableSticky>
                                                        {/*Apmokėjimas*/}
                                                        <StatusChipPaymentGroup order={order} />
                                                        {/*{order.paymentStatus.name}*/}
                                                        <span style={{flex: 1}}></span>
                                                        {order.paymentMethod.name}
                                                    </ListSubheader>
                                                </List>
                                                <OrderTotalsTable order={order} />
                                                {order.payments.length > 0 ? (
                                                    <React.Fragment>
                                                        <List
                                                            dense={true}
                                                            style={{
                                                                paddingTop: 20,
                                                                paddingBottom: 0,
                                                            }}
                                                        >
                                                            <ListSubheader
                                                                component="div"
                                                                disableSticky
                                                                style={{
                                                                    lineHeight: '12px',
                                                                }}
                                                            >
                                                                Atlikti mokėjimai
                                                            </ListSubheader>
                                                            {order.payments.map((payment) => (
                                                                <ListItem key={payment.id}>
                                                                    <ListItemText
                                                                        primary={payment.type.name + " " + payment.day}
                                                                        disableTypography
                                                                        secondary={
                                                                            <React.Fragment>
                                                                                <br/>
                                                                                {(() => {
                                                                                    if (payment.type.code === 'TYPE_CASH' && payment.orderReceipt === null) {
                                                                                        return <span>Nėra kvito</span>;
                                                                                    } else if (payment.type.code === 'TYPE_CASH' && payment.orderReceipt !== null && payment.orderReceipt.needsPrinting) {
                                                                                        return <span>Kvitas neperspausdintas {payment.orderReceipt.serie + '' + payment.orderReceipt.number}</span>;
                                                                                    } else if (payment.type.code === 'TYPE_CASH' && payment.orderReceipt !== null && !payment.orderReceipt.needsPrinting) {
                                                                                        return <span>Kvitas {payment.orderReceipt.serie + '' + payment.orderReceipt.number}</span>;
                                                                                    }
                                                                                })()}
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                    <StyledListItemTextRight>
                                                                        <PaymentDelete
                                                                            order={order}
                                                                            payment={payment}
                                                                            onPaymentDeleted={setOrder}
                                                                        />
                                                                    </StyledListItemTextRight>
                                                                    <ListItemPriceText>
                                                                        <Currency value={payment.amount}/>
                                                                    </ListItemPriceText>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </React.Fragment>
                                                ) : null}

                                                {/*Skirtumas: {order.paymentDifference}*/}
                                                {/*<ul>*/}
                                                {/*{order.payments.map((payment) =>*/}
                                                {/*<li key={payment.id}>{payment.type.name} {payment.amount}</li>*/}
                                                {/*)}*/}
                                                {/*</ul>*/}
                                                <PaymentSetToPaid
                                                    order={order}
                                                    onPaymentUpdated={setOrder}
                                                />
                                                {/*{order.paymentStatus.code === 'STATUS_OVERPAID' ?*/}
                                                {/*<React.Fragment>*/}
                                                {/*<AddOrderPaymentRefund*/}
                                                {/*orderId={order.id}*/}
                                                {/*paymentDifference={order.paymentDifference}*/}
                                                {/*onMarkingDone={setOrder}*/}
                                                {/*/>*/}
                                                {/*</React.Fragment>*/}
                                                {/*: null*/}
                                                {/*}*/}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} style={{
                                    padding: theme.spacing(2)
                                }}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Paper theme={theme} elevation={3}>
                                                <List dense={true}>
                                                    <ListSubheader component="div" style={{display: 'flex'}} disableSticky>
                                                        <StatusChipInvoiceGroup order={order} fixedWidth={false} />
                                                        {/*{!fulfillment.fulfilled ? ("Neatliktas") : ("Atliktas")}*/}
                                                        <span style={{flex: 1}}></span>
                                                        {/*<span style={{*/}
                                                        {/*color: 'rgba(0, 0, 0, 0.54)',*/}
                                                        {/*fontSize: '0.875rem',*/}
                                                        {/*fontWeight: 500,*/}
                                                        {/*}}>*/}
                                                        {/*<LinkRoute to={getInvoicePath(order.invoice.id)}>{order.invoice.serie}{order.invoice.number}</LinkRoute>*/}
                                                        {/*</span>*/}
                                                    </ListSubheader>
                                                    {order.invoice !== null ? (
                                                        <>
                                                        <ListSubheader component="div" style={{display: 'flex'}} disableSticky>
                                                            Sąskaita
                                                            <span style={{flex: 1}}></span>
                                                            <span>
                                                                {/*{hasInvoiceViewPermission ? (*/}
                                                                {/*    <LinkRoute to={getInvoicePath(order.invoice.id)}>*/}
                                                                {/*        <span style={{*/}
                                                                {/*            color: 'rgba(0, 0, 0, 0.54)',*/}
                                                                {/*            fontSize: '0.875rem',*/}
                                                                {/*            fontWeight: 500,*/}
                                                                {/*        }}>*/}
                                                                {/*            {order.invoice.serie}{order.invoice.number}*/}
                                                                {/*        </span>*/}
                                                                {/*    </LinkRoute>*/}
                                                                {/*) : (*/}
                                                                    <span style={{
                                                                        color: 'rgba(0, 0, 0, 0.54)',
                                                                        fontSize: '0.875rem',
                                                                        fontWeight: 500,
                                                                    }}>
                                                                        {order.invoice.serie}{order.invoice.number}
                                                                    </span>
                                                                {/*)}*/}
                                                            </span>
                                                        </ListSubheader>
                                                        </>
                                                    ) : null}
                                                    {order.invoice !== null && order.invoice.orderReceipts.map(orderReceipt => (
                                                        <ListSubheader key={orderReceipt.number} component="div" style={{display: 'flex'}} disableSticky>
                                                            Kvitas
                                                            <span style={{flex: 1}}></span>
                                                            <span>
                                                                {/*{hasInvoiceViewPermission ? (*/}
                                                                {/*    <LinkRoute to={getInvoicePath(order.invoice.id)}>*/}
                                                                {/*        <span style={{*/}
                                                                {/*            color: 'rgba(0, 0, 0, 0.54)',*/}
                                                                {/*            fontSize: '0.875rem',*/}
                                                                {/*            fontWeight: 500,*/}
                                                                {/*        }}>*/}
                                                                {/*            {orderReceipt.serie}{orderReceipt.number}*/}
                                                                {/*        </span>*/}
                                                                {/*    </LinkRoute>*/}
                                                                {/*) : (*/}
                                                                    <span style={{
                                                                        color: 'rgba(0, 0, 0, 0.54)',
                                                                        fontSize: '0.875rem',
                                                                        fontWeight: 500,
                                                                    }}>
                                                                        {orderReceipt.serie}{orderReceipt.number}
                                                                    </span>
                                                                {/*)}*/}
                                                            </span>
                                                        </ListSubheader>
                                                    ))}
                                                </List>
                                                <PermissionRequiredComponent permission={permissions.PERMISSION_INVOICES_CREATE}>
                                                    <div style={{padding: '0 16px 16px 16px'}}>
                                                        <div style={{display: 'flex'}}>
                                                            <span style={{flex: 1}} />
                                                            <PrintInvoiceReceiptV2
                                                                order={order}
                                                                onCreated={() => {
                                                                    fetchOrder();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </PermissionRequiredComponent>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper theme={theme} elevation={3}>
                                                <List>
                                                    <ListSubheader component="div" disableSticky style={{display: 'flex'}}>
                                                        Pirkėjas
                                                        <span style={{flex: 1}} />
                                                        {order.customerFirstName + ' ' + order.customerLastName}
                                                    </ListSubheader>
                                                </List>
                                                <PermissionRequiredComponent permission={permissions.PERMISSION_USERS_EDIT}>
                                                    <div style={{paddingBottom: 16}}>
                                                        <CustomerListInfo
                                                            isCompany={order.customerIsCompany}
                                                            email={order.customerEmail}
                                                            phoneNumberCountryCode={order.customerPhoneNumberCountryCode}
                                                            phoneNumber={order.customerPhoneNumber}
                                                            address={order.customerAddress}
                                                            code={order.customerCode}
                                                            pvmCode={order.pvmCode}
                                                        />
                                                    </div>
                                                </PermissionRequiredComponent>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper theme={theme} elevation={3}>
                                                <List>
                                                    <ListSubheader component="div" disableSticky  style={{display: 'flex'}}>
                                                        Pardavėjas
                                                        <span style={{flex: 1}} />
                                                        {order.seller.firstName + ' ' + order.seller.lastName}
                                                    </ListSubheader>
                                                </List>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </FillHeightContainer>
                </React.Fragment>
            )} />
            <Route path={`${match.path}/redagavimas`} render={() => (
                <React.Fragment>
                    <SaleOrderEditPage setOriginalOrder={setOrder} history={history}/>
                </React.Fragment>
            )} />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateOrderAndData: (order) => {
        dispatch(updateOrderAndData(NAME_EDIT_ORDER, order));
    },
});

export default connect(
    null,
    mapDispatchToProps
)(SaleOrderPage);