import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

function ProgressInputIndicator({props}) {
    return (
        <LinearProgress {...props} style={{
            marginTop: -4,
            borderRadius: "0 0 5px 5px",
        }} />
    );
}
export default ProgressInputIndicator;