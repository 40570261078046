import React from 'react';
import {connect} from "react-redux";
import isArray from 'lodash/isArray';
import get from 'lodash/get';

import {MaterialSelectField} from "shared-react/formik/MaterialFields"

import {setWarehouseId} from "shared-react/ducks/shop";

function WarehouseSelect({isDisabled, warehouseId, setWarehouseId, metadata, ...rest}) {
    const userWarehouses = get(metadata, 'userWarehouses', []);

    const warehouseOptions = userWarehouses.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.id,
    }));

    React.useEffect(() => {
        if (
            isArray(userWarehouses) && userWarehouses.length > 0
            &&
            (
                typeof warehouseId === 'undefined' ||
                warehouseId === null ||
                warehouseId === '' ||
                typeof userWarehouses.find((warehouse) => warehouse.id === warehouseId) === 'undefined'
            )
        ) {
            setWarehouseId(userWarehouses[0].id);
        } else if (!isArray(userWarehouses) || userWarehouses.length === 0) {
            setWarehouseId('');
        }
    }, []);

    //console.log('userWarehouses', warehouseId, userWarehouses);
    if (!isArray(userWarehouses) || userWarehouses.length === 0) {
        return null;
    }

    return (
        <MaterialSelectField
            label={'Sandėlis'}
            value={warehouseId}
            options={warehouseOptions}
            onChange={(e) => {setWarehouseId(e.target.value)}}
            disabled={isDisabled}
            margin="dense"
            nomargin={1}
            style={{
                margin: 0,
            }}
            fullWidth
        />
    );
}

const mapStateToProps = (state) => {
    return {
        warehouseId: state.shop.warehouseId,
        //userDefaultWarehouseId: state.auth.defaultWarehouseId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setWarehouseId: (warehouseId) => {
        dispatch(setWarehouseId(warehouseId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WarehouseSelect);