import React, {useCallback, useRef, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone'
import { useFormikContext } from 'formik';

import uuidv4 from 'uuid/v4';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import FancyImage from "shared-react/utils/FancyImage";
import ForcedSquare from "shared-react/utils/ForcedSquare";
import ImageUpload from "./ImageUpload";
import FancyImageContainer from "shared-react/utils/FancyImageContainer";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import {useSortImages} from "shared-react/api/ImageApi";

import {
    ImageEditorDialogProvider,
    useImageEditorDialog,
    openDialog as openImageEditor,
    sortEnded,
    addImagesToUpload,
} from 'shared-react/utils/FormImages/ImageEditorContext';
import ImageEditorDialog from 'shared-react/utils/FormImages/ImageEditorDialog';
import {getImagePath, TYPE_ORIGINAL} from "shared-react/utils/images";
import ResponseErrors from "shared-react/utils/ResponseErrors";

const SortableItem = SortableElement(({imageType, image, imageIndex, enlargeFirstImage = false}) => {
    const [imageEditorState, imageEditorDispatch] = useImageEditorDialog();


    return (
        <div
            style={{
                ...(enlargeFirstImage && imageIndex === 0) && {gridColumn: '1/span 2'},
                ...(enlargeFirstImage && imageIndex === 0) && {gridRow: '1/span 2'},
            }}
            onClick={(e) =>{
                imageEditorDispatch(openImageEditor(imageIndex));
                //console.log('on click', imageIndex);
            }}
        >
            <ForcedSquare>
                <FancyImageContainer borderStyle="solid">
                    <FancyImage src={getImagePath(TYPE_ORIGINAL, image.folder, image.versionedName)} fullWidth={false}/>
                </FancyImageContainer>
            </ForcedSquare>
        </div>
    )
});

const SortableList = SortableContainer(({imageType, maxFiles, open, forceCropping, enlargeFirstImage = false}) => {
    const [imageEditorState, imageEditorDispatch] = useImageEditorDialog();
    const { values } = useFormikContext();

    //console.log('imageEditorState.imagesToUpload', imageEditorState.imagesToUpload);

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7,1fr)',
                gridGap: '.8rem',
            }}
        >
            {imageEditorState.images.map((image, index) => (
                <SortableItem
                    key={'k' + index}
                    imageType={imageType}
                    image={image}
                    index={index}
                    imageIndex={index}
                    enlargeFirstImage={enlargeFirstImage}
                />
            ))}

            {imageEditorState.imagesToUpload.map((imageInfo, index) => (
                <div key={'kk' + index}>
                    <ForcedSquare>
                        <FancyImageContainer borderStyle="solid" background={'none'}>
                            <ImageUpload
                                imageType={imageType}
                                imageInfo={imageInfo}
                                index={index}
                                forceCropping={forceCropping}
                            />
                        </FancyImageContainer>
                    </ForcedSquare>
                </div>
            ))}
            {maxFiles === 0 || imageEditorState.images.length + imageEditorState.imagesToUpload.length < maxFiles ? (
                 <div>
                     <ForcedSquare>
                         <FancyImageContainer borderStyle="dashed">
                             <Button size="small" fullWidth onClick={open} style={{
                                 height: '100%',
                             }}>
                                 Pridėti naują
                             </Button>
                         </FancyImageContainer>
                     </ForcedSquare>
                 </div>
            ) : null}
        </div>
    );
});

function FormImages({imageType, maxFiles = 0, allowedAspectRatios = [], forceCropping = false, onImagesUpdated, enlargeFirstImage = false}) {
    const [state, dispatch] = useImageEditorDialog();
    const isFirstRun = useRef(true);
    const [data, isLoading, errors, updateImagesSort] = useSortImages(imageType, state.images);

    //console.log('FormImages', state, isFirstRun);

    const onDrop = useCallback(acceptedFiles => {
        //console.log('acceptedFiles', state.images.length, acceptedFiles, maxFiles);

        // do nothing if no files
        if (acceptedFiles.length === 0) { return; }

        let newImages = acceptedFiles.map((file) => ({
            file,
        }));

        if (maxFiles !== 0) {
            const newImagesCount = state.images.length + acceptedFiles.length;

            if (newImagesCount > maxFiles) {
                const imagesToTake = maxFiles - state.images.length;

                if (imagesToTake > 0) {
                    newImages = acceptedFiles.slice(0, imagesToTake).map((file) => ({
                        file,
                    }));
                } else {
                    newImages = [];
                }
            }
        }

        if (newImages.length === 0) {
            return;
        }

        dispatch(addImagesToUpload(newImages));

        //setFieldValue("images", newImages);
        //setFieldValue("assignImages", values.assignImages.concat(acceptedFiles));
    }, [state.images.length]);

    const onSortEnd = ({oldIndex, newIndex}) => {
        dispatch(sortEnded(oldIndex, newIndex));
    };

    const { isDragActive, getRootProps, getInputProps, isDragReject, open, acceptedFiles, fileRejections } = useDropzone({
        onDrop,
        accept: 'image/*',
        minSize: 0,
        noClick: true,
        noKeyboard: true,
        maxFiles: maxFiles,
    });

    const rejectionErrors = fileRejections.map(({ file, errors }) => {
        return errors.reduce((accumulator = [], error) => {
            if (error.code === 'too-many-files') {

            }
            accumulator.push('Pasirinkta per daug failų, max kiekis ' + maxFiles);

            return accumulator;
        }, []);
    });

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else {
            //console.log('images effect');
            //updateImagesSort();
            if (typeof onImagesUpdated === 'function') {
                onImagesUpdated(state.images)
            }
        }
    }, [state.images.map(function(elem) {
        return elem.name;
    }).join(",")]);
    //const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
    //console.log('images', state.images);
    //console.log('imagesToUpload', imagesToUpload);

    return (
        <React.Fragment>
            <ImageEditorDialog imageType={imageType} allowedAspectRatios={allowedAspectRatios} />
            <ResponseErrors errors={rejectionErrors} />
            <div {...getRootProps()} style={{position: 'relative',}}>
                <input {...getInputProps()} />
                {/*{!isDragActive && 'Click here or drop a file to upload!'}*/}
                {isDragActive && !isDragReject && (
                    <div style={{
                        position: 'absolute',
                        zIndex: 30,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '1.5rem',
                        border: '.2rem dashed #5c6ac4',
                        borderRadius: 3,
                        textAlign: 'center',
                        color: '#5c6ac4',
                        backgroundColor: '#f4f5fa',
                    }}>
                        <Typography variant="h6" align="center">
                            Įkelkite failus
                        </Typography>
                    </div>
                )}


                {/*{isDragReject && "File type not accepted, sorry!"}*/}
                {state.images.length > 0 || state.imagesToUpload.length > 0 ? (
                    <div style={{padding: '0 0 8px 0'}}>
                        <SortableList
                            imageType={imageType}
                            maxFiles={maxFiles}
                            open={open}
                            axis={'xy'}
                            onSortEnd={onSortEnd}
                            distance={1}
                            forceCropping={forceCropping}
                            enlargeFirstImage={enlargeFirstImage}
                            // hideSortableGhost={false}
                        />
                    </div>
                ) : (
                    <div style={{}}>
                        <Grid container
                              spacing={0}
                              direction="column"
                              justify="center"
                              alignItems="center"
                              style={{
                                  border: '.2rem dashed #5c6ac4',
                                  borderRadius: 3,
                                  // backgroundColor: '#f4f5fa',
                                  minHeight: enlargeFirstImage ? '15.5rem' : '8rem',
                              }}
                              onClick={open}
                        >
                            <Grid item>
                                <CropOriginalIcon fontSize="large"/>
                            </Grid>
                            <Grid item>
                                <Button size="medium">
                                    Pridėti paveikslėlį
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
            {/*{*/}
            {/*    values.uploadImages.length === 0 ?*/}
            {/*        <p>nėra paveikslėlių...</p> :*/}
            {/*        values.uploadImages.map((file, i) => (<Chip key={i} label={file.name} />))*/}
            {/*}*/}
        </React.Fragment>
    );
}

function FormImagesWithProvider({images, ...props}) {
    return (
        <ImageEditorDialogProvider images={images}>
            <FormImages {...props} />
        </ImageEditorDialogProvider>
    );
};

export default FormImagesWithProvider;