import qs from "qs";
import {useMakeRequest, API_URL} from './Api';

const API_PACKAGES = '/packages';

export function useGetPackages(queryParams) {
    const queryString = '?' + qs.stringify(queryParams);

    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', API_URL + API_PACKAGES + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useGetPackage(packageId) {
    const {data, setData, isLoading, errors, doRequest} = useMakeRequest('get', API_URL + API_PACKAGES + '/' + packageId);

    return [data, setData, isLoading, errors, doRequest];
}

export function useSavePackage(packageData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (packageData ? packageData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, API_URL + API_PACKAGES + url, packageData);

    return [ data, isLoading, errors, doRequest ];
}
