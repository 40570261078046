import React, {useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Link} from "react-router-dom";

import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import  ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import LinkWrapper from 'utils/LinkWrapper';
import MenuLinks from 'menu/MenuLinks'
import StyledToolbarHeightDiv from 'utils/StyledToolbarHeightDiv';
import {getSearchPath, getProductsPath} from 'shared-react/utils/routes';
import * as permissions from "shared-react/constants/permissions";
import PermissionRequiredComponent from "shared-react/utils/PermissionRequiredComponent";

import {WEB_SERVER_URL} from 'shared-react/api/Api';

const drawerWidth = 240;

const StyledMenuIcon = styled(MenuIcon)`
  && {
    margin-left: -12px;
    margin-right: 20px;
  }
`;

const StyledAppBar = styled(AppBar)`
  && {
    z-index: ${props => props.theme.zIndex.drawer + 1};
  }
`;

const StyledDesktopDrawer = styled(Drawer)`
  && {
    width: ${drawerWidth}px;
    flex-shrink: 0;
    
    & .paper {
        width: ${drawerWidth}px;
    }
  }
`;

function AppMenu({productsInBasketQuantity}) {
    const theme = useTheme();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    // const routeSearchText = searchText.length > 0 ? '/' + searchText : '';
//console.log('WEB_SERVER_URL', WEB_SERVER_URL);
    return (
        <React.Fragment>
            <StyledAppBar position="fixed" elevation={1} theme={theme}>
            {/*<StyledAppBar position="static" theme={theme}>*/}
                <Toolbar>
                    <Hidden mdUp implementation="css">
                        <IconButton color="inherit" onClick={() => setIsMobileMenuOpen((isOpen) => !isOpen)}>
                            <StyledMenuIcon />
                        </IconButton>
                    </Hidden>
                    <Link to={getProductsPath()}>
                        <img
                            style={{
                                width: '100%',
                                maxWidth: 180,
                                verticalAlign: 'middle',
                            }}
                            src={WEB_SERVER_URL + "/img/catherine-logo-white-large.png"}
                        />
                    </Link>
                    {/*<Typography variant="h4" color="inherit" noWrap style={{marginLeft: 35}}>*/}
                        {/*Produktai*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="h5" color="inherit" style={{marginLeft: 45}}>*/}
                        {/*Produktai*/}
                    {/*</Typography>*/}
                    {/*<Button to={"/paieska" + routeSearchText} color="inherit" component={LinkWrapper}>Produktai</Button>*/}
                    {/*<Button to={getSearchPath()} color="inherit" component={LinkWrapper}>*/}
                        {/*<SearchIcon />*/}
                        {/*Pirkimas*/}
                    {/*</Button>*/}
                    {/*<Badge color="secondary" badgeContent={productsInBasketQuantity}>*/}
                        {/*<Button to={"/krepselis"} color="inherit" component={LinkWrapper}>*/}
                            {/*<ShoppingCartIcon />*/}
                            {/*Krepšelis*/}
                        {/*</Button>*/}
                    {/*</Badge>*/}
                    <span style={{flex: 1}}/>
                    <PermissionRequiredComponent permission={permissions.PERMISSION_PRODUCTS_LIST_VIEW}>
                        <Button to={getProductsPath()} color="inherit" component={LinkWrapper}>
                            <SearchIcon />
                            Prekės
                        </Button>
                    </PermissionRequiredComponent>
                    <PermissionRequiredComponent permission={permissions.PERMISSION_SALE_ORDERS_CREATE}>
                        <Button to={"/krepselis"} color="inherit" component={LinkWrapper}>
                            Krepšelis
                            <Badge color="secondary" badgeContent={productsInBasketQuantity}>
                                <ShoppingCartIcon />
                            </Badge>
                        </Button>
                    </PermissionRequiredComponent>
                </Toolbar>
            </StyledAppBar>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={isMobileMenuOpen}
                    onClose={() => setIsMobileMenuOpen(false)}
                >
                    <MenuLinks />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <StyledDesktopDrawer variant="permanent" classes={{ paper: 'paper' }}>
                    <StyledToolbarHeightDiv theme={theme} />
                    <MenuLinks />
                </StyledDesktopDrawer>
            </Hidden>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    let productsInBasketQuantity = 0;

    state.newOrder.orderData.fulfillments.forEach((fulfillment, index) => {
        fulfillment.orderProducts.forEach((orderProduct, index) => {
            productsInBasketQuantity = productsInBasketQuantity + orderProduct.quantity;
        });
    });

    return {
        productsInBasketQuantity,
        searchText: state.shop.searchText,
    }
};

export default connect(
    mapStateToProps,
    null
)(AppMenu);