import React from 'react';

import { useFormikContext, Formik, Form, Field } from 'formik';

import {FormMaterialTextField} from "shared-react/formik/FormikMaterialFields";

function FormField() {
    const { values } = useFormikContext();

    return (
        <Field
            name="firstName"
            label={values.isCompany === "0" ? 'Vardas' : 'Pavadinimas'}
            margin="dense"
            fullWidth
            component={FormMaterialTextField}
            nomargin={1}
        />
    );
}

export default FormField;